import React from 'react';
import propTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import moment from 'moment';

import Agrupador from '../Agrupador'
import Typography from '../Typography';

import styles from './style';

const NaveCultivos = ({ nave }) => {
  const classes = styles();
  const { tablas = [] } = nave;

  const data = tablas.map(tabla => ([
    {
      label: 'Nombre',
      value: tabla?.nombre || 'N / A',
      title: true,
    },
    {
      label: 'Cultivo',
      value: tabla?.cultivo?.nombre || 'N / A'
    },
    {
      label: 'Tipo Suelo',
      value: nave?.tipoSuelo?.nombre || 'N / A'
    },
    {
      label: 'Variedad',
      value: tabla?.variedad || 'N / A'
    },
    ...(() => {
      const { temporadaCultivo = [{}] } = tabla;
      const [ temporada ] = temporadaCultivo || [{}];
      const { fechaCultivo, fechaCorte, fechaTransplante } = temporada || {};

      return [
        {
          label: 'Semana Transplante',
          value: fechaCultivo ? moment(fechaTransplante).format('WW (GGGG)') : 'N / A'
        },
        {
          label: 'Semana Cultivo',
          value: fechaCultivo ? moment(fechaCultivo).format('WW (GGGG)') : 'N / A'
        },
        {
          label: 'Semana Cosecha',
          value: fechaCorte ? moment(fechaCorte).format('WW (GGGG)') : 'N / A'
        },
      ]
    })(),
  ]));

  return (
    <Agrupador customClass={classes.root}>
      <Typography variant='h6' bold className={classes.labelTitle}>
        CULTIVOS
      </Typography>
      <Box className={classes.tablasBox}>
        {
          data.map((tabla, index) => (
            <Box className={classes.itemContainer} key={`tabla:${index}`}>
              <Typography variant="subtitle2" className={classes.tablaName}>
                {tabla[0].value}
              </Typography>
              <Box component="div" className={classes.itemList}>
                {
                  tabla.filter(({title}) => !title).map((option, index) => (
                    <Box component="div" key={index} className={classes.item}>
                      <Typography variant="subtitle2" className={classes.title}>
                        {option.value || 'N / A'}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.subtitle}>
                        {option.label}
                      </Typography>
                    </Box>
                  ))
                }
                {data.length === 0 && (
                  <h1>No hay información disponible en este momento.</h1>
                )}
              </Box>
            </Box>
          ))
        }
        {
          data.length === 0 && (
            <Typography variant="subtitle1">
              No hay información disponible en este momento.
            </Typography>
          )
        }
      </Box>
    </Agrupador>
  );
}

NaveCultivos.propTypes = {
  /** Objeto con la información de la nave */
  nave: propTypes.object,
};

export default React.memo(NaveCultivos);
