import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { USUARIOS } from '../../configuraciones/endpoints';

const cabeceros = [
  { label: 'Usuario', key: 'nombreCorto' },
  { label: 'Empleado', key: 'nombre' },
  { label: 'Código', key: 'codigoEmpleado' },
  { label: 'Tipo perfil', transform: ({ perfil }) => perfil?.tipoPerfil?.nombre },
  { label: 'Digito verificador', key: 'digitoVerificador' },
  { label: 'Estatus', transform: ({ estatus }) => <Estatus activo={estatus} /> },
];

const Usuarios = ({ history, location }) => {

  return <Listados
    titulo="Usuarios"
    placeHolder="Búsqueda por nombre"
    history={history}
    location={location}
    showActions
    cabeceros={cabeceros || []}
    apiKey={USUARIOS}
  />;
};

export default Usuarios;