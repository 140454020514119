/* eslint-disable no-fallthrough */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chip } from '@material-ui/core';
import queryString from 'query-string';
import moment from 'moment';

import CabeceroReportes from '../../componentes/CabeceroReportes';
import Default from '../../contenedores/Default';
import ListaConfiguracion from '../../componentes/ListaConfiguracion';
import Tabla from '../../componentes/Table';

import { setRegistros } from '../../ducks/listados';

import endpoints, {
	FENOLOGIAS,
	INVERNADEROS,
	NAVES,
	SITIOS,
	TABLAS,
} from '../../configuraciones/endpoints';
import axios from '../../configuraciones/axios';
import general from '../../configuraciones/general';
import paleta from '../../configuraciones/paleta';

import { CamposValidados, getWeeks } from '../../utilidades/functions';
import { DatePickerTypes } from '../../constantes/datePickerTypes';

const estados = {
	Vegetativo: paleta.configuraciones.vegetativo,
	Generativo: paleta.configuraciones.generativo,
	Balanceado: paleta.configuraciones.balanceado,
};

const cabeceros = [
	{ label: 'Sitio', key: 'sitio', align: 'left' },
	{ label: 'Invernadero', key: 'invernadero', align: 'left' },
	{ label: 'Nave', key: 'nave', align: 'left' },
	{ label: 'Tabla', key: 'tabla', align: 'left' },
	{ label: 'Planta', key: 'planta', align: 'left' },
	{ label: 'Cultivo', key: 'cultivo', align: 'left' },
	{ label: 'Fenología', key: 'fenologia', align: 'left' },
	{ label: 'Registro', key: 'registro', align: 'left' },
	{
		label: 'Estado',
		align: 'center',
		transform: (data) => {
			if (!data) return null;
			return (
				<Chip
					style={{
						backgroundColor: estados[data.estado] || 'transparent',
						color: estados[data.estado] ? '#FFFF' : '#000',
						width: 100,
						height: 25,
						fontWeight: '800',
					}}
					label={data.estado?.toString().toUpperCase()}
					size='small'
				/>
			);
		},
	},
];

const datosLista = {
	labelProp: 'nombre',
	headerLista: 'Nombre',
	title: 'Reporte de Fenologías',
	data: [
		{
			id: 0,
			nombre: 'Registros por promedio',
			subTitle: 'Listado de fenologías promediadas.',
		},
		{
			id: 1,
			nombre: 'Registros por planta',
			subTitle: 'Listado de capturas por planta.',
		},
	],
};

const dataInicial = {
	sitios: [],
	invernaderos: [],
	naves: [],
	tablas: [],
	plantas: [],
	fenologias: [],
};

const ReportesFenologiasPlantas = ({ history, location }) => {
	const dispatch = useDispatch();
	const { registros } = useSelector((store) => store.listados);
	const isMounted = useRef(false);
	const paginaActual = useRef(1);
	const [data, setData] = useState({
		...dataInicial,
		semanas: getWeeks(),
	});

	const [formData, setFormData] = useState(dataInicial);

	const onSubMenuClick = (id) => {
		if (id === 1) {
			history.push(`/reportes/fenologiasplanta`);
		} else {
			history.push(`/reportes/fenologiaspromedio`);
		}
	};

	const consultarDatosIniciales = async () => {
		try {
			const promesas = [
				axios.get(endpoints.base.busqueda(SITIOS)),
				axios.get(endpoints.base.busqueda(INVERNADEROS)),
				axios.get(endpoints.base.busqueda(NAVES)),
				axios.get(endpoints.base.busqueda(TABLAS)),
				axios.get(endpoints.base.busqueda(FENOLOGIAS)),
			];
			const [sitiosBD, inverneaderosBD, navesBD, tablasDB, fenologiasDB] =
				await Promise.all(promesas);
			const plantas = tablasDB.map((tabla) => tabla.plantas).flat();
			fenologiasDB.unshift({
				id: 0,
				nombre: 'Todas',
			});

			plantas.unshift({
				id: 0,
				nombre: 'Todas',
			});

			setData((current) => ({
				...current,
				sitios: sitiosBD,
				invernaderos: inverneaderosBD,
				naves: navesBD,
				tablas: tablasDB,
				fenologias: fenologiasDB,
				plantas,
			}));
		} catch {}
	};

	const handleRemoverDependencia = (name, nuevaData) => {
		switch (name) {
			case 'sitios':
				const invernaderosDisponibles = data.invernaderos
					.filter((invernadero) =>
						nuevaData.sitios.includes(invernadero.sitioID),
					)
					.map(({ id }) => id);
				nuevaData.invernaderos = nuevaData.invernaderos.filter((invernadero) =>
					invernaderosDisponibles.includes(invernadero),
				);
			case 'invernaderos':
				const navesEnInveradero = data.naves
					.filter((nave) => nuevaData.invernaderos.includes(nave.invernaderoID))
					.map(({ id }) => id);
				nuevaData.naves = nuevaData.naves.filter((nave) =>
					navesEnInveradero.includes(nave),
				);
			case 'naves':
				const tablasEnNaves = data.tablas
					.filter((tabla) => nuevaData.naves.includes(tabla.nave?.id))
					.map(({ id }) => id);
				nuevaData.tablas = nuevaData.tablas.filter((tabla) =>
					tablasEnNaves.includes(tabla),
				);
			case 'tablas':
				const plantasTabla = data.plantas
					.filter((planta) => nuevaData.tablas.includes(planta?.id))
					.map(({ id }) => id);

				nuevaData.plantas = nuevaData.plantas.filter((planta) =>
					plantasTabla.includes(planta),
				);
			default:
				break;
		}
	};

	const onChange = ({ target: { name, value } }) => {
		const nuevaData = {
			...formData,
		};
		nuevaData[name] = value;
		handleRemoverDependencia(name, nuevaData);
		setFormData((currentData) => ({ ...currentData, ...nuevaData }));
		paginaActual.current = 1;
	};

	const onDelete = (id, name) => {
		let nuevaData = {
			...formData,
			[name]:
				id === -1
					? []
					: formData[name].filter((busquedaID) => busquedaID !== id),
		};
		handleRemoverDependencia(name, nuevaData);
		setFormData({ ...nuevaData });
		paginaActual.current = 1;
	};

	const handleTodosSelected = useCallback(() => {
		const fenologias = formData.fenologias.includes(0)
			? data.fenologias
					.filter((fenologia) => fenologia.id !== 0)
					.map((fenologia) => fenologia.id)
			: formData.fenologias;

		const plantas = formData.plantas.includes(0)
			? data.plantas
					.filter(({ tablaID, id }) => formData.tablas.includes(tablaID))
					.map((planta) => planta.id)
			: formData.plantas;

		const params = {
			naves: formData.naves,
			plantas,
			fenologias,
			fecha: moment(formData.fecha).format('W-GGGG'),
		};
		return params;
	}, [formData, data]);

	const ValidarData = useCallback(() => CamposValidados(formData), [formData]);

	const buscar = async () => {
		try {
			if (ValidarData()) return;
			const params = handleTodosSelected();

			const resp = await axios.post(endpoints.reporteFenologiasPlantas(), {
				...params,
				registrosPorPagina: general.ELEMENTOS_POR_PAGINA,
				pagina: paginaActual.current,
			});
			dispatch(setRegistros({ rows: resp.rows, count: resp.count }));
		} catch {}
  };

	useEffect(() => {
		if (isMounted.current) {
			const query = queryString.parse(location.search);
			if (query.pagina === paginaActual || !query.pagina) return;
			paginaActual.current = query.pagina;
			buscar();
		}
	}, [location]);

	useEffect(() => {
		isMounted.current = true;
		consultarDatosIniciales();

		return () => {
			isMounted.current = false;
			paginaActual.current = 1;
			dispatch(setRegistros({ rows: [], count: 0 }));
		};
	}, [dispatch]);

	return (
		<Default
			mostrarLista={
				<ListaConfiguracion
					datosLista={datosLista}
					onClick={onSubMenuClick}
					initOpcion={1}
				/>
			}
		>
			<CabeceroReportes
				data={data}
				formData={formData}
				buscar={buscar}
				onChange={onChange}
				onDelete={onDelete}
				handleTodosSelected={handleTodosSelected}
				selects={{
					sitio: { width: 3, show: true },
					invernadero: { width: 3, show: true },
					nave: { width: 3, show: true },
					tabla: { width: 3, show: true },
					fenologia: { width: 3, show: true },
					planta: { width: 2, show: true },
					fecha: { width: 3, show: true, type: DatePickerTypes.week },
				}}
				endpoint={endpoints.reporteFenologiasPlantas()}
				method='POST'
			/>
			<Tabla
				headers={cabeceros}
				rows={registros.rows.length > 0 ? registros.rows : []}
				count={registros.count}
				showActions={false}
			/>
		</Default>
	);
};

export default ReportesFenologiasPlantas;
