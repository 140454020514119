import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { toast } from 'react-toastify';
import RightDrawer from '../RightDrawer';
import HeaderSubcatalogo from '../HeaderSubcatalogo';
import Tabla from '../Table';
import TextField from '../TextField';
import Select from '../Select';
import { findPropertysEmpty } from '../../utilidades/functions';
import estatus from '../../constantes/estatus';
import { NOMBRE_SECCION_REPETIDO } from '../../configuraciones/mensajes';
import { NUMBER_REGEX } from '../../utilidades/regex';
import styles from './styles';

const NaveSecciones = ({ mostrar, guardar, cancelar, secciones, naveID }) => {
  const classes = styles();
  const cabeceros = [
    {
      label: 'Código',
      key: 'id'
    },
    {
      label: 'Nombre',
      transform: ({ nombre, repetido }, index) => (
        <TextField
          name="nombre"
          onChange={(e) => manejadorSeccion(e, index)}
          value={nombre}
          required
          error={(formErrors.nombre && (!nombre || nombre === '')) || repetido}
          inputProps={{ maxLength: 5, regexonchange: 'true', regex: NUMBER_REGEX }}
          className={classes.input}
        />
      ),
    },
    {
      label: 'Estatus',
      transform: (s, index) => (
        <Select
          required
          options={estatus}
          onChange={(e) => manejadorSeccion(e, index)}
          name="estatus"
          value={s.estatus}
          error={formErrors.estatus && !s.hasOwnProperty('estatus')}
          style={{minWidth: 125}}
        />
      )
    },
  ];
  const [formErrors, setFormErrors] = useState({});
  const [nuevasSecciones, setNuevasSecciones] = useState([]);

  const validarNombresRepetidos = () => {
    let todosValidos = true;
    const edicionSecciones = [...nuevasSecciones];
    let index = 0;
    for (const s of edicionSecciones) {
      const indexExiste = nuevasSecciones.findIndex(({ nombre, activo }) => nombre?.toString().trim() === s.nombre?.toString().trim() && activo && s.activo);
      s.repetido = indexExiste >= 0 && indexExiste !== index;
      if (todosValidos && s.repetido) todosValidos = false;
      index++;
    }
    setNuevasSecciones(edicionSecciones);
    return todosValidos;
  };

  const agregar = () => {
    const seccion = {
      activo: true,
      nombre: nuevasSecciones.filter(({ activo }) => activo).length + 1,
      estatus: true,
    };
    if (naveID) seccion.naveID = naveID;
    setNuevasSecciones((current) => [...current, seccion]);
  };

  const eliminar = (row) => {
    if (row.id) {
      manejadorSeccion({ target: { name: 'activo', value: false } }, row.index);
    } else {
      const edicionSecciones = [...nuevasSecciones];
      const seccionesActivas = edicionSecciones.filter(({ activo }) => activo);
      const indexEliminar = edicionSecciones.indexOf(seccionesActivas[row.index]);
      edicionSecciones.splice(indexEliminar, 1);
      setNuevasSecciones(edicionSecciones);
    }
  }

  const manejadorSeccion = (e, index) => {
    const edicionSecciones = [...nuevasSecciones];
    edicionSecciones.filter(({ activo }) => activo)[index][e.target.name] = e.target.value;
    setNuevasSecciones(edicionSecciones);
  };

  const customGuardar = () => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmSecciones'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;
    const validos = validarNombresRepetidos();
    if (!validos) {
      return toast.warning(NOMBRE_SECCION_REPETIDO);
    }
    guardar(nuevasSecciones);
  };

  useEffect(() => {
    setNuevasSecciones([...secciones])
  }, [secciones, mostrar]);

  return (
    <RightDrawer
      paperClassName={classes.paperDrawer}
      isOpen={mostrar}
      header={<HeaderSubcatalogo
        titulo="Secciones"
        subtitulo="Configuración"
        guardar={customGuardar}
        cerrar={cancelar}
        agregar={agregar}
        textField={{
          label: 'Cantidad de secciones',
          disabled: true,
          name: 'txtCantidadSecciones',
          value: nuevasSecciones.filter(({ activo }) => activo).length || '0'
        }}
      />}>
      <div id="frmSecciones">
        <Tabla
          headers={cabeceros}
          rows={nuevasSecciones.filter(({ activo }) => activo)}
          hidePaginator
          onDelete={eliminar}
        />
      </div>
    </RightDrawer>
  );
};

NaveSecciones.propTypes = {
  mostrar: propTypes.bool.isRequired,
  guardar: propTypes.func.isRequired,
  cancelar: propTypes.func.isRequired,
  secciones: propTypes.array.isRequired,
  naveID: propTypes.oneOfType([propTypes.string, propTypes.number]),
};

NaveSecciones.defaultProps = {
  naveID: null,
};

export default NaveSecciones;
