import React, { useCallback, useEffect, useState, useRef } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

import CalendarioSemanal from '../CalendarioSemanal';
import Typography from '../Typography';

import { setParametros } from '../../ducks/tablero';
import endpoints, { CAPTURA_IRRIGACIONES } from '../../configuraciones/endpoints';
import axios from '../../configuraciones/axios';
import paleta from '../../configuraciones/paleta';

import { obtenerRangoFechas } from '../../utilidades/functions';

import styles from './styles';

const StyledTableCell = withStyles(() => ({
	head: {
		backgroundColor: paleta.sidebar.active,
		fontSize: 12,
	},
	body: {
		fontSize: 12,
	},
}))(TableCell);

const SemanasRiego = ({ naveID }) => {
	const classes = styles();
	const dispatch = useDispatch();
	const { legend, parametros, temporada, sitioID, semana } = useSelector(store => store.tablero);
	const isMounted = useRef(false);

	const { matutinos, vespertinos } = parametros;
	
	const [periodos, setPeriodos] = useState([]);
	const [semanaIrrigacion, setSemanaIrrigacion] = useState(semana);
	const [fecha, setFecha] = useState();
	const [parametrosIrrigacion, setParametrosIrrigacion] = useState({});
	const [headerTable, setHeaderTable] = useState([]);
	const [irrigaciones, setIrrigaciones] = useState([]);
	const [irrigacionSemanal, setIrrigacionSemanal] = useState([]);
	const [propiedadesRegistros, setPropiedadesRegistros] = useState([]);

	const promediar = useCallback((irrigacionID, capturas) => {
		const capturasIrrigacion = capturas.filter(
			(captura) => captura.irrigacionID === irrigacionID,
		);
		if (capturasIrrigacion.length === 0) return '--';
		const totalCapturas = capturasIrrigacion.map(({ valor }) => valor);
		const promedio =
			capturasIrrigacion
				.map(({ valor }) => parseFloat(valor))
				.reduce((a, b) => (parseFloat(a) || 0) + (parseFloat(b) || ''), 0) /
			totalCapturas.length;
		return promedio.toFixed(2);
	}, []);

	const consultarDatosIniciales = useCallback(async () => {
		try {
			if (!sitioID) return;
			const promesas = [
				axios(endpoints.obtenerIrrigacionesTipoSuelo(naveID)),
				axios(endpoints.obtenerIrrigacionesSemanales(naveID), {
					params: { sitioID },
				}),
			];
			const [irrigacionesDb, irrigacionSemanalDb] = await Promise.all(promesas);
			setHeaderTable(mapearCabeceros(irrigacionesDb || []));
			setIrrigacionSemanal(irrigacionSemanalDb);
		} catch	{ }
	}, [naveID, sitioID]);

	const mapearCabeceros = (header) => {
		let propiedades = [];
		let nuevosHeaders = [
			...new Set(header.map(({ agrupador }) => agrupador?.id).filter((v) => v)),
		];
		nuevosHeaders = nuevosHeaders.map((nuevoHeader) => {
			const parametro = header.find(
				({ agrupador }) => agrupador?.id === nuevoHeader,
			);
			const irrigaciones = header.filter(
				({ agrupadorID }) => nuevoHeader === agrupadorID,
			);
			propiedades = [...propiedades, ...irrigaciones.map(({ id }) => id)];
			return {
				...parametro.agrupador,
				irrigaciones,
				agrupador: true,
				longitud: irrigaciones.length,
			};
		});

		let headersParametros = [
			...new Set(header.filter((v) => !v.agrupador).map((v) => v.id)),
		];
		propiedades = [...propiedades, ...headersParametros];
		headersParametros = headersParametros.map((v) => {
			const irrigacion = header.find(({ id }) => id === v);
			return {
				id: irrigacion.id,
				nombre: irrigacion.nombre,
				irrigaciones: [
					{
						id: irrigacion.id,
						nombre: irrigacion.nombre,
					},
				],
				agrupador: false,
				longitud: 0,
			};
		});
		nuevosHeaders = [
			{ nombre: 'Periodo', longitud: 0 },
			...nuevosHeaders,
			...headersParametros,
		];
		setPropiedadesRegistros(propiedades);
		return nuevosHeaders;
	};

	const obtenerParametros = useCallback(() => {
		if (!sitioID) return;
		const { data, agrupador } = parametrosIrrigacion;
		const semanas = obtenerRangoFechas(temporada.fechaInicio, temporada.fechaFin, 'W-YYYY', true);
		let matutinos = [];
		let vespertinos = [];
		let dataSets = [];
		let agrupadores = [];
		let parametros = [];

		if (agrupador) {
			agrupadores = irrigacionSemanal?.filter(
				({ agrupador }) => agrupador === data?.agrupador?.nombre,
			);
			parametros = headerTable?.filter(
				({ nombre }) => nombre === data?.agrupador?.nombre,
			);
		} else {
			agrupadores = irrigacionSemanal?.filter(
				({ parametro }) => parametro === data?.nombre,
			);
			parametros = headerTable?.filter(({ nombre }) => nombre === data?.nombre);
		}

		if (fecha) {
			agrupadores = irrigacionSemanal?.filter((el) => {
				return moment(el.fecha).format('YYYY-MM-DD') === fecha;
			});
		}
		semanas?.forEach((semana) => {
			parametros[0]?.irrigaciones.forEach(({ nombre }) => {
				const totalMatutinos = agrupadores
					.filter(
						(captura) =>
							captura.parametro === nombre &&
							captura.estadoHorario === 'Matutino' &&
							captura.semana === semana,
					)
					.map(({ valor }) => valor);
				const totalVespertinos = agrupadores
					.filter(
						(captura) =>
							captura.parametro === nombre &&
							captura.estadoHorario === 'Vespertino' &&
							captura.semana === semana,
					)
					.map(({ valor }) => valor);
				const capturasMatutinas =
					agrupadores
						.filter(
							(captura) =>
								captura.parametro === nombre &&
								captura.estadoHorario === 'Matutino' &&
								captura.semana === semana,
						)
						.map(({ valor }) => parseFloat(valor))
						.reduce((a, b) => (parseFloat(a) || 0) + (parseFloat(b) || ''), 0) /
					totalMatutinos.length;
				const capturasVespertinas =
					agrupadores
						.filter(
							(captura) =>
								captura.parametro === nombre &&
								captura.estadoHorario === 'Vespertino' &&
								captura.semana === semana,
						)
						.map(({ valor }) => parseFloat(valor))
						.reduce((a, b) => (parseFloat(a) || 0) + (parseFloat(b) || ''), 0) /
					totalVespertinos.length;

				dataSets.push({
					capturasMatutinas: capturasMatutinas.toFixed(2),
					capturasVespertinas: capturasVespertinas.toFixed(2),
					nombre,
					semana,
				});
			});
		});
		parametros[0]?.irrigaciones.forEach(({ nombre }) => {
			let matutino = [];
			let vespertino = [];
			const capturas = dataSets.filter((captura) => captura.nombre === nombre);
			capturas.forEach((captura) => {
				if (captura.capturasMatutinas) {
					matutino.push(
						captura.capturasMatutinas === 'NaN' ? 0 : captura.capturasMatutinas,
					);
				}
				if (captura.capturasVespertinas) {
					vespertino.push(
						captura.capturasVespertinas === 'NaN'
							? 0
							: captura.capturasVespertinas,
					);
				}
			});
			const dataSetsMatutino = {
				label: nombre,
				data: matutino,
				hidden: nombre === data?.nombre ? false : true,
				pointStyle: 'circle',
				borderWidth: 2,
				pointRadius: 3,
				pointHoverRadius: 5,
			};
			const dataSetsVespertino = {
				label: nombre,
				data: vespertino,
				hidden: nombre === data?.nombre ? false : true,
				pointStyle: 'circle',
				borderWidth: 2,
				pointRadius: 3,
				pointHoverRadius: 5,
			};
			matutinos.push(dataSetsMatutino);
			vespertinos.push(dataSetsVespertino);
		});
		dispatch(
			setParametros({
				matutinos,
				vespertinos,
			}),
		);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parametrosIrrigacion, fecha]);

	const obtenerSemana = useCallback((semana) => {
		setSemanaIrrigacion(semana);
		setFecha(null);
	}, []);

	const obtenerFecha = useCallback((fecha) => {
		setFecha(fecha);
	}, []);

	useEffect(() => {
		isMounted.current = true;
		consultarDatosIniciales();
		return () => {
			isMounted.current = false;
		};
	}, [consultarDatosIniciales]);

	useEffect(() => {
		const { id } = legend;
		const parametros = {
			matutinos: matutinos.map((elemento, index) => {
				return {
					...elemento,
					hidden: index === id ? !elemento.hidden : elemento.hidden,
				};
			}),
			vespertinos: vespertinos.map((elemento, index) => ({
				...elemento,
				hidden: index === id ? !elemento.hidden : elemento.hidden,
			})),
		};
		dispatch(setParametros(parametros));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [legend]);

	useEffect(() => {
		let periodosMapeados = [
			...new Set([...irrigaciones.map(({ periodo }) => periodo)]),
		];
		periodosMapeados = periodosMapeados.map((p) => ({
			periodo: p,
			capturas: irrigaciones.filter(({ periodo }) => periodo === p),
		}));
		setPeriodos(periodosMapeados);
	}, [irrigaciones]);

	useEffect(() => {
		if (parametrosIrrigacion) {
			obtenerParametros();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [obtenerParametros]);

	useEffect(() => {
		if ( semana ) setSemanaIrrigacion(semana);
	}, [semana]);

	useEffect(() => {
		if ( !sitioID ) return;

		const semanaActual = moment(semanaIrrigacion, 'W (YYYY)');

		axios(endpoints.base.url(CAPTURA_IRRIGACIONES, naveID), {
			params: {
				sitioID,
				fecha: fecha
					? fecha
					: semanaActual.startOf('isoWeek').format('YYYY-MM-DD'),
				soloDia: fecha ? 1 : 0,
			},
		})
			.then((irrigacionesCapturadaDB) => {
				setIrrigaciones(irrigacionesCapturadaDB);
			});
	}, [naveID, sitioID, fecha, semanaIrrigacion]);

	return (
		<>
			<Paper className={classes.calendario}>
				<Grid container className={classes.root}>
					<Grid item xs={12}>
            <CalendarioSemanal
              className={classes.calendarioItem}
							onSelectDia={obtenerFecha}
							onChangeSemana={obtenerSemana}
							semana={semanaIrrigacion}
							temporada={{
								fechaInicio: temporada ? temporada.fechaInicio : '',
								fechaFin: temporada ? temporada.fechaFin : '',
              }}
						/>
					</Grid>
				</Grid>
			</Paper>
			<Paper className={classes.tabla}>
				<TableContainer component={Paper}>
					<Table
						className={classes.table}
						size="small"
						aria-label="a dense table"
					>
						<TableHead>
							<TableRow>
								{headerTable.map((option, index) => {
									if (index === 0) {
										return (
											<StyledTableCell
												rowSpan={2}
												className={classes.header}
												key={index}
											>
												<Typography
													className={classes.title}
													aling="center"
													display="block"
												>
													{option.nombre}
												</Typography>
											</StyledTableCell>
										);
									}
									if (option.longitud === 0 || undefined) {
										return (
											<StyledTableCell
												rowSpan={2}
												className={classes.header}
												key={index}
												onClick={() => {
													setParametrosIrrigacion({
														data: option,
														agrupador: false,
													});
												}}
												style={{ cursor: 'pointer' }}
											>
												<Typography
													className={classes.title}
													aling="center"
													display="block"
												>
													{option.nombre}
												</Typography>
											</StyledTableCell>
										);
									} else {
										return (
											<StyledTableCell
												colSpan={option.longitud}
												className={classes.header}
												key={index}
											>
												<Typography
													className={classes.title}
													aling="center"
													display="block"
												>
													{option.nombre}
												</Typography>
											</StyledTableCell>
										);
									}
								})}
							</TableRow>
							<TableRow>
								{headerTable.map(
									(group) =>
										group.longitud > 0 &&
										group.irrigaciones?.map((option, index) => (
											<StyledTableCell
												align="center"
												className={classes.header}
												key={index}
												onClick={() => {
													setParametrosIrrigacion({
														data: option,
														agrupador: true,
													});
												}}
												style={{ cursor: 'pointer' }}
											>
												{option.nombre}
											</StyledTableCell>
										)),
								)}
							</TableRow>
						</TableHead>
						<TableBody>
							{periodos.map((row, index) => (
								<TableRow key={index}>
									<StyledTableCell align="center">
										{row.periodo}
									</StyledTableCell>
									{propiedadesRegistros.map((irrigacionID, index) => (
										<StyledTableCell align="center" key={index}>
											{promediar(irrigacionID, row.capturas, row.periodo)}
										</StyledTableCell>
									))}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</>
	);
};

SemanasRiego.propTypes = {
	/** Identificador de la Nave */
	naveID: propTypes.string.isRequired,
};

export default React.memo(SemanasRiego, (prevProps, nextProps) => {
	return prevProps.naveID === nextProps.naveID;
});
