import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Grid, Hidden } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import TextField from '../../componentes/TextField';
import Button from '../../componentes/Button';
import Footer from '../../componentes/Footer';
import { setUser, STORAGE } from '../../ducks/user';
import { findPropertysEmpty } from '../../utilidades/functions';
import styles from './styles';
import endpoints from '../../configuraciones/endpoints';
import axios from '../../configuraciones/axios';
import md5 from 'md5';

const Login = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const [usuario, setUsuario] = useState({ nombreCorto: '', password: '', mantenerSesion: false });
  const [formErrors, setFormErrors] = useState({});

  const handleCheckboxChecked = (e) => {
    let usuarioEstado = usuario;
    usuarioEstado.mantenerSesion = e.target.checked;
    setUsuario(usuarioEstado);
  }

  const btnLogin = (e) => {
    e.preventDefault();
    const { errors, totalErrors } = findPropertysEmpty(document.getElementById('card'), true);

    setFormErrors(errors);

    if (totalErrors === 0) {
      iniciarSesion();
    }
  }

  const iniciarSesion = (e) => {
    let usuarioLogin = {
      nombreCorto: usuario.nombreCorto,
      contrasena: md5(usuario.password),
    };

    axios.post(endpoints.iniciarSesion(), usuarioLogin).then((sesion) => {
      if (usuario.mantenerSesion)
        localStorage.setItem(STORAGE, JSON.stringify(sesion));
      else
        sessionStorage.setItem(STORAGE, JSON.stringify(sesion));

      dispatch(setUser(sesion.usuario));
    });
  }

  return (
    <Grid container className={classes.main}>
      <Grid item xs={12}>
        <Grid container className={classes.body}>
          <Hidden smDown>
            <Grid item md={7} className={classes.contenedorTitulo}>
              <Grid container alignItems='baseline'>
                <img src='/assets/images/hoja-color.png' alt='icono' width='50' height='50' />
              </Grid>
              <Grid container alignItems='baseline'>
                <h1>SCOUTER&nbsp;</h1>
                <h2>ADMON</h2>
              </Grid>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={4}>
            <form noValidate onSubmit={btnLogin}>
              <Grid container spacing={1} className={classes.card} id="card">
                <Grid item xs={12}>
                  <h2>INICIO DE SESIÓN</h2>
                  <p>Ingrese sus credenciales para identificarse</p>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent='center'>
                    <img src='/assets/images/logoSINfondo.png' alt='icono' className={classes.logoDelCampo} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='nombreCorto'
                    placeHolder="Usuario"
                    value={usuario.nombreCorto}
                    onChange={setUsuario}
                    isHandleChange
                    required
                    error={formErrors.nombreCorto}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='password'
                    placeHolder="Contraseña"
                    type="password"
                    value={usuario.password}
                    onChange={setUsuario}
                    isHandleChange
                    required
                    error={formErrors.password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel control={
                    <Checkbox
                      name='MantenerSesion'
                      value={usuario.mantenerSesion}
                      onChange={handleCheckboxChecked} />
                  }
                    label="Recordar mis credenciales" />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    name="iniciar"
                    label="Iniciar"
                    classesCustom={{ boton: classes.botonInicio }}
                    isSubmit
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid >
  );
};

export default Login;
