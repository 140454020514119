import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Chip } from '@material-ui/core';
import moment from 'moment';

import CabeceroReportes from '../../componentes/CabeceroReportes';
import Default from '../../contenedores/Default';
import ListaConfiguracion from '../../componentes/ListaConfiguracion';
import Tabla from '../../componentes/Table';

import { useFetchReporte } from '../../hooks/useFetchReporte';

import endpoints, { FENOLOGIAS } from '../../configuraciones/endpoints';
import { DatePickerTypes } from '../../constantes/datePickerTypes';

import paleta from '../../configuraciones/paleta';

const estados = {
	Vegetativo: paleta.configuraciones.vegetativo,
	Generativo: paleta.configuraciones.generativo,
	Balanceado: paleta.configuraciones.balanceado,
};

const cabeceros = [
	{ label: 'Sitio', key: 'sitio', align: 'left' },
	{ label: 'Invernadero', key: 'invernadero', align: 'left' },
	{ label: 'Nave', key: 'nave', align: 'left' },
	{ label: 'Cultivo', key: 'cultivo', align: 'left' },
	{ label: 'Fenología', key: 'fenologia', align: 'left' },
	{ label: 'Promedio', key: 'promedio', align: 'left' },
	{
		label: 'Estado',
		align: 'center',
		transform: (data) => {
			if (!data) return null;
			return (
				<Chip
					style={{
						backgroundColor: estados[data.estado] || 'transparent',
						color: estados[data.estado] ? '#FFFF' : '#000',
						width: 100,
						height: 25,
						fontWeight: '800',
					}}
					label={data?.estado?.toString().toUpperCase()}
					size='small'
				/>
			);
		},
	},
];

const datosLista = {
	labelProp: 'nombre',
	headerLista: 'Nombre',
	title: 'Reporte de Fenologías',
	data: [
		{
			id: 0,
			nombre: 'Registros por promedio',
			subTitle: 'Listado de fenologías promediadas.',
		},
		{
			id: 1,
			nombre: 'Registros por planta',
			subTitle: 'Listado de capturas por planta.',
		},
	],
};

const ReportesFenologiasPromedio = ({ history }) => {
	const { registros } = useSelector((store) => store.listados);
	const { data, formData, buscar, onChange, onDelete } = useFetchReporte(
		{ fenologias: endpoints.base.busqueda(FENOLOGIAS) },
		endpoints.reporteFenologias(),
	);
  const { fenologias = [] } = data;

	const handleTodosSelected = useCallback(() => {
		const { sitios, naves, fecha } = formData;
		const fenologiasData = formData.fenologias.includes(0)
			? fenologias
					.filter((fenologia) => fenologia.id !== 0)
					.map((fenologia) => fenologia.id)
			: formData.fenologias;
		const params = {
			sitios,
			naves,
			fecha: moment(fecha).format('W-GGGG'),
			fenologias: fenologiasData,
		};
		return params;
	}, [formData, fenologias]);

	const onSubMenuClick = (id) => {
		if (id === 1) {
			history.push(`/reportes/fenologiasplanta`);
		} else {
			history.push(`/reportes/fenologiaspromedio`);
		}
	};

	return (
		<Default
			mostrarLista={
				<ListaConfiguracion
					datosLista={datosLista}
					onClick={onSubMenuClick}
					initOpcion={0}
				/>
			}
		>
			<CabeceroReportes
				data={data}
				formData={formData}
				buscar={buscar}
				onChange={onChange}
				onDelete={onDelete}
				handleTodosSelected={handleTodosSelected}
				selects={{
					sitio: { width: 4, show: true },
					invernadero: { width: 4, show: true },
					nave: { width: 4, show: true },
					fenologia: { width: 4, show: true },
					fecha: { width: 4, show: true, type: DatePickerTypes.week },
				}}
				endpoint={endpoints.reporteFenologias()}
				method='POST'
			/>
			<Tabla
				headers={cabeceros}
				rows={registros.rows.length > 0 ? registros.rows : []}
				count={registros.count}
				showActions={false}
			/>
		</Default>
	);
};

export default ReportesFenologiasPromedio;
