import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { ACTIVIDADES } from '../../configuraciones/endpoints';

const cabeceros = [
  { label: 'Código', key: 'id' },
  { label: 'Nombre', key: 'nombre' },
  { label: 'Opción Menú', transform: ({ modulo }) => modulo?.descripcion },
  { label: 'Estatus', transform: ({ estatus }) => <Estatus activo={estatus} /> },
];

const Actividades = ({ history, location }) => {

  return <Listados
    titulo="Actividades"
    placeHolder="Búsqueda por nombre"
    history={history}
    location={location}
    showActions
    cabeceros={cabeceros || []}
    apiKey={ACTIVIDADES}
  />;
};

export default Actividades;