import React from 'react';
import propTypes from 'prop-types';
import {
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
} from '@material-ui/core';
import { IoImages } from 'react-icons/io5';

import Typography from '../Typography';

import styles from './styles';

const ListaImagenes = ({ data }) => {
  const classes = styles();

  return (
    <>
      {data.length > 0 ? (
        <ImageList rowHeight={200} gap={1} className={classes.imageList}>
          {data?.map((item) => (
            <ImageListItem
              key={item.path}
              cols={item.featured ? 2 : 1}
              rows={item.featured ? 2 : 1}
            >
              <img src={item.path} alt={item.title} />
              <ImageListItemBar
                className={classes.titleBar}
                title={item.title}
                position='top'
                actionPosition='left'
              />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <Paper className={classes.paper}>
          <Grid
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
          >
            <Grid item>
              <IoImages size={55} className={classes.icon} />
            </Grid>
            <Grid item>
              <Typography
                variant='subtitle2'
                display='block'
                aling='center'
                className={classes.empty}
              >
                Sin Imágenes
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

ListaImagenes.propTypes = {
  /** Datos de las imagenes */
  data: propTypes.array.isRequired,
};

export default ListaImagenes;
