import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  root: {
    padding: 5,
    gap: 10,
    justifyContent: 'flex-start',
    marginBlockEnd: 20,
    opacity: 0,
    animation: '$fadeInUp 500ms ease-in-out 500ms forwards',
  },
  '@keyframes fadeInUp': {
    '0%': {
      opacity: 0,
      transform: 'translateY(20px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
}));

export default styles;
