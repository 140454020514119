import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback,
} from 'react';
import propTypes from 'prop-types';
import Geocode from 'react-geocode';
import clsx from 'clsx';
import { GoogleMap, Polygon, Marker, useJsApiLoader, OverlayView } from '@react-google-maps/api';
import { BiTrashAlt } from 'react-icons/bi';
import { toast } from 'react-toastify';

import Button from '../Button';
import BugMarker from '../Marker';
import TextField from '../TextField';
import LabelMapa from '../LabelMapa';

import general from '../../configuraciones/general';
import styles from './styles';

Geocode.setApiKey(general.MAPS_API_KEY);
Geocode.setRegion('mx');
Geocode.setLanguage('es');

const libraries = ['drawing'];

const Mapa = forwardRef(({
  containerStyle,
  defaultCenter, zoom, mapType, setRef,
  onClick, isHandleOnClick,
  poligonos, drawPolygonOptions, poligonoInicial, esPoligono,
  ocultarBusqueda, ocultarEliminar,
  motivoBloqueo, bloquearClick,
  marcador, marcadores, iconoMarcador, onChangeMarcador, otrosMarcadores,
  customMarcador, customMarcadorClick, customEliminar, onChangePoligono,
  ubicarMiPosicion, ComponenteAcciones
}, ref) => {
  const [map, setMap] = useState(null);
  const libRef = useRef(libraries);
  const [drawPath, setDrawPath] = useState([]);
  const [center, setCenter] = useState(defaultCenter || null);
  const [txtBusqueda, setTxtBusqueda] = useState('');
  const classes = styles();
  useImperativeHandle(ref, () => {
    return {
      clearPreviousState: handleRemovePaths
    }
  })

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: general.MAPS_API_KEY,
    libraries: libRef.current,
  })

  const customOnClick = ({ latLng: { lat, lng } }) => {
    if (bloquearClick) {
      motivoBloqueo && toast.error(motivoBloqueo);
      return;
    }
    if (onClick && isHandleOnClick) onClick();
    if (esPoligono) {
      setDrawPath([...drawPath, { lat: lat(), lng: lng() }]);
      onChangePoligono([...drawPath, { lat: lat(), lng: lng() }]);
    }
    else onChangeMarcador({ lat: lat(), lng: lng() });
  };

  const manejadorKeyDown = (e) => {
    if (e.key === 'Enter') {
      Geocode.fromAddress(txtBusqueda).then(
        (response) => {
          if (response.results.length > 0) {
            const { lat, lng } = response.results[0].geometry.location;
            setCenter({ lat, lng });
          }
        },
        (error) => {
          console.error('error al obtener geolocalización', error);
        }
      );
    }
  };

  const handleRemovePaths = useCallback(() => {
    setDrawPath([]);
    customEliminar && customEliminar();
  }, [customEliminar]);

  const onLoad = useCallback((map) => { setMap(map) }, []);

  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const onEditPolygon = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setDrawPath(nextPath);
      onChangePoligono(nextPath);
    }
  }, [onChangePoligono]);

  const onLoadPolygon = useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEditPolygon),
        path.addListener("insert_at", onEditPolygon),
        path.addListener("remove_at", onEditPolygon)
      );
    },
    [onEditPolygon]
  );

  const onUnmountPolygon = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
  }, []);

  useEffect(() => {
    if (map && poligonos.length > 0) {
      const [{ path }] = poligonos;
      const bounds = new window.google.maps.LatLngBounds();
      path.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
      map.fitBounds(bounds, 0);
    }
  }, [map, poligonos])

  useEffect(() => {
    setDrawPath(poligonoInicial);
  }, [poligonoInicial]);

  useEffect(() => {
    if (ubicarMiPosicion) {
      navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
        setCenter({ lat: latitude, lng: longitude });
      });
    }
  }, [ubicarMiPosicion]);

  if ( loadError ) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return (
    <div className={classes.contenedorMapa}>
      {
        (isLoaded)
        ?
        <>
          {!ocultarEliminar && <Button
            name='eliminar'
            icono={<BiTrashAlt color='#666666' size={22} />}
            classesCustom={{ boton: classes.btnDelete }}
            onClick={handleRemovePaths}
          />}
          {!ocultarBusqueda && <TextField
            placeHolder='Buscar por dirección'
            name="txtDireccion"
            value={txtBusqueda}
            className={classes.txtDireccion}
            onChange={({ target: { value } }) => setTxtBusqueda(value)}
            onKeyDown={manejadorKeyDown}
          />}
          { ComponenteAcciones }
          <GoogleMap
            mapContainerClassName={clsx(classes.mapa, containerStyle)}
            center={defaultCenter || center}
            mapTypeId={mapType}
            onClick={customOnClick}
            onLoad={onLoad}
            zoom={zoom || 18}
          >
            {poligonos.map((polygon, index) =>
              <Polygon
                key={index}
                path={polygon.path}
                options={polygon.options}
                onClick={polygon.options.clickable ? customOnClick : null}
              />
            )}

            {esPoligono && <Polygon
              ref={setRef}
              editable={!bloquearClick}
              draggable={!bloquearClick}
              visible={drawPath.length > 0}
              path={drawPath || []}
              options={drawPolygonOptions}
              onMouseUp={onEditPolygon}
              onDragEnd={onEditPolygon}
              onLoad={onLoadPolygon}
              onUnmount={onUnmountPolygon}
            />}

            {
              !customMarcador
              ?
              <>
                {!esPoligono && marcador?.lat && marcador?.lng &&
                  <Marker
                    position={marcador}
                    icon={iconoMarcador || {}}
                  />
                }
                {marcadores.map((m, index) => (
                  <Marker
                    key={index}
                    position={{ lat: m.lat, lng: m.lng }}
                    icon={m.icon || {}}
                  />
                ))}
                {otrosMarcadores.map(({ id, lat, lng, icon }) => (
                  <Marker
                    key={id}
                    position={{ lat, lng }}
                    icon={icon || {}}
                  />
                ))}
              </>
              :
              <>
                {marcadores.naves.map((nave) => (
                  <OverlayView
                    key={nave.id}
                    position={{ lat: nave.lat, lng: nave.lng }}
                    mapPaneName={"overlayMouseTarget"}
                    getPixelPositionOffset={(width, height) => ({
                      x: -50,
                      y: -15
                    })}
                  >
                    <LabelMapa
                      texto={nave.nombre}
                      estado={nave.estado}
                      parametro={nave.parametro}
                      extraInfo={nave.extraInfo}
                      tooltipTitle={nave.nombreDeteccion}
                      mostrarTooltip={nave.mostrarTooltip}
                      tipoDeteccion={nave.tipoDeteccion}
                      detalles={nave.detalles}
                      onClick={() => customMarcadorClick(nave)}
                    />
                  </OverlayView>
                ))}
                {marcadores.otros.map(({id, lat, lng, informacion}) => (
                  <OverlayView
                    key={id}
                    position={{ lat, lng }}
                    mapPaneName="overlayMouseTarget"
                    getPixelPositionOffset={(width, height) => ({
                      x: -(width / 2),
                      y: -(height / 2)
                    })}
                  >
                    <BugMarker
                      mostrarTooltip={informacion.mostrarTooltip}
                      titulo={informacion.titulo}
                      imagen={informacion.imagen}
                      detalles={informacion.detalles}
                      icono={informacion.icono}
                    />
                  </OverlayView>
                ))}
              </>
            }
          </GoogleMap>
        </>
        :
        <div className={classes.loadingMap}></div>
      }
    </div>
  )
});

Mapa.propTypes = {
  containerStyle: propTypes.string,
  defaultCenter: propTypes.shape({
    lat: propTypes.number,
    lng: propTypes.number,
  }),
  zoom: propTypes.number,
  mapType: propTypes.string,
  onClick: propTypes.func,
  poligonos: propTypes.arrayOf(propTypes.shape({
    path: propTypes.arrayOf(propTypes.shape({
      lat: propTypes.number,
      lng: propTypes.number,
    })),
    options: propTypes.shape({
      strokeColor: propTypes.string,
      strokeOpacity: propTypes.number,
      strokeWeight: propTypes.number,
      fillColor: propTypes.string,
      fillOpacity: propTypes.number,
    }),
  })),
  drawPolygonOptions: propTypes.shape({
    strokeColor: propTypes.string,
    strokeOpacity: propTypes.number,
    strokeWeight: propTypes.number,
    fillColor: propTypes.string,
    fillOpacity: propTypes.number,
  }),
  setRef: propTypes.func,
  poligonoInicial: propTypes.array,
  esPoligono: propTypes.bool,
  marcadores: propTypes.oneOfType([propTypes.object, propTypes.array]),
  iconoMarcador: propTypes.object,
  ocultarBusqueda: propTypes.bool,
  bloquearClick: propTypes.bool,
  marcador: propTypes.shape({
    lat: propTypes.number,
    lng: propTypes.number,
  }),
  onChangeMarcador: propTypes.func,
  ocultarEliminar: propTypes.bool,
  customMarcador: propTypes.bool,
  customMarcadorClick: propTypes.func,
  otrosMarcadores: propTypes.array,
  onChangePoligono: propTypes.func,
  ubicarMiPosicion: propTypes.bool,
  ComponenteAcciones: propTypes.node,
}

Mapa.defaultProps = {
  containerStyle: '',
  defaultCenter: null,
  zoom: 15,
  mapType: 'satellite',
  poligonos: [],
  drawPolygonOptions: {
    strokeColor: 'red',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: 'red',
    fillOpacity: 0.35,
  },
  poligonoInicial: [],
  esPoligono: true,
  marcadores: [],
  iconoMarcador: null,
  ocultarBusqueda: false,
  bloquearClick: false,
  marcador: {},
  onChangeMarcador: null,
  ocultarEliminar: false,
  customMarcador: false,
  customMarcadorClick: () => {},
  otrosMarcadores: [],
  onChangePoligono: () => {},
  ubicarMiPosicion: true,
  ComponenteAcciones: null,
}

export default React.memo(Mapa);