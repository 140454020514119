/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chip } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { BsImageFill } from 'react-icons/bs';
import queryString from 'query-string';
import moment from 'moment';

import Default from '../../contenedores/Default';

import CabeceroReportes from '../../componentes/CabeceroReportes';
import ListaConfiguracion from '../../componentes/ListaConfiguracion';
import ListaImagenes from '../../componentes/ListaImagenes';
import Modal from '../../componentes/Modal';
import Tabla from '../../componentes/Table';

import { setRegistros } from '../../ducks/listados';

import { CamposValidados } from '../../utilidades/functions';

import endpoints, {
	CAPTURA_SANEOS,
	INVERNADEROS,
	SANEOS,
	SITIOS,
	SURCOS,
	TABLAS,
} from '../../configuraciones/endpoints';
import general from '../../configuraciones/general';
import axios from '../../configuraciones/axios';

import { DatePickerTypes } from '../../constantes/datePickerTypes';

import styles from './styles';

const datosLista = {
	labelProp: 'nombre',
	headerLista: 'Nombre',
	title: 'Reporte de Saneos Realizados',
	data: [
		{
			id: 1,
			nombre: 'Saneos',
			subTitle: 'Listado por plagas',
		},
	],
};

const dataInicial = {
	sitios: [],
	naves: [],
	tablas: [],
	surcos: [],
  saneos: [],
};

const ReporteSaneos = ({ history }) => {
	const classes = styles();
	const [open, setOpen] = useState(false);
	const { registros } = useSelector((store) => store.listados);
	const [imagenes, setImagenes] = useState([]);
	const [params, setParams] = useState({});
	const dispatch = useDispatch();
	const location = useLocation();
	const isMounted = useRef(false);
	const paginaActual = useRef(1);

	const [data, setData] = useState({ ...dataInicial });

	const [formData, setFormData] = useState({ ...dataInicial });

	const consultarDatosIniciales = () =>
		new Promise((resolve, reject) => {
			const promesas = [
				axios.get(endpoints.base.busqueda(SITIOS)),
				axios.get(endpoints.base.busqueda(INVERNADEROS)),
				axios.get(endpoints.base.busqueda(TABLAS)),
				axios.get(endpoints.base.busqueda(SURCOS)),
				axios.get(endpoints.base.busqueda(SANEOS)),
			];
			Promise.all(promesas)
				.then((resultadoPromesas) => {
					const [sitiosBD, inverneaderosBD, tablasBD, surcosBD, saneosBD] =
						resultadoPromesas;
					const invernaderosMapeados = [];
					for (const invernaderoPadre of inverneaderosBD) {
						for (const nave of invernaderoPadre.naves) {
							const nav = {
								id: nave.id,
								invernaderoID: nave.invernaderoID,
								sitioID: invernaderoPadre.sitioID,
								nombre: invernaderoPadre.nombre + ' - ' + nave.nombre,
							};
							invernaderosMapeados.push(nav);
						}
					}

					const surcosTabla = surcosBD.map((surco) => {
						const surcos = {
							id: surco.id,
							nombre: `${surco.tabla?.nombre} - ${surco.nombre}`,
							tablaID: surco.tabla?.id
						};
						return surcos;
					});

					surcosTabla.unshift({
						id: 0,
						nombre: 'Todos',
					});

					saneosBD.unshift({
						id: 0,
						nombre: 'Todas',
					});

					setData({
						sitios: sitiosBD,
						naves: invernaderosMapeados,
						tablas: tablasBD,
						surcos: surcosTabla,
						saneos: saneosBD,
					});
					resolve();
				})
				.catch(reject);
		});

	const onChange = ({ target: { name, value } }) => {
		let nuevaData = {
			...formData,
		};
		switch (name) {
			default:
				nuevaData = { ...formData, [name]: value };
				switch (name) {
					case 'sitios':
						const invernaderiosConNaves = data.naves
							.filter((invernadero) =>
								nuevaData.sitios.includes(invernadero.sitioID),
							)
							.map(({ id }) => id);
						nuevaData.naves = nuevaData.naves.filter((invernaderosNave) =>
							invernaderiosConNaves.includes(invernaderosNave),
						);
						break;

					case 'invernaderosNave':
						const tablasNave = data.tablas
							.filter((tabla) => nuevaData.naves.includes(tabla.naveID))
							.map(({ id }) => id);
						nuevaData.tablas = nuevaData.tablas.filter((tablas) =>
							tablasNave.includes(tablas),
						);
						break;

					case 'tablas':
						const surcosTabla = data.surcos
							.filter((surco) => nuevaData.tablas.includes(surco.tablaID))
							.map(({ id }) => id);
						nuevaData.surcos = nuevaData.surcos.filter((surcos) =>
							surcosTabla.includes(surcos),
						);
						break;
					default:
						break;
				}
				break;
		}

		setFormData((currentData) => ({ ...currentData, ...nuevaData }));
		paginaActual.current = 1;
	};

	const onDelete = (id, name) => {
		let nuevaData = {
			...formData,
			[name]: formData[name].filter((busquedaID) => busquedaID !== id),
		};
		const invernaderiosConNaves = data.naves
			.filter((invernadero) => nuevaData.sitios.includes(invernadero.sitioID))
			.map(({ id }) => id);
		nuevaData.naves = nuevaData.naves.filter((invernaderosNave) =>
			invernaderiosConNaves.includes(invernaderosNave),
		);
		const tablasNave = data.tablas
			.filter((tabla) => nuevaData.naves.includes(tabla.naveID))
			.map(({ id }) => id);
		nuevaData.tablas = nuevaData.tablas.filter((tablas) =>
			tablasNave.includes(tablas),
		);
		const surcosTabla = data.surcos
			.filter((surco) => nuevaData.tablas.includes(surco.tablaID))
			.map(({ id }) => id);
		nuevaData.surcos = nuevaData.surcos.filter((surcos) =>
			surcosTabla.includes(surcos),
		);

		setFormData((currentData) => ({
			...nuevaData,
		}));

		paginaActual.current = 1;
	};

	const handleTodosSelected = useCallback(() => {
		const { naves: invernaderosNave, tablas, fecha } = formData;
		const surcos = formData.surcos.includes(0)
			? data.surcos.filter((surco) => surco.id !== 0).map((surco) => surco.id)
			: formData.surcos;

		const saneos = formData.saneos.includes(0)
			? data.saneos.filter((saneo) => saneo.id !== 0).map((saneo) => saneo.id)
			: formData.saneos;
		const params = {
			fecha: moment(fecha).format('W-GGGG'),
			naves: invernaderosNave,
			surcos,
			tablas,
			saneos,
		};

		return params;
	}, [formData, data]);

	const ValidarData = useCallback(() => CamposValidados(formData), [formData]);

	const buscar = async () => {
		try {
			if (ValidarData()) return;
			const params = handleTodosSelected();
			const response = await axios.post(endpoints.reporteSaneos(), {
				...params,
				registrosPorPagina: general.ELEMENTOS_POR_PAGINA,
				pagina: paginaActual.current,
			});

			dispatch(setRegistros({ rows: response.rows, count: response.count }));
		} catch {}
	};

	const handleClickOpen = (params) => {
		setParams(params);
		setOpen(true);
  };

	const handleClose = () => {
		setOpen(false);
  };
  
  const onSubMenuClick = (id) => {
		if (id === 1) {
			history.push(`/reportes/saneos`);
		}
	};

	useEffect(() => {
		isMounted.current = true;
		consultarDatosIniciales();

		return () => {
			isMounted.current = false;
			paginaActual.current = 1;
			dispatch(setRegistros({ rows: [], count: 0 }));
		};
	}, []);

	useEffect(() => {
		let itemImagenes = [];
		if (Object.keys(params).length > 0) {
			axios
				.get(endpoints.base.url(`${CAPTURA_SANEOS}/imagenes`), {
					params,
				})
				.then((plantasImagenesBD) => {
					itemImagenes = plantasImagenesBD.map((element, index) => {
						return {
							...element,
							title: moment(element.fechaRegistro).format('LLLL'),
							author: '@scoutter',
							featured: true,
						};
					});

					setImagenes(itemImagenes);
				});
		}
	}, [params]);

	useEffect(() => {
		if (isMounted.current) {
			const query = queryString.parse(location.search);
			if (query.pagina === paginaActual || !query.pagina) return;
			paginaActual.current = query.pagina;
			buscar();
		}
	}, [location]);

	const cabeceros = [
		{ label: 'SITIO', key: 'sitio', align: 'left' },
		{ label: 'INVERNADERO', key: 'invernadero', align: 'left' },
		{ label: 'NAVE', key: 'nave', align: 'left' },
		{ label: 'TABLA', key: 'tabla', align: 'left' },
		{ label: 'SURCO', key: 'surco', align: 'left' },
		{ label: 'SANEO', key: 'saneo', align: 'left' },
		{ label: 'CANTIDAD', key: 'cantidad', align: 'left' },
		{
			label: 'EVIDENCIA',
			key: '',
			align: 'center',
			transform: (data) => {
				if (!data) return null;
				return (
					<Chip
						icon={<BsImageFill color='#FFFF' />}
						className={classes.imagenView}
						label={`Ver`}
						size='small'
						onClick={() => {
							handleClickOpen({
								naveID: data.naveID,
                surcoID: data.surcoID,
                fecha: data.fecha,
							});
						}}
					/>
				);
			},
		},
	];

	return (
		<Default
			mostrarLista={
				<ListaConfiguracion
					datosLista={datosLista}
					onClick={onSubMenuClick}
					initOpcion={0}
				/>
			}
		>
			<CabeceroReportes
				data={data}
				formData={formData}
				buscar={buscar}
				onChange={onChange}
				onDelete={onDelete}
				handleTodosSelected={handleTodosSelected}
				selects={{
					sitio: { width: 4, show: true },
					invernaderoNave: { width: 4, show: true },
					tabla: { width: 4, show: true },
					surco: { width: 2, show: true },
					saneo: { width: 3, show: true },
					fecha: { width: 3, show: true, type: DatePickerTypes.week },
				}}
				endpoint={endpoints.reporteSaneos()}
				method='POST'
			/>
			<Tabla
				headers={cabeceros}
				rows={registros.rows.length > 0 ? registros.rows : []}
				count={registros.count}
				showActions={false}
			/>
			<Modal open={open} handleClose={handleClose} titulo='Imágenes de Saneos'>
				<ListaImagenes data={imagenes} />
			</Modal>
		</Default>
	);
};

export default ReporteSaneos;
