import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  cardContainer: {
    backgroundColor: paleta.textField.backgroundColor,
  },
  title: {
    fontSize: '12px',
    fontWeight: '800',
    letterSpacing: '0.3px',
  },
}));

export default styles;