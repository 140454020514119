import Button from '@material-ui/core/Button';
import MuiMenu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import { alpha, makeStyles, styled } from '@material-ui/core/styles';

import Typography from '../Typography';

import paleta from '../../configuraciones/paleta';

export const Boton = styled(Button)({
  padding: 0,
  height: 20,
  borderRadius: 5,
  color: paleta.bar.primary,
  transition: 'transform 100ms',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& > .MuiButton-label': {
    fontWeight: 'bold',
    fontSize: 12,
    '& > .MuiButton-startIcon': {
      marginRight: 2,
      marginTop: -2,
    }
  }
});

export const Titulo = styled(Typography)({
  fontSize: 15,
  fontWeight: 'bold',
  color: paleta.textField.textBlack,
  margin: 0,
});

export const Menu = styled((props) => (
  <MuiMenu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    getContentAnchorEl={null}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export const MenuItem = styled(MuiMenuItem)({
  display: 'flex',
  gridGap: 5,
  alignItems: 'center',
});

const styles = makeStyles(() => ({
  botonOpciones: {
    color: paleta.bar.primary,
    fontWeight: 'bold',
    marginTop: 4,
  },
  animacion: {
    animation: '$fadeInMove 500ms ease-in-out',
  },
  '@keyframes fadeInMove': {
    '0%': {
      opacity: 0,
      transform: 'translateY(20px) scale(0.95)',
    },
    '50%': {
      opacity: 0.5,
      transform: 'translateY(10px) scale(1.02)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0) scale(1)',
    },
  },
}));

export default styles;
