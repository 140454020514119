import React from 'react';
import { Backdrop } from '@material-ui/core';
import { BeatLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import paleta from '../../configuraciones/paleta';
import styles from './styles';
import clsx from 'clsx';

const Spinner = () => {
  const classes = styles();
  const ajax = useSelector(({ ajax }) => ajax);
  return (
    <Backdrop
      open={ajax > 0}
      transitionDuration={400}
      className={clsx(classes.root)}
    >
      <BeatLoader loading={ajax > 0} color={paleta.bar.loading} size={30} />
    </Backdrop>
  );
};

export default Spinner;
