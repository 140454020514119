import React from 'react';
import propTypes from 'prop-types';
import HeaderTablero from '../../componentes/HeaderTablero';

const Macro = ({children}) => {

  return (
    <div>
      <HeaderTablero />
      <div >
        <main>
          {children}
        </main>
      </div>
    </div>
  );
};

Macro.propTypes = {
  children: propTypes.node,
};

export default React.memo(Macro);
