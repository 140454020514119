import { addWeeks, isAfter, isBefore, isValid, parseISO, startOfDay } from 'date-fns';
import { batch } from 'react-redux';
import { toast } from 'react-toastify';

import axios from '../configuraciones/axios';
import endpoints, { SITIOS, TEMPORADAS } from '../configuraciones/endpoints';
import { FORM_TEMPORADA as mensajes } from '../configuraciones/mensajes';

import estatus from '../constantes/estatus';
import {
  desnormalizarTemporada,
  estaPoligonoDentroDeOtro,
  formatearCoordenadas,
  losPoligonosIntersectan,
} from '../utilidades/functions';

export const enumPasos = {
  SITIOS: 0,
  INVERNADEROS: 1,
  NAVES: 2,
  BASE: 3,
};

const plantillaTemporada = {
  id: '',
  nombre: '',
  estatus: estatus[0].id,
  fechaInicio: new Date(),
  fechaFin: addWeeks(new Date(), 1),
  sitioId: '',
};

let abortController = new AbortController();
const types = {
  precargarFormulario: 'precargarFormulario',

  formLimpiarErrores: 'form/limpiarErrores',
  formRestablecer: 'form/restablecer',
  formSetErrores: 'form/setErrores',
  formSetBase: 'form/setBase',

  dataCargarSitios: 'data/cargarSitios',

  formSetSitios: 'form/sitios/setSitios',
  formEliminarSitio: 'form/sitios/eliminarSitio',
  formActualizarSitioOrigen: 'form/sitios/actualizarSitioOrigen',
  formSetSitiosInvernaderos: 'form/sitios/setSitiosInvernaderos',

  formSetInvernaderos: 'form/invernaderos/setInvernaderos',

  formSetNaves: 'form/naves/setNaves',
  formSeleccionarNave: 'form/naves/seleccionarNave',
  formSeleccionarTodasNaves: 'form/naves/seleccionarTodasNaves',

  formDivisorSetDisabled: 'formDivisor/setDisabled',
  formDivisorAgregarDivision: 'formDivisor/agregarDivision',
  formDivisorActualizarDivision: 'formDivisor/actualizarDivision',
  formDivisorSetDivisionEnEdicion: 'formDivisor/setDivisionEnEdicion',
  formDivisorEliminarDivision: 'formDivisor/eliminarDivision',
  formDivisorActualizarCoordenadasDivision: 'formDivisor/actualizarCoordenadasDivision',
  formDivisorAgregarCoordenadaDivision: 'formDivisor/agregarCoordenadaDivision',
  formDivisorCargarDivisiones: 'formDivisor/cargarDivisiones',

  formUnificacionCargar: 'formUnificacion/cargar',
  formUnificacionSeleccionarInvernadero: 'formUnificacion/seleccionarInvernadero',
  formUnificacionSeleccionarTodosInvernadero: 'formUnificacion/seleccionarTodosInvernadero',
  formUnificacionSetDisabled: 'formUnificacion/setDisabled',
  formUnificacionActualizarCoordenadas: 'formUnificacion/actualizarCoordenadas',

  uiSetAyudaTemporadas: 'ui/setAyudaTemporadas',
  uiRegresarPasos: 'ui/regresarPasos',
  uiSiguientePaso: 'ui/siguientePaso',
};

const initState = {
  // ID de la temporada
  id: null,
  // Datos de consulta
  data: {
    temporadas: {
      byId: {},
      allIds: [],
    },
    sitios: {
      byId: {},
      allIds: [],
    },
    invernaderos: {
      byId: {},
      allIds: [],
    },
    naves: {
      byId: {},
      allIds: [],
    },
  },
  // Datos del formulario
  form: {
    [enumPasos.SITIOS]: {
      id: enumPasos.SITIOS,
      data: {
        byId: {},
        allIds: [],
        sitioOrigenId: '',
      },
      errores: {},
    },
    [enumPasos.INVERNADEROS]: {
      id: enumPasos.INVERNADEROS,
      data: {
        byId: {},
        allIds: [],
      },
      errores: {},
    },
    [enumPasos.NAVES]: {
      id: enumPasos.NAVES,
      data: {
        byId: {},
        allIds: [],
      },
      errores: {},
    },
    [enumPasos.BASE]: {
      id: enumPasos.BASE,
      data: {
        byId: {},
        allIds: [],
      },
      errores: {},
    },
  },
  // Propedades de componentes externos
  ui: {
    mostrarAyudaTemporadas: false,
    stepper: {
      pasoEnCurso: enumPasos.SITIOS,
      pasoAnterior: null,
      pasos: [
        { id: enumPasos.SITIOS, nombre: 'Configuración de sitio' },
        { id: enumPasos.INVERNADEROS, nombre: 'Configuración de invernaderos' },
        { id: enumPasos.NAVES, nombre: 'Configuración de naves' },
        { id: enumPasos.BASE, nombre: 'Configuración de temporada' },
      ],
    },
  },
  // Formulario de división
  formDivisor: {
    disabled: true,
    byId: {},
    allIds: [],
    editingItemId: null,
  },
  // Formulario de unificación
  formUnificacion: {
    disabled: true,
    byId: {},
    allIds: [],
    editingItemId: null,
  },
  // Plantilla de temporada
  plantillaId: null,
};

const reducer = (state = initState, { type, payload, meta }) => {
  switch (type) {
    case types.precargarFormulario: {
      const { id, plantillaId = null, temporada, sitios, invernaderos, naves } = payload;
      return {
        ...state,
        id,
        plantillaId: id ? null : plantillaId,
        form: {
          ...state.form,
          [enumPasos.BASE]: {
            ...state.form[enumPasos.BASE],
            data: {
              ...state.form[enumPasos.BASE].data,
              ...temporada
            }
          },
          [enumPasos.SITIOS]: {
            ...state.form[enumPasos.SITIOS],
            data: {
              ...state.form[enumPasos.SITIOS].data,
              ...sitios,
            }
          },
          [enumPasos.INVERNADEROS]: {
            ...state.form[enumPasos.INVERNADEROS],
            data: {
              ...state.form[enumPasos.INVERNADEROS].data,
              ...invernaderos,
            }
          },
          [enumPasos.NAVES]: {
            ...state.form[enumPasos.NAVES],
            data: {
              ...state.form[enumPasos.NAVES].data,
              ...naves,
            }
          },
        },
      };
    }

    /** Manejo de formularios */
    case types.formSetBase: {
      const id = meta.id;
      const nuevaTemporada = {
        ...state.form[enumPasos.BASE].data.byId[id],
        ...payload,
      };

      return {
        ...state,
        form: {
          ...state.form,
          [enumPasos.BASE]: {
            ...state.form[enumPasos.BASE],
            data: {
              ...state.form[enumPasos.BASE].data,
              byId: {
                ...state.form[enumPasos.BASE].data.byId,
                [id]: nuevaTemporada,
              }
            }
          }
        }
      };
    }
    case types.formSetErrores: {
      const { pasoEnCurso, errores } = payload;
      return {
        ...state,
        form: {
          ...state.form,
          [pasoEnCurso]: {
            ...state.form[pasoEnCurso],
            errores
          }
        }
      };
    }
    case types.formLimpiarErrores: {
      const pasoEnCurso = state.ui.stepper.pasoEnCurso;
      return {
        ...state,
        form: {
          ...state.form,
          [pasoEnCurso]: {
            ...state.form[pasoEnCurso],
            errores: {}
          }
        }
      };
    }
    case types.formRestablecer: {
      return { ...initState }
    }

    /** Manejo de datos */
    case types.dataCargarSitios: {
      const { sitios, invernaderos, naves } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          sitios: {
            byId: sitios,
            allIds: Object.keys(sitios)
          },
          invernaderos: {
            byId: invernaderos,
            allIds: Object.keys(invernaderos)
          },
          naves: {
            byId: naves,
            allIds: Object.keys(naves)
          }
        }
      };
    }

    /** Manejo de UI */
    case types.uiSiguientePaso: {
      const { pasoEnCurso } = state.ui.stepper;
      return {
        ...state,
        form: {
          ...state.form,
          [pasoEnCurso]: {
            ...state.form[pasoEnCurso],
            errores: {}
          }
        },
        ui: {
          ...state.ui,
          stepper: {
            ...state.ui.stepper,
            pasoEnCurso: state.ui.stepper.pasoEnCurso + 1
          },
        }
      };
    }
    case types.uiRegresarPasos: {
      return {
        ...state,
        ui: {
          ...state.ui,
          stepper: {
            ...state.ui.stepper,
            pasoEnCurso: state.ui.stepper.pasoEnCurso - 1
          },
        }
      };
    }
    case types.uiSetAyudaTemporadas: {
      return {
        ...state,
        ui: {
          ...state.ui,
          mostrarAyudaTemporadas: payload
        }
      };
    }

    /** Manejo de Form Divisor */
    case types.formDivisorSetDisabled: {
      return {
        ...state,
        formDivisor: {
          ...state.formDivisor,
          disabled: payload,
          editingItemId: null,
        }
      };
    }
    case types.formDivisorAgregarDivision: {
      return {
        ...state,
        formDivisor: {
          ...state.formDivisor,
          editingItemId: payload.id,
          allIds: [...state.formDivisor.allIds, payload.id],
          byId: {
            ...state.formDivisor.byId,
            [payload.id]: payload
          },
        }
      };
    }
    case types.formDivisorActualizarDivision: {
      return {
        ...state,
        formDivisor: {
          ...state.formDivisor,
          byId: {
            ...state.formDivisor.byId,
            [payload.id]: {
              ...state.formDivisor.byId[payload.id],
              ...payload
            }
          }
        }
      };
    }
    case types.formDivisorSetDivisionEnEdicion: {
      const currentEditing = state.formDivisor.editingItemId;
      return {
        ...state,
        formDivisor: {
          ...state.formDivisor,
          editingItemId: currentEditing === payload ? null : payload
        }
      };
    }
    case types.formDivisorEliminarDivision: {
      const { [payload]: _, ...nuevasDivisiones } = state.formDivisor.byId;
      return {
        ...state,
        formDivisor: {
          ...state.formDivisor,
          allIds: state.formDivisor.allIds.filter(id => id !== payload),
          byId: nuevasDivisiones,
          editingItemId: null,
        },
      };
    }
    case types.formDivisorActualizarCoordenadasDivision: {
      const { id, coordenadas } = payload;
      return {
        ...state,
        formDivisor: {
          ...state.formDivisor,
          byId: {
            ...state.formDivisor.byId,
            [id]: {
              ...state.formDivisor.byId[id],
              coordenadas
            }
          }
        },
      };
    }
    case types.formDivisorAgregarCoordenadaDivision: {
      const { id, coordenada } = payload;
      return {
        ...state,
        formDivisor: {
          ...state.formDivisor,
          byId: {
            ...state.formDivisor.byId,
            [id]: {
              ...state.formDivisor.byId[id],
              coordenadas: [...state.formDivisor.byId[id].coordenadas, coordenada]
            }
          }
        },
      };
    }
    case types.formDivisorCargarDivisiones: {
      return {
        ...state,
        formDivisor: {
          ...state.formDivisor,
          allIds: Object.keys(payload),
          byId: payload,
        }
      };
    }

    /** Manejo de Form Unificador */
    case types.formUnificacionCargar: {
      return {
        ...state,
        formUnificacion: {
          ...state.formUnificacion,
          byId: payload,
          allIds: Object.keys(payload),
          editingItemId: [],
        }
      };
    }
    case types.formUnificacionSeleccionarInvernadero: {
      const { formUnificacion } = state;
      const { byId, allIds } = formUnificacion;
      const isSelected = !byId[payload].isSelected;
      const unificacionActiva = !formUnificacion.disabled;
      const newById = {
        ...byId,
        [payload]: {
          ...byId[payload],
          isSelected,
          disabled: false,
        },
      };

      allIds.forEach(id => {
        if (id !== payload) {
          newById[id] = {
            ...byId[id],
            disabled: isSelected && unificacionActiva
          };
        }
      });

      return {
        ...state,
        formUnificacion: {
          ...formUnificacion,
          byId: newById,
          editingItemId: unificacionActiva ? payload : null,
        }
      };
    }
    case types.formUnificacionSeleccionarTodosInvernadero: {
      const { sitioId, valor } = payload;
      return {
        ...state,
        formUnificacion: {
          ...state.formUnificacion,
          byId: Object.keys(state.formUnificacion.byId).reduce((acc, id) => {
            acc[id] = {
              ...state.formUnificacion.byId[id],
              isSelected: state.formUnificacion.byId[id].sitioId === sitioId
                ? !valor
                : state.formUnificacion.byId[id].isSelected,
            };
            return acc;
          }, {})
        }
      };
    }
    case types.formUnificacionSetDisabled: {
      const { formUnificacion } = state;
      const { byId, allIds } = formUnificacion;
      const newById = { ...byId };

      allIds.forEach(id => {
        newById[id] = { ...byId[id], isSelected: false, disabled: false, };
      });

      return {
        ...state,
        formUnificacion: {
          ...state.formUnificacion,
          disabled: payload,
          editingItemId: null,
          byId: newById,
        }
      };
    }
    case types.formUnificacionActualizarCoordenadas: {
      const { id, coordenadas } = payload;
      return {
        ...state,
        formUnificacion: {
          ...state.formUnificacion,
          byId: {
            ...state.formUnificacion.byId,
            [id]: {
              ...state.formUnificacion.byId[id],
              coordenadas
            }
          }
        },
      };
    }

    /** Sitios */
    case types.formActualizarSitioOrigen: {
      return {
        ...state,
        form: {
          ...state.form,
          [enumPasos.SITIOS]: {
            ...state.form[enumPasos.SITIOS],
            data: {
              ...state.form[enumPasos.SITIOS].data,
              sitioOrigenId: payload,
              byId: {},
              allIds: []
            }
          }
        },
        formDivisor: {
          ...state.formDivisor,
          disabled: true,
          byId: {},
          allIds: [],
          editingItemId: null,
        }
      };
    }
    case types.formSetSitios: {
      const formTemporadas = state.form[enumPasos.BASE].data;
      const nuevoFormTemporadas = Object.keys(payload).map((sitioId) => {
        const temporada = Object
          .values(formTemporadas.byId)
          .find(({ sitioId: id }) => id === sitioId);

        if (temporada) {
          return temporada;
        }

        return {
          id: `${new Date().getTime()}-${Math.random().toString(36).slice(2, 11)}`,
          sitioId,
        };
      });

      return {
        ...state,
        form: {
          ...state.form,
          [enumPasos.SITIOS]: {
            ...state.form[enumPasos.SITIOS],
            data: {
              ...state.form[enumPasos.SITIOS].data,
              byId: payload,
              allIds: Object.keys(payload)
            }
          },
          [enumPasos.BASE]: {
            ...state.form[enumPasos.BASE],
            data: {
              byId: nuevoFormTemporadas.reduce((acc, temporada) => {
                acc[temporada.id] = {
                  ...plantillaTemporada,
                  ...temporada
                };
                return acc;
              }, {}),
              allIds: nuevoFormTemporadas.map(({ id }) => id),
            }
          },
        }
      };
    }
    case types.formEliminarSitio: {
      const { [payload]: _, ...nuevosSitios } = state.form[enumPasos.SITIOS].data.byId;
      return {
        ...state,
        form: {
          ...state.form,
          [enumPasos.SITIOS]: {
            ...state.form[enumPasos.SITIOS],
            data: {
              ...state.form[enumPasos.SITIOS].data,
              byId: nuevosSitios,
              allIds: state.form[enumPasos.SITIOS].data.allIds.filter(id => id !== payload)
            }
          }
        }
      };
    }
    case types.formSetSitiosInvernaderos: {
      const { invernaderos, sitioId } = payload;
      return {
        ...state,
        form: {
          ...state.form,
          [enumPasos.SITIOS]: {
            ...state.form[enumPasos.SITIOS],
            data: {
              ...state.form[enumPasos.SITIOS].data,
              byId: {
                ...state.form[enumPasos.SITIOS].data.byId,
                [sitioId]: {
                  ...state.form[enumPasos.SITIOS].data.byId[sitioId],
                  invernaderos: Object.keys(invernaderos)
                }
              }
            }
          },
          [enumPasos.INVERNADEROS]: {
            ...state.form[enumPasos.INVERNADEROS],
            data: {
              ...state.form[enumPasos.INVERNADEROS].data,
              byId: invernaderos,
              allIds: Object.keys(invernaderos)
            }
          }
        }
      };
    }

    /** Invernaderos */
    case types.formSetInvernaderos: {
      return {
        ...state,
        form: {
          ...state.form,
          [enumPasos.INVERNADEROS]: {
            ...state.form[enumPasos.INVERNADEROS],
            data: {
              ...state.form[enumPasos.INVERNADEROS].data,
              byId: payload,
              allIds: Object.keys(payload)
            }
          },
        }
      };
    }

    /** Naves */
    case types.formSetNaves: {
      return {
        ...state,
        form: {
          ...state.form,
          [enumPasos.NAVES]: {
            ...state.form[enumPasos.NAVES],
            data: {
              ...state.form[enumPasos.NAVES].data,
              byId: payload,
              allIds: Object.keys(payload)
            }
          }
        }
      };
    }
    case types.formSeleccionarNave: {
      const form = state.form[enumPasos.NAVES].data;

      return {
        ...state,
        form: {
          ...state.form,
          [enumPasos.NAVES]: {
            ...state.form[enumPasos.NAVES],
            data: {
              ...form,
              byId: {
                ...form.byId,
                [payload]: {
                  ...form.byId[payload],
                  isSelected: !form.byId[payload].isSelected
                }
              }
            }
          }
        }
      };
    }
    case types.formSeleccionarTodasNaves: {
      return {
        ...state,
        form: {
          ...state.form,
          [enumPasos.NAVES]: {
            ...state.form[enumPasos.NAVES],
            data: {
              ...state.form[enumPasos.NAVES].data,
              byId: Object.keys(state.form[enumPasos.NAVES].data.byId).reduce((acc, id) => {
                acc[id] = {
                  ...state.form[enumPasos.NAVES].data.byId[id],
                  isSelected: payload,
                };
                return acc;
              }, {})
            }
          }
        }
      };
    }

    /** Default */
    default:
      return state;
  }
};

const validarFormBase = (_, getState) => {
  const form = getState().temporada.form[enumPasos.BASE].data;
  const keys = form.allIds;
  const errores = {};

  for (const key of keys) {
    errores[key] = {};
    const { nombre, fechaInicio, fechaFin } = form.byId[key];

    if (!nombre.trim()) {
      errores[key].nombre = mensajes.NOMBRE_TEMPORADA_REQUERIDO;
    } else if (nombre.length > 255) {
      errores[key].nombre = mensajes.NOMBRE_TEMPORADA_LARGO;
    }

    if (!fechaInicio) {
      errores[key].fechaInicio = mensajes.FECHA_INICIO_REQUERIDA;
    } else if (!isValid(fechaInicio)) {
      errores[key].fechaInicio = mensajes.FECHA_INICIO_INVALIDA;
    } else if (isBefore(startOfDay(fechaInicio), startOfDay(parseISO(new Date())))) {
      errores[key].fechaInicio = mensajes.FECHA_INICIO_MAYOR;
    } else if (isAfter(startOfDay(fechaInicio), startOfDay(fechaFin))) {
      errores[key].fechaInicio = mensajes.FECHA_INICIO_MAYOR;
    }

    if (!fechaFin) {
      errores[key].fechaFin = mensajes.FECHA_FIN_REQUERIDA;
    } else if (!isValid(fechaFin)) {
      errores[key].fechaFin = mensajes.FECHA_FIN_INVALIDA;
    } else if (isBefore(startOfDay(fechaFin), startOfDay(parseISO(new Date())))) {
      errores[key].fechaFin = mensajes.FECHA_FIN_MAYOR;
    } else if (isBefore(startOfDay(fechaFin), startOfDay(fechaInicio))) {
      errores[key].fechaFin = mensajes.FECHA_FIN_MAYOR;
    }
  }

  return Object.values(errores).some(error => Object.keys(error).length > 0) && errores;
};

const validarFormSitios = (dispatch, getState) => {
  const state = getState();

  const { allIds, disabled } = state.temporada.formDivisor;
  const { sitioOrigenId } = state.temporada.form[enumPasos.SITIOS].data;

  // Si el formulario de división está habilitado, no se puede continuar
  if (!disabled) {
    toast.warn(mensajes.FORM_DIVISOR_EN_USO);
    return {};
  }

  // Validar que se haya seleccionado un sitio origen
  if (!sitioOrigenId) {
    return { sitioOrigenId: mensajes.SITIO_ORIGEN_REQUERIDO };
  }

  // Si no se han agregado divisiones, se procede con el sitio origen
  // Se agregan los invernaderos del sitio origen al formulario
  if (allIds.length === 0) {
    let nuevasNaves = {};
    const sitioOrigen = state.temporada.data.sitios.byId[sitioOrigenId];
    const nuevosInvernaderos = sitioOrigen.invernaderos.reduce((acc, invernaderoId) => {
      acc[invernaderoId] = {
        ...state.temporada.data.invernaderos.byId[invernaderoId],
        isSelected: state.temporada.form[enumPasos.INVERNADEROS].data.byId[invernaderoId]?.isSelected || false,
        disabled: false,
      };

      // Actualizar el estado de las naves
      state.temporada.data.invernaderos.byId[invernaderoId].naves.forEach(naveId => {
        nuevasNaves[naveId] = {
          ...state.temporada.data.naves.byId[naveId],
          isSelected: state.temporada.form[enumPasos.NAVES].data.byId[naveId]?.isSelected || false,
        };
      });

      return acc;
    }, {});

    batch(() => {
      dispatch({
        type: types.formSetSitios,
        payload: {
          [sitioOrigenId]: {
            ...sitioOrigen,
            esDefault: true,
          }
        }
      });
      dispatch({ type: types.formSetInvernaderos, payload: nuevosInvernaderos });
      dispatch({ type: types.formSetNaves, payload: nuevasNaves });
    });
  }
};

const validarFormInvernaderos = (dispatch, getState) => {
  const state = getState().temporada;
  const { byId, disabled } = state.formUnificacion;
  const { byId: navesBD } = state.data.naves;

  // Si el formulario de unificación está habilitado, no se puede continuar
  if (!disabled) {
    toast.warn(mensajes.FORM_UNIFICADOR_EN_USO);
    return {};
  }

  // Validar que se haya invernaderos seleccionados
  const invernaderosSeleccionados = Object.values(byId).filter(invernadero => invernadero.isSelected);
  if (invernaderosSeleccionados.length === 0) {
    toast.warn(mensajes.INVERNADEROS_REQUERIDOS);
    return {};
  }

  // Si la validación es correcta, se procede a traspasar la info al formulario principal
  const nuevasNaves = {};
  for (const { id: invernaderoId, naves } of invernaderosSeleccionados) {
    for (const naveId of naves) {
      nuevasNaves[naveId] = {
        ...navesBD[naveId],
        invernaderoId,
        isSelected: state.form[enumPasos.NAVES].data.byId[naveId]?.isSelected || false,
      };
    }
  }

  batch(() => {
    dispatch({ type: types.formSetInvernaderos, payload: byId });
    dispatch({ type: types.formSetNaves, payload: nuevasNaves });
  });
};

const validarFormNaves = (_, getState) => {
  const state = getState().temporada;
  const { byId } = state.form[enumPasos.NAVES].data;

  // Validar que se haya invernaderos seleccionados
  const navesSeleccionadas = Object.values(byId).filter(invernadero => invernadero.isSelected);
  if (navesSeleccionadas.length === 0) {
    toast.warn(mensajes.NAVES_REQUERIDAS);
    return {};
  }
};

/** Acciones UI */
export const regresarPasos = () => ({ type: types.uiRegresarPasos });
export const siguientePaso = () => ({ type: types.uiSiguientePaso });
export const setAyudaTemporadas = (mostrar) => ({ type: types.uiSetAyudaTemporadas, payload: mostrar });

/** Acciones Formulario */
export const limpiarFormErrores = (pasoEnCurso) => ({ type: types.formLimpiarErrores, payload: pasoEnCurso });
export const restablecerFormulario = () => (dispatch) => {
  abortController.abort();
  dispatch({ type: types.formRestablecer });
};
export const setFormBase = (id, payload) => ({ type: types.formSetBase, payload, meta: { id } });
export const setFormErrores = (payload) => {
  if (Object.keys(payload.errores).length > 0) toast.warn(mensajes.FORM_ERROR);
  return { type: types.formSetErrores, payload };
};

// Sitios
export const actualizarSitioOrigen = (payload) => ({ type: types.formActualizarSitioOrigen, payload });
export const eliminarSitio = (id) => ({ type: types.formEliminarSitio, payload: id });
export const guardarDivisionesSitio = ({ byId, allIds }) => (dispatch, getState) => {
  const state = getState();
  const { sitios: sitiosDB, invernaderos: invernaderosBD, naves: navesBD } = state.temporada.data;
  const { sitioOrigenId, allIds: sitiosFormIds } = state.temporada.form[enumPasos.SITIOS].data;

  if (allIds.length === 0 && sitiosFormIds.length === 0) {
    dispatch(setDisabledFormDivisor(true));
    return;
  }

  const { invernaderos: invernaderoKeys, ...sitioOrigen } = sitiosDB.byId[sitioOrigenId];
  const coordenadasSitioOrigen = formatearCoordenadas(sitioOrigen.coordenadas);

  try {
    // Validar que las divisiones
    for (const id of allIds) {
      const { [id]: division } = byId;
      const { nombre, coordenadas } = division;

      if (coordenadas?.length === 0) {
        throw new Error(mensajes.DIVISIONES_COORDENDAS_REQUERIDAS);
      }

      if (!nombre.trim()) {
        throw new Error(mensajes.DIVISIONES_NOMBRE_REQUERIDO);
      }

      const coordenadasDivision = formatearCoordenadas(coordenadas);

      const estaDentroSitioOrigen = !losPoligonosIntersectan(coordenadasDivision, coordenadasSitioOrigen);
      if (!estaDentroSitioOrigen) {
        throw new Error(mensajes.DIVISIONES_FUERA_SITIO);
      }

      const estaInvernaderoDentroYNoIntersectan = invernaderoKeys.every(invernaderoId => {
        const { coordenadas: coordenadasInvernadero } = invernaderosBD.byId[invernaderoId];

        const estaDentro = estaPoligonoDentroDeOtro(formatearCoordenadas(coordenadasInvernadero), coordenadasDivision);
        const seIntersectan = losPoligonosIntersectan(coordenadasDivision, formatearCoordenadas(coordenadasInvernadero));

        return (estaDentro && !seIntersectan) || (!estaDentro && !seIntersectan);
      });

      if (!estaInvernaderoDentroYNoIntersectan) {
        throw new Error(mensajes.INVERNADEROS_FUERA_DIVISION);
      }

      const estaDivisionIntersectandoOtra = allIds.some(otraDivisionId => {
        if (otraDivisionId === id) return false;
        const { coordenadas: coordenadasOtra } = byId[otraDivisionId];
        return losPoligonosIntersectan(coordenadasDivision, formatearCoordenadas(coordenadasOtra));
      });
      if (estaDivisionIntersectandoOtra) {
        throw new Error(mensajes.DIVISIONES_SOLAPADAS);
      }
    }

    // Validar que todos los inversnaderos estén dentro de una división
    const invernaderosSinDivision = invernaderoKeys.some(invernaderoId => {
      const { coordenadas: coordenadasInvernadero } = invernaderosBD.byId[invernaderoId];
      return !allIds.some(divisionId => {
        const { coordenadas: coordenadasDivision } = byId[divisionId];
        const estaDentro = estaPoligonoDentroDeOtro(formatearCoordenadas(coordenadasInvernadero), formatearCoordenadas(coordenadasDivision));
        return estaDentro;
      });
    });

    if (invernaderosSinDivision) {
      throw new Error(mensajes.INVERNADEROS_FUERA_DIVISION);
    }

    // Asignar inverndaeros a divisiones si estos se encuentran dentro de la división
    const invernaderos = {};
    const naves = {};
    const divisiones = allIds.reduce((acc, id) => {
      const { coordenadas } = byId[id];
      const invernaderosDivision = invernaderoKeys.filter(invernaderoId => {
        const { coordenadas: coordenadasInvernadero } = invernaderosBD.byId[invernaderoId];
        return estaPoligonoDentroDeOtro(
          formatearCoordenadas(coordenadasInvernadero),
          formatearCoordenadas(coordenadas)
        );
      });

      // Actualizar el estado de los invernaderos
      invernaderosDivision.forEach(invernaderoId => {
        const invernadero = invernaderosBD.byId[invernaderoId];
        invernaderos[invernaderoId] = {
          ...invernadero,
          sitioId: id,
          isSelected: false,
          disabled: false,
        };

        // Actualizar el estado de las naves
        invernadero.naves.forEach(naveId => {
          naves[naveId] = {
            ...navesBD.byId[naveId],
            isSelected: false,
          };
        });
      });

      acc[id] = {
        ...byId[id],
        invernaderos: invernaderosDivision
      };

      return acc;
    }, {});

    batch(() => {
      dispatch({ type: types.formSetSitios, payload: divisiones });
      dispatch({ type: types.formSetInvernaderos, payload: invernaderos });
      dispatch({ type: types.formSetNaves, payload: naves });
      dispatch(setDisabledFormDivisor(true));
    });

    toast.info(mensajes.DIVISIONES_GUARDADAS);
  } catch (error) {
    toast.warn(error.message);
  }
};

/** Acciones Formulario Divisor */
export const actualizarCoordenadasDivision = (payload) => ({ type: types.formDivisorActualizarCoordenadasDivision, payload });
export const actualizarDivision = (payload) => ({ type: types.formDivisorActualizarDivision, payload });
export const agregarCoordenadaDivision = (payload) => ({ type: types.formDivisorAgregarCoordenadaDivision, payload });
export const agregarDivision = () => {
  return async (dispatch, getState) => {
    const { allIds: divisiones } = getState().temporada.formDivisor;

    const division = {
      id: `${new Date().getTime()}-${Math.random().toString(36).slice(2, 11)}`,
      nombre: `DIVISIÓN ${divisiones.length + 1}`,
      coordenadas: [],
    };

    dispatch({ type: types.formDivisorAgregarDivision, payload: division });
  };
};
export const cargarDivisiones = (payload) => ({ type: types.formDivisorCargarDivisiones, payload });
export const eliminarDivision = (id) => ({ type: types.formDivisorEliminarDivision, payload: id });
export const setDisabledFormDivisor = (editable) => ({ type: types.formDivisorSetDisabled, payload: editable });
export const setDivisionEnEdicion = (id) => ({ type: types.formDivisorSetDivisionEnEdicion, payload: id });

export const obtenerDivisiones = () => (_, getState) => {
  const divisiones = getState().temporada.formDivisor;
  return divisiones;
};

/** Acciones Formulario Unificador */
export const cargarFormUnificacion = (payload) => ({ type: types.formUnificacionCargar, payload });
export const seleccionarInvernadero = (id) => ({ type: types.formUnificacionSeleccionarInvernadero, payload: id });
export const seleccionarTodosInvernaderos = (payload) => ({ type: types.formUnificacionSeleccionarTodosInvernadero, payload });
export const setDisabledFormUnificacion = (payload) => ({ type: types.formUnificacionSetDisabled, payload: payload });
export const actualizarCoordenadasUnificacion = (payload) => ({ type: types.formUnificacionActualizarCoordenadas, payload });
export const agregarCoordenadaUnificacion = (payload) => (dispatch, getState) => {
  const { formUnificacion } = getState().temporada;
  const { editingItemId } = formUnificacion;
  const { coordenadas } = formUnificacion.byId[editingItemId];
  const newCoordenadas = [...coordenadas, payload];
  dispatch(actualizarCoordenadasUnificacion({ id: editingItemId, coordenadas: newCoordenadas }));
};
export const validarSeleccionUnificacion = () => (_, getState) => {
  const { formUnificacion, form } = getState().temporada;
  const { byId, editingItemId } = formUnificacion;
  const { byId: sitios } = form[enumPasos.SITIOS].data;

  const { [editingItemId]: invernaderoSeleccionado, ...newById } = byId;
  const { nombre, coordenadas, sitioId } = invernaderoSeleccionado;
  const coordenadasInvernaderoSeleccionado = formatearCoordenadas(coordenadas);

  try {
    // Validr que no se encuentre fuera del sitio que lo contiene
    const coordenadasSitio = formatearCoordenadas(sitios[sitioId].coordenadas);
    const estaDentroSitio = estaPoligonoDentroDeOtro(coordenadasInvernaderoSeleccionado, coordenadasSitio)
      && !losPoligonosIntersectan(coordenadasInvernaderoSeleccionado, coordenadasSitio);
    if (!estaDentroSitio) throw new Error(mensajes.INVERNADEROS_UNIFICAR_FUERA_SITIO);

    // Validar que se haya seleccionado al menos un invernadero
    const esValidaCantidad = Object.keys(byId).filter(id => byId[id].isSelected).length > 0;
    if (!esValidaCantidad) throw new Error(mensajes.INVERNADEROS_UNIFICAR_SELECCION_REQUERIDA);

    // Valida que la selección abarque al menos un invernadero
    const invernaderosAfectados = Object.keys(newById).filter(id => {
      const { coordenadas: coordenadasInvernadero } = {
        ...newById[id],
        [editingItemId]: {
          ...byId[editingItemId],
        }
      };
      return losPoligonosIntersectan(formatearCoordenadas(coordenadasInvernadero), coordenadasInvernaderoSeleccionado) || estaPoligonoDentroDeOtro(formatearCoordenadas(coordenadasInvernadero), coordenadasInvernaderoSeleccionado);
    });
    if (invernaderosAfectados.length === 0) throw new Error(mensajes.INVERNADEROS_UNIFICAR_MINIMO);

    // Validar que lo cubra en su totalidad
    const coordenadasInvernaderosAfectados = invernaderosAfectados.map(id => newById[id].coordenadas);
    const unificacionValida = coordenadasInvernaderosAfectados.every(coordenadas => {
      return estaPoligonoDentroDeOtro(formatearCoordenadas(coordenadas), coordenadasInvernaderoSeleccionado);
    });
    if (!unificacionValida) throw new Error(mensajes.INVERNADEROS_UNIFICACION_ABARCAR_EN_SU_TOTALIDAD)

    return { nombre, invernaderosAfectados };
  } catch (error) {
    toast.warn(error.message);
    return null;
  }
};
export const unificarInvernaderos = (nombre, invernaderosAfectados) => (dispatch, getState) => {
  const state = getState().temporada.formUnificacion;
  const invernaderoSeleccionado = state.editingItemId;
  const newById = { ...state.byId };
  const nuevasNaves = newById[invernaderoSeleccionado].naves.slice();

  for (const id of invernaderosAfectados) {
    nuevasNaves.push(...newById[id].naves);
    delete newById[id];
  }

  newById[invernaderoSeleccionado] = {
    ...state.byId[invernaderoSeleccionado],
    nombre,
    naves: nuevasNaves,
    origen: invernaderosAfectados,
  };

  batch(() => {
    dispatch({ type: types.formUnificacionCargar, payload: newById });
    dispatch(setDisabledFormUnificacion(true));
  });
  toast.info(mensajes.INVERNADEROS_UNIFICADOS);
};

/** Acciones Formulario Naves */
export const seleccionarNave = (id) => ({ type: types.formSeleccionarNave, payload: id });
export const seleccionarTodasNaves = (payload) => ({ type: types.formSeleccionarTodasNaves, payload });

/**
 * Función que precarga los datos de los sitios, invernaderos, naves y temporadas
 * @param {number | string} id Identificador de la temporada
 */
export const precargarDatos = (id, plantillaId) => async (dispatch) => {
  try {
    const temporadaID = id || plantillaId;
    // Abortar cualquier solicitud previa
    abortController.abort();
    abortController = new AbortController();
    const signal = abortController.signal;
    const consultas = [];
    const sitiosNormalizados = {};
    const invernaderosNormalizados = {};
    const navesNormalizadas = {};

    // Agregar consultas
    consultas.push(axios.get(TEMPORADAS + SITIOS, { signal, params: { temporadaID } }));

    // Buscamos la información de la temporada si nos proporcionan un id
    if (temporadaID) {
      consultas.push(axios.get(endpoints.base.url(TEMPORADAS, temporadaID), { signal }));
    }

    // Realizar consultas
    const [{ sitios, invernaderos, naves }, temporadaResponse = null] = await Promise.all(consultas);

    // Normalizar datos
    sitios.forEach(({ id: sitioId, nombre, coordenadas }) => {
      const sitioKey = String(sitioId);
      const invernaderosSitio = invernaderos.filter(({ sitioID }) => sitioID === sitioId);

      sitiosNormalizados[sitioKey] = {
        id: sitioKey,
        nombre,
        coordenadas: coordenadas.map(({ longitud, latitud }) => ({ lng: longitud, lat: latitud })),
        invernaderos: invernaderosSitio.map(({ id }) => String(id)),
      };

      invernaderosSitio.forEach(({ id: invernaderoId, nombre: invernaderoNombre, coordenadas: invernaderoCoordenadas }) => {
        const invernaderoKey = String(invernaderoId);
        const navesInvernadero = naves.filter(({ invernaderoID }) => invernaderoID === invernaderoId);

        invernaderosNormalizados[invernaderoKey] = {
          id: invernaderoKey,
          nombre: invernaderoNombre,
          coordenadas: invernaderoCoordenadas.map(({ longitud, latitud }) => ({ lng: longitud, lat: latitud })),
          sitioId: sitioKey,
          naves: navesInvernadero.map(({ id }) => String(id))
        };

        naves.forEach(({ id: naveId, nombre: naveNombre, coordenadas: naveCoordenadas, invernaderoID }) => {
          const naveKey = String(naveId);
          navesNormalizadas[naveKey] = {
            id: naveKey,
            nombre: naveNombre,
            coordenadas: naveCoordenadas.map(({ longitud, latitud }) => ({ lng: longitud, lat: latitud })),
            invernaderoId: String(invernaderoID),
          };
        });
      });
    });

    batch(() => {
      if (temporadaResponse) {
        const { temporada, naves: navesKeys } = temporadaResponse;
        const { nombre, estatus, fechaInicio, fechaFin } = temporada;
        const formTemporada = { byId: {}, allIds: [] };
        const formNaves = { byId: {}, allIds: [] };
        const formInvernaderos = { byId: {}, allIds: [] };
        const formSitios = { byId: {}, allIds: [], sitioOrigenId: '' };

        // Normalizar data
        for (const sitio of sitios) {
          const sitioId = String(sitio.id);
          const invernaderosSitio = invernaderos.filter(({ sitioID }) => sitioID === sitio.id);

          for (const invernadero of invernaderosSitio) {
            const invernaderoId = String(invernadero.id);
            const navesInvernadero = naves.filter(({ invernaderoID }) => invernaderoID === invernadero.id);

            for (const nave of navesInvernadero) {
              const naveId = String(nave.id);
              const naveSeleccionada = navesKeys.includes(nave.id);

              if (naveSeleccionada) {
                // Agregar naves seleccionadas al formulario
                formNaves.allIds.push(naveId);
                formNaves.byId[naveId] = {
                  id: naveId,
                  nombre: nave.nombre,
                  coordenadas: nave.coordenadas.map(({ longitud, latitud }) => ({ lng: longitud, lat: latitud })),
                  isSelected: naveSeleccionada,
                  invernaderoId: invernaderoId,
                };

                // Agregar invernaderos seleccionados al formulario
                if (!formInvernaderos.byId[invernaderoId]) {
                  formInvernaderos.allIds.push(invernaderoId);
                  formInvernaderos.byId[invernaderoId] = {
                    id: invernaderoId,
                    nombre: invernadero.nombre,
                    coordenadas: invernadero.coordenadas.map(({ longitud, latitud }) => ({ lng: longitud, lat: latitud })),
                    sitioId: sitioId,
                    naves: [naveId],
                    isSelected: true,
                    disabled: false,
                  }
                } else {
                  formInvernaderos.byId[invernaderoId].naves.push(naveId);
                }

                // Agregar sitios seleccionados al formulario
                if (!formSitios.byId[sitioId]) {
                  formSitios.allIds.push(sitioId);
                  formSitios.byId[sitioId] = {
                    id: sitioId,
                    nombre: sitio.nombre,
                    coordenadas: sitio.coordenadas.map(({ longitud, latitud }) => ({ lng: longitud, lat: latitud })),
                    invernaderos: [invernaderoId],
                    esDefault: true,
                  };

                  formSitios.sitioOrigenId = sitioId;
                } else {
                  formSitios.byId[sitioId].invernaderos.push(invernaderoId);
                }
              } else if (sitioId === formSitios.sitioOrigenId) {
                // Agregar naves no seleccionadas al formulario
                formNaves.allIds.push(naveId);
                formNaves.byId[naveId] = {
                  id: naveId,
                  nombre: nave.nombre,
                  coordenadas: nave.coordenadas.map(({ longitud, latitud }) => ({ lng: longitud, lat: latitud })),
                  isSelected: false,
                  invernaderoId: invernaderoId,
                };

              }
            }
          }
        }

        // Setear datos de la temporada
        formTemporada.allIds.push(temporadaID);
        formTemporada.byId[temporadaID] = {
          id: String(temporadaID),
          nombre,
          estatus,
          fechaInicio: startOfDay(parseISO(fechaInicio)),
          fechaFin: startOfDay(parseISO(fechaFin)),
          sitioId: formSitios.sitioOrigenId,
        };

        dispatch({
          type: types.precargarFormulario,
          payload: {
            id,
            plantillaId,
            temporada: formTemporada,
            sitios: formSitios,
            invernaderos: formInvernaderos,
            naves: formNaves,
          }
        });
      }

      // Despachar acción con los datos normalizados
      dispatch({
        type: types.dataCargarSitios,
        payload: { sitios: sitiosNormalizados, invernaderos: invernaderosNormalizados, naves: navesNormalizadas }
      });
    });

    return true;
  } catch (error) {
    if (error.isCancel) return;
    toast.warn(mensajes.FETCH_ERROR);
    return false;
  }
};

export const obtenerCoordenadasSitio = (id) => (_, getState) => {
  const { data: { sitios } } = getState().temporada;
  return sitios.byId[id] ? sitios.byId[id].coordenadas : [];
};

const guardarTemporada = async (state) => {
  const { temporada } = state;
  const { id, plantillaId } = temporada;
  const {
    [enumPasos.BASE]: { data: base },
    [enumPasos.SITIOS]: { data: sitios },
    [enumPasos.INVERNADEROS]: { data: invernaderos },
    [enumPasos.NAVES]: { data: naves },
  } = temporada.form;

  const sitiosDesnormalizados = desnormalizarTemporada({ sitios, invernaderos, naves });
  const temporadas = Object
    .values(base.byId)
    .map(({ sitioId, ...rest }) => ({
      ...rest,
      sitio: sitiosDesnormalizados.find(sitio => sitio.id === sitioId),
    }));

  try {
    if (plantillaId) {
      await axios.post(endpoints.importarConfiguracionTemporada(), { temporadas, plantillaId });
    } else {
      if (id) {
        await axios.put(endpoints.base.url(TEMPORADAS, id), temporadas);
      } else {
        await axios.post(TEMPORADAS, temporadas);
      }
    }

    return true;
  } catch (error) { }
};

export const submitFormulario = () => async (dispatch, getState) => {
  let errores;
  const pasoEnCurso = getState().temporada.ui.stepper.pasoEnCurso;

  switch (pasoEnCurso) {
    case enumPasos.SITIOS: {
      errores = validarFormSitios(dispatch, getState);
      break;
    }
    case enumPasos.INVERNADEROS: {
      errores = validarFormInvernaderos(dispatch, getState);
      break;
    }
    case enumPasos.NAVES: {
      errores = validarFormNaves(dispatch, getState);
      break;
    }
    case enumPasos.BASE: {
      errores = validarFormBase(dispatch, getState);
      break;
    }
    default:
      return;
  }

  if (errores) {
    dispatch(setFormErrores({ pasoEnCurso, errores }));
  } else {
    if (pasoEnCurso === Object.keys(enumPasos).length - 1) {
      const exito = await guardarTemporada(getState());
      return exito;
    } else {
      dispatch(siguientePaso());
      return;
    }
  }
};

export const regresarFormulario = () => (dispatch) => {
  dispatch(regresarPasos());
};

export default reducer;
