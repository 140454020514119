import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  root: {
    width: '100%',
    minHeight: 380,
    height: '95%',
    borderRadius: 8,
    position: 'relative',
  },
  boton: {
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
    position: 'absolute',
    top: 70,
    right: 8,
    zIndex: 1,
    minHeight: 20,
    height: 40,
    minWidth: 18,
    width: 18,
    borderRadius: 2,

    '&:hover': {
      backgroundColor: '#FFFFFF',
      borderColor: '#FFFFFF',
    },
  },
  botonUnificar: {
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
    position: 'absolute',
    top: 10,
    right: 100,
    zIndex: 1,
    minHeight: 20,
    height: 40,
    borderRadius: 2,

    '&:hover': {
      backgroundColor: '#FFFFFF',
      borderColor: '#FFFFFF',
    },

    '& span': {
      color: '#000000',
    }
  }
}));

export default styles;
