import React from 'react';
import propTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '../Typography';
import styles from './styles';

const Estatus = ({ activo }) => {
  const classes = styles();

  return <Typography className={clsx(classes.root, { disabled: !activo })}>
    {activo ? 'Habilitado' : 'Inhabilitado'}
  </Typography>
};

Estatus.propTypes = {
  activo: propTypes.bool.isRequired,
};

export default React.memo(Estatus);