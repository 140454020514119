import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: 'calc(100vh - 130px)',
    padding: '8px 0px 0px 8px',
  },
  alias: {
    color: paleta.bar.primary,
    paddingInline: 10,
    fontWeight: '700',
    fontSize: 17,
    letterSpacing: 0.5,
  },
  detalles: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    borderRadius: '7px',
    margin: '8px 0px',
    gap: 15,
  },
  mapa: {
    maxHeight: '95vh',
    padding: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2),
    }
  },
}));

export default styles;
