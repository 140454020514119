import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  table: {
    minHeight: '250px',
    borderRadius: 0,
    borderTop: 'none',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderTopRightRadius: 8,
  },
  tabList: {
    height: 35,
    minHeight: 35,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  tab: {
    border: '1px solid #E6EBF5',
    fontSize: 11,
    minHeight: 35,
    height: 35,
    minWidth: 115,
    width: 115,
  },
  activeTab: {
    border: '1px solid #E6EBF5',
    fontSize: 11,
    backgroundColor: paleta.sidebar.active,
    minHeight: 35,
    height: 35,
    minWidth: 115,
    width: 115,
  }
}));

export default styles;
