import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    aspectRatio: 5 / 3,
    backgroundColor: paleta.sidebar.active,
    border: `1px solid ${paleta.bar.border}`,
    cursor: 'pointer',
    height: 155,
    '&.active': {
      backgroundColor: `${paleta.bar.primary}EE`,
    },
    transition: 'background-color 230ms linear',
  },
  text: {
    color: paleta.textField.text,
    '&.active': {
      color: paleta.textField.backgroundColor,
    },
    transition: 'color 230ms linear',
  },
  textContainer: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: 3,
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px',
    '&.active': {
      color: paleta.textField.backgroundColor,
    }
  },
  subtitle: {
    color: paleta.textField.textBlack,
    fontSize: '15px',
    fontWeight: '600',
    '&.active': {
      color: paleta.textField.backgroundColor,
    },
  },
  auxtext: {
    color: paleta.textField.textBlack,
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: '700',
    '&.active': {
      color: paleta.textField.backgroundColor,
    },
  },
  semanaContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '30px',
  },
  mainInfo: {
    fontSize: '30px',
    transition: 'color 2s ease',
  },
  noData: {
    fontSize: '19px',
    lineHeight: '50px',
  },
  subInfo: {
    fontSize: '15px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  progressContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  btnChange: {
    color: paleta.textField.backgroundColor,
    padding: 3,
  }
}));

export default styles;
