import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  root: {
    width: '100%',
    minHeight: 380,
    height: '95%',
    borderRadius: 5,
    position: 'relative',
  },
}));

export default styles;
