import React from 'react'
import { Box } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers'
import clsx from 'clsx';

import Typography from '../Typography';

import styles from './styles';

const DatePicker = ({
  disabled,
  error,
  label,
  name,
  onChange,
  required,
  value,
  ...rest
}) => {
  const classes = styles();

  const customOnChange = React.useCallback((date, value) => {
    onChange({ target: { name, value: date } });
  }, [name, onChange]);

  return (
    <Box component="div">
      <Typography className={classes.datePickerLabel}>
        {label}
        {required && ' * '}
      </Typography>
      <KeyboardDatePicker
        {...rest}
        autoOk
        disabled={disabled}
        color="primary"
        disableToolbar
        format="dd/MM/yyyy"
        fullWidth
        invalidDateMessage="Fecha inválida"
        mask="__/__/____"
        name={name}
        onChange={customOnChange}
        value={value}
        variant="inline"
        InputProps={{
          disableUnderline: true,
          classes: {
            root: clsx(classes.input, { error }),
          },
        }}
        KeyboardButtonProps={{
          'aria-label': 'cambiar fecha',
        }}
      />
    </Box>
  )
}

export default DatePicker;
