import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  agrupador: {
    marginInline: 5,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 30,
    border: 'none'
  },
  titleContainer: {
    position: 'relative',
  },
  labelTitle: {
    color: paleta.textField.text,
    fontSize: '16px',
    fontWeight: 'bold',
  },
  chart: {
    marginTop: 20,
  }
}));

export default styles;
