import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  paperDrawer: {
    minWidth: '80%',
  },
  txtID: {
    width: 100,
  },
}));

export default styles;
