const estatus = [
  { label: 'Habilitado', id: true },
  { label: 'Inhabilitado', id: false },
];

export const estatusTemporada = {
  todas: { id: '', nombre: 'Todos' },
  enCurso: { id: '1', nombre: 'En curso' },
  finalizada: { id: '2', nombre: 'Finalizada' },
  proxima: { id: '3', nombre: 'Próxima' },
};

export default estatus
