import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  agrupador: {
    marginInline: 5,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 30,
    gap: 15,
  },
  tituloModulo: {
    color: paleta.textField.text,
    fontSize: '16px',
    fontWeight: 'bold',
  },
  informacion: {
    backgroundColor: '#FFF',
    paddingBlock: 10,
    paddingInline: 25,
    borderRadius: 5,
    justifyContent: 'space-between',
  },
  estadoGeneral: {
    color: '#FFF',
    borderRadius: 5,
  },
  wrapperInfo: {
    minWidth: 60,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  label: {
    fontWeight: 'bold',
    width: '10ch',
  },
  valor: {
    textAlign: 'center',
    fontSize: 13,
  },
  secciones: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  estados: {
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  chip: {
    backgroundColor: '#49B1F2',
    color: '#FFFF',
    fontSize: 12,
    fontWeight: 'bold',
    minWidth: 75,

    '& > span': {
      verticalAlign: 'middle',
    },

    '&:hover': {
      backgroundColor: 'currentColor',
    }
  },
  badge: {
    backgroundColor: paleta.bar.user,
    color: '#000',
    fontWeight: 'bold',
    fontSize: 12,
  },
  chartContainerClass: {
    width: '100%',
  }
}));

export default styles;
