import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderRadius: 6,
    backgroundColor: "#FFFF",
    border: `0px solid ${paleta.bar.border}`,
  },
  container: {
    flexGrow: 1,
    position: 'static',
    borderRadius: 6,
  },
  calendario: {
    borderRadius: 6,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    marginBottom: 15,
    padding: 10,
  },
  calendarioItem: {
    width: '100%',
  },
  contenedor:{
    backgroundColor: '#FFFF',
    width: 'auto',
    height: 300,
    padding:5,
  },
  tabla: {
    height: 300,
    width: 'auto',
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    overflowX: 'scroll',
    position: 'relative',
    border: `1px solid ${paleta.bar.point}`,
    backgroundColor: paleta.sidebar.active,
  },
  title: {
    fontSize: 15,
    color: "#0F0E0E",
    fontWeight: 600,
  },
  subTitleDetail: {
    fontSize: 14,
    color: "#737272",
    fontWeight: 600,
  },
  titleTable:{
    fontSize: 18,
    marginLeft: 10,
    color: "#737272",
    fontWeight: 600,
  },
  underline:{
    color: "#737272",
    fontWeight: 600,
    textDecoration: 'none',
    '&:hover': {
      color: 'red'
    }
  },
  subTitle: {
    flexGrow: 1,
    align: "center",
    fontWeight: 600,
    color: '#737272',
    fontSize: 11,
    // color: "red",
    "&::after": {
      borderBottom: "2px solid red"
    },
    "&::before": {
      borderBottom: "2px solid yellow"
    }
  },
  divider: {
    borderWidth: 1,
    height: 'auto',
    borderTop: `dashed ${paleta.bar.point}`,
  },
  dividerDetailV: {
    width: '2px',
    borderWidth: 1,
    height: 'auto',
    borderStyle: 'dashed',
    borderColor: '#D0D2D3',
    backgroundColor:paleta.bar.details,
  },
  dividerDetailH: {
    border: '1px',
    height: 0.1,
    borderStyle: 'dashed',
    borderColor: '#D0D2D3',
    backgroundColor:paleta.bar.details,
  },
  dividerList: {
    border: '3px',
    width: '7vh',
    height: 2.1,
  },
  dividerListItem: {
    border: '1px',
    borderStyle: 'dashed',
    borderColor: '#E8EBEC',
    backgroundColor: '#FFFF',
  },
  box:{
    marginLeft: 'auto',
    marginRight: 'auto',
    color: "#8E9295"
  },
  paper: {
    padding: theme.spacing(1.5),
    backgroundColor:  paleta.bar.details,
    borderWidth: 1,
    height: 'auto',
    borderRight: `dashed ${paleta.bar.point}`,
  },
  table: {
    minWidth: 300,
  },
  header: {
    borderWidth: 1,
    height: 'auto',
    borderTop: 'none',
    borderLeft: 'none',
    border: `dashed ${paleta.bar.point}`,
  }
}));

export default styles;
 