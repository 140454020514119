/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';

import Agrupador from '../../componentes/Agrupador';
import Default from '../../contenedores/Default';
import Mapa from '../../componentes/Mapa';
import Select from '../../componentes/Select';
import TextField from '../../componentes/TextField';

import {
  ELEMENTO_NO_ENCONTRADO,
  POLIGONO_VACIO,
  SITIO_DEPENDENCIAS,
} from '../../configuraciones/mensajes';
import axios from '../../configuraciones/axios';
import endpoints, { SITIOS } from '../../configuraciones/endpoints';
import paleta from '../../configuraciones/paleta';

import { findPropertysEmpty, trim } from '../../utilidades/functions';
import { LETTERSNUMBERS } from '../../utilidades/regex';

import estatus from '../../constantes/estatus';

import styles from './styles';

const Sitio = ({ history, match }) => {
  const classes = styles();
  const [formErrors, setFormErrors] = useState({});
  const [polygonRef, setPolygonRef] = useState({});

  const [sitio, setSitio] = useState({
    coordenadas: [],
    invernaderos: [],
  });

  const { params: { id } } = match;

  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/sitios',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  const cancelar = useCallback(regresar, []);

  const guardar = useCallback(() => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmSitio'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;

    const metodo = id ? 'put' : 'post';

    const poligonos = polygonRef.state.polygon.getPath().getArray();
    const coordenadas = poligonos.map((coord) => {
      return { sitioID: sitio.id, latitud: coord.lat(), longitud: coord.lng() };
    });

    if (poligonos.length < 3) {
      toast.warning(POLIGONO_VACIO);
      return null;
    }

    let objTrim = trim(sitio);

    axios[metodo](SITIOS, { ...objTrim, coordenadas }).then(regresar);
  });

  const consultarDatosIniciales = () => new Promise(async (resolve, reject) => {
    const promesas = [];
    if (id) promesas.push(axios.get(endpoints.base.url(SITIOS, id)));
    Promise.all(promesas).then((resultadosPromesas) => {
      const [sitioDb] = resultadosPromesas;
      if (sitioDb?.id) {
        sitioDb.coordenadas = sitioDb.coordenadas.map((coord) => {
          return { lat: coord.latitud, lng: coord.longitud };
        });
        setSitio(sitioDb);
      } else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      } else
        setSitio({ coordenadas: [], estatus: true });
      resolve();
    }).catch(reject);
  });

  useEffect(() => {
    consultarDatosIniciales();
  }, [id]);

  useEffect(() => {
    consultarDatosIniciales();
  }, []);

  return <Default
    titulo={Boolean(id) ? 'Editar Sitio' : 'Nuevo Sitio'}
    placeHolder={''}
    mostrarCabeceroFormulario
    guardar={guardar}
    cancelar={cancelar}>
    <Grid container spacing={2} id="frmSitio">
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4}>
          {id &&
            <Grid item xs={12}>
              <TextField
                label="Código de Sitio"
                name="id"
                value={sitio.id}
                disabled
              />
            </Grid>}
          <Grid item xs={12}>
            <TextField
              label="Nombre"
              onChange={setSitio}
              type="nombre"
              name="nombre"
              value={sitio.nombre}
              isHandleChange
              inputProps={{ regex: LETTERSNUMBERS, regexonsubmit: 'true', maxLength: 50 }}
              error={formErrors.nombre}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Estatus"
              options={estatus}
              onChange={setSitio}
              name="estatus"
              value={sitio.estatus}
              error={formErrors.estatus}
              isHandleChange
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12}>
        <Agrupador label="Geolocalización">
          <Grid container spacing={4}>
            <Grid item xs={12} className={classes.contenedorMapa}>
              <Mapa
                setRef={setPolygonRef}
                bloquearClick={(sitio.invernaderos?.length > 0) ? true : false}
                motivoBloqueo={SITIO_DEPENDENCIAS}
                poligonoInicial={sitio.coordenadas}
                drawPolygonOptions={{
                  strokeWeight: 0,
                  fillColor: paleta.geocerca.primary,
                  fillOpacity: 0.7,
                }}
                defaultCenter={sitio?.coordenadas.length > 0 ? sitio.coordenadas[0] : null}
              />
            </Grid>
          </Grid>
        </Agrupador>
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(Sitio);
