import React, { useCallback, useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { Grid } from '@material-ui/core';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';

import Typography from '../Typography';

import paleta from '../../configuraciones/paleta';
import styles from './styles';

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    if (options.display) {
      const ul = getOrCreateLegendList(chart, options.containerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }
      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);
      items.forEach((item) => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.onclick = () => {
          const { type } = chart.config;
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(
              item.datasetIndex,
              !chart.isDatasetVisible(item.datasetIndex)
            );
          }

          chart.update();
        };

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.strokeStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + 'px';
        boxSpan.style.borderRadius = '50%';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.height = '12px';
        boxSpan.style.marginRight = '5px';
        boxSpan.style.marginTop = 0;
        boxSpan.style.width = '12px';

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = 0;
        textContainer.style.padding = 0;
        textContainer.style.marginTop = '5px';
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });

      // Botón reset zoom
      if (chart.options.plugins.zoom) {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.marginLeft = 'auto';
        li.onclick = () => {
          chart.resetZoom();
        };
  
        const textContainer = document.createElement('p');
        textContainer.style.color = paleta.textField.text;
        textContainer.style.margin = 0;
        textContainer.style.padding = 0;
        textContainer.style.marginTop = '5px';
        textContainer.style.textDecoration = 'none';
  
        const text = document.createTextNode('Reset Zoom');
        textContainer.appendChild(text);
        li.appendChild(textContainer);
        ul.appendChild(li);
      }

    }
  },
};

const initOptions = {
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    crosshair: false,
  },
};

ChartJS.register(
  ...registerables,
  annotationPlugin,
  ChartDataLabels,
  htmlLegendPlugin,
  zoomPlugin
);

const CustomChart = React.forwardRef(({
  type, data, options, title, width, height,
  showLegend, handleClickElement, redraw,
  staticYAxis, legendStyles, containerClass,
  containerID, scrollTo,
}, ref) => {
  const classes = styles();
  const target = useRef();
  const sourceInterno = useRef();

  const source = ref || sourceInterno;
  const scrollContainer = useRef();
  const [isMounted, setIsMounted] = useState(false);

  const scrollToIndex = useCallback(() => {
    if ( source.current && scrollContainer.current ) {   
      const chart = source.current;
      const len = chart.scales?.x?.ticks?.length || 0;
      const index = chart.scales?.x?.ticks.findIndex(tick => tick.label === scrollTo) || 0;
      const scrollContainerWidth = scrollContainer.current.offsetWidth;
      const labelWidth = width / len;
      const xOffset = (index * labelWidth) - (scrollContainerWidth / 2);
  
      if ( xOffset > 0 ) {
        scrollContainer.current?.scrollTo(xOffset, 0);
      }
    }
  }, [scrollTo, source, width]);

  const animation = useRef({
    onComplete: (context) => {
      if (context.initial && target.current && source.current ) {
        const chart = source.current;

        const targetCtx = target.current.getContext('2d');
        const scale = window.devicePixelRatio;
        const sourceCanvas = chart.canvas;
        const sourceCtx = chart.ctx;

        const copyWidth = chart.scales.y.width - 5;
        const copyHeight = chart.scales.y.height + chart.scales.y.top + 10;

        targetCtx.canvas.width = copyWidth * scale;
        targetCtx.canvas.height = copyHeight * scale;
        targetCtx.canvas.style.width = copyWidth + 'px';
        targetCtx.canvas.style.height = copyHeight + 'px';
        targetCtx.canvas.style.backgroundColor = `${paleta.sidebar.active}`;

        targetCtx.drawImage(sourceCanvas, 0, 0, copyWidth * scale, copyHeight * scale, 0, 0, copyWidth * scale, copyHeight * scale);
        (staticYAxis) && sourceCtx.clearRect(0, 0, copyWidth, copyHeight);
        setIsMounted(true);
        if (scrollTo && redraw) scrollToIndex();
      }
    },
  });

  const handleClick = (e, element) => {
    if (element.length > 0) {
      if (handleClickElement) {
        const datasetIndex = element[0].datasetIndex;
        const dataIndex = element[0].index;
        const datasetLabel = e.chart.data.datasets[datasetIndex].label;
        const value = e.chart.data.datasets[datasetIndex].data[dataIndex];
        const label = e.chart.data.labels[dataIndex];
        handleClickElement({
          datasetLabel,
          datasetIndex,
          dataIndex,
          label,
          value,
        });
      }
    } else {
      handleClickElement(null);
    }
  };

  useEffect(() => {
    if (scrollTo && !redraw) {
      scrollToIndex();
    }
  }, [isMounted, redraw, scrollTo, scrollToIndex]);

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" className={classes.title}>{ title }</Typography>
      { showLegend && <div className={classes.legends} style={legendStyles} id={containerID}></div> }
      <Grid style={{ position: 'relative' }}>
        <Grid container className={classes.scrollableContainer} ref={scrollContainer}>
          <Grid className={containerClass}>
            <Chart
              id={new Date().getTime().toString()}
              key={redraw && new Date().getTime().toString()}
              ref={source}
              type={type}
              options={{
                ...initOptions,
                ...options,
                onClick: handleClick,
                plugins: {
                  ...initOptions.plugins,
                  ...options.plugins,
                  htmlLegend: {
                    containerID,
                    display: showLegend ? true : false,
                  },
                },
                animation: animation.current,
              }}
              data={data}
              width={width}
              height={height}
              redraw={redraw}
            />
          </Grid>
        </Grid>
        <canvas ref={target} height={height} width={width} style={{
          position: 'absolute',
          left: 0,
          top: 0,
          pointerEvents: 'none',
          opacity: (staticYAxis) ? 1 : 0,
        }}></canvas>
      </Grid>
    </div>
  );
});

CustomChart.propTypes = {
  /** Tipo de grafica */
  type: propTypes.string.isRequired,
  /** La información que mostrara la gráfica */
  data: propTypes.object.isRequired,
  /** Opciones de personalización de la gráfica */
  options: propTypes.object,
  /** Titulo que mostrará el contenedor */
  title: propTypes.string,
  /** Función que se ejecutará al hacer click en el gráfico */
  handleClickElement: propTypes.func,
  /** Indica si quiere mostrar las leyendas */
  showLegend: propTypes.bool,
  /** Ancho de la gráfica */
  width: propTypes.oneOfType([propTypes.string, propTypes.number]),
  /** Alto de la gráfica */
  height: propTypes.number,
  /** Indica si se redibuja por cada render */
  redraw: propTypes.bool,
  /** Indica si se recorre el eje Y con el scroll */
  staticYAxis: propTypes.bool,
  /** Valor del axis X a donde scrollear */
  scrollTo: propTypes.oneOfType([propTypes.string, propTypes.number]),
  /** Estilos de las leyendas */
  legendStyles: propTypes.object,
  /** ID del contenedor de las leyendas */
  containerID: propTypes.oneOfType([propTypes.string, propTypes.number]),
  /** Clase del contenedor */
  containerClass: propTypes.string,
};

CustomChart.defaultProps = {
  options: {},
  title: '',
  handleClickElement: () => {},
  showLegend: false,
  width: 1200,
  height: 300,
  redraw: false,
  staticYAxis: true,
  scrollTo: null,
  legendStyles: {},
  containerID: 'legend-container',
  containerClass: '',
};

export default React.memo(CustomChart);
