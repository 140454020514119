/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { toast } from 'react-toastify';
import { BsFillGearFill } from 'react-icons/bs';

import Agrupador from '../../componentes/Agrupador';
import Default from '../../contenedores/Default';
import NaveTrampas from '../../componentes/NaveTrampas';
import Select from '../../componentes/Select';
import TablaPlantas from '../../componentes/TablaPlantas';
import TablaSurcos from '../../componentes/TablaSurcos';
import TextField from '../../componentes/TextField';
import TablaTemporada from '../../componentes/TablaTemporada';

import axios from '../../configuraciones/axios';
import {
  ELEMENTO_NO_ENCONTRADO,
  NAVE_NO_SELECCIONADA,
  NAVE_SIN_GEOLOCALIZACION,
  INVERNADERO_NO_SELECCIONADO,
} from '../../configuraciones/mensajes';
import endpoints, { TABLAS, SITIOS, INVERNADEROS, CULTIVOS, NAVES, } from '../../configuraciones/endpoints';

import { findPropertysEmpty, trim } from '../../utilidades/functions';
import { LETTERSNUMBERS, NUMBER_REGEX, EQUALS } from '../../utilidades/regex';
import estatus from '../../constantes/estatus';
import styles from './styles';
import paleta from '../../configuraciones/paleta';
import TablaGeolocalizacion from '../../componentes/TablaGeolocalizacion';

const tablaInicial = {
  plantas: [],
  trampas: [],
  trampasEnNave: [],
  cantidadTrampas: 0,
  cantidadPlantas: '0',
  surcos: [],
  cantidadSurcos: '0',
  plantasMonitoreadasPorCuadrante: 4,
  estatus: true,
  coordenadas: [],
  coordenadasSurcos: {
    latitudInicioPrimerSurco: null,
    longitudInicioPrimerSurco: null,
    latitudInicioUltimoSurco: null,
    longitudInicioUltimoSurco: null,
    latitudFinPrimerSurco: null,
    longitudFinPrimerSurco: null,
    latitudFinUltimoSurco: null,
    longitudFinUltimoSurco: null,
  },
  tablasVecinas: [],
  temporadas: [],
  temporadaCultivo: [],
};

const seleccionesDefault = {
  sitioID: '',
  invernaderoID: '',
  naveID: '',
};

const Tabla = ({ history, match }) => {
  const classes = styles();
  const { params: { id } } = match;

  const [formErrors, setFormErrors] = useState({});

  const [mostrarPlantas, setMostrarPlantas] = useState(false);
  const [mostrarSurcos, setMostrarSurcos] = useState(false);
  const [mostrarTrampas, setMostrarTrampas] = useState(false);
  const [mostrarGeolocalizacion, setMostrarGeolocalizacion] = useState(false);
  const [mostrarFechas, setMostrarFechas] = useState(false);

  const [tabla, setTabla] = useState({ ...tablaInicial });
  const [tablaSelecciones, setTablaSelecciones] = useState({ ...seleccionesDefault });

  const [sitios, setSitios] = useState([]);
  const [invernaderos, setInvernaderos] = useState([]);
  const [naves, setNaves] = useState([]);
  const [cultivos, setCultivos] = useState([]);

  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/tablas',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  const cancelar = useCallback(regresar, []);
  const cancelarTemporada = useCallback(() => {
    setMostrarFechas(false);
  }, []);

  const guardar = useCallback(() => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmTabla'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;

    const metodo = id ? 'put' : 'post';
    const { nave, ...tablaGuardar } = tabla;

    const objTrim = trim(tablaGuardar);
    const objGuardar = {
      ...objTrim,
      plantas: tabla.plantas || [],
      surcos: tabla.surcos || [],
      trampas: tabla.trampas || [],
      naveID: tablaSelecciones.naveID,
    };

    axios[metodo](TABLAS, objGuardar).then(regresar);
  }, [tabla, tablaSelecciones]);

  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.base.busqueda(INVERNADEROS)),
      axios.get(endpoints.naveCoordenadas()),
      axios.get(endpoints.base.busqueda(SITIOS)),
      axios.get(endpoints.base.busqueda(CULTIVOS)),
    ];
    if (id) promesas.push(axios.get(endpoints.base.url(TABLAS, id)));

    Promise.all(promesas).then(async (resultadosPromesas) => {
      const [invernaderosDb, navesDb, sitiosDb, cultivosDb, tablaDb] = resultadosPromesas;
      setInvernaderos(invernaderosDb);
      setNaves(navesDb);
      setSitios(sitiosDb);
      setCultivos(cultivosDb);

      if (tablaDb?.id) {
        const naveSeleccionada = navesDb.find(({ id }) => id === tablaDb.naveID)?.id;
        const invernaderoSeleccionado = navesDb.find(({ id }) => id === tablaDb.naveID)?.invernaderoID;
        const sitioSeleccionado = invernaderosDb.find(({ id }) => id === invernaderoSeleccionado)?.sitioID;
        const { hectareas, tipoSuelo } = await axios.get(endpoints.base.url(NAVES, naveSeleccionada));

        setTabla({
          ...tablaDb,
          cantidadPlantas: tablaDb.plantas.filter(({ activo }) => activo).length || '0',
          cantidadSurcos: tablaDb.surcos.filter(({ activo }) => activo).length || '0',
          coordenadas: tablaDb.coordenadas || [],
          coordenadasSurcos: tablaDb.coordenadasSurcos || tablaInicial.coordenadasSurcos,
          tablasVecinas: tablaDb.coordenadasTablasEnNave || tablaInicial.tablasVecinas,
          plantasMonitoreadasPorCuadrante: tablaDb.plantasMonitoreadasPorCuadrante || tablaInicial.plantasMonitoreadasPorCuadrante,
          invernaderoID: invernaderoSeleccionado,
          temporadaCultivo: tablaDb.temporadaCultivo || tablaInicial.temporadaCultivo,
          hectareas,
          tipoSuelo
        });
        setTablaSelecciones({ naveID: naveSeleccionada, invernaderoID: invernaderoSeleccionado, sitioID: sitioSeleccionado });
      } else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      } else
        setTabla({ ...tablaInicial });
      resolve();
    }).catch(reject);
  });

  const reiniciarTrampas = () => {
    const trampas = tabla.trampas.map(trampa => ({ ...trampa, activo: false }));
    const cantidadTrampas = trampas.filter(({ activo }) => activo).length || 0;
    return { cantidadTrampas, trampas };
  };

  const guardarPlantas = (plantas) => {
    setTabla((current) => ({ ...current, plantas, cantidadPlantas: plantas.filter(({ activo }) => activo).length }));
    setMostrarPlantas(false);
  };

  const guardarSurcos = (surcos) => {
    setTabla((current) => ({ ...current, surcos, cantidadSurcos: surcos.filter(({ activo }) => activo).length }));
    setMostrarSurcos(false);
  };

  const guardarGeolocalizacion = useCallback(({ coordenadas, coordenadasSurcos }) => {
    setTabla((current) => ({ ...current, coordenadas, coordenadasSurcos }));
    setMostrarGeolocalizacion(false);
  }, []);

  const guardarTrampas = useCallback((trampas) => {
    setTabla((current) => ({ ...current, trampas, cantidadTrampas: trampas.filter(({ activo }) => activo).length }));
    setMostrarTrampas(false);
  }, []);

  const consultarTemporadaSitio = (e) => {
    const { target: { value: sitioID } } = e;
    axios.get(endpoints.base.url(SITIOS, sitioID)).then((resultado) => {
      const { temporadas } = resultado;
      setTablaSelecciones({ sitioID: e.target.value });
      setTabla((current) => ({
        ...current,
        sitioID: id,
        invernaderoID: '',
        coordenadas: [],
        temporadas,
        ...reiniciarTrampas()
      }));
    }).catch();
  }

  const existeFechasCultivo = () => {
    if (!tabla.temporadaCultivo) return 'Temporada de sitio no registrada';
    if (tabla.temporadaCultivo.length <= 0) return 'Temporada de sitio no registrada';
    if (tabla.temporadaCultivo.length <= 0) return 'Temporada de cultivo sin registrar';
    return `${tabla.temporadaCultivo[0]?.fechaCultivo} - ${tabla.temporadaCultivo[0]?.fechaCorte}`
  }

  const validarTemporada = (setState) => {
    if (!tabla.invernaderoID) {
      toast.error(INVERNADERO_NO_SELECCIONADO);
      return;
    }
    setMostrarFechas(true);
  }

  const guardarTemporada = useCallback((temporada) => {
    setTabla((current) => ({ ...current, temporadaCultivo: [{ ...temporada }] }));
    setMostrarFechas(false);
  }, [tabla]);

  const handleSelectChange = (e) => {
    switch (e.target.name) {
      case 'invernaderoID':
        setTablaSelecciones({ sitioID: tablaSelecciones.sitioID, invernaderoID: e.target.value });
        setTabla(tabla => ({ ...tabla, invernaderoID: e.target.value, ...reiniciarTrampas() }));
        break;
      case 'naveID':
        setTablaSelecciones({ ...tablaSelecciones, naveID: e.target.value });
        break;
      default:
        break;
    }
  };

  const handleMostrarTrampas = () => {
    const nave = naves.find(({ id }) => id === tablaSelecciones.naveID);
    if (!tablaSelecciones.naveID) {
      return toast.warning(NAVE_NO_SELECCIONADA);
    } else if (nave.coordenadas?.length > 0) {
      setMostrarTrampas(true);
    } else {
      toast.warning(NAVE_SIN_GEOLOCALIZACION);
    }
  };

  const handleNaveChange = async (e) => {
    try {
      const promesas = [
        axios.get(endpoints.naveTrampas(e.target.value), {
          params: { exceptoTablaID: id },
        }),
        axios.get(endpoints.naveTablas(e.target.value), {
          params: { exceptoTablaID: id },
        }),
        axios.get(endpoints.base.url(NAVES, e.target.value))
      ];
      const [naveTrampas, naveTablas, { hectareas, tipoSuelo }] = await Promise.all(promesas);
      handleSelectChange(e);
      setTabla(tabla => ({
        ...tabla,
        hectareas,
        tipoSuelo,
        trampasEnNave: naveTrampas,
        tablasVecinas: naveTablas,
      }));
    } catch { }
  };

  const handleCerrarGeolocalizacion = useCallback(() => {
    setMostrarGeolocalizacion(false);
  }, []);

  useEffect(() => {
    consultarDatosIniciales();
  }, [id]);

  return <Default
    titulo={Boolean(id) ? 'Editar Tabla' : 'Nueva Tabla'}
    placeHolder={''}
    mostrarCabeceroFormulario
    guardar={guardar}
    cancelar={cancelar}>
    <Grid container spacing={2} id="frmTabla">
      {/* Drawers */}
      <TablaPlantas
        mostrar={mostrarPlantas}
        cancelar={() => setMostrarPlantas(false)}
        plantas={tabla.plantas}
        guardar={guardarPlantas}
        tablaID={id}
      />
      <TablaSurcos
        mostrar={mostrarSurcos}
        cancelar={() => setMostrarSurcos(false)}
        surcos={tabla.surcos}
        guardar={guardarSurcos}
        tablaID={id}
      />
      <NaveTrampas
        mostrar={mostrarTrampas}
        cancelar={() => setMostrarTrampas(false)}
        trampas={tabla.trampas}
        trampasActivas={tabla.trampasEnNave}
        guardar={guardarTrampas}
        tablaID={id}
        naveID={tablaSelecciones.naveID}
        naveCoordenadas={naves.find(({ id }) => id === tablaSelecciones.naveID)?.coordenadas || []}
      />
      <TablaTemporada
        mostrar={mostrarFechas}
        cancelar={cancelarTemporada}
        guardar={guardarTemporada}
        tabla={tabla}
      />
      <TablaGeolocalizacion
        tablaID={id}
        mostrar={mostrarGeolocalizacion}
        guardar={guardarGeolocalizacion}
        cancelar={handleCerrarGeolocalizacion}
        naveCoordenadas={naves.find(({ id }) => id === tablaSelecciones.naveID)?.coordenadas || []}
        coordenadas={tabla.coordenadas}
        coordenadasSurcos={tabla.coordenadasSurcos}
        poligonosVecinos={tabla.tablasVecinas}
        cantidadSurcos={Number(tabla.cantidadSurcos)}
        cantidadCuadrantes={tabla.cantidadCuadrantesSurco}
      />

      {/* Formulario */}
      <Grid item xs={12} md={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Código de Tabla"
              name="codigo"
              value={tabla.id || ''}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nombre"
              onChange={setTabla}
              name="nombre"
              value={tabla.nombre || ''}
              isHandleChange
              inputProps={{ maxLength: 50, regex: LETTERSNUMBERS }}
              error={formErrors.nombre}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Sitio"
              required
              options={sitios}
              onChange={e => { consultarTemporadaSitio(e) }}
              name="sitioID"
              value={tablaSelecciones.sitioID}
              labelProp="nombre"
              error={formErrors.sitioID}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Invernadero"
              required
              options={invernaderos.filter(({ sitioID }) => tablaSelecciones.sitioID === sitioID)}
              onChange={e => handleSelectChange(e)}
              name="invernaderoID"
              value={tablaSelecciones.invernaderoID}
              labelProp="nombre"
              error={formErrors.invernaderoID}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Nave"
              required
              options={naves.filter(({ invernaderoID }) => tablaSelecciones.invernaderoID === invernaderoID)}
              onChange={handleNaveChange}
              name="naveID"
              value={tablaSelecciones.naveID}
              labelProp="nombre"
              error={formErrors.naveID}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Cultivo"
              required
              options={cultivos}
              labelProp="nombre"
              onChange={({ target: { value } }) => {
                setTabla((current) => ({ ...current, cultivoID: value }));
              }}
              name="cultivoID"
              value={tabla.cultivoID || ''}
              error={formErrors.cultivoID}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Estatus"
              required
              options={estatus}
              onChange={setTabla}
              name="estatus"
              value={tabla.estatus}
              error={formErrors.estatus}
              isHandleChange
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Variedad"
              onChange={setTabla}
              name="variedad"
              value={tabla.variedad}
              isHandleChange
              inputProps={{ maxLength: 50 }}
              error={formErrors.variedad}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="flex-end">
              <Grid item xs={10}>
                <TextField
                  label="Temporada de cultivo"
                  name="temporadaCultivo"
                  value={existeFechasCultivo()}
                  error={formErrors.temporadaCultivo}
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => validarTemporada(setMostrarFechas)}>
                  <BsFillGearFill color={paleta.bar.primary} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="flex-end">
              <Grid item xs={10}>
                <TextField
                  label="Cantidad de trampas"
                  name="cantidadTrampas"
                  value={tabla.trampas.filter(({ activo }) => activo).length || '0'}
                  inputProps={{ regex: NUMBER_REGEX, regexonchange: 'true', maxLength: 9 }}
                  error={formErrors.cantidadTrampas}
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={handleMostrarTrampas}>
                  <BsFillGearFill color={paleta.bar.primary} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <TextField
              label="Plantas a Monitorear"
              name="cantidadPlantas"
              value={tabla.plantas.filter(({ activo }) => activo).length || '0'}
              inputProps={{ regex: NUMBER_REGEX, regexonchange: 'true', maxLength: 9 }}
              error={formErrors.cantidadPlantas}
              required
              disabled
            />
          </Grid>
          <Grid item xs={1} className={classes.contenedorBtnConfig}>
            <IconButton onClick={() => setMostrarPlantas(true)}>
              <BsFillGearFill className={classes.btnConfig} />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Cantidad de Polines"
              onChange={setTabla}
              name="cantidadPolines"
              value={tabla.cantidadPolines}
              isHandleChange
              inputProps={{ regex: NUMBER_REGEX, regexonchange: 'true', maxLength: 9 }}
              error={formErrors.cantidadPolines}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Plantas por Surco a Monitorear"
              onChange={setTabla}
              name="plantasMonitoreadasPorCuadrante"
              value={tabla.plantasMonitoreadasPorCuadrante}
              isHandleChange
              inputProps={{ regex: NUMBER_REGEX, regexonchange: 'true', maxLength: 9 }}
              error={formErrors.plantasMonitoreadasPorCuadrante}
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={4}>
        <Agrupador>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <TextField
                label="Cantidad de Surcos"
                name="cantidadSurcos"
                value={tabla.surcos.filter(({ activo }) => activo).length || '0'}
                inputProps={{ regex: NUMBER_REGEX, regexonchange: 'true', maxLength: 9 }}
                error={formErrors.cantidadSurcos}
                required
                disabled
              />
            </Grid>
            <Grid item xs={1} className={classes.contenedorBtnConfig}>
              <IconButton onClick={() => setMostrarSurcos(true)}>
                <BsFillGearFill className={classes.btnConfig} />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Cantidad de Plantas por Surco"
                onChange={setTabla}
                name="cantidadPlantasSurco"
                value={tabla.cantidadPlantasSurco}
                isHandleChange
                inputProps={{ regex: NUMBER_REGEX, regexonchange: 'true', maxLength: 9 }}
                error={formErrors.cantidadPlantasSurco}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Cantidad de Cuadrantes por Surco"
                onChange={setTabla}
                name="cantidadCuadrantesSurco"
                value={tabla.cantidadCuadrantesSurco}
                isHandleChange
                inputProps={{ regex: NUMBER_REGEX, regexonchange: 'true', maxLength: 9 }}
                error={formErrors.cantidadCuadrantesSurco}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Tamaño Surco"
                onChange={setTabla}
                name="tamanoSurco"
                value={tabla.tamanoSurco}
                isHandleChange
                inputProps={{ regex: NUMBER_REGEX, regexonchange: 'true', maxLength: 9 }}
                error={formErrors.tamanoSurco}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Longitud Surco"
                onChange={setTabla}
                name="longitudSurco"
                value={tabla.longitudSurco}
                isHandleChange
                inputProps={{ regex: NUMBER_REGEX, regexonchange: 'true', maxLength: 9 }}
                error={formErrors.longitudSurco}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Marca Surco"
                onChange={setTabla}
                name="marcaSurco"
                value={tabla.marcaSurco || ''}
                isHandleChange
                inputProps={{ regex: LETTERSNUMBERS, regexonchange: 'true', maxLength: 50 }}
                error={formErrors.marcaSurco}
                required
              />
            </Grid>

            {/* Geolocation */}
            <Grid item xs={10}>
              <TextField
                label="Geolocalización (tabla, surco y cuadrante)"
                name="geolocalizacion"
                value={
                  tabla.coordenadasSurcos.longitudInicioPrimerSurco && tabla.coordenadasSurcos.longitudFinUltimoSurco
                    ? 'CONFIGURADO'
                    : 'NO CONFIGURADO'
                }
                inputProps={{ regex: EQUALS, regexonsubmit: 'true', equals: 'CONFIGURADO' }}
                error={formErrors.geolocalizacion}
                required
                disabled
              />
            </Grid>
            <Grid item xs={1} className={classes.contenedorBtnConfig}>
              <IconButton onClick={() => setMostrarGeolocalizacion(true)}>
                <BsFillGearFill className={classes.btnConfig} />
              </IconButton>
            </Grid>
            {/* End Geolocation */}
          </Grid>
        </Agrupador>
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(Tabla);
