import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { toast } from 'react-toastify';
import RightDrawer from '../RightDrawer';
import HeaderSubcatalogo from '../HeaderSubcatalogo';
import Tabla from '../Table';
import TextField from '../TextField';
import Select from '../Select';
import { findPropertysEmpty } from '../../utilidades/functions';
import estatus from '../../constantes/estatus';
import { NOMBRE_PLANTA_REPETIDO } from '../../configuraciones/mensajes';
import styles from './styles';
import {  NUMBER_REGEX } from '../../utilidades/regex';

const TablaPlanta = ({ mostrar, guardar, cancelar, plantas, tablaID }) => {
  const classes = styles();
  const cabeceros = [
    {
      label: 'Código',
      key: 'id'
    },
    {
      label: 'Nombre',
      transform: ({ nombre, repetido }, index) => (
        <TextField
          name="nombre"
          onChange={(e) => manejadorPlanta(e, index)}
          value={nombre}
          required
          inputProps={{ regex: NUMBER_REGEX, regexonchange: 'true', maxLength: 9 }}
          error={(formErrors.nombre && (!nombre || nombre === '')) || repetido}
          className={classes.input}
        />
      ),
    },
    {
      label: 'Estatus',
      transform: (s, index) => (
        <Select
          required
          options={estatus}
          onChange={(e) => manejadorPlanta(e, index)}
          name="estatus"
          value={s.estatus}
          error={formErrors.estatus && !s.hasOwnProperty('estatus')}
          style={{minWidth: 125}}
        />
      )
    },
  ];
  const [formErrors, setFormErrors] = useState({});
  const [nuevasPlantas, setNuevasPlantas] = useState([]);
  const [cantidadElementos, setCantidadElementos] = useState('');
  const [anchorConfirmacion, setAnchorConfirmacion] = useState(null);

  const handleConfirmacion = (event) => {
		setAnchorConfirmacion(event.currentTarget);
	};

	const handleCancelar = () => {
		setAnchorConfirmacion(null);
	};

  const validarNombresRepetidos = () => {
    let todosValidos = true;
    const edicionPlantas = [...nuevasPlantas];
    let index = 0;
    for (const p of edicionPlantas) {
      const indexExiste = nuevasPlantas.findIndex(({ nombre, activo }) => nombre === p.nombre && activo && p.activo);
      p.repetido = indexExiste >= 0 && indexExiste !== index;
      if (todosValidos && p.repetido) todosValidos = false;
      index++;
    }
    setNuevasPlantas(edicionPlantas);
    return todosValidos;
  };

  const isHandleChangeElementos = (e) => {
    const limitNumber = /^(?:[0-9]\d?|[12]\d{2}|2[0-5]\d|2[0-9])$/;
    if (e.target.value === '' || limitNumber.test(e.target.value)) {
      setCantidadElementos(e.target.value);
    }
  };


	const handleAgregarPlantas = (cantidad) => {
		let plantasAgregadas = [...nuevasPlantas];
		const valorActual = nuevasPlantas.length;
		if (cantidad >= valorActual) {
			for (let i = valorActual; i < cantidad; i++) {
				plantasAgregadas.push({
					activo: true,
					nombre: i + 1,
					estatus: true,
					isSelected: false,
          tablaID,
				});
			}
		} else {
			for (let i = valorActual; i >= cantidad; i--) {
        plantasAgregadas.splice(i, 1);
      }
		}
    if(plantas.length > cantidad){
      return toast.warning('La cantidad de plantas debe ser mayor a la actual');
    } 
    setNuevasPlantas(plantasAgregadas);
	};
  const manejadorKeyDown = (e) => {
		if (e.key === 'Enter') handleAgregarPlantas(cantidadElementos);
	};

  const agregar = () => {
    const planta = {
      activo: true,
      nombre: nuevasPlantas.filter(({ activo }) => activo).length + 1,
      estatus: true,
      isSelected: false,
    };
    if (tablaID) planta.tablaID = tablaID;
    setNuevasPlantas((current) => [...current, planta]);
    setCantidadElementos(nuevasPlantas.filter(({ activo }) => activo).length + 1);
  };
  
  const manejadorPlanta = (e, index) => {
    const edicionPlanta = [...nuevasPlantas];
    edicionPlanta.filter(({ activo }) => activo)[index][e.target.name] = e.target.value;
    setNuevasPlantas(edicionPlanta);
  };

  const customGuardar = () => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmPlantas'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;
    const validos = validarNombresRepetidos();
    if (!validos) {
      return toast.warning(NOMBRE_PLANTA_REPETIDO);
    }
    guardar(nuevasPlantas);
  };

  const eliminarTodoSurcos = () => {
		const edicionPlantas = [...nuevasPlantas];
		const plantasActivas = edicionPlantas.map((planta) => {
			if (planta.isSelected) {
				return {
					...planta,
					activo: false,
					estatus: false,
				};
			}
			return planta;
		});
    setCantidadElementos(plantasActivas.filter(({ activo }) => activo).length);
		setNuevasPlantas(plantasActivas);
		setAnchorConfirmacion(null);
	};

  const seleccionarTodos = (flag) => {
    const edicionPlantas = [...nuevasPlantas];
    const plantasActivas = edicionPlantas.map((planta) => {
      return {
        ...planta,
        isSelected: !flag
      }
    });
    setNuevasPlantas(plantasActivas);
  };


  const seleccionarSurco = (row) => {
    const index = nuevasPlantas.findIndex((surco) => surco.id === row.id);
    if (index !== -1) {
      const edicioPlantas = [...nuevasPlantas];
      edicioPlantas[index].isSelected = !edicioPlantas[index].isSelected;
      setNuevasPlantas(edicioPlantas);
    }
  }

  useEffect(() => {
    setNuevasPlantas([...plantas].map((planta) => {
      return {
        ...planta,
        isSelected: false,
      };
    }),);
    setCantidadElementos([...plantas].length);
  }, [plantas, mostrar]);
  
  return (
    <RightDrawer
      paperClassName={classes.paperDrawer}
      isOpen={mostrar}
      header={<HeaderSubcatalogo
        titulo="Plantas"
        subtitulo="Configuración"
        guardar={customGuardar}
        cerrar={cancelar}
        agregar={agregar}
        eliminar={eliminarTodoSurcos} 
        anchor={anchorConfirmacion}
        onClose={handleCancelar}
        openConfirmacion={handleConfirmacion}
        textField={{
          label: 'Cantidad plantas a monitorear',
          name: 'txtCantidadPlantas',
          value: cantidadElementos,
          onChange: isHandleChangeElementos,
          onKeyDown: manejadorKeyDown,
        }}
      />}>
      <div id="frmPlantas">
        <Tabla
          showCheckbox
          headers={cabeceros}
          rows={nuevasPlantas.filter(({ activo }) => activo)}
          hidePaginator
          onSelectAll={seleccionarTodos}
          onSelect={seleccionarSurco}
          showActions={false}
        />
      </div>
    </RightDrawer>
  );
};

TablaPlanta.propTypes = {
  mostrar: propTypes.bool.isRequired,
  guardar: propTypes.func.isRequired,
  cancelar: propTypes.func.isRequired,
  plantas: propTypes.array.isRequired,
  tablaID: propTypes.oneOfType([propTypes.string, propTypes.number]),
};

TablaPlanta.defaultProps = {
  tablaID: null,
};

export default TablaPlanta;
