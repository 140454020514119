/* eslint prefer-promise-reject-errors: 0 */
import axios from 'axios';
import { toast } from 'react-toastify';

import general from './general';
import { STORAGE } from '../ducks/user';
import { ERROR, SUCCESS } from './mensajes';

const tableroAPI = axios.create({
  baseURL: general.URL_API,
});

// Se agrega un interceptor para manejar las peticiones a la API
// Se inyecta el token en el header de la petición
tableroAPI.interceptors.request.use((config) => {
    const user = JSON.parse(localStorage.getItem(STORAGE) || sessionStorage.getItem(STORAGE) || '{}');
    const token = user.token || '';
    config.headers.Authorization = token;
    return config;
  }
);

// Se agrega un interceptor para manejar las respuestas de la API
tableroAPI.interceptors.response.use(({ data, status }) => {
  if (status === 201 || status === 204) toast.success(SUCCESS);
  return data;
},
  ({ response }) => {
    let isError = false;
    let isMessage = false;
    if (response) {
      const { status, data } = response;
      if ((typeof data === 'string' || data instanceof String) && data.trim() !== '') {
        if (!data.includes('html')) isMessage = true;
      }
      if (status !== 404 && status !== 403) isError = true;
    } else {
      isError = true;
      isMessage = false;
    }

    toast.error(isMessage ? response.data : ERROR, { toastId: `AXIOS_ERROR_${response.status || 500}` });
    return Promise.reject({ ...response, isError, isMessage });
  });

export default tableroAPI;
