import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  subtitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  caption: {
    color: paleta.textField.text,
    fontSize: '0.75rem',
    marginBottom: 10,
  },
}));

export default styles;
