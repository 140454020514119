import React, { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { TABLAS } from '../../configuraciones/endpoints';
import { getInvernaderos, getNaves, getSitios } from '../../ducks/listados';

const cabeceros = [
  { label: 'Codigo', key: 'id' },
  { label: 'Nombre', key: 'nombre' },
  { label: 'Nave', transform: ({ nave }) => nave?.nombre },
  { label: 'Invernadero', transform: ({ nave }) => nave?.invernadero?.nombre },
  { label: 'Sitio', transform: ({ nave }) => nave?.invernadero?.sitio?.nombre },
  { label: 'Cultivo', transform: ({ cultivo }) => cultivo?.nombre },
  { label: 'Estatus', transform: ({ estatus }) => <Estatus activo={estatus} /> },
];

const Tablas = ({ history, location }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    batch(() => {
      dispatch(getSitios());
      dispatch(getInvernaderos());
      dispatch(getNaves());
    });
  }, [dispatch]);

  return <Listados
    titulo="Tablas"
    placeHolder="Búsqueda por nombre"
    history={history}
    location={location}
    showActions
    cabeceros={cabeceros || []}
    apiKey={TABLAS}
    filtrosBusqueda={{ sitio: true, invernadero: true, nave: true }}
  />;
};

export default Tablas;