import { Box, Button, makeStyles, styled } from '@material-ui/core';
import { BsPlusCircle } from 'react-icons/bs';

import TextField from '../../../../componentes/TextField';
import Typography from '../../../../componentes/Typography';

const styles = makeStyles(() => ({
  tabla: {
    boxSizing: 'border-box',
    maxHeight: 292,
  },
  input: {
    width: '100%',
  }
}));

export const RowTextField = styled(({ id, ...props }) => (
  <Box display="flex" justifyItems="center" alignItems="center" flex={1} zIndex={10}>
    <TextField
      className={styles().input}
      inputClassName={styles().input}
      inputProps={{ id }}
      onClick={(event) => event.stopPropagation()}
      {...props}
    />
  </Box>
))({
  height: 32,
  marginTop: 1,
  width: '80%',
  '& > div > input': {
    margin: 0,
    minHeight: 32,
    height: 32,
  },
});

export const BotonAgregarDivison = styled(({ ...props }) => (
  <Button
    variant="text"
    startIcon={<BsPlusCircle size={16} />}
    classes={{ label: 'label' }}
    {...props}
  >
    <Typography component="span">Agregar División</Typography>
  </Button>
))({
  height: 38,
  fontSize: 12,
  fontWeight: 'bold',
  width: 'fit-content',

  '& .label': {
    alignItems: 'center',
  }
});


export default styles;
