import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  title: {
    color: `${paleta.activo.pronostico}66`,
    marginTop: 8,
    fontSize: 8.5,
    "&.active": {
      color: paleta.activo.pronostico,
    },
  },
  subTitle: {
    fontSize: 13,
    color: paleta.bar.primary
  },
  paper: {
    maxHeight: 'fit-content',
    border: `solid ${paleta.bar.pronostico}`,
    borderRadius: 10,
    borderWidth: 2,
    minWidth: 95,
    padding: 10,
    paddingLeft: 2,
    height: 60,
    width: 'auto',

    transition: 'border-color 100ms ease. background-color 100 ease',
    "&.active": {
      backgroundColor: paleta.bar.pronostico,
      borderColor: paleta.bar.pronostico,
    },
    "&.disabled": {
      filter: 'grayscale(60%)',
    },
    "&.selected": {
      borderColor: paleta.activo.pronostico,
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginTop: 2,
    color: `${paleta.bar.point}`,
    "&.active": {
      color: paleta.activo.pronostico,
    },
  },
  animation: {
    animation: `$myEffect 200ms linear`,
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  animar: {
    animationDuration: '0.5s',
    animationName: '$aparecer',
    animationTimingFunction: 'cubic-bezier(.26, .53, .74, 1.48)',
    animationFillMode: 'backwards'
  },
  '@keyframes aparecer': {
    '0%': {
      opacity: 0,
      transform: 'scale(0.5, 0.5)'
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1, 1)',
    }
  },
}));

export default styles;
