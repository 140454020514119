/* eslint-disable import/no-anonymous-default-export */
import React, { Suspense, useEffect, useState } from 'react';
import {
  HashRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import AppBar from '../componentes/AppBar';

import NotFound from '../paginas/404';
import Forbidden from '../paginas/401';
import Login from '../paginas/Login';
import Dashboard from '../paginas/Dashboard';
import Perfiles from '../paginas/Perfiles';
import Perfil from '../paginas/Perfil';
import Usuarios from '../paginas/Usuarios';
import Usuario from '../paginas/Usuario';
import Naves from '../paginas/Naves';
import Nave from '../paginas/Nave';
import Fenologias from '../paginas/Fenologias';
import Fenologia from '../paginas/Fenologia';
import Irrigaciones from '../paginas/Irrigaciones';
import Irrigacion from '../paginas/Irrigacion';
import Enfermedades from '../paginas/Enfermedades';
import Enfermedad from '../paginas/Enfermedad';
import Sitios from '../paginas/Sitios';
import Sitio from '../paginas/Sitio';
import Invernaderos from '../paginas/Invernaderos';
import Invernadero from '../paginas/Invernadero';
import Actividades from '../paginas/Actividades';
import Actividad from '../paginas/Actividad';
import Tablas from '../paginas/Tablas';
import Tabla from '../paginas/Tabla';
import NivelesInfeccion from '../paginas/NivelesInfeccion';
import NivelInfeccion from '../paginas/NivelInfeccion';
import NivelesPolinizacion from '../paginas/NivelesPolinizacion';
import NivelPolinizacion from '../paginas/NivelPolinizacion';
import Saneos from '../paginas/Saneos';
import Saneo from '../paginas/Saneo';
import UnidadesMedida from '../paginas/UnidadesMedida';
import UnidadMedida from '../paginas/UnidadMedida';
import Cultivos from '../paginas/Cultivos';
import Cultivo from '../paginas/Cultivo';
import Tablero from '../paginas/Tablero';
import Temporada from '../paginas/Temporada';
import TemporadaDetalles from '../paginas/TemporadaDetalles';
import Temporadas from '../paginas/Temporadas';
import TemporadasHistorico from '../paginas/TemporadasHistorico';
import NaveTablero from '../paginas/NaveTablero';
import ConfiguracionFenologias from '../paginas/ConfiguracionFenologias';
import ConfiguracionPolinizaciones from '../paginas/ConfiguracionPolinizaciones';
import ConfiguracionEnfermedades from '../paginas/ConfiguracionEnfermedades';
import ConfiguracionIrrigaciones from '../paginas/ConfiguracionIrrigaciones';
import ReportesIrrigaciones from '../paginas/ReportesIrrigaciones';
import ReportesEnfermedades from '../paginas/ReportesEnfermedades';
import ReportesTrampas from '../paginas/ReporteTrampas';
import ReportesFenologiasPromedio from '../paginas/ReportesFenologiasPromedio';
import ReportesFenologiasPlantas from '../paginas/ReportesFenologiasPlantas';
import ReportePolinizacionDetallado from '../paginas/ReportePolinizacionDetallado';
import ReportePolinizacionAgrupado from '../paginas/ReportePolinizacionAgrupado';
import ReporteNematodos from '../paginas/ReporteNematodos';
import ReporteSaneos from '../paginas/ReporteSaneos';

export default () => {
  const user = useSelector(store => store.user);

  const PrivateRoute = ({ component, ...rest }) => {
    const [tienePermiso, setTienePermiso] = useState(true);
    const { isAuth, permisos, permisosConsultados } = useSelector(
      ({
        user: { isAuth }, appbar: { permisos, permisosConsultados }
      }) => ({ isAuth, permisos, permisosConsultados }),
      (prev, next) => {
        return (
          prev.isAuth === next.isAuth &&
          prev.permisosConsultados === next.permisosConsultados &&
          isEqual(prev.permisos, next.permisos)
        )
      }
    );
    rest.location.state = { ...rest.state, ...rest.location.state };

    useEffect(() => {
      if (permisosConsultados && rest.location.state.moduloID) {
        setTienePermiso(Boolean(permisos.find((permiso) => permiso.moduloID === rest.location.state.moduloID)));
      }
    }, [rest.location, permisos, permisosConsultados]);

    return (
      <Route
        {...rest}
        render={(props) => (isAuth && tienePermiso ? (
          React.createElement(component, props)
        ) : (
          <Redirect to={{ pathname: !isAuth ? '/login' : '/forbidden', state: { from: props.location, }, }} />
        ))
        }
      />
    );
  };
  const PublicRoute = ({ component, ...rest }) => {
    rest.location.state = { ...rest.state, ...rest.location.state };
    const { isAuth } = useSelector(({ user: { isAuth } }) => ({ isAuth }));
    return (
      <Route
        {...rest}
        render={(props) => (isAuth ? (
          <Redirect to={{ pathname: '/tablero', }} />
        ) : (
          React.createElement(component, props)
        ))}
      />
    );
  };

  return (
    <HashRouter>
      {(user.isAuth) && <AppBar />}
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <PublicRoute path={['/', '/login']} component={Login} exact />
          <PrivateRoute path="/dashboard" component={Dashboard} exact />
          <PrivateRoute path="/catalogos/usuarios" component={Usuarios} state={{ moduloID: 1 }} exact />
          <PrivateRoute path="/catalogos/usuarios/formulario/:id?" state={{ moduloID: 1 }} component={Usuario} exact />
          <PrivateRoute path="/catalogos/perfiles" component={Perfiles} state={{ moduloID: 2 }} exact />
          <PrivateRoute path="/catalogos/perfiles/formulario/:id?" component={Perfil} state={{ moduloID: 2 }} exact />
          <PrivateRoute path="/catalogos/sitios" component={Sitios} state={{ moduloID: 3 }} exact />
          <PrivateRoute path="/catalogos/sitios/formulario/:id?" component={Sitio} state={{ moduloID: 3 }} exact />
          <PrivateRoute path="/catalogos/naves" component={Naves} state={{ moduloID: 4 }} exact />
          <PrivateRoute path="/catalogos/naves/formulario/:id?" component={Nave} state={{ moduloID: 4 }} exact />
          <PrivateRoute path="/catalogos/invernaderos" component={Invernaderos} state={{ moduloID: 5 }} exact />
          <PrivateRoute path="/catalogos/invernaderos/formulario/:id?" component={Invernadero} state={{ moduloID: 5 }} exact />
          <PrivateRoute path="/catalogos/tablas" component={Tablas} state={{ moduloID: 6 }} exact />
          <PrivateRoute path="/catalogos/tablas/formulario/:id?" component={Tabla} state={{ moduloID: 6 }} exact />
          <PrivateRoute path="/catalogos/enfermedades" component={Enfermedades} state={{ moduloID: 7 }} exact />
          <PrivateRoute path="/catalogos/enfermedades/formulario/:id?" state={{ moduloID: 7 }} component={Enfermedad} exact />
          <PrivateRoute path="/catalogos/fenologias" component={Fenologias} state={{ moduloID: 8 }} exact />
          <PrivateRoute path="/catalogos/fenologias/formulario/:id?" state={{ moduloID: 8 }} component={Fenologia} exact />
          <PrivateRoute path="/catalogos/irrigaciones" component={Irrigaciones} state={{ moduloID: null }} exact />
          <PrivateRoute path="/catalogos/irrigaciones/formulario/:id?" component={Irrigacion} state={{ moduloID: null }} exact />
          <PrivateRoute path="/catalogos/saneos" component={Saneos} state={{ moduloID: 10 }} exact />
          <PrivateRoute path="/catalogos/saneos/formulario/:id?" state={{ moduloID: 10 }} component={Saneo} exact />
          <PrivateRoute path="/catalogos/nivelesinfeccion" component={NivelesInfeccion} state={{ moduloID: 11 }} exact />
          <PrivateRoute path="/catalogos/nivelesinfeccion/formulario/:id?" state={{ moduloID: 11 }} component={NivelInfeccion} exact />
          <PrivateRoute path="/catalogos/actividades" component={Actividades} state={{ moduloID: 12 }} exact />
          <PrivateRoute path="/catalogos/actividades/formulario/:id?" component={Actividad} state={{ moduloID: 12 }} exact />
          <PrivateRoute path="/catalogos/nivelespolinizacion" component={NivelesPolinizacion} state={{ moduloID: 13 }} exact />
          <PrivateRoute path="/catalogos/nivelespolinizacion/formulario/:id?" state={{ moduloID: 13 }} component={NivelPolinizacion} exact />
          <PrivateRoute path="/catalogos/unidadesmedida" component={UnidadesMedida} state={{ moduloID: 14 }} exact />
          <PrivateRoute path="/catalogos/unidadesmedida/formulario/:id?" component={UnidadMedida} state={{ moduloID: 14 }} exact />
          <PrivateRoute path="/catalogos/cultivos" component={Cultivos} state={{ moduloID: 25 }} exact />
          <PrivateRoute path="/catalogos/cultivos/formulario/:id?" component={Cultivo} state={{ moduloID: 25 }} exact />

          <PrivateRoute path="/tablero/" component={Tablero} state={{ moduloID: 23 }} exact />
          <PrivateRoute path="/tablero/nave/:id?" component={NaveTablero} state={{ moduloID: 24 }} exact />

          <PrivateRoute path="/configuraciones/fenologias" component={ConfiguracionFenologias} state={{ moduloID: 28 }} exact />
          <PrivateRoute path="/configuraciones/irrigaciones" component={ConfiguracionIrrigaciones} state={{ moduloID: 27 }} exact />
          <PrivateRoute path="/configuraciones/enfermedades" component={ConfiguracionEnfermedades} state={{ moduloID: 29 }} exact />
          <PrivateRoute path="/configuraciones/polinizaciones" component={ConfiguracionPolinizaciones} state={{ moduloID: 30 }} exact />

          <PrivateRoute path="/reportes/fenologiaspromedio" component={ReportesFenologiasPromedio} state={{ moduloID: 31 }} exact />
          <PrivateRoute path="/reportes/fenologiasplanta" component={ReportesFenologiasPlantas} state={{ moduloID: 31 }} exact />
          <PrivateRoute path="/reportes/irrigaciones" component={ReportesIrrigaciones} state={{ moduloID: 32 }} exact />
          <PrivateRoute path="/reportes/enfermedades" component={ReportesEnfermedades} state={{ moduloID: 33 }} exact />
          <PrivateRoute path="/reportes/trampas" component={ReportesTrampas} state={{ moduloID: 33 }} exact />
          <PrivateRoute path="/reportes/saneos" component={ReporteSaneos} state={{ moduloID: 34 }} exact />
          <PrivateRoute path="/reportes/polinizacion/detallado" component={ReportePolinizacionDetallado} state={{ moduloID: 35 }} exact />
          <PrivateRoute path="/reportes/polinizacion/agrupado" component={ReportePolinizacionAgrupado} state={{ moduloID: 35 }} exact />
          <PrivateRoute path="/reportes/nematodos" component={ReporteNematodos} state={{ moduloID: 36 }} exact />

          <PrivateRoute path="/temporadas/listado" component={Temporadas} state={{ moduloID: 38 }} exact />
          <PrivateRoute path="/temporadas/formulario/:id?" component={Temporada} state={{ moduloID: 38 }} exact />
          <PrivateRoute path="/temporadas/detalles/:id?" component={TemporadaDetalles} state={{ moduloID: 38 }} exact />
          <PrivateRoute path="/temporadas/historico" component={TemporadasHistorico} state={{ moduloID: 39 }} exact />

          <Route path="/forbidden" component={Forbidden} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </HashRouter>
  );
};
