export const semaforo = {
  verde: 'VERDE',
  amarillo: 'AMARILLO',
  rojo: 'ROJO',
  indefinido: 'INDEFINIDO',
  vegetativo: 'VEGETATIVO',
  generativo: 'GENERATIVO',
  balanceado: 'BALANCEADO',
};

export default semaforo;