import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  titleContainer: {
    marginBottom: 20,
  },
  title: {
    fontWeight: 600,
    '& .subtitle': {
      fontSize: 10,
      marginLeft: 10,
      color: paleta.bar.elements,
    },
  },
  infoContainer: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column-reverse'
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    '& button': {
      margin: '0 3px',
      [theme.breakpoints.only('xs')]: {
        margin: '0 10px 12px -5px',
        transform: 'scale(0.9)',
      },
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%',
      flexBasis: '100%',
      justifyContent: 'stretch'
    },
  },
  txtContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    '& button': {
      color: paleta.bar.primary,
    },
    [theme.breakpoints.only('xs')]: {
      flexBasis: '100%',
      maxWidth: '100%'
    },
  },
  label: {
    textAlign: 'left',
    color: paleta.bar.primary,
    fontSize: '12px',
    fontWeight: 'bolder',
    paddingBottom: '1em',
    paddingLeft: '5px',
  }
}));

export default styles;
