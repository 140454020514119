import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: `${paleta.bar.primary}`,
  },
  container: {
    position: 'relative'
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60vh'
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    borderColor: paleta.bar.primary,
    borderLeft: '2px dotted',
    padding: 6,
    position: 'relative',
  },
  labelTitle: {
    color: paleta.bar.primary,
    fontSize: '14.5px'
  },
  labelSubtitle: {
    color: paleta.bar.primary,
    fontSize: '10px',
    fontWeight: '600'
  },
  divider: {
    borderColor: paleta.bar.elements,
    borderTop: "dashed 1.5px",
    marginTop: 20,
    marginBottom: 20,
    opacity: 0,
    width: '100%',

    transition: 'opacity 300ms ease-in-out',
    '&.active': {
      opacity: 1,
    },
  },
  lineChartContainer: {
    width: '100%',
    height: 300,
    overflowX: 'scroll',
  },
  lineChart: {
    minWidth: 2000,
    maxHeight: 300,
  },
  detalles: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  refreshControl: {
    flex: 0,
    marginRigth: 3,
    position: 'relative',
  },
  historial: {
    width: '100%',
    transform: 'translateY(100%)',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'transform 300ms ease-in-out, opacity 300ms ease-in-out',
    '&.active': {
      transform: 'translateY(0)',
      opacity: 1,
      pointerEvents: 'all',
    },
  },
}));

export default styles;
