import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { MdArrowDropDown } from 'react-icons/md';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import clsx from 'clsx';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';

import Typography from '../Typography';

import styles from './styles';

const SplitButton = ({
  classes: customClasses,
  opciones,
  label,
  icono: Icono,
  onClick,
}) => {
  const classes = styles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }, []);

  const handleItemClick = useCallback((onClick) => () => {
    onClick();
    setOpen(false);
  }, []);

  return (
    <Box>
      <ButtonGroup
        variant="contained"
        color="primary"
        disableElevation
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          onClick={onClick}
          className={clsx(classes.boton, customClasses.boton)}
        >
          <Box flex={1} display="flex" justifyContent="center" alignItems="center">
            <Icono size={20} />
            {label !== '' && <Typography className={clsx({ icono: Boolean(Icono) })}>{label}</Typography>}
          </Box>
        </Button>
        <Button
          className={clsx(classes.boton, classes.botonMenu, customClasses.boton)}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <MdArrowDropDown size={20} />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Box className={classes.contenedorMenu}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {opciones.map(({ label, icono: Icono, onClick }, index) => (
                    <MenuItem
                      key={index}
                      onClick={handleItemClick(onClick)}
                      className={classes.contenedorMenuItem}
                    >
                      <Icono size={20} />
                      <Typography component="b">{label}</Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Box>
          </Grow>
        )}
      </Popper>
    </Box>
  )
};

SplitButton.propTypes = {
  classes: PropTypes.shape({
    boton: PropTypes.string,
  }),
  opciones: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    icono: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  })),
  label: PropTypes.string,
  icono: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  onClick: PropTypes.func,
};

SplitButton.defaultProps = {
  classes: {
    boton: '',
  },
  opciones: [],
  label: '',
  icono: null,
  onClick: () => {},
};

export default React.memo(SplitButton);
