import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import paleta from '../configuraciones/paleta';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: paleta.bar.primary,
    },
    secondary: {
      main: paleta.bar.secondary,
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: '"Avenir", "Helvetica", "Arial", sans-serif',
  },
  overrides: {
    MuiTypography: {
      root: {
        color: paleta.bar.icono,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 5,
        maxHeight: 42,
        height: 42,
        boxShadow: 'none !important',
        textTransform: 'none'
      }
    },
    MuiPaper: {
      root: {
        boxShadow: 'none !important',
      },
      rounded: {
        borderRadius: 5,
      }
    },
    MuiContainer: {
      maxWidthLg: {
        maxWidth: 'inherit !important',
      },
    },
    MuiTabs: {
      indicator: {
        top: 0,
        backgroundColor: paleta.bar.primary,
      },
      root: {
        paddingTop: 1,
        '& .MuiTab-root': {
          color: paleta.bar.secondary,
          textTransform: 'none',
          fontWeight: 600,
        },
        '& .Mui-selected': {
          color: `${paleta.bar.primary} !important`,
        },
      },
    },
    MuiCheckbox: {
      root: {
        '&.Mui-checked': {
          color: `${paleta.bar.primary} !important`,
        },
      },
    },
    MuiMenu: {
      paper: {
        boxShadow: `rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important`,
      },
    }
  },
});

theme = responsiveFontSizes(theme);

export default theme;
