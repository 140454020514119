import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

import Macro from '../../contenedores/Macro';
import MapaSitio from '../../componentes/MapaSitio';

import { NAVE } from '../../constantes/rutas';

import styles from './styles';

const TableroDetalles = ({ history, location, children }) => {
  const classes = styles();

  const { sitioID } = useSelector(
    store => store.tablero,
    (prev, next) => prev.sitioID === next.sitioID
  );

  const handleMarkClick = (nave) => {
    history.push(`${location.pathname}/${NAVE}/${ nave.id }`);
  };

  return (
    <Macro>
      <Grid
        container
        className={classes.root}
        style={{ paddingRight: (sitioID ? 0 : 8) }}
      >
        {(sitioID) ? (
          <React.Fragment>
            <Grid item md={7} sm={12} xs={12} className={classes.instancias}>
              {children}
            </Grid>
            <Grid item md={5} sm={12} xs={12} className={classes.mapa}>
              { (sitioID) && <MapaSitio handleMarkClick={handleMarkClick} /> }
            </Grid>
          </React.Fragment>
        ) : (
          <Grid className={classes.contenedorVacio}>
            <img
              alt="logo"
              height="60%"
              src="/assets/images/logoSINfondo.svg"
              className={classes.img}
            />
          </Grid>
        )}
      </Grid>
    </Macro>
  )
}

export default TableroDetalles;
