import React from 'react';

import NaveDetalles from '../../componentes/NaveDetalles';
import NaveDetecciones from '../../componentes/NaveDetecciones'
import NaveFenologias from '../../componentes/NaveFenologias';
import NavePolinizaciones from '../../componentes/NavePolinizaciones';
import NaveRiegos from '../../componentes/NaveRiegos';
import NaveSaneos from '../../componentes/NaveSaneos';

const NaveTablero = () => {
  return (
    <NaveDetalles>
      {
        ({ naveID }) => (
          <>
            <NaveRiegos naveID={naveID} />
            <NaveDetecciones naveID={naveID} />
            <NaveFenologias naveID={naveID} />
            <NavePolinizaciones naveID={naveID} />
            <NaveSaneos naveID={naveID} />
          </>
        )
      }
    </NaveDetalles>
  );
};

export default React.memo(NaveTablero);
