import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  mapa: {
    animation: '$fadeInUp 600ms ease-in-out',
    height: '100%',
  },
  '@keyframes fadeInUp': {
    from: {
      opacity: 0,
      transform: 'translate3d(0, 40px, 0)',
    },
    to: {
      opacity: 1,
      transform: 'none',
    },
  },
}));

export default styles;
