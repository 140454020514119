import React, { useCallback, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import autocolors from 'chartjs-plugin-autocolors';
import { Grid, Box } from '@material-ui/core';
import { Chart as ChartJS } from 'chart.js';
import moment from 'moment';

import Agrupador from '../Agrupador';
import Chart from '../Chart';
import SemanasRiego from '../SemanasRiego';
import Typography from '../Typography';

import { setLegend } from '../../ducks/tablero';
import store from '../../ducks';

import { obtenerRangoFechas } from '../../utilidades/functions';

import styles from './styles';

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegendDual',
  afterUpdate(chart, args, options) {
    const { dispatch } = store;
    if (options.display) {
      const ul = getOrCreateLegendList(chart, options.containerID);
      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }
      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);
      items.forEach((item) => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.marginLeft = '10px';
        li.onclick = () => {
          const { type } = chart.config;
          dispatch(
            setLegend({
              id: item.datasetIndex,
            })
          );
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(
              item.datasetIndex,
              !chart.isDatasetVisible(item.datasetIndex)
            );
          }

          chart.update();
        };

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + 'px';
        boxSpan.style.borderRadius = '50%';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.height = '12px';
        boxSpan.style.marginRight = '5px';
        boxSpan.style.marginTop = 0;
        boxSpan.style.width = '12px';

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = 0;
        textContainer.style.padding = 0;
        textContainer.style.marginTop = '5px';
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    }
  },
};

ChartJS.register(autocolors, htmlLegendPlugin);

const NaveRiegos = ({ naveID }) => {
  const classes = styles();
  const { parametros, temporada, semana } = useSelector(store => store.tablero); 

  const [datosMatutinos, setDatosMatutinos] = useState({
    grafica: null,
    options: {},
  });
  const [datosVespertinos, setDatosVespertinos] = useState({
    grafica: null,
    options: {},
  });

  const inicializarGraficos = useCallback(() => {
    if ( !parametros || !temporada ) return;

    const { vespertinos, matutinos } = parametros;
    const semanas = obtenerRangoFechas(temporada.fechaInicio, temporada.fechaFin, 'W-YYYY', true);
    const options = {
      plugins: {
        crosshair: false,
        htmlLegendDual: {
          containerID: 'legend-container',
          display: true,
        },
        autocolors: {
          enabled: true,
        },
        datalabels: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: (tooltipItems) => {
              return 'Semana ' + tooltipItems[0].label;
            },
            label: (tooltipItems) => {
              return 'Estado ' + tooltipItems.formattedValue;
            }
          },
        },
      },
      scales: {
        y: {
          min: 0,
          ticks: {
            beginAtZero: true,
            callback: function (value) { if (value % 1 === 0) { return value; } },
          },
        }
      },
    };

    setDatosMatutinos({
      grafica: {
        labels: semanas,
        datasets: matutinos,
      },
      options,
    });
    setDatosVespertinos({
      grafica: {
        labels: semanas,
        datasets: vespertinos,
      },
      options,
    });
  }, [parametros, temporada]);

  useEffect(() => {
    setDatosMatutinos({ grafica: null, options: {} });
    setDatosVespertinos({ grafica: null, options: {} });
    inicializarGraficos();
  }, [inicializarGraficos]);

  return (
    <Agrupador customClass={classes.agrupador}>
        <Typography variant='h6' bold className={classes.labelTitle}>
          RIEGO
        </Typography>
      <Grid justifyContent='center' container className={classes.titleContainer}>
        <div className={classes.legends} id={'legend-container'}></div>
        <Grid item xs={12} sm={12} md={5} lg={5} className={classes.grafica}>
          {datosMatutinos.grafica ? (
            <>
              <Chart
                containerID={'matutino'}
                title={'Mañana'}
                type='line'
                data={datosMatutinos.grafica}
                options={datosMatutinos.options}
                height={200}
                scrollTo={moment(semana, 'W (YYYY)').format('W-YYYY')}
                redraw
              />
            </>
          ) : (
            <Box className={classes.emptyContainer}>
              <Typography className={classes.emptyMessage}>No se encontraron registros irrigaciones.</Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} className={classes.grafica}>
          {datosVespertinos.grafica ? (
            <>
              <Chart
                containerID={'vespertino'}
                title={'Tarde'}
                type='line'
                data={datosVespertinos.grafica}
                options={datosVespertinos.options}
                height={200}
                scrollTo={moment(semana, 'W (YYYY)').format('W-YYYY')}
                redraw
              />
            </>
          ) : (
            <Box className={classes.emptyContainer}>
              <Typography className={classes.emptyMessage}>No se encontraron registros irrigaciones.</Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.grafica2}>
        <SemanasRiego naveID={naveID} />
      </Grid>
    </Agrupador>
  );
};

NaveRiegos.propTypes = {
  /** Identificador de la nave */
  naveID: propTypes.string.isRequired,
};

export default React.memo(NaveRiegos, (prevProps, nextProps) => {
  return prevProps.naveID === nextProps.naveID;
});
