import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import CustomSelect from '../../../../componentes/Select';
import DatePicker from '../../../../componentes/DatePicker';
import TextField from '../../../../componentes/TextField';

import { enumPasos, setFormBase } from '../../../../ducks/temporada';
import opcionesEstatus from '../../../../constantes/estatus';

import styles, { Fieldset, Legend } from '../../styles';

const baseFormSelector = (state) => state.temporada.form[enumPasos.BASE];
const sitioFormSelector = (state) => state.temporada.form[enumPasos.SITIOS];

const FormTemporada = ({ id }) => {
  const classes = styles();

  const dispatch = useDispatch();
  const {
    nombre,
    fechaInicio,
    fechaFin,
    estatus,
    sitio,
  } = useSelector((state) => {
    const { sitioId, ...rest } = baseFormSelector(state).data.byId[id];
    const nombreSitio = sitioFormSelector(state).data.byId[sitioId].nombre;

    return {
      sitio: nombreSitio,
      ...rest,
    };
  }, shallowEqual);

  const errores = useSelector((state) => baseFormSelector(state).errores[id], shallowEqual);

  const onChange = useCallback((e) => {
    const { name, value } = e.target;
    dispatch(setFormBase(id, { [name]: value }));
  }, [dispatch, id]);

  return (
    <Fieldset>
      <Legend>Temporada del sitio {sitio}</Legend>
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            error={errores?.nombre}
            label="Nombre de Temporada"
            name="nombre"
            onChange={onChange}
            required
            value={nombre}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <CustomSelect
            error={errores?.estatus}
            label="Estatus"
            name="estatus"
            onChange={onChange}
            options={opcionesEstatus}
            required
            value={estatus}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} />
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <DatePicker
            error={errores?.fechaInicio}
            label="Fecha inicio"
            maxDate={moment(fechaFin).startOf('day').toDate()}
            maxDateMessage="La fecha de inicio no puede ser mayor a la fecha de fin"
            name="fechaInicio"
            onChange={onChange}
            required
            value={fechaInicio}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <DatePicker
            error={errores?.fechaFin}
            label="Fecha fin"
            minDate={moment(fechaInicio).startOf('day').toDate()}
            minDateMessage="La fecha de fin no puede ser menor a la fecha de inicio"
            name="fechaFin"
            onChange={onChange}
            required
            value={fechaFin}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} />
      </Grid>
    </Fieldset>
  );
}

export default React.memo(FormTemporada)
