import React, { useState, useEffect, useCallback, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import isEqual from 'lodash/isEqual';

import Tarjeta from '../Tarjeta';

import axios from '../../configuraciones/axios';
import { CAPTURA_FENOLOGIAS } from '../../configuraciones/endpoints';

import {
  setMonitoreoActivo,
  setNavesDetectadas,
  clearNavesDetectadas,
  clearMonitoreoActivo
} from '../../ducks/tablero';
import semaforo from '../../constantes/semaforos';

const TableroFenologias = () => {
  const dispatch = useDispatch();
  const dataInicialRef = useRef(null);
  const { sitioID, monitoreoActivo, navesEnSitio, temporada, semana } = useSelector(
    store => store.tablero,
    (prev, next) => {
      return (
        isEqual(prev.navesEnSitio, next.navesEnSitio) &&
        isEqual(prev.temporada, next.temporada) &&
        prev.sitioID === next.sitioID &&
        prev.monitoreoActivo.fenologia === next.monitoreoActivo.fenologia &&
        prev.semana === next.semana
      )
    }
  );
  const [naveEstados, setNaveEstados] = useState(null);

  const resaltarNavesEnMapa = useCallback((naves = []) => {
    const navesDetectadas = naves.map((nave) => {
      const { id, estado } = nave;
      return { id, estado, extraInfo: estado ? estado : 'Sin estado' };
    });

    batch(() => {      
      dispatch(setNavesDetectadas(navesDetectadas));
      dispatch(setMonitoreoActivo({ id: null, fenologia: true }));
    })
  }, [dispatch]);

  const onClick = useCallback(() => {
    if ( !monitoreoActivo.fenologia ) {
      const { naves = [] } = naveEstados;
      resaltarNavesEnMapa(naves);
    } else {
      batch(() => {
        dispatch(clearNavesDetectadas());
        dispatch(clearMonitoreoActivo());
      })
    };
    
  }, [dispatch, resaltarNavesEnMapa, monitoreoActivo.fenologia, naveEstados]);

  const fetchNaveEstados = useCallback((fecha, resaltarNaves = false, matenerReferencia = false) => {
    if ( sitioID && semana ) {
      const fechaObjetivo = fecha
        ? fecha
        : moment(semana, 'W (YYYY)')
          .startOf('isoWeek')
          .format('YYYY-MM-DD');

      axios.get(`${CAPTURA_FENOLOGIAS}/tablero`, {
        params: { id: sitioID, fechaObjetivo },
      })
      .then((naves) => {
        const estados = navesEnSitio.map((nave) => {
          const estado = naves.find(item => item.id === nave.id && item.estado);
          return estado ? estado : {id: nave.id, estado: 'Indefinido'};
        });

        const progreso = [
          {
            id: semaforo.generativo,
            color: semaforo.generativo,
            valor: estados.filter(item => item.estado.toUpperCase() === semaforo.generativo).length,
            orden: estados.filter(item => item.estado.toUpperCase() === semaforo.generativo).length,
          },
          {
            id: semaforo.vegetativo,
            color: semaforo.vegetativo,
            valor: estados.filter(item => item.estado.toUpperCase() === semaforo.vegetativo).length,
            orden: estados.filter(item => item.estado.toUpperCase() === semaforo.vegetativo).length,
          },
          {
            id: semaforo.balanceado,
            color: semaforo.balanceado,
            valor: estados.filter(item => item.estado.toUpperCase() === semaforo.balanceado).length,
            orden: estados.filter(item => item.estado.toUpperCase() === semaforo.balanceado).length,
          },
          {
            id: semaforo.indefinido,
            color: semaforo.indefinido,
            valor: estados.filter(item => item.estado.toUpperCase() === semaforo.indefinido).length,
            orden: estados.filter(item => item.estado.toUpperCase() === semaforo.indefinido).length,
          },
        ];

        progreso.sort((a, b) => b.orden - a.orden);

        const obj = {
          title: 'Fenología',
          semana: fechaObjetivo,
          naves,
          progreso,
        };
        setNaveEstados(obj);
        resaltarNaves && resaltarNavesEnMapa(obj.naves);
        matenerReferencia && (dataInicialRef.current = obj);
      })
      .catch(() => {
        dataInicialRef.current = null;
      });
    }
  }, [sitioID, navesEnSitio, resaltarNavesEnMapa, semana]);

  const onSemanaChange = useCallback((fecha) => {
    fetchNaveEstados(fecha, true);
  }, [fetchNaveEstados]);

  useEffect(() => {
    fetchNaveEstados(undefined, false, true);

    return () => {
      dataInicialRef.current = null;
      batch(() => {
        dispatch(clearNavesDetectadas());
        dispatch(clearMonitoreoActivo());

        setNaveEstados(null);
      });
    };
  }, [fetchNaveEstados, dispatch]);

  useEffect(() => {
    if (sitioID && !dataInicialRef.current) {
      dataInicialRef.current = naveEstados;
    }
  }, [sitioID, naveEstados]);

  useEffect(() => {
    if ( !monitoreoActivo.fenologia ) {
      setNaveEstados(dataInicialRef.current);
    }
  }, [monitoreoActivo.fenologia]);

  if ( !naveEstados ) return null;
  return (
    <Tarjeta
      data={naveEstados} 
      onClick={onClick} activo={monitoreoActivo.fenologia}
      onSemanaChange={onSemanaChange}
      temporada={temporada}
    />
  );
}

export default React.memo(TableroFenologias);