import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    listStyle: 'none',
    justifyContent: 'center',
    position: 'relative',
    padding: 0,
    bottom: 0,
    width: '100%',
    flexWrap: 'wrap',
  },
  btn: {
    height: 30,
    minHeight: 30,
    minWidth: 30,
    borderRadius: 5,
    '&.active': {
      backgroundColor: paleta.sidebar.active,
      fontWeight: '600',
    },
    '&:first-child, &:last-child': {
      marginInline: 5,
    },
  },
  label: {
    fontSize: 14,
  },
}));

export default styles;
