/* eslint-disable import/no-anonymous-default-export */
import axios from "../configuraciones/axios";
import endpoints, { INVERNADEROS, NAVES, SITIOS } from "../configuraciones/endpoints";

const SET_PAGINA = 'SET_PAGINA';
const SET_REGISTROS = 'SET_REGISTROS';
const CLEAR_REGISTROS = 'CLEAR_REGISTROS';

const SET_SITIOS = 'SET_SITIOS';
const SET_INVERNADEROS = 'SET_INVERNADEROS';
const SET_NAVES = 'SET_NAVES';

const initialState = {
  pagina: 1,
  registros: { rows: [], count: 0 },
  sitios: [],
  invernaderos: [],
  naves: [],
};

export default (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case SET_PAGINA:
      return { ...state, pagina: payload };
    case SET_REGISTROS:
      return { ...state, registros: payload };
    case CLEAR_REGISTROS:
      return { ...state, registros: initialState.registros, pagina: initialState.pagina };
    case SET_SITIOS:
      return { ...state, sitios: payload };
    case SET_INVERNADEROS:
      return { ...state, invernaderos: payload };
    case SET_NAVES:
      return { ...state, naves: payload };
    default:
      return state;
  }
};

const setSitios = (payload) => ({ type: SET_SITIOS, payload });
const setInvernaderos = (payload) => ({ type: SET_INVERNADEROS, payload });
const setNaves = (payload) => ({ type: SET_NAVES, payload });

export const setPagina = (payload) => ({ type: SET_PAGINA, payload });
export const setRegistros = (payload) => ({ type: SET_REGISTROS, payload });
export const setLimpiar = () => ({ type: CLEAR_REGISTROS });

export const getSitios = () => {
  return (dispatch, getState) => {
    const { listados } = getState();
    if (listados.sitios.length === 0) {
      axios.get(endpoints.base.busqueda(SITIOS))
        .then((sitios) => {
          dispatch(setSitios(sitios));
        });
    }
  };
};

export const getInvernaderos = () => {
  return (dispatch, getState) => {
    const { listados } = getState();
    if (listados.invernaderos.length === 0) {
      axios.get(endpoints.base.busqueda(INVERNADEROS), { params: { includeChildren: 0 }})
        .then((invernaderos) => {
          dispatch(setInvernaderos(invernaderos));
        });
    }
  };
};

export const getNaves = () => {
  return (dispatch, getState) => {
    const { listados } = getState();
    if (listados.naves.length === 0) {
      axios.get(endpoints.base.busqueda(NAVES))
        .then((naves) => {
          dispatch(setNaves(naves));
        });
    }
  };
};
