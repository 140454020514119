import React, { useCallback, useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LinearScale
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import moment from 'moment';

import Typography from '../Typography';

import tooltipOptions from '../../constantes/chartTooltipOptions';
import { PLANTAS, TRAMPAS } from '../../constantes/tipoDeteccion';
import { clearEnfermedad, setPlagaEnfermedadID } from '../../ducks/tablero';
import { setUbicacionDetecciones } from '../../ducks/nave';
import axios from '../../configuraciones/axios';
import endpoints from '../../configuraciones/endpoints';
import paleta from '../../configuraciones/paleta';

import styles from './styles';

Tooltip.positioners.bottom = function(items) {
  const pos = Tooltip.positioners.average(items);

  if (pos === false) {
    return false;
  }

  const chart = this.chart;

  return {
    x: pos.x,
    y: chart.chartArea.bottom,
    xAlign: 'center',
    yAlign: 'bottom',
  };
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const chartOptions = {
  maintainAspectRatio: false,
  plugins: {
    crosshair: false,
    legend: {
      display: true,
      labels: {
        usePointStyle: true,
        font: {
          size: 12,
          family: 'Avenir',
        }
      }
    },
    datalabels: { display: false },
    tooltip: {
      ...tooltipOptions,
      callbacks: {
        title: (tooltipItem) => {
          const { label } = tooltipItem[0];
          return label;
        },
        label: ({ formattedValue, dataset }) => {
          const { tipo } = dataset;
          const texto = tipo === TRAMPAS ? 'En Trampas' : 'En Plantas';
          return `${texto}: ${formattedValue}`;
        }
      },
    },
  },
  scales: {
    y: { beginAtZero: true },
  },
  parsing: {
    xAxisKey: 'nombre',
    yAxisKey: 'valor'
  },
  interaction: {
    intersect: false,
    mode: 'index',
  }
};

const NaveDetalleDetecciones = ({ naveID }) => {
  const classes = styles()

  const dispatch = useDispatch();
  const { semana } = useSelector((store) => store.tablero, (prev, next) => (prev.semana === next.semana));
  const chartRef = useRef(null);

  const [ data, setData ] = useState({ datasets: [] });

  const consultarDatos = useCallback(async () => {
    try {
      const fechaObjetivo = moment(semana, 'W (YYYY)')
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      const { deteccionesTrampas, deteccionesPlantas } = await axios.get(endpoints.obtenerDeteccionesEnNave(naveID), { params: { fechaObjetivo }});

      const trampasData = {
        label: 'Trampas',
        data: deteccionesTrampas,
        backgroundColor: paleta.graficos.barPrimary,
        parsing: {
          xAxisKey: 'nombre',
          yAxisKey: 'valor'
        },
        tipo: TRAMPAS
      };
      const plantasData = {
        label: 'Plantas',
        data: deteccionesPlantas,
        backgroundColor: paleta.graficos.barSelected,
        parsing: {
          xAxisKey: 'nombre',
          yAxisKey: 'valor'
        },
        tipo: PLANTAS
      };

      setData({ datasets: [trampasData, plantasData] });
    } catch {}
  }, [naveID, semana]);

  const onClick = useCallback((event) => {
    const { current: chart } = chartRef;
    const element = getElementAtEvent(chart, event);

    if (!chart || !element.length ) return;

    const { datasetIndex, index } = element[0];
    const { tipo } = data.datasets[datasetIndex];
    const { enfermedadID } = data.datasets[datasetIndex].data[index];

    dispatch(setPlagaEnfermedadID({
      id: enfermedadID,
      esTrampa: tipo === TRAMPAS
    }));
  }, [data.datasets, dispatch]);

  useEffect(() => {
    consultarDatos();

    return () => {
      batch(() => {
        dispatch(setUbicacionDetecciones([]));
        dispatch(clearEnfermedad());
      });
    };
  }, [consultarDatos, dispatch]);

  return (
    <Grid>
      <Grid container>
        <Grid container className={classes.titleContainer}>
          <Typography variant='h6' bold className={classes.labelTitle}>
            DETECCIONES DE P&E
          </Typography>
        </Grid>
        <Grid className={classes.chart} container>
          <Bar
            ref={chartRef}
            options={chartOptions}
            data={data}
            onClick={onClick}
            height={300}
            width="100%"
          />
        </Grid>
      </Grid>
    </Grid>
  )
};

NaveDetalleDetecciones.propTypes = {
  /** ID de la nave */
  naveID: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
};

export default React.memo(NaveDetalleDetecciones);
