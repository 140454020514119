import React, { useEffect } from 'react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Box, Tab } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { batch, useDispatch, useSelector } from 'react-redux';

import GraficasSaneos from '../../componentes/GraficasSaneos';
import GraficasDetecciones from '../../componentes/GraficasDetecciones';

import { setOpcionActiva } from '../../ducks/appbar';
import {
  clearNavesDetectadas,
  clearMonitoreoActivo,
  setGraficoMacroActivo,
} from '../../ducks/tablero';

import styles from './styles';

const TableroDetecciones = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { graficoMacroActivo } = useSelector(
    (store) => store.tablero,
    (prev, next) => prev.graficoMacroActivo === next.graficoMacroActivo
  );

  useEffect(() => {
    dispatch(setOpcionActiva(0));
  }, [dispatch]);

  const handleChange = (event, tabID) => {
    batch(() => {
      dispatch(setGraficoMacroActivo(tabID));
      dispatch(clearNavesDetectadas());
      dispatch(clearMonitoreoActivo());
    });
  };

  return (
    <Grid className={classes.root}>
      <Grid className={classes.contenedor}>
        <TabContext value={graficoMacroActivo}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="Detecciones">
              <Tab label="Detecciones" value="DETECCIONES" />
              <Tab label="Saneos" value="SANEOS" />
            </TabList>
          </Box>
          <TabPanel value="DETECCIONES">
            <GraficasDetecciones />
          </TabPanel>
          <TabPanel value="SANEOS">
            <GraficasSaneos />
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  )
}

export default TableroDetecciones;
