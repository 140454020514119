import React, { useCallback, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { Box, Button, ButtonGroup, FormControlLabel, FormHelperText, Grid } from '@material-ui/core';
import {
  HiOutlineLocationMarker as LocationIcon,
  HiOutlineExclamationCircle as ErrorIcon
} from 'react-icons/hi';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { toast } from 'react-toastify';

import Boton from '../Button';
import Switch from '../CustomSwitch';
import Mapa from '../Mapa';
import RightDrawer from '../RightDrawer';
import Typography from '../Typography';

import useTablaGeolocalizacion, { tabEnum, instrucciones, causasDesactivacion, mensajeVistaPrevia } from '../../hooks/useTablaGeolocalizacion';

import paleta from '../../configuraciones/paleta';
import styles from './styles';

const Acciones = React.memo(({guardar, cancelar}) => {
  const classes = styles();
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="subtitle1" className={classes.title}>
        Configuración de geolocalización
      </Typography>
      <Box display="flex" gridGap={10}>
        <Boton onClick={guardar} label="Guardar" />
        <Boton onClick={cancelar} label="Cancelar" border />
      </Box>
    </Box>
  );
});

const Step = React.memo(({
  children,
  titulo,
  instrucciones,
  disabledDescripcion,
  disabled,
  error,
}) => {
  const classes = styles();

  return (
    <Grid justifyContent="center" container style={{ gap: 15, paddingBlock: 10 }}>
      {
        disabled
          ? (<Typography aling="center" className={clsx(classes.contentTitle, classes.error)}>{ disabledDescripcion }</Typography>)
          : (
            <>
              <Typography aling="center" className={classes.contentTitle}>{ titulo }</Typography>
              {
                instrucciones.map(({descripcion, icono = null}) => (
                  <Grid key={descripcion} className={classes.instruccionContainer}>
                    { icono }
                    <Typography aling="left" component="p" className={classes.contentTitle}>
                      {
                        descripcion.map((text, index) => {
                          if ( index === 1 ) {
                            return (<Typography key={index} bold component="span">{' '}{text}{' '}</Typography>);
                          }
                          return text;
                        })
                      }
                    </Typography>
                  </Grid>
                ))
              }
              <Typography
                aling="center"
                component="p"
                className={clsx(classes.stepTitle, {
                  [classes.stepError]: error,
                })}
              >
                { error ?? '' }
              </Typography>
              <Grid item xs={12} container justifyContent="center">
                { children }
              </Grid>
            </>
          )
      }
    </Grid>
  );
});

const TablaGeolocalizacion = ({
  tablaID,
  mostrar,
  guardar,
  cancelar,
  coordenadas,
  coordenadasSurcos,
  cantidadSurcos,
  cantidadCuadrantes,
  naveCoordenadas,
  poligonosVecinos,
}) => {
  const classes = styles();
  const [tabActivo, setTabActivo] = useState(tabEnum.TABLA);

  const {
    tabla, primerSurco: surcosInicio, ultimoSurco: surcosFinal, error, mostrarVistaPrevia,
    handleChangePoligono, handleChangeSurcos, handleChangeCuadrantes,
    eliminarTabla, eliminarSurcos, eliminarCuadrantes,
    generarVistaPrevia, toggleVistaPrevia,
  } = useTablaGeolocalizacion({
    activo: mostrar,
    tablaID,
    coordenadasTabla: coordenadas,
    coordenadasSurcos,
    contenedor: naveCoordenadas,
    cantidadSurcos,
    cantidadCuadrantes,
    poligonosVecinos,
  });

  const cambiarTab = useCallback((e) => {
    setTabActivo(Number(e.currentTarget.value));
  }, []);

  const customGuardar = useCallback(() => {
    const hayError = Object.values(error).some(error => Boolean(error));

    if ( hayError ) {
      toast.error('Existen errores sin atender');
      return;
    };

    const coordenadas = tabla.map(({ lat, lng }) => ({ tablaID, latitud: lat, longitud: lng }));
    const coordenadasSurcos = {
      tablaID,
      latitudInicioPrimerSurco: surcosInicio[0]?.lat,
      longitudInicioPrimerSurco: surcosInicio[0]?.lng,
      latitudInicioUltimoSurco: surcosInicio[1]?.lat,
      longitudInicioUltimoSurco: surcosInicio[1]?.lng,
      latitudFinPrimerSurco: surcosFinal[0]?.lat,
      longitudFinPrimerSurco: surcosFinal[0]?.lng,
      latitudFinUltimoSurco: surcosFinal[1]?.lat,
      longitudFinUltimoSurco: surcosFinal[1]?.lng,
    };

    if ( coordenadas.length > 0 && surcosInicio.length === 2 && surcosFinal.length === 2 ) {
      guardar({ coordenadas, coordenadasSurcos });
    }
  }, [error, guardar, surcosFinal, surcosInicio, tabla, tablaID]);

  const handleOnClose = useCallback(() => {
    eliminarTabla();
    cancelar();
  }, [cancelar, eliminarTabla]);

  const poligonos = useMemo(() => {
    if (tabActivo !== tabEnum.TABLA) return [{
      path: tabla.map(({ lat, lng }) => ({ lat, lng })),
      options: {
        strokeColor: paleta.geocerca.tertiary,
        fillColor: paleta.geocerca.tertiary,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        clickable: true,
      },
    }];

    return [
      {
        path: naveCoordenadas.map(({ latitud, longitud }) => ({ lat: latitud, lng: longitud })),
        options: {
          strokeColor: paleta.geocerca.primary,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: paleta.geocerca.primary,
          clickable: true,
        },
      },
      ...poligonosVecinos.map((coordenadas) => {
        return {
          path: coordenadas.map(({ latitud, longitud }) => ({ lat: latitud, lng: longitud })),
          options: {
            strokeColor: paleta.geocerca.secondary,
            fillColor: paleta.geocerca.secondary,
            strokeWeight: 0,
          },
        };
      }),
    ];
  }, [naveCoordenadas, poligonosVecinos, tabActivo, tabla]);

  const disabled = useMemo(() => ({
    [tabEnum.TABLA]: false,
    [tabEnum.PRIMER_SURCO]: tabla.length < 4 || error[tabEnum.TABLA],
    [tabEnum.ULTIMO_SURCO]: surcosInicio.length < 2 || error[tabEnum.PRIMER_SURCO],
  }), [error, surcosInicio.length, tabla.length]);

  const mapProps = {
    [tabEnum.TABLA]: {
      poligonoInicial: tabla,
      drawPolygonOptions: {
        strokeColor: paleta.geocerca.tertiary,
        fillColor: paleta.geocerca.tertiary,
      },
      otrosMarcadores: [],
      customEliminar: eliminarTabla,
      onChangePoligono: handleChangePoligono
    },
    [tabEnum.PRIMER_SURCO]: {
      marcadores: surcosInicio,
      otrosMarcadores: mostrarVistaPrevia ? generarVistaPrevia(true) : [],
      customEliminar: eliminarSurcos,
      onChangeMarcador: handleChangeSurcos,
    },
    [tabEnum.ULTIMO_SURCO]: {
      marcadores: surcosFinal,
      otrosMarcadores: mostrarVistaPrevia ? generarVistaPrevia() : [],
      customEliminar: eliminarCuadrantes,
      onChangeMarcador: handleChangeCuadrantes,
    },
  };

  return (
    <RightDrawer
      paperClassName={classes.paperDrawer}
      isOpen={mostrar}
      header={<Acciones guardar={customGuardar} cancelar={cancelar} />}
      guardar={customGuardar}
      cancelar={handleOnClose}
    >
      {/* Tabs */}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ButtonGroup
          variant="outlined"
          color="primary"
          aria-label="outlined primary button group"
          fullWidth
          classes={{
            groupedOutlinedHorizontal: classes.groupedOutlinedHorizontal,
          }}
        >
          <Button
            startIcon={error[tabEnum.TABLA] ? <ErrorIcon /> : <LocationIcon />}
            value={tabEnum.TABLA}
            classes={{
              outlined: classes.tabButton,
              startIcon: classes.iconTab,
            }}
            className={clsx({ active: tabActivo === tabEnum.TABLA, error: error[tabEnum.TABLA] })}
            onClick={cambiarTab}
          >
            <Typography component="p">
              Tabla
            </Typography>
          </Button>
          <Button
            startIcon={error[tabEnum.PRIMER_SURCO] ? <ErrorIcon /> : <LocationIcon />}
            value={tabEnum.PRIMER_SURCO}
            classes={{
              outlined: classes.tabButton,
              startIcon: classes.iconTab,
            }}
            className={clsx({ active: tabActivo === tabEnum.PRIMER_SURCO, error: error[tabEnum.PRIMER_SURCO] })}
            onClick={cambiarTab}
          >
            <Typography component="p">
              Surco{' '}<Typography component="span" bold>(Primero)</Typography>
            </Typography>
          </Button>
          <Button
            startIcon={error[tabEnum.ULTIMO_SURCO] ? <ErrorIcon /> : <LocationIcon />}
            value={tabEnum.ULTIMO_SURCO}
            classes={{
              outlined: classes.tabButton,
              startIcon: classes.iconTab,
            }}
            className={clsx({ active: tabActivo === tabEnum.ULTIMO_SURCO, error: error[tabEnum.ULTIMO_SURCO] })}
            onClick={cambiarTab}
          >
            <Typography component="p">
              Surco{' '}<Typography component="span" bold>(Último)</Typography>
            </Typography>
          </Button>
        </ButtonGroup>
      </Grid>

      {/* Contenido */}
      <Grid item lg={12} md={12} sm={12} xs={12} classes={{ root: classes.contentRoot }}>
        <Step
          titulo={tabEnum.TABLA !== tabActivo ? 'Del área señalada que corresponde a la tabla:' : ''}
          instrucciones={instrucciones[tabActivo]}
          disabled={disabled[tabActivo]}
          disabledDescripcion={causasDesactivacion[tabActivo]}
          error={error[tabActivo]}
        >
          <Mapa
            ocultarBusqueda
            containerStyle={classes.mapa}
            esPoligono={tabEnum.TABLA === tabActivo}
            poligonos={poligonos}
            ubicarMiPosicion={false}
            {...mapProps[tabActivo]}
          />
        </Step>
      </Grid>

      {/* Vista previa */ }
      {
        mensajeVistaPrevia[tabActivo] && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <FormControlLabel
              control={<Switch onChange={toggleVistaPrevia} value={mostrarVistaPrevia} />}
              label={mensajeVistaPrevia[tabActivo]}
            />
            <FormHelperText>Advertencia: Tenga en cuenta que la carga de la vista previa puede tardar más de lo esperado, especialmente si hay una gran cantidad de surcos y cuadrantes.</FormHelperText>
          </Grid>
        )
      }
    </RightDrawer>
  );
};

TablaGeolocalizacion.propTypes = {
  /** ID de la tabla */
  tablaID: propTypes.string,
  /** Indica si es visible el drawer */
  mostrar: propTypes.bool.isRequired,
  /** Función que se ejecuta al guardar la información */
  guardar: propTypes.func.isRequired,
  /** Función que se ejecuta al cerrar el drawer */
  cancelar: propTypes.func.isRequired,
  /** Coordenadas de la nave */
  naveCoordenadas: propTypes.arrayOf(propTypes.shape({
    latitud: propTypes.number,
    longitud: propTypes.number,
  })).isRequired,
  /** Cantidad de surcos */
  cantidadSurcos: propTypes.number,
  /** Cantidad de cuadrantes */
  cantidadiCuadrantes: propTypes.number,
};

TablaGeolocalizacion.defaultProps = {
  tablaID: null,
  cantidadSurcos: 0,
  cantidadiCuadrantes: 0,
};

const funcionComparacion = (prevProps, nextProps) => {
  return (
    prevProps.tablaID === nextProps.tablaID
    && prevProps.mostrar === nextProps.mostrar
    && prevProps.guardar === nextProps.guardar
    && prevProps.cancelar === nextProps.cancelar
    && prevProps.naveCoordenadas.every((coordenada, index) => isEqual(coordenada, nextProps.naveCoordenadas[index]))
    && prevProps.cantidadSurcos === nextProps.cantidadSurcos
    && prevProps.cantidadCuadrantes === nextProps.cantidadCuadrantes
    && prevProps.coordenadas.every((coordenada, index) => isEqual(coordenada, nextProps.coordenadas[index]))
    && isEqual(prevProps.coordenadasSurcos, nextProps.coordenadasSurcos)
    && prevProps.poligonosVecinos.every((poligono, index) => isEqual(poligono, nextProps.poligonosVecinos[index]))
  );
};

export default React.memo(TablaGeolocalizacion, funcionComparacion);