import React, { useCallback, useEffect } from 'react';
import propTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import AccionesPYE from '../AccionesPYE';
import ListaImagenes from '../ListaImagenes';
import Macro from '../../contenedores/Macro';
import Mapa from '../Mapa';
import Modal from '../Modal';
import NaveCultivos from '../NaveCultivos';

import {
  clearDetallesNave,
  setDetallesNave,
  setModalClose,
  setTipoMarcadorPYE,
} from '../../ducks/nave';

import axios from '../../configuraciones/axios';
import endpoints, { NAVES } from '../../configuraciones/endpoints';
import paleta from '../../configuraciones/paleta';
import { ELEMENTO_NO_ENCONTRADO } from '../../configuraciones/mensajes';

import { obtenerCentroide } from '../../utilidades/functions';
import { TABLERO } from '../../constantes/rutas';

import styles from './styles';
import Typography from '../Typography';

const NaveDetalles = ({ children }) => {
  const classes = styles();
  
  const { id } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const nave = useSelector(state => state.nave, (prev, next) => (
    prev.id === next.id &&
    prev.isModalOpen === next.isModalOpen &&
    prev.ubicacionDetecciones === next.ubicacionDetecciones
  ));

  const consultarDetalles = useCallback(async () => {
    try {
      const nave = await axios.get(endpoints.base.url(NAVES, id));
      if (!nave?.id) throw new Error(ELEMENTO_NO_ENCONTRADO);

      const { coordenadas } = nave;
      const centro = obtenerCentroide(coordenadas.map((vertice) => ({ x: vertice.latitud, y: vertice.longitud })));

      dispatch(setDetallesNave({
        ...nave,
        centro: centro ? { lat: centro.x, lng: centro.y } : null,
        marcador: [{
          id: nave.id,
          nombre: `${nave.invernadero.nombre} - ${nave.nombre}`,
          lat: centro?.x || 0,
          lng: centro?.y || 0,
        }],
        ubicacion: [{
          id: nave.id,
          path: coordenadas ? coordenadas.map((vertice) => ({ lat: vertice.latitud, lng: vertice.longitud })) : [],
          options: {
            strokeColor: 'transparent',
            fillColor: paleta.geocerca.primary,
            fillOpacity: 0.60,
            clickable: false
          },
        }],
      }));
    } catch (error) {
      if (error.message === ELEMENTO_NO_ENCONTRADO) {
        toast.error(ELEMENTO_NO_ENCONTRADO);
      }
      dispatch(clearDetallesNave({}));
      history.push(`/${TABLERO}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const closeModal = useCallback(() => { dispatch(setModalClose()) }, [dispatch]);

  const setTipoMarcador = useCallback((e) => {
    dispatch(setTipoMarcadorPYE(e.currentTarget.value));
  }, [dispatch]);

  useEffect(() => {
    consultarDetalles();
  }, [consultarDetalles, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearDetallesNave());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Macro>
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={12} md={7} className={classes.detalles}>
          {
            nave.nombre && (
              <Typography variant="h6" className={classes.alias} bold>
                {nave?.invernadero?.nombre} - {nave?.nombre} - {`${nave.hectareas} Hectáreas`}
              </Typography>
            )
          }
          <NaveCultivos nave={nave} />
          { children({ naveID: id }) }
        </Grid>
        <Grid item xs={12} sm={12} md={5} className={classes.mapa}>
          <Mapa
            bloquearClick={false}
            ComponenteAcciones={<AccionesPYE onToggleMarcadores={setTipoMarcador} />}
            customMarcador
            defaultCenter={nave.centro}
            esPoligono={false}
            marcadores={{ naves: nave.marcador, otros: nave.ubicacionDetecciones }}
            ocultarBusqueda
            ocultarEliminar
            onChangeMarcador={() => {}}
            poligonos={nave.ubicacion}
            zoom={17}
          />
        </Grid>
        <Modal open={nave.isModalOpen} handleClose={closeModal} titulo="Evidecias de capturas">
          <ListaImagenes data={nave.evidencia} />
        </Modal>
      </Grid>
    </Macro>
  );
};

NaveDetalles.propTypes = {
  /** Componente hijo a renderizar */
  children: propTypes.oneOfType([propTypes.func, propTypes.node]).isRequired,
};

export default React.memo(NaveDetalles, (prevProps, nextProps) => prevProps.id === nextProps.id);
