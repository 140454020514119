import { makeStyles, styled } from '@material-ui/core';
import Button from '../../componentes/Button';

const styles = makeStyles((theme) => ({
  boton: {
    height: 38,
    minHeight: 38,
    minWidth: 125,
    padding: theme.spacing(2),
  },
  botonTexto: {
    paddingInline: theme.spacing(2),
    paddingBlock: 0,
    height: 'auto',
    minHeight: 0,
    background: 'transparent',

    '& span': {
      color: theme.palette.primary.main,
    },
  },
  gap: {
    gap: theme.spacing(2),
  },
  select: {
    width: 350,
  },
  stepper: {
    width: '100%',
    padding: 0,
    paddingRight: 20,
  },
  stepLabel: {
    fontSize: 14,
    fontWeight: 'bold !important',
  },
  stepButton: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  fadeInAnimate: {
    animation: '$fadeInUp 600ms ease-in-out'
  },
  '@keyframes fadeInUp': {
    from: {
      opacity: 0,
      transform: 'translate3d(0, 30px, 0)',
    },
    to: {
      opacity: 1,
      transform: 'none',
    },
  },
}));

export const Fieldset = styled('fieldset')(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(4, 3),
  borderRadius: 5,
  display: 'flex',
  gap: theme.spacing(2),
}));

export const Legend = styled('legend')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'bold',
  padding: theme.spacing(0, 1),
  marginTop: theme.spacing(-3),
}));

export const Boton = (props) => <Button classesCustom={{ boton: styles().boton }} {...props} />;

export const BotonTexto = (props) => <Button classesCustom={{ boton: styles().botonTexto }} {...props} />;

export default styles;
