/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';

import Default from '../../contenedores/Default';

import CabeceroReportes from '../../componentes/CabeceroReportes';
import ListaConfiguracion from '../../componentes/ListaConfiguracion';
import Tabla from '../../componentes/Table';

import { setRegistros } from '../../ducks/listados';

import { CamposValidados, getTheDaysOfTheWeek, getWeeks } from '../../utilidades/functions';

import axios from '../../configuraciones/axios';
import general from '../../configuraciones/general';
import endpoints, {
	ENFERMEDADES,
	INVERNADEROS,
	NAVES,
	SITIOS,
	TRAMPAS,
} from '../../configuraciones/endpoints';

import { DatePickerTypes } from '../../constantes/datePickerTypes';

const semanaActual = moment().isoWeek();

const datosLista = {
	labelProp: 'nombre',
	headerLista: 'Nombre',
	title: 'Reporte de Plagas & Enfermedades',
	data: [
		{
			id: 1,
			nombre: 'P&E en plantas',
			subTitle: 'Plagas/Enfermedades Encontradas',
		},
		{
			id: 2,
			nombre: 'P&E en trampas',
			subTitle: 'Listado por plaga/enfermedad',
		},
	],
};

const cabeceros = [
	{ label: 'SITIO', key: 'sitio', align: 'left' },
	{ label: 'INVERNADERO', key: 'invernadero', align: 'left' },
	{ label: 'NAVE', key: 'Nave', align: 'left' },
	{ label: 'Trampa', key: 'Trampa', align: 'left' },
	{ label: 'PLAGA/ENFERMEDAD', key: 'Enfermedad', align: 'left' },
	{ label: 'CANTIDAD', key: 'cantidad', align: 'left' },
];

const obtenerFechasActuales = (semana) => {
	return {
		semana,
		diasSemana: getTheDaysOfTheWeek(semana ? semana : semanaActual, 'ddd ll'),
	};
};

const dataInicial = {
	sitios: [],
	invernaderos: [],
	naves: [],
	trampas: [],
  enfermedades: [],
};

const ReporteTrampas = ({ history }) => {
	const { registros } = useSelector((store) => store.listados);
	const [fechas, setFechas] = useState(obtenerFechasActuales());
	const dispatch = useDispatch();
	const location = useLocation();
	const isMounted = useRef(false);
	const paginaActual = useRef(1);

  const [data, setData] = useState({ ...dataInicial });

	const [formData, setFormData] = useState({
		...dataInicial,
	});

	const consultarDatosIniciales = () =>
		new Promise((resolve, reject) => {
			const promesas = [
				axios.get(endpoints.base.busqueda(SITIOS)),
				axios.get(endpoints.base.busqueda(INVERNADEROS)),
				axios.get(endpoints.base.busqueda(NAVES)),
				axios.get(endpoints.base.busqueda(TRAMPAS)),
				axios.get(endpoints.base.busqueda(ENFERMEDADES)),
			];
			Promise.all(promesas)
				.then((resultadoPromesas) => {
					const [
						sitiosBD,
						inverneaderosBD,
						navesBD,
						trampasBD,
						enfermedadesBD,
					] = resultadoPromesas;
					
					const trampasNave = trampasBD.map((trampa) => {
						const trampas = {
							id: trampa.id,
							nombre: `${trampa.nave?.nombre} - ${trampa.nombre}`,
							naveID: trampa.naveID
						};
						return trampas;
					});

					trampasNave.unshift({
						id: 0,
						nombre: 'Todas',
					});

					enfermedadesBD.unshift({
						id: 0,
						nombre: 'Todas',
					});

					setData({
						sitios: sitiosBD,
						invernaderos: inverneaderosBD,
						naves: navesBD,
						trampas: trampasNave,
						enfermedades: enfermedadesBD,
						semanas: getWeeks(),
					});
					resolve();
				})
				.catch(reject);
		});

	const onChange = ({ target: { name, value } }) => {
		let nuevaData = {
			...formData,
		};

		switch (name) {
			case 'semanaID':
				nuevaData.semana =
					data.semanas.find((semana) => semana.id === value)?.semanaID || 0;
				nuevaData[name] = value;
				setFechas(obtenerFechasActuales(value));
				break;
			default:
				nuevaData = { ...formData, [name]: value };
				switch (name) {
					case 'sitios':
						const invernaderosDisponibles = data.invernaderos
							.filter((invernadero) =>
								nuevaData.sitios.includes(invernadero.sitioID),
							)
							.map(({ id }) => id);
						nuevaData.invernaderos = nuevaData.invernaderos.filter(
							(invernadero) => invernaderosDisponibles.includes(invernadero),
						);

						const navesEnSitio = data.naves
							.filter((nave) =>
								nuevaData.invernaderos.includes(nave.invernaderoID),
							)
							.map(({ id }) => id);
						nuevaData.naves = nuevaData.naves.filter((nave) =>
							navesEnSitio.includes(nave),
						);
						break;
					case 'invernaderos':
						const navesEnInveradero = data.naves
							.filter((nave) =>
								nuevaData.invernaderos.includes(nave.invernaderoID),
							)
							.map(({ id }) => id);
						nuevaData.naves = nuevaData.naves.filter((nave) =>
							navesEnInveradero.includes(nave),
						);
						break;
					case 'naves':
						const navesEnTrampas = data.trampas
							.filter((trampa) => nuevaData.naves.includes(trampa.naveID))
							.map(({ id }) => id);
						nuevaData.trampas = nuevaData.trampas.filter((trampa) =>
							navesEnTrampas.includes(trampa),
						);
						break;
					default:
						break;
				}
				break;
		}

		setFormData((currentData) => ({ ...currentData, ...nuevaData }));
		paginaActual.current = 1;
	};

	const onDelete = (id, name) => {
		const nuevaData = {
			...formData,
			[name]: formData[name].filter((busquedaID) => busquedaID !== id),
		};

		const invernaderosDisponibles = data.invernaderos
			.filter((invernadero) => nuevaData.sitios.includes(invernadero.sitioID))
			.map(({ id }) => id);
		nuevaData.invernaderos = nuevaData.invernaderos.filter((invernaderos) =>
			invernaderosDisponibles.includes(invernaderos),
		);

		const trampaDisponibles = data.trampas
			.filter((trampa) => nuevaData.naves.includes(trampa.naveID))
			.map(({ id }) => id);
		nuevaData.trampas = nuevaData.trampas.filter((trampas) =>
			trampaDisponibles.includes(trampas),
		);

		setFormData((currentData) => ({
			...nuevaData,
		}));

		// Resetear pagina
		paginaActual.current = 1;
	};
	const ValidarData = useCallback(() => CamposValidados(formData), [formData]);

	const handleTodosSelected = useCallback(() => {
    const { naves, fecha } = formData;

		const trampas = formData.trampas.includes(0)
			? data.trampas
					.filter((trampa) => trampa.id !== 0)
					.map((trampa) => trampa.id)
			: formData.trampas;

		const enfermedades = formData.enfermedades.includes(0)
			? data.enfermedades
					.filter((enfermedad) => enfermedad.id !== 0)
					.map((enfermedad) => enfermedad.id)
			: formData.enfermedades;

		const params = {
			naves,
			fecha: moment(fecha).format('YYYY-MM-DD'),
			trampas,
			enfermedades,
		};

		return params;
	}, [formData, data, fechas]);

	const buscar = async () => {
		try {
			if (ValidarData()) return;
			const params = handleTodosSelected();
			const response = await axios.post(endpoints.reporteEnfermedadesTrampas(), {
				...params,
				registrosPorPagina: general.ELEMENTOS_POR_PAGINA,
				pagina: paginaActual.current,
			});

			dispatch(setRegistros({ rows: response.rows, count: response.count }));
		} catch {}
	};

	useEffect(() => {
		isMounted.current = true;
		consultarDatosIniciales();

		return () => {
			isMounted.current = false;
			paginaActual.current = 1;
			dispatch(setRegistros({ rows: [], count: 0 }));
		};
	}, []);

	useEffect(() => {
		if (isMounted.current) {
			const query = queryString.parse(location.search);
			if (query.pagina === paginaActual || !query.pagina) return;
			paginaActual.current = query.pagina;
			buscar();
		}
	}, [location]);
	const onSubMenuClick = (id) => {
		if (id === 1) {
			history.push(`/reportes/enfermedades`);
		} else {
			history.push(`/reportes/trampas`);
		}
	};

	return (
		<Default
			mostrarLista={
				<ListaConfiguracion
					datosLista={datosLista}
					onClick={onSubMenuClick}
					initOpcion={1}
				/>
			}
		>
			<CabeceroReportes
				data={data}
				formData={formData}
				buscar={buscar}
				onChange={onChange}
				onDelete={onDelete}
				handleTodosSelected={handleTodosSelected}
				selects={{
					sitio: { width: 4, show: true },
					invernadero: { width: 4, show: true },
					nave: { width: 4, show: true },
					trampa: { width: 2, show: true },
					enfermedad: { width: 3, show: true },
					fecha: { width: 3, show: true, type: DatePickerTypes.date },
				}}
				endpoint={endpoints.reporteEnfermedadesTrampas()}
				method='POST'
			/>
			<Tabla
				headers={cabeceros}
				rows={registros.rows.length > 0 ? registros.rows : []}
				count={registros.count}
				showActions={false}
			/>
		</Default>
	);
};

export default ReporteTrampas;
