import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingInline: theme.spacing(2),
      paddingBlock: theme.spacing(2),
    },
  },
  small: {
    color: paleta.bar.elements,
    marginTop: 3,
    fontSize: 11,
    marginLeft: 10
  },
  input: {
    minWidth: 150,
    maxWidth: 150,
  },
  select: {
    marginTop: -25,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      minWidth: 'calc(50% - 10px)',
      maxWidth: 'calc(50% - 10px)',
    },
  },
  search: {
    minWidth: 250,
    maxWidth: 250,
    marginTop: -5,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
  date: {
    minWidth: 300,
    maxWidth: 300,
    marginTop: -25,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
  textoDetalle: {
    color: paleta.textField.textBlack,
    fontSize: 13,
  },
  tabla: {
    width: '100%',
  },
  celda: {
    color: theme.palette.text.primary,
  },
  chipButton: {
    backgroundColor: paleta.bar.user,
    border: 'none',
    borderRadius: 5,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    height: 24,
    padding: theme.spacing(0.8),
    textDecoration: 'none',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

export const VerDetallesButton = React.memo(({ id }) => {
  const classes = styles();

  return (
    <Link
      to={`/temporadas/detalles/${id}?historico=true`}
      className={classes.chipButton}
    >
      Ver Detalles
    </Link>
  );
});

export default styles;
