import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  refresh: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& button': {
      cursor: 'pointer',
    },
  },
  refreshAnimation: {
    animation: '$refresh 750ms linear infinite',
  },
  refreshControl: {
    padding: theme.spacing(1),
  },
  '@keyframes refresh': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

export default styles;
