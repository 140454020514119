import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  agrupador: {
    marginInline: 5,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 30,
  },
  divider: {
    borderColor: paleta.bar.elements,
    borderTop: "dotted 2px",
    marginTop: 20,
    marginBottom: 20,
    opacity: 1,
    width: '100%',
  },
}));

export default styles;
