import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Listados from '../../contenedores/Listados';
import ListaConfiguracion from '../../componentes/ListaConfiguracion';
import RangoTextFields from '../../componentes/RangoTextFields';

import axios from '../../configuraciones/axios';
import paleta from '../../configuraciones/paleta';
import { RANGO_INVALIDO } from '../../configuraciones/mensajes';
import endpoints, { CONFIGURACION_POLINIZACION, SITIOS } from '../../configuraciones/endpoints';
import { setRegistros } from '../../ducks/listados';
import { setSitioID } from '../../ducks/configuraciones';
import { ValidarRangos } from '../../utilidades/functions';

const DatosIniciales = {
  labelProp: '',
  headerLista: '',
  title: '',
  subTitle: '',
  data: [],
};

const ConfiguracionPolinizaciones = ({ history, location }) => {
  const dispatch = useDispatch();
  const { registros } = useSelector(store => store.listados);
  const { sitioID } = useSelector(store => store.configuraciones);
  const [datosLista, setDatosLista] = useState({ ...DatosIniciales });
  const [formErrors, setFormErrors] = useState([]);

  useEffect(() => {
    consultarDatosIniciales();
    return () => {
      dispatch(setRegistros({ rows: [], count: 0 }));
      dispatch(setSitioID(null));
      setFormErrors([]);
    }
  }, [dispatch]);

  useEffect(() => {
    setFormErrors([]);
  }, [registros])

  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.base.busqueda(SITIOS))
    ];
    Promise.all(promesas)
      .then((resultadosPromesas) => {
        const [sitiosDb] = resultadosPromesas;
        setDatosLista({
          labelProp: 'nombre',
          headerLista: 'Nombre',
          title: 'Sitios',
          subTitle: 'Configuración de niveles de polinización',
          data: sitiosDb,
        });
        resolve();
      })
      .catch(reject);
  });

  const handleChange = (e, index) => {
    const propiedad = e.target.name;
    const nuevoEstado = registros;
    nuevoEstado.rows[index].configuracionPolinizaciones = {
      ...nuevoEstado.rows[index].configuracionPolinizaciones,
      [propiedad]: parseInt(e.target.value),
    };
    dispatch(setRegistros(nuevoEstado));
  }

  const guardar = async () => {
    try {
      const rowErrors = [];

      const filtro = registros.rows.filter(configuracion => configuracion.configuracionPolinizaciones);
      let configuracionPolinizaciones = filtro.map(configuracion => ({
        ...configuracion.configuracionPolinizaciones,
        nivelID: configuracion.id,
        sitioID: parseInt(sitioID)
      }));

      configuracionPolinizaciones.forEach(polinizacion => {
        const id = ValidarRangos(polinizacion, polinizacion.nivelID);
        id && rowErrors.push(registros.rows.findIndex(r => r.id === id));
      });

      if (rowErrors.length > 0) {
        setFormErrors(rowErrors);
        return toast.error(RANGO_INVALIDO);
      }

      const data = {
        configuracionPolinizaciones,
        sitioID: sitioID,
      }
      axios.put(CONFIGURACION_POLINIZACION, data).then(() => {
        setFormErrors([]);
      });
    } catch {}
  }

  const cabeceros = [
    {
      label: 'Nombre', transform: () => 'Nivel efectivo de polinización',
    },
    {
      label: 'Rango Estado Rojo', align: 'center',
      transform: (data, index) => {
        return (
          <RangoTextFields
            backgroundColor={paleta.configuraciones.rojo}
            borderColor={paleta.configuraciones.rojo}
            names={['rangoEstadoRojoMin', 'rangoEstadoRojoMax']}
            formValues={{
              min: data?.configuracionPolinizaciones?.rangoEstadoRojoMin,
              max: data?.configuracionPolinizaciones?.rangoEstadoRojoMax
            }}
            activo={true}
            onChange={(e) => handleChange(e, index)}
            mostrarSimbolo={<i style={{
              position: 'absolute',
              transform: 'translate(-85%, 40%)',
              width: '25px'
            }}>%</i>}
          />
        )
      }
    },
    {
      label: 'Rango Estado amarillo', align: 'center',
      transform: (data, index) => {
        return (
          <RangoTextFields
            backgroundColor={paleta.configuraciones.amarillo}
            borderColor={paleta.configuraciones.borderAmarillo}
            names={['rangoEstadoAmarilloMin', 'rangoEstadoAmarilloMax']}
            formValues={{
              min: data?.configuracionPolinizaciones?.rangoEstadoAmarilloMin,
              max: data?.configuracionPolinizaciones?.rangoEstadoAmarilloMax
            }}
            activo={true}
            onChange={(e) => handleChange(e, index)}
            mostrarSimbolo={<i style={{
              position: 'absolute',
              transform: 'translate(-85%, 40%)',
              width: '25px'
            }}>%</i>}
          />
        )
      }
    },
    {
      label: 'Rango Estado verde', align: 'center',
      transform: (data, index) => {
        return (
          <RangoTextFields
            backgroundColor={paleta.configuraciones.verde}
            borderColor={paleta.configuraciones.borderVerde}
            names={['rangoEstadoVerdeMin', 'rangoEstadoVerdeMax']}
            formValues={{
              min: data?.configuracionPolinizaciones?.rangoEstadoVerdeMin,
              max: data?.configuracionPolinizaciones?.rangoEstadoVerdeMax
            }}
            activo={true}
            onChange={(e) => handleChange(e, index)}
            mostrarSimbolo={<i style={{
              position: 'absolute',
              transform: 'translate(-85%, 40%)',
              width: '25px'
            }}>%</i>}
          />
        )
      }
    },
  ];

  return (
    <Listados
      titulo={`Niveles de polinización`}
      subtitulo={'Rangos de porcentaje'}
      placeHolder='Búsqueda por nombre'
      mostrarLista={<ListaConfiguracion datosLista={datosLista} />}
      history={history}
      location={location}
      cabeceros={cabeceros || []}
      apiKey={CONFIGURACION_POLINIZACION}
      parametros={{sitioID: sitioID}}
      showActions={false}
      mostrarCabeceroListado={false}
      mostrarCabeceroFormulario={true}
      guardar={guardar}
      cancelar={() => { }}
      rowErrors={formErrors}
    />
  );
};

export default ConfiguracionPolinizaciones;
