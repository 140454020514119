import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import { Box } from '@material-ui/core';

import TextField from '../TextField';
import Typography from '../Typography';

import paleta from '../../configuraciones/paleta';

import styles from './styles';

const RangoTextFields = ({ 
  names, formValues, activo, onChange,
  backgroundColor, inputProps, mostrarSimbolo,
}) => { 
  const classes = styles();
  const { min, max } = formValues;
  const mainColor = useMemo(() => paleta.changeOpacity(backgroundColor, 90), [backgroundColor])
  return (
    <Box className={classes.root}>
      <TextField
        inputClassName={classes.input}
        inputStyles={{
          backgroundColor: activo ? mainColor : paleta.configuraciones.disabled,
          borderColor: activo ? backgroundColor : paleta.configuraciones.disabled,
        }}
        onChange={onChange}
        name={names[0] || ''}
        placeHolder="- - -"
        inputProps={inputProps}
        value={min && activo ? min : 0}
        disabled={activo ? !activo : true}
        required
        simbolo={mostrarSimbolo}
      />
      <Typography className={classes.guion}> - </Typography>
      <TextField
        inputClassName={classes.input}
        inputStyles={{
          backgroundColor: activo ? mainColor : paleta.configuraciones.disabled,
          borderColor: activo ? backgroundColor : paleta.configuraciones.disabled,
        }}
        onChange={onChange}
        name={names[1] || ''}
        placeHolder="- - -"
        inputProps={inputProps}
        value={max && activo ? max : 0}
        disabled={activo ? !activo : true}
        required
        simbolo={mostrarSimbolo}
      />
    </Box>
  )
}

RangoTextFields.propTypes = {
  /* Identificadores de los input */
  names: propTypes.array.isRequired,
  /* Estado inicial del input */
  formValues: propTypes.object,
  /* Estado inicial del input */
  activo: propTypes.bool,
  /* Función a ejecutar cuando el valor del input cambie */
  onChange: propTypes.func,
  /* Color del fondo de los input */
  backgroundColor: propTypes.string,
  /** Propiedades extras para los inputs */
  inputProps: propTypes.object,
};

RangoTextFields.defaultProps = {
  backgroundColor: paleta.configuraciones.verde,
  borderColor: paleta.configuraciones.borderVerde,
  formValues: {},
  activo: false,
  inputProps: {}
};

export default RangoTextFields;