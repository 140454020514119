export const ERROR = 'Ocurrió un error.';
export const SUCCESS = 'Acción realizada con éxito.';

export const USUARIO_NO_PERMISO = 'No cuenta con los permisos necesarios.'
export const PERFILE_SIN_PERMISOS = 'Es necesario seleccionar al menos un módulo.';
export const POLIGONO_FUERA_SITIO = 'El polígono dibujado está fuera del sitio.';
export const POLIGONO_DENTRO_INVERNDERO = 'El polígono dibujado está dentro de otro invernadero.';
export const POLIGONO_VACIO = 'Es necesario capturar un polígono.';
export const NOMBRE_SECCION_REPETIDO = 'El nombre de la sección ya existe.';
export const NOMBRE_PLANTA_REPETIDO = 'El nombre de la planta ya existe.';
export const NOMBRE_SURCO_REPETIDO = 'El nombre del surco ya existe.';
export const NOMBRE_TRAMPA_REPETIDO = 'Existe una o mas trampas con el mismo nombre.';
export const NOMBRE_VALVULA_REPETIDO = 'El nombre de la válvula ya existe.';
export const TRAMPA_FUERA_POLIGONO = 'Todas las trampas deben estar dentro del polígono.';
export const ELEMENTO_NO_ENCONTRADO = 'El elemento no ha sido encontrado.';
export const INVERNADERO_NO_SELECCIONADO = 'Debe seleccionar un invernadero.';
export const NAVE_NO_SELECCIONADA = 'Debe seleccionar una nave.';
export const NAVE_SIN_GEOLOCALIZACION = 'La nave no cuenta con geolocalización.';
export const SITIO_NO_SELECCIONADO = 'Debe seleccionar un sitio.'
export const TIPO_SUELO_NO_SELECCIONADO = 'Debe seleccionar un tipo de suelo.'
export const GEOLOCALIZACION_NAVE_NO_CONFIGURADO = 'Debe configurar la geolocalización de la nave.';
export const SITIO_DEPENDENCIAS = 'No se puede actualizar las coordenadas porque cuenta con invernaderos asignados.';
export const INVERNADERO_DEPENDENCIAS = 'No se puede actualizar las coordenadas porque cuenta con naves asignadas.';
export const TEMPORADA_ACTIVA_REPETIDA = 'Solo puede existir una temporada activa.';
export const TEMPORADASITIO_REPETIDA = 'Las temporadas no pueden compartir el mismo periodo.';
export const NOMBRE_TEMPORADASITIO_REPETIDO = 'Los nombres de las temporadas deben ser únicos.';
export const FECHA_CULTIVO_FUERA_DE_RANGO = 'La fecha de cultivo no se encuentra dentro de la temporada.';
export const FECHA_CORTE_FUERA_DE_RANGO = 'La fecha de corte no se encuentra dentro de la temporada.';
export const FECHA_TRANSPLANTE_FUERA_DE_RANGO = 'La fecha de transplante no se encuentra dentro de la temporada.';
export const FECHA_CULTIVO_MAYOR_FECHA_TRANSPLANTE = 'La fecha de cultivo no debe ser superior a la fecha de transplante.';
export const FECHA_TRANSPLANTE_MAYOR_FECHA_CORTE = 'La fecha de transplante no debe ser superior a la fecha de corte.';
export const SITIO_SIN_TEMPORADAS = 'El sitio no cuenta con temporadas registradas.';
export const RANGO_INVALIDO = 'El rango de inicio no puede ser mayor al rango final y el rango final del estado verde debe ser menor a 100.';
export const VALIDAR_SELECCION_REPORTE = 'Debe seleccionar al menos un campo en cada sección';
export const NO_MODIFICAR_PERMISO_SITIO = 'No se puede modificar el permiso de un sitio si el usuario es administrador.';
export const CLIMA_ERROR = 'No fue posible obtener el clima';
export const CLIMA_HISTORIAL_ERROR = 'No fue posible obtener el historial del clima';

export const ERROR_COORDENADA_FUERA_CONTENEDOR = '¡La coordenada se encuentra fuera del poligono!';
export const ERROR_COORDENADA_EN_CONFLICTO_CON_TABLA = '¡La ubicación se encuentra en conflicto con otra tabla!';

/** TEMPORADAS */
export const FORM_TEMPORADA = {
  FORM_ERROR: 'Para continuar, es necesario corregir los errores en el formulario.',
  FETCH_ERROR: 'Ha ocurrido un error al obtener los datos, por favor intente de nuevo.',

  NOMBRE_TEMPORADA_REQUERIDO: 'El nombre de la temporada es requerido.',
  NOMBRE_TEMPORADA_LARGO: 'El nombre de la temporada no puede ser mayor a 255 caracteres.',
  FECHA_INICIO_REQUERIDA: 'La fecha de inicio es requerida.',
  FECHA_INICIO_INVALIDA: 'La fecha de inicio no es válida.',
  FECHA_INICIO_MAYOR: 'La fecha de inicio no puede ser mayor a la fecha de fin.',
  FECHA_INICIO_MENOR: 'La fecha de inicio no puede ser menor a la fecha actual.',
  FECHA_FIN_REQUERIDA: 'La fecha de fin es requerida.',
  FECHA_FIN_INVALIDA: 'La fecha de fin no es válida.',
  FECHA_FIN_MAYOR: 'La fecha de fin no puede ser menor a la fecha actual.',
  FECHA_FIN_MENOR: 'La fecha de fin no puede ser menor a la fecha de inicio.',

  DIVISIONES_NOMBRE_REQUERIDO: 'El nombre de la división es requerido.',
  DIVISIONES_COORDENDAS_REQUERIDAS: 'Todas las divisiones deben contar con coordenadas registradas.',
  DIVISIONES_FUERA_SITIO: 'Todas las divisiones deben estar dentro del sitio de origen.',
  DIVISIONES_GUARDADAS: 'Las divisiones han sido guardadas.',
  DIVISIONES_REQUERIDAS: 'Es necesario agregar al menos una división.',
  DIVISIONES_SOLAPADAS: 'Las divisiones no pueden estar solapadas.',
  FORM_DIVISOR_EN_USO: 'Para continuar, debe terminar el proceso de división de sitio.',
  INVERNADEROS_FUERA_DIVISION: 'Todos los invernaderos deben estar dentro de una división.',
  SITIO_ORIGEN_REQUERIDO: 'El sitio de origen es requerido.',

  INVERNADEROS_UNIFICAR_SELECCION_REQUERIDA: 'Es necesario seleccionar un invernadero para unificar.',
  INVERNADEROS_UNIFICAR_MINIMO: 'Es necesario abarcar al menos un invernadero para unificar.',
  INVERNADEROS_DESUNIFICAR_MINIMO: 'El invernadero no fue creado a partir de una unificación.',
  INVERNADEROS_UNIFICACION_ABARCAR_EN_SU_TOTALIDAD: 'La unificación debe abarcar a los invernaderos en su totalidad.',
  INVERNADEROS_UNIFICAR_FUERA_SITIO: 'La unificación debe estar dentro de la zona del contenedor.',
  INVERNADEROS_UNIFICADOS: 'Los invernaderos han sido unificados.',
  FORM_UNIFICADOR_EN_USO: 'Para continuar, debe terminar el proceso de unificación de invernaderos.',

  INVERNADEROS_REQUERIDOS: 'Es necesario seleccionar al menos un invernadero.',

  NAVES_REQUERIDAS: 'Es necesario seleccionar al menos una nave.',
}
