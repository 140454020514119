import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import { MdArrowForwardIos } from 'react-icons/md';
import clsx from 'clsx';
import queryString from 'query-string';

import { setSitioID } from '../../ducks/configuraciones';
import styles from './styles';

const ListaConfiguracion = ({ datosLista, onClick, initOpcion }) => {
  const dispatch = useDispatch();
  const classes = styles();
  const history = useHistory();
  const location = useLocation();
  const [seleccion, setSeleccion] = useState(initOpcion);
  
  const handleClick = (id, index) => {
    setSeleccion(index);
    if (onClick) {
      onClick(id);
    } else {
      const search ={
        ...queryString.parse(location.search),
        sitioID: id,
      }
      dispatch(setSitioID(id));
      history.replace({ search: `?${queryString.stringify(search)}`});
    }
  };

  return (
    <div className={classes.root}>
      <List
        component="nav"
        subheader={
          <ListSubheader className={classes.listSubheader}>
            <Typography
              className={classes.title}
              aling="center"
            >
              {datosLista?.title}
            </Typography>
            <Typography
              component="p"
              variant="subtitle2"
              className={classes.subTitle}
              aling="center"
            >
              {datosLista?.subTitle}
            </Typography>
          </ListSubheader>
        }
      >
        <div className={classes.listItems}>
          <Typography
            className={classes.headerLista}
            display="block"
            aling="left"
          >
            {datosLista?.headerLista}
          </Typography>
          {datosLista.data?.map((option, index) => (
            <ListItem
              key={option.id}
              button
              onClick={() => handleClick(option.id, index)}
              className={clsx(classes.item, {
                active: index === seleccion,
              })}
            >
              <ListItemText primary={option[datosLista?.labelProp]} secondary={`${option.subTitle || ''}`}/>
              <ListItemIcon>
                <MdArrowForwardIos
                  className={clsx(classes.icon, {
                    active: index === seleccion,
                  })}
                  size={18}
                />
              </ListItemIcon>
            </ListItem>
          ))}
        </div>
      </List>
    </div>
  );
};

ListaConfiguracion.propTypes = {
  datosLista: propTypes.shape({
    labelProp: propTypes.string,
    headerLista: propTypes.string,
    title: propTypes.string,
    subTitle: propTypes.string,
    data: propTypes.array,
  }),
  initOpcion: propTypes.number,
  onClick: propTypes.func,
};

ListaConfiguracion.defaultProps = {
  datosLista: {},
  onClick: null,
  initOpcion: null,
}

export default ListaConfiguracion;
