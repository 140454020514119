/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '@material-ui/core';
import queryString from 'query-string';

import Default from '../Default';
import Tabla from '../../componentes/Table';

import endpoints from '../../configuraciones/endpoints';
import axios from '../../configuraciones/axios';
import general from '../../configuraciones/general';

import { setRegistros, setLimpiar } from '../../ducks/listados';

import styles from './styles';

const AgrupadorListados = ({
  titulo, subtitulo, mostrarLista, datosLista,
  history, location, placeHolder, tabsData,
  customActions, guardar, rowErrors, handleTabChange
}) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { registros } = useSelector(store => store.listados);

  const paginaRef = useRef(1);
  const [tabActivo, setTabActivo] = useState(Object.keys(tabsData)[0] || false);

  const consultarListado = async (key) => {
    try {
      const { apiKey = Object.values(tabsData)[0].apiKey, params: tabParams } = tabsData[key];
      const params = {
        ...(tabParams && tabParams),
        ...queryString.parse(location.search),
      };

      const data = await axios.get(endpoints.base.url(apiKey),
        {
          params: {
            ...params,
            registrosPorPagina: general.ELEMENTOS_POR_PAGINA,
            pagina: paginaRef.current,
          },
        }
      );

      if (data.rows.length === 0 && paginaRef.current > 1) {
        const newParams = {
          ...params,
          pagina: paginaRef.current - 1,
        };
        history.push({ search: `?${queryString.parse(newParams)}` });
      }

      dispatch(setRegistros(data));
    } catch { };
  };

  const cancelar = () => {
    consultarListado(tabActivo);
  }

  const buscar = (txtBusqueda) => {
    const params = {
      ...queryString.parse(location.search),
      txtBusqueda,
    }
    history.push({ search: `?${queryString.stringify(params, { skipEmptyString: true })}` });
  };

  const tabChange = (key) => {
    !!handleTabChange && handleTabChange(key);
    consultarListado(key)
    setTabActivo(key);
  };

  const handleGuardar = async () => {
    const error = await guardar(tabActivo);
    if (!error) consultarListado(tabActivo);
  }

  useEffect(() => {
    if (!tabActivo) return;

    const query = queryString.parse(location.search);
    paginaRef.current = query.pagina;
    consultarListado(tabActivo);

    return () => {
      dispatch(setLimpiar());
    }
  }, [location]);

  useEffect(() => {
    if (Object.keys(tabsData).length > 0 && !tabActivo) {
      setTabActivo(Object.keys(tabsData)[0]);
    };
  }, [tabsData]);

  return (
    <Default
      titulo={titulo}
      placeHolder={placeHolder}
      buscar={buscar}
      agregar={null}
      subtitulo={subtitulo || `${registros?.count || 0} elemento(s)`}
      mostrarLista={mostrarLista}
      datosLista={datosLista}
      mostrarCabeceroListado={false}
      mostrarCabeceroFormulario={true}
      guardar={handleGuardar}
      cancelar={cancelar}
      style={{ fontSize: 16 }}
    >
      <Tabs
        value={tabActivo || false}
        indicatorColor="primary"
        textColor="primary"
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        className={classes.tabList}
      >
        {
          Object.entries(tabsData).map(([key, { apiKey, name, params }], index) => (
            <Tab
              key={key}
              label={name}
              value={key}
              onClick={() => { tabChange(key, apiKey, params) }}
              className={(tabActivo === key) ? classes.activeTab : classes.tab}
              style={{
                borderTopLeftRadius: (index === 0) ? 8 : 0,
                borderTopRightRadius: (index === Object.entries(tabsData).length - 1) ? 8 : 0,
                borderLeft: (tabActivo === key && index === 0) ? '1px solid #E6EBF5' : '1px solid rgba(36, 36, 36, 0.1)',
                borderRight: (tabActivo === key && index === Object.entries(tabsData).length - 1) ? '1px solid #E6EBF5' : '1px solid rgba(36, 36, 36, 0.1)'
              }}
            />
          ))
        }
      </Tabs>
      <Tabla
        headers={tabsData[tabActivo]?.cabeceros || []}
        rows={registros?.rows || []}
        count={registros?.count || 0}
        onDelete={null}
        onEdit={null}
        moreActions={customActions}
        customClass={classes.table}
        rowErrors={rowErrors}
        showActions={!!tabsData[tabActivo]}
        customStyles={{
          borderTop: !!tabActivo ? 'none' : '1px solid rgba(36, 36, 36, 0.1)'
        }}
      />
    </Default>
  );
};

AgrupadorListados.propTypes = {
  /** Titulo a mostrar en el contenedor */
  titulo: propTypes.string.isRequired,
  /** Objeto history de react-router */
  history: propTypes.object.isRequired,
  /** Objeto location de react-router */
  location: propTypes.object.isRequired,
  /** Placeholder a mostrar en el input de busqueda */
  placeHolder: propTypes.string.isRequired,
  /** Objeto con la información de los tabs */
  tabsData: propTypes.object.isRequired,
  /** Arreglo con acciones extras a mostrar en la tabla */
  customActions: propTypes.array,
  /** Función a ejecutar cuando se presione el botón guardar */
  guardar: propTypes.func,
  /** Arreglo con las filas a mostrar error */
  rowErrors: propTypes.array,
  /** Función a ejecutar cuando se cambie de pestaña */
  handleTabChange: propTypes.func,
};

AgrupadorListados.defaultProps = {
  customActions: [],
  guardar: null,
  rowErrors: [],
  handleTabChange: null,
};

export default React.memo(AgrupadorListados);
