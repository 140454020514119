import React, { useCallback, useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Divider, Grid } from '@material-ui/core';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import moment from 'moment';
import clsx from 'clsx';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import cloneDeep from 'lodash/cloneDeep';

import Chart from '../Chart';
import RefreshControl from '../RefreshControl';
import Typography from '../Typography';

import {
  clearMonitoreoActivo,
  clearNavesDetectadas,
  setNavesDetectadas
} from '../../ducks/tablero';
import axios from '../../configuraciones/axios';
import endpoints, { CAPTURA_SANEOS } from '../../configuraciones/endpoints';
import paleta from '../../configuraciones/paleta';

import { obtenerRangoFechas, sumarArr } from '../../utilidades/functions';
import tooltipOptions from '../../constantes/chartTooltipOptions';

import styles from './styles';

const barChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    crosshair: false,
    title: {
      display: true,
      color: paleta.bar.primary,
      text: 'Causas',
      align: 'start',
      font: {
        weight: 'bold',
      },
      padding: {
        top: 10,
        bottom: 30
      },
    },
    datalabels: {
      formatter: function (valor) {
        const cantidad = valor.cantidad;
        if (isNaN(cantidad)) return 0;
        return Math.round(cantidad);
      },
      backgroundColor: paleta.graficos.dataLabel,
      color: paleta.graficos.dataLabelText,
      borderRadius: 5,
      font: {
        weight: 'bold',
      },
      padding: 6,
    },
    tooltip: {
      ...tooltipOptions,
      callbacks: {
        label: (tooltipItems) => {
          return 'Cabezas:  ' + tooltipItems.formattedValue
        }
      },
    },
  },
  scales: {
    y: {
      suggestedMin: 0,
      ticks: {
        beginAtZero: true,
        callback: function(value) {if (value % 1 === 0) {return value;}}
      }
    },
    x: {
      ticks: {
        major: {
          enabled: true,
        },
        font: function(context) {
          if (context.tick && context.tick.major) {
            return {
              weight: 'bold'
            };
          }
        }
      },
      afterBuildTicks: ({ ticks }) => {
        ticks.forEach(t => { t.major = false; });
        return ticks;
      },
    },
  },
  parsing: {
    xAxisKey: 'saneo',
    yAxisKey: 'cantidad',
  },
};

const lineChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  interaction: { intersect: false },
  plugins: {
    title: { display: false },
    datalabels: { display: false },
    crosshair: false,
    tooltip: {
      callbacks: {
        title: (tooltipItems) => 'Semana ' + tooltipItems[0].label,
      }
    },
  },
  scales: {
    y: {
      min: 0,
      ticks: {
        beginAtZero: true,
        callback: function(value) {if (value % 1 === 0) {return value;}}
      }
    },
  },
  parsing: {
    yAxisKey: 'id'
  }
};

const SANEOS_TABLERO = 'saneos-tablero';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const GraficasSaneos = () => {
  const classes = styles()
  const { sitioID, semana, temporada } = useSelector(
    store => store.tablero,
    (prev, next) => (
      prev.sitioID === next.sitioID &&
      prev.semana === next.semana &&
      prev.temporada.fechaInicio === next.temporada.fechaInicio &&
      prev.temporada.fechaFin === next.temporada.fechaFin
    )
  );

  const dispatch = useDispatch();
  const chartRef = useRef(null);

  const [ uiOptions, setUiOptions ] = useState({
    estaRefrescando: false,
  });
  const [ barChart, setBarChart ] = useState({
    data: null,
    options: barChartOptions,
  });
  const [ lineChart, setLineChart ] = useState({
    data: null,
    options: lineChartOptions,
  });

  const consultarDatos = useCallback(async () => {
    try {
      if (sitioID && semana) {
        const cacheData = JSON.parse(localStorage.getItem(SANEOS_TABLERO)) || {};
        if ( cacheData.sitioID === sitioID && cacheData.semana === semana ) {
          setBarChart(prev => ({ ...prev, data: cacheData.datos }));
          return;
        }

        const fechaObjetivo = moment(semana, 'W (YYYY)')
          .startOf('isoWeek')
          .format('YYYY-MM-DD');

        const data = [];
        const { saneos, capturaSaneos, totalSaneos } = await axios.get(CAPTURA_SANEOS, {
          params: { sitioID, fechaObjetivo },
        });

        for (const saneo of saneos) {
          const captura = capturaSaneos.find(captura => captura.saneoID === saneo.id) || null;
          data.push({
            id: saneo.id,
            saneo: saneo.nombre,
            cantidad: Number(captura?.cantidad) || 0
          })
        };

        data.sort((anterior, actual) => {
          if (actual.cantidad > anterior.cantidad) {
            return 1;
          } else if (anterior.cantidad > actual.cantidad) {
            return -1;
          } else {
            return 0;
          }
        });

        const barChartData = {
          datasets: [
            {
              label: `Sitio ${sitioID}`,
              data: data,
              backgroundColor: paleta.graficos.barPrimary,
              datalabels: {
                formatter: function(value) {
                  return String(value.cantidad)
                },
                spanGaps: true,
                align: 'end',
                anchor: 'end',
                backgroundColor: paleta.graficos.dataLabel,
              }
            }
          ],
          total: totalSaneos,
          sitioID: sitioID,
        };

        setBarChart({
          data: barChartData,
          options: barChartOptions,
        });
        localStorage.setItem(SANEOS_TABLERO, JSON.stringify({
          sitioID,
          semana,
          datos: barChartData,
        }));
      }
    } finally {
      setLineChart({ data: null, options: lineChartOptions });
      setUiOptions({ estaRefrescando: false });
    }
  }, [sitioID, semana]);

  const obtenerHistorial = useCallback(async(saneo, index) => {
    try {
      const {
        capturasTemporadaActual,
        capturasTemporadaPasada,
      } = await axios.get(endpoints.historialCapturas(saneo.id), {
        params: { sitioID },
      });

      const newBarChartOptions = cloneDeep(barChartOptions);
      const newLineChartOptions = cloneDeep(lineChartOptions);

      const semanas = obtenerRangoFechas(temporada.fechaInicio, temporada.fechaFin, 'W-YYYY', true, false);

      const dataset1 = [];
      const dataset2 = [];
      const capturasEnSemana = capturasTemporadaActual.filter(val => val.fecha === moment(semana, 'W (YYYY)').format('W-YYYY'));

      for (const semana of semanas) {
        const semanaTemporadaPasada = moment(semana, 'W-YYYY').subtract(1, 'year').format('W-YYYY');
        dataset1.push({
          valor: sumarArr(capturasTemporadaActual.filter(val => val.fecha === semana), 'valor'),
          semana,
        });
        dataset2.push({
          valor: sumarArr(capturasTemporadaPasada.filter(val => val.fecha === semanaTemporadaPasada), 'valor'),
          semana,
        });
      }

      const capturasEnNave = capturasEnSemana.reduce((acumulador, captura) => ({
        ...acumulador,
        [captura.naveID]: Number(captura.valor) + (acumulador[captura.naveID] || 0),
      }), {});
      const naves = Object.entries(capturasEnNave).map(([key, value]) => ({
        id: parseInt(key, 10),
        estado: 'Verde',
        mostrarTooltip: true,
        nombreDeteccion: saneo.saneo,
        detalles: [{ parametro: "Cabezas saneadas", valor: value, }]
      }));

      newBarChartOptions.scales.x.afterBuildTicks = (axis) => {
        const { ticks } = axis;
        ticks.forEach(t => {
          t.major = index === t.value;
        });
        return ticks;
      };
      newLineChartOptions.scales.y.suggestedMax = Math.max(...dataset1.map(val => val.valor)) + (Math.max(...dataset1.map(val => val.valor)) * 0.1);

      setLineChart({
        options: newLineChartOptions,
        data: {
          labels: semanas,
          datasets: [
            {
              label: `${saneo.saneo}`,
              data: dataset1.map(val => val.valor),
              borderColor: paleta.graficos.linePrimary,
              pointBackgroundColor: dataset1.map(() => '#FFF'),
              pointHoverBackgroundColor: dataset1.map(() => paleta.graficos.linePrimary),
              pointBorderColor: dataset1.map(() => paleta.graficos.linePrimary),
              borderWidth: 2,
              pointRadius: 3,
              pointHoverRadius: 8,
              cubicInterpolationMode: 'monotone',
              tension: 0.4,
            },
            {
              label: `${saneo.saneo} (Temporada pasada)`,
              data: dataset2.map(val => val.valor),
              borderColor: paleta.graficos.lineSecondary,
              pointBackgroundColor: dataset2.map(() => '#FFF'),
              pointHoverBackgroundColor: dataset2.map(() => paleta.graficos.linePrimary),
              pointBorderColor: dataset2.map(() => paleta.graficos.linePrimary),
              borderWidth: 2,
              pointRadius: 3,
              pointHoverRadius: 8,
              cubicInterpolationMode: 'monotone',
              tension: 0.4,
            },
          ],
        }
      });
      setBarChart(prev => ({
        options: newBarChartOptions,
        data: {
          ...prev.data,
          datasets: [
            {
              ...prev.data.datasets[0],
              backgroundColor: prev.data.datasets[0].data.map((data, indexData) => indexData === index ? paleta.graficos.barSelected : paleta.graficos.barPrimary),
              borderColor: '#000',
              datalabels: {
                formatter: function(value) {
                  return String(value.cantidad)
                },
                align: 'end',
                anchor: 'end',
                backgroundColor: prev.data.datasets[0].data.map((data, indexData) => indexData === index ? paleta.graficos.barPrimary : paleta.graficos.dataLabel),
              },
            }
          ],
        },
      }));
      batch(() => {
        dispatch(clearMonitoreoActivo());
        dispatch(setNavesDetectadas(naves));
      });
    } catch { }
  }, [dispatch, semana, sitioID, temporada]);

  const onRefreshData = useCallback(() => {
    localStorage.removeItem(SANEOS_TABLERO);
    setUiOptions(prevState => ({ ...prevState, estaRefrescando: true }));
    batch(() => {
      dispatch(clearMonitoreoActivo());
      dispatch(clearNavesDetectadas());
    });
    consultarDatos();
  }, [dispatch, consultarDatos]);

  const onClickBar = useCallback((event) => {
    const { current: chart } = chartRef;
    const element = getElementAtEvent(chart, event);

    if (!chart || !element.length ) return;

    const { datasetIndex, index } = element[0];
    const saneo = barChart.data.datasets[datasetIndex].data[index];
    obtenerHistorial(saneo, index);
  }, [barChart, obtenerHistorial]);

  useEffect(() => {
    consultarDatos();

    return () => {
      dispatch(clearMonitoreoActivo());
      dispatch(clearNavesDetectadas());
    }
  }, [consultarDatos, dispatch]);

  if ( !barChart.data ) return null;

  return (
    <Grid container className={classes.container}>
      {/* Información general */}

      <Grid container>
        {/* Grafico de barras */}
        <Grid container className={classes.detalles}>
          <Grid item className={classes.label}>
            <Typography bold className={classes.labelTitle}>{barChart.data?.total?.toFixed(3) || 0}</Typography>
            <Typography className={classes.labelSubtitle}>Cabezas saneadas</Typography>
          </Grid>
          <RefreshControl
            onClick={onRefreshData}
            isRefreshing={uiOptions.estaRefrescando}
            className={classes.refreshControl}
          />
        </Grid>
        <Bar ref={chartRef} onClick={onClickBar} options={barChart.options} data={barChart.data} />
      </Grid>

      <Divider className={clsx(classes.divider, { active: (lineChart.data) })} />

      {/* Información detallada */}
      <Grid container className={clsx(classes.historial, { active: (lineChart.data) })}>
        <Grid style={{ width: '100%' }}>
          {lineChart.data && (
            <Chart
              type="line"
              data={lineChart.data}
              options={lineChart.options}
              title="Tendencia"
              scrollTo={moment(semana, 'W (YYYY)').format('W-YYYY')}
              showLegend
              redraw
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(GraficasSaneos);
