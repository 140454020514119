/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { toast } from 'react-toastify';
import { BsFillGearFill } from 'react-icons/bs';

import Default from '../../contenedores/Default';
import IrrigacionAgrupador from '../../componentes/IrrigacionAgrupador';
import Select from '../../componentes/Select';
import TextField from '../../componentes/TextField';

import axios from '../../configuraciones/axios';
import { findPropertysEmpty, trim } from '../../utilidades/functions';
import endpoints, {
  AGRUPADORES,
  IRRIGACIONES, TIPO_SUELO
} from '../../configuraciones/endpoints';
import estatus from '../../constantes/estatus';
import requerido from '../../constantes/requerido';
import tipoCaptura from '../../constantes/tipoCaptura';
import { ELEMENTO_NO_ENCONTRADO, TIPO_SUELO_NO_SELECCIONADO } from '../../configuraciones/mensajes';
import paleta from '../../configuraciones/paleta';

const noneAgrupador = [{id: -1, nombre: 'N/A'}];

const Irrigacion = ({ history, match }) => {
  const [irrigacion, setIrrigacion] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [agrupadores, setAgrupadores] = useState(noneAgrupador);
  const [tipos, setTipos] = useState([]);
  const [mostrarAgrupadores, setMostrarAgrupadores] = useState(false);
  const { params: { id } } = match;

  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/irrigaciones',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  const cancelar = useCallback(regresar, []);

  const guardar = useCallback(() => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmIrrigacion'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;
    
    const metodo = id ? 'put' : 'post';

    const objTrim = trim(irrigacion);

    if ( irrigacion.agrupadorID === -1 ) {
      objTrim.agrupadorID = null
    }

    axios[metodo](IRRIGACIONES, objTrim).then(regresar);
  }, [irrigacion]);

  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.base.url(TIPO_SUELO)),
    ];
    if (id) promesas.push(axios.get(endpoints.base.url(IRRIGACIONES, id)));

    Promise.all(promesas).then((resultadosPromesas) => {
      const [tiposDB, irrigacionDb] = resultadosPromesas;
      setTipos(tiposDB);
      if (irrigacionDb?.id){
        setAgrupadores([...noneAgrupador, ...tiposDB.find(tipo => tipo.id === irrigacionDb.tipoSueloID).agrupadores])
        setIrrigacion(irrigacionDb);
      }
      else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      } else {
        setIrrigacion({estatus: true, requerido: true});
      }
      resolve();
    }).catch(reject);
  });
  
  const guardarAgrupadores =  async (agrupadores) => {
    try {
      const metodo = (agrupadores.length > 0) ? 'put' : 'post';
      const filtrarAgrupadores = agrupadores.filter(agrupador => agrupador.id !== -1);
      const datosTrim = filtrarAgrupadores.map(obj => trim(obj));
  
      await axios[metodo](AGRUPADORES, {agrupadores: [...datosTrim]});
  
      const tipoDb = await axios.get(endpoints.base.url(TIPO_SUELO));
      const nuevosAgrupadores = tipoDb.find(tipo => tipo.id === irrigacion.tipoSueloID).agrupadores;
  
      setTipos(tipoDb);
      setAgrupadores([...noneAgrupador, ...nuevosAgrupadores]);
      setMostrarAgrupadores(false);
    } catch {}
  }

  const handleMostrarAgrupadores = () => {
    if ( irrigacion.tipoSueloID )
      setMostrarAgrupadores(true);
    else
      toast.error(TIPO_SUELO_NO_SELECCIONADO);
  }

  useEffect(() => {
    consultarDatosIniciales();
  }, [id]);
  
  useEffect(() => {
    if ( tipos.length > 0 ) {
      const agrupadores = tipos.find(tipo => tipo.id === irrigacion.tipoSueloID).agrupadores;
      setAgrupadores([...noneAgrupador, ...agrupadores]);

      const agrupadoresIDs = agrupadores.map(agrupador => agrupador.id);
      if ( !agrupadoresIDs.includes(irrigacion.agrupadorID) ){
        setIrrigacion({...irrigacion, agrupadorID: -1 });
      }
    }
  }, [irrigacion.tipoSueloID]);

  return <Default
    titulo={Boolean(id) ? 'Editar Irrigación' : 'Nueva Irrigación'}
    placeHolder={''}
    mostrarCabeceroFormulario
    guardar={guardar}
    cancelar={cancelar}>
    <IrrigacionAgrupador
      mostrar={mostrarAgrupadores}
      cancelar={() => setMostrarAgrupadores(false)}
      agrupadores={agrupadores || []}
      guardar={guardarAgrupadores}
      tipoSuelo={(tipos.find(tipo => tipo.id === irrigacion.tipoSueloID))}
      irrigacionID={id}
    />
    <Grid container spacing={2} id="frmIrrigacion">
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Nombre de Irrigación"
              onChange={setIrrigacion}
              name="nombre"
              value={irrigacion.nombre}
              isHandleChange
              inputProps={{ maxLength: 50 }}
              error={formErrors.nombre}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Tipo de Captura"
              required
              options={tipoCaptura}
              onChange={setIrrigacion}
              name="tipoCaptura"
              value={irrigacion.tipoCaptura || ''}
              error={formErrors.tipoCaptura}
              isHandleChange
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Tipo de Suelo"
              required
              options={tipos}
              onChange={setIrrigacion}
              labelProp="nombre"
              name="tipoSueloID"
              value={irrigacion.tipoSueloID || ''}
              error={formErrors.tipoSueloID}
              isHandleChange
            />
          </Grid>
          <Grid item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item xs={10}>
              <Select
                label="Agrupador"
                options={agrupadores}
                onChange={setIrrigacion}
                labelProp="nombre"
                name="agrupadorID"
                value={irrigacion.agrupadorID || -1}
                error={formErrors.agrupadorID}
                isHandleChange
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={handleMostrarAgrupadores}>
                <BsFillGearFill color={paleta.bar.primary} />
              </IconButton>
            </Grid>
          </Grid>
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Captura Obligatoria"
              required
              options={requerido}
              onChange={setIrrigacion}
              name="requerido"
              value={irrigacion.requerido}
              error={formErrors.requerido}
              isHandleChange
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4}>    
          <Grid item xs={12}>
            <Select
              label="Estatus"
              required
              options={estatus}
              onChange={setIrrigacion}
              name="estatus"
              value={irrigacion.estatus}
              error={formErrors.estatus}
              isHandleChange
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(Irrigacion);