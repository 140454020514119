/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import Default from '../../contenedores/Default';
import TextField from '../../componentes/TextField';
import Select from '../../componentes/Select';
import axios from '../../configuraciones/axios';
import { findPropertysEmpty, trim } from '../../utilidades/functions';
import endpoints, {
  ACTIVIDADES, PERMISOS
} from '../../configuraciones/endpoints';
import estatus from '../../constantes/estatus';
import { ELEMENTO_NO_ENCONTRADO } from '../../configuraciones/mensajes';

const Actividad = ({ history, match }) => {
  const [actividad, setActividad] = useState({});
  const [opcionesMenu, setOpcionesMenu] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const { params: { id } } = match;

  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/actividades',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  const cancelar = useCallback(regresar, []);

  const guardar = useCallback(() => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmActividad'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;

    const objTrim = trim(actividad);

    const metodo = id ? 'put' : 'post';

    axios[metodo](ACTIVIDADES, objTrim).then(regresar);
  }, [actividad]);

  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(PERMISOS),
    ];

    if (id) promesas.push(axios.get(endpoints.base.url(ACTIVIDADES, id)));

    Promise.all(promesas).then((resultadosPromesas) => {
      const [modulosDb, actividadDb] = resultadosPromesas;

      const modulosMovil = modulosDb?.find(({ moduloWeb }) => moduloWeb === false).modulos;
      setOpcionesMenu(modulosMovil);

      if (actividadDb?.id)
        setActividad(actividadDb);
      else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      } else {
        setActividad({estatus: true});
      }
      resolve();
    }).catch(reject);
  });

  useEffect(() => {
    consultarDatosIniciales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return <Default
    titulo={Boolean(id) ? 'Editar Actividad' : 'Nueva Actividad'}
    placeHolder={''}
    mostrarCabeceroFormulario
    guardar={guardar}
    cancelar={cancelar}>
    <Grid container spacing={2} id="frmActividad">
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Código de Actividad"
              name="id"
              value={actividad.id}
              isHandleChange
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nombre de Actividad"
              onChange={setActividad}
              name="nombre"
              value={actividad.nombre}
              isHandleChange
              inputProps={{ maxLength: 50 }}
              error={formErrors.nombre}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Opción Menú"
              required
              options={opcionesMenu}
              labelProp="descripcion"
              onChange={({ target: { value } }) => {
                setActividad((current) => ({ ...current, moduloID: value }));
              }}
              name="moduloID"
              value={actividad.moduloID || ''}
              error={formErrors.moduloID}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Estatus"
              required
              options={estatus}
              onChange={setActividad}
              name="estatus"
              value={actividad.estatus}
              error={formErrors.estatus}
              isHandleChange
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(Actividad);