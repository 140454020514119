import { makeStyles } from "@material-ui/core";
import paleta from "../../configuraciones/paleta";

const styles = makeStyles((theme) => ({
  root: {
    width: 240,
    maxWidth: 240,
    overflowY: "scroll",
    backgroundColor: paleta.sidebar.active,
  },
  listSubheader: {
    backgroundColor: paleta.sidebar.active,
    position: 'static'
  },
  title: {
    fontSize: 16,
    marginTop: 15,
    color: paleta.bar.icono,
    marginRight: 10,
    fontWeight: "600",
  },
  headerLista: {
    fontSize: 16,
    marginTop: 15,
    marginBottom: 4,
    color: paleta.bar.icono,
    marginLeft: 15,
    fontWeight: "600",
  },
  subTitle: {
    fontSize: 12,
  },
  listItems: {
    marginTop: 40,
  },
  item: {
    width: "auto",
    "& span": {
      color: paleta.bar.icono,
      fontWeight: "600",
      fontSize: 12,
      "& p": {
        color: paleta.bar.elements,
        fontSize: 10,
        fontWeight: "500",
      },
    },
    "&.active": {
      backgroundColor: paleta.sidebar.backgroundColor,
      borderLeft: `3px solid ${paleta.bar.primary}`,
    },
  },
  icon: {
    color: paleta.bar.point,
    marginLeft: 'auto',
    "&.active": {
      color: paleta.bar.primary,
    },
  },
}));

export default styles;
