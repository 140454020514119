import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Chip } from '@material-ui/core';
import moment from 'moment';

import CabeceroReportes from '../../componentes/CabeceroReportes';
import Default from '../../contenedores/Default';
import ListaConfiguracion from '../../componentes/ListaConfiguracion';
import Tabla from '../../componentes/Table';

import { useFetchReporte } from '../../hooks/useFetchReporte';

import endpoints, {
	NIVELESPOLINIZACION,
} from '../../configuraciones/endpoints';

import { DatePickerTypes } from '../../constantes/datePickerTypes';

const datosLista = {
	labelProp: 'nombre',
	headerLista: 'Nombre',
	title: 'Reporte de Polinizaciones',
	data: [
		{
			id: 1,
			nombre: 'Polinizaciones detallado',
			subTitle: 'Listado por nivel.',
		},
		{
			id: 2,
			nombre: 'Polinizaciones agrupado',
			subTitle: 'Listado por nivel.',
		},
	],
};

const cabeceros = [
	{ label: 'SITIO', key: 'sitio', align: 'left' },
	{ label: 'INVERNADERO', key: 'invernadero', align: 'left' },
	{ label: 'NAVE', key: 'Nave', align: 'left' },
	{ label: 'POLINIZADO', key: 'polinizado', align: 'left' },
	{ label: 'NIVEL', key: 'Nivel', align: 'left' },
	{
		label: 'Estado',
		key: 'estado',
		align: 'center',
		transform: (data) => {
			if (!data) return null;
			return (
				<Chip
					style={{
						backgroundColor: data?.estado?.color,
						color: '#FFFF',
						width: 100,
						height: 25,
						fontWeight: 'bold',
						fontSize: 13,
					}}
					label={`${data?.estado?.valor}`}
					size='small'
				/>
			);
		},
	},
];

const ReportePolinizacionAgrupado = ({ history }) => {
	const { registros } = useSelector((store) => store.listados);

  const { data, formData, buscar, onChange, onDelete } = useFetchReporte(
		{ niveles: endpoints.base.busqueda(NIVELESPOLINIZACION) },
		`${endpoints.reportePolinizaciones()}/agrupados`,
  );

  const { niveles = [] } = data;

	const handleTodosSelected = useCallback(() => {
		const { naves, fecha } = formData;
		const nivelesData = formData.niveles.includes(0)
			? niveles.filter((nivel) => nivel.id !== 0).map((nivel) => nivel.id)
      : formData.niveles;

		const params = {
			naves,
			fecha: moment(fecha).format('W-GGGG'),
			niveles: nivelesData,
    };

		return params;
	}, [formData, niveles]);

	const onSubMenuClick = (id) => {
		if (id === 1) {
			history.push(`/reportes/polinizacion/detallado`);
		} else {
			history.push(`/reportes/polinizacion/agrupado`);
		}
	};

	return (
		<Default
			mostrarLista={
				<ListaConfiguracion
					datosLista={datosLista}
					onClick={onSubMenuClick}
					initOpcion={1}
				/>
			}
		>
			<CabeceroReportes
				data={data}
				formData={formData}
				buscar={buscar}
				onChange={onChange}
				onDelete={onDelete}
				handleTodosSelected={handleTodosSelected}
				selects={{
					sitio: { width: 4, show: true },
					invernadero: { width: 4, show: true },
					nave: { width: 4, show: true },
					nivel: { width: 4, show: true },
					fecha: { width: 4, show: true, type: DatePickerTypes.week },
				}}
				endpoint={`${endpoints.reportePolinizaciones()}/agrupados`}
				method='POST'
			/>
			<Tabla
				headers={cabeceros}
				rows={registros.rows.length > 0 ? registros.rows : []}
				count={registros.count}
				showActions={false}
			/>
		</Default>
	);
};

export default ReportePolinizacionAgrupado;
