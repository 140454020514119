import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  PyE: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 70,
    right: 8,
    zIndex: 99,
  },
  btnPyE: {
    minHeight: 20,
    height: 40,
    minWidth: 18,
    width: 18,
    borderRadius: 2,
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
    
    '&:hover': {
      backgroundColor: '#FFFFFF',
      borderColor: '#FFFFFF',
    },
  },
  active: {
    backgroundColor: '#91a3bf',
    borderColor: '#FFFFFF',
    color: '#FFFFFF',

    '&:hover': {
      backgroundColor: '#91a3bf',
      borderColor: '#FFFFFF',
      color: '#FFFFFF',
    },
  },
}));

export default styles;