import React from 'react';
import { Divider } from '@material-ui/core';

import Agrupador from '../Agrupador';
import NaveDetalleDetecciones from '../NaveDetalleDetecciones';
import NavePlagasEnfermedades from '../NavePlagasEnfermedades';

import styles from './styles';

const NaveDetecciones = ({ naveID }) => {
  const classes = styles() 

  return (
    <Agrupador customClass={classes.agrupador}>
      <NaveDetalleDetecciones naveID={naveID} />
      <Divider className={classes.divider} />
      <NavePlagasEnfermedades naveID={naveID} />
    </Agrupador>
  )
};

export default React.memo(NaveDetecciones);
