import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import TextField from '../TextField';

import useDebounce from '../../hooks/useDebounce';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const SearchBox = ({
  name,
  label,
  placeholder,
  className,
  debounceDelay,
}) => {
  const history = useHistory();
  const query = useQuery();

  const [busqueda, setBusqueda] = React.useState(() => query.get(name));
  const debouncedBusqueda = useDebounce(busqueda, debounceDelay);

  const handleChange = useCallback((e) => {
    const { value } = e.target;
    setBusqueda(value);
  }, []);

  React.useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    if (debouncedBusqueda !== query.get(name)) {
      if (!debouncedBusqueda) query.delete(name);
      else query.set(name, debouncedBusqueda);
      history.push({ search: query.toString() });
    }
  }, [debouncedBusqueda, history, name]);

  return (
    <TextField
      placeHolder={placeholder}
      className={className}
      onChange={handleChange}
      name={name}
      value={busqueda}
      label={label}
    />
  )
}

SearchBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  debounceDelay: PropTypes.number,
};

SearchBox.defaultProps = {
  label: '',
  placeholder: '',
  className: '',
  debounceDelay: 500,
};

export default React.memo(SearchBox);
