import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';

import Table from '../../../componentes/Table';
import Typography from '../../../componentes/Typography';

import { enumPasos, seleccionarNave, seleccionarTodasNaves } from '../../../ducks/temporada';

const cabeceros = [
  { key: 'nombre', label: 'Nombre' },
  { key: 'invernadero', label: 'Invernadero' },
];

const ConfiguracionNaves = () => {
  const dispatch = useDispatch();

  const naves = useSelector((state) => {
    const { byId } = state.temporada.form[enumPasos.NAVES].data;
    const { byId: invernaderosForm } = state.temporada.form[enumPasos.INVERNADEROS].data;
    return Object.values(byId).map((nave) => ({
      ...nave,
      invernadero: invernaderosForm[nave.invernaderoId].nombre,
    }));
  }, shallowEqual);

  const seleccionar = useCallback((({ id }) => {
    dispatch(seleccionarNave(id));
  }), [dispatch]);

  const seleccionarTodas = useCallback((valor) => {
    dispatch(seleccionarTodasNaves(!valor));
  }, [dispatch]);

  return (
    <Box maxWidth="75%" maxHeight="58vh">
      <Box mb={1}>
        <Typography variant="subtitle1">
          <b>Naves</b>
        </Typography>
      </Box>
      <Box>
        <Table
          headers={cabeceros}
          rows={naves}
          showCheckbox
          showActions={false}
          hidePaginator
          customStyles={{ maxHeight: '54vh' }}
          onSelect={seleccionar}
          onSelectAll={seleccionarTodas}
          minRows={0}
        />
      </Box>
    </Box>
  )
};

export default ConfiguracionNaves;
