import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { MdLoop } from 'react-icons/md';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';

import Typography from '../Typography';

import paleta from '../../configuraciones/paleta';
import styles from './styles';

const RefreshControl = ({
  buttonClassName,
  className,
  isRefreshing,
  label,
  mostrarUltimaActualizacion,
  onClick,
}) => {
  const classes = styles();
  const [lastUpdated, setLastUpdated] = useState(moment());
  const [, setForceUpdate] = useState(false);

  const onRefresh = () => {
    setLastUpdated(moment());
    onClick();
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setForceUpdate(prev => !prev);
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Grid container className={clsx(classes.refresh, className)}>
      {
        label && <Typography variant="body1">{ label }</Typography>
      }
      {
        mostrarUltimaActualizacion && (
          <Typography variant="body1" color="textSecondary">
            Última actualización: {lastUpdated.format('h:mm a')} ({lastUpdated.fromNow()})
          </Typography>
        )
      }
      <Tooltip title="Recargar información" arrow className={buttonClassName}>
        <IconButton onClick={onRefresh} className={classes.refreshControl} disabled={isRefreshing}>
          <MdLoop
            size={22}
            color={paleta.bar.primary}
            className={clsx({
              [classes.refreshAnimation]: isRefreshing,
            })}
          />
        </IconButton>
      </Tooltip>
    </Grid>
  )
};

RefreshControl.propTypes = {
  onClick: propTypes.func.isRequired,
  isRefreshing: propTypes.bool,
  className: propTypes.string,
  label: propTypes.string,
};

RefreshControl.defaultProps = {
  isRefreshing: false,
  className: null,
  label: null,
  mostrarUltimaActualizacion: false,
};

export default React.memo(RefreshControl);
