import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Agrupador from '../Agrupador';
import BarraProgreso from '../BarraProgreso';
import RefreshControl from '../RefreshControl';
import Table from '../Table';
import Typography from '../Typography';

import endpoints from '../../configuraciones/endpoints';
import paleta from '../../configuraciones/paleta';
import tableroAPI from '../../configuraciones/tableroAPI';

import styles from './styles';

const EstadoMonitoreo = () => {
	const classes = styles();
	const { sitioID, semana } = useSelector(
		(store) => store.tablero,
		(prev, next) => prev.sitioID === next.sitioID && prev.semana === next.semana
  );

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);

	const cabeceros = useMemo(() => {
		const semanas = [];
		for (let i = 0; i < 5; i++) {
			semanas.push(moment(semana, 'W (YYYY)').subtract(i, 'weeks').isoWeek());
		}

		return [
			{ label: 'ACTIVIDAD', align: 'left', key: 'nombre' },
			{
				label: `SEMANA ${semanas[4]}`,
				transform: ({ data }) => {
					return (
						<BarraProgreso
							progreso={data[semanas[4]]?.promedio || 0}
							detalleMonitoreo={data[semanas[4]]?.detalle}
							progresoSinFormato
							monitoreo
							mostrarTooltip
						/>
					);
				},
			},
			{
				label: `SEMANA ${semanas[3]}`,
				transform: ({ data }) => {
					return (
						<BarraProgreso
							progreso={data[semanas[3]]?.promedio || 0}
							detalleMonitoreo={data[semanas[3]]?.detalle}
							progresoSinFormato
							monitoreo
							mostrarTooltip
						/>
					);
				},
			},
			{
				label: `SEMANA ${semanas[2]}`,
				transform: ({ data }) => {
					return (
						<BarraProgreso
							progreso={data[semanas[2]]?.promedio || 0}
							detalleMonitoreo={data[semanas[2]]?.detalle}
							progresoSinFormato
							monitoreo
							mostrarTooltip
						/>
					);
				},
			},
			{
				label: `SEMANA ${semanas[1]}`,
				transform: ({ data }) => {
					return (
						<BarraProgreso
							progreso={data[semanas[1]]?.promedio || 0}
							detalleMonitoreo={data[semanas[1]]?.detalle}
							progresoSinFormato
							monitoreo
							mostrarTooltip
						/>
					);
				},
			},
			{
				label: `SEMANA ${semanas[0]}`,
				transform: ({ data }) => {
					return (
						<BarraProgreso
							progreso={data[semanas[0]]?.promedio || 0}
							detalleMonitoreo={data[semanas[0]]?.detalle}
							progresoSinFormato
							monitoreo
							mostrarTooltip
						/>
					);
				},
			},
		];
	}, [semana]);

  const consultarAvanceCapturas = useCallback(async () => {
    setRows([]);
    setLoading(true);
		try {
      if (!sitioID) return;

      const fechaObjetivo = moment(semana, 'W (YYYY)').startOf('isoWeek').format('YYYY-MM-DD');
      let cantidadPeticionesRealizadas = 0;

      const urls = [
        endpoints.obtenerEstadoMonitoreoPolinizacion,
        endpoints.obtenerEstadoMonitoreoPlantasEnfermedades,
        endpoints.obtenerEstadoMonitoreoTrampas,
        endpoints.obtenerEstadoMonitoreoFenologias,
        endpoints.obtenerEstadoMonitoreoIrrigaciones,
        endpoints.obtenerEstadoMonitoreoSaneos
      ];

      const titulos = ['Polinización', 'P&E: Plantas', 'P: Trampas', 'Fenologías', 'Irrigación', 'Saneo'];

      urls.forEach((endpoint, index) => {
        tableroAPI.get(endpoint(sitioID), { params: { fechaObjetivo } })
          .then(response => {
            setRows(prevRows => [
              ...prevRows,
              {
                id: index,
                nombre: titulos[index],
                data: response
              }
            ]);
            cantidadPeticionesRealizadas++;
          })
          .catch(error => {
            // Manejo de error para la petición individual
            console.error(`Error en la carga de ${titulos[index]}: `, error);
          })
          .finally(() => {
            if (cantidadPeticionesRealizadas === urls.length) setLoading(false);
          })
      });
		} catch { }
	}, [semana, sitioID]);

	useEffect(() => {
		consultarAvanceCapturas();

		return () => {
			setRows([]);
		}
	}, [consultarAvanceCapturas]);

	return (
    <Agrupador customClass={classes.agrupador}>
      <Grid container style={{ gap: 15 }}>
        <Grid item container>
          <Typography variant="h4" bold className={classes.labelTitle}>
            ESTADO DEL MONITOREO
          </Typography>
          <RefreshControl
            isRefreshing={loading}
            onClick={consultarAvanceCapturas}
            mostrarUltimaActualizacion
            className={classes.refresh}
            buttonClassName={classes.refreshButton}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.labelSubtitle}>
            Porcentaje de avance de captura de información.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Table
            headers={cabeceros}
            showActions={false}
            hidePaginator
            rows={rows}
            customStyles={{
              height: 'auto',
              minHeight: 250,
              borderColor: paleta.tabla.bordertd,
            }}
            cellStyles={{
              padding: 5,
            }}
            minRows={6}
            emptyMessage={loading ? 'Cargando...' : 'No hay datos para mostrar'}
          />
        </Grid>
      </Grid>
		</Agrupador>
	);
};

export default React.memo(EstadoMonitoreo);
