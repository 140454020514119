import React, { useCallback, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { BsPencil } from 'react-icons/bs';
import { FiTrash } from 'react-icons/fi';
import { Paper, IconButton, Checkbox, Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import Confirmacion from '../Confirmacion';
import Paginador from '../Paginador';
import Typography from '../Typography';

import general from '../../configuraciones/general';
import styles from './styles';

const Table = ({
  headers, rows, showActions, onEdit,
  onDelete, moreActions, showEmptyLabel, hideDelete,
  count, hidePaginator, showCheckbox, onSelectAll,
  onSelect, customStyles, customClass, cellStyles,
  rowErrors, emptyMessage, editingItemId,
  onSelectRow, hideSelectAll, minRows,
}) => {
  const classes = styles();
  const [anchorConfirmacion, setAnchorConfirmacion] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);
  const ajax = useSelector(({ ajax }) => (ajax));
  const [isPaginatorVisible, setIsPaginatorVisible] = useState(false);

  const customDelete = (event, row, index) => {
    event.stopPropagation();
    if (typeof row !== 'object') {
      setItemSelected({ row, index });
    } else {
      setItemSelected({ ...row, index });
    }
    setAnchorConfirmacion(event.currentTarget);
  };

  const cancelDelete = () => {
    setAnchorConfirmacion(null);
    setItemSelected(null);
  };

  const acceptDelete = () => {
    onDelete({ ...itemSelected });
    cancelDelete();
  };

  const handleRowSelected = useCallback((field) => {
    return () => {
      if (onSelectRow) onSelectRow(field);
    };
  }, [onSelectRow]);

  useEffect(() => {
    if (rows.length === 0 && showEmptyLabel && ajax === 0) {
      setIsPaginatorVisible(false);
    } else {
      setIsPaginatorVisible(true);
    }
  }, [rows.length, showEmptyLabel, ajax]);

  return (
    <div>
      <Paper
        className={clsx(classes.root, customClass, hidePaginator && classes.noPaginator)}
        style={{
          ...((!rows.length || rows.length) === 0 && { minHeight: 150 }),
          ...customStyles,
        }}
      >
        <Confirmacion
          anchor={anchorConfirmacion}
          onClose={cancelDelete}
          onAccept={acceptDelete}
        />
        <table className={clsx(classes.table)}>
          <thead>
            <tr>
              {showCheckbox && <th style={{ textAlign: 'center' }}>
                {
                  !hideSelectAll && (
                    <Checkbox
                      checked={rows.every(({ isSelected }) => isSelected)}
                      value={rows.every(({ isSelected }) => isSelected)}
                      onClick={() => onSelectAll(rows.every(({ isSelected }) => isSelected))}
                    />
                  )
                }
              </th>}
              {headers.map((head, index) => (
                <th key={index} className={head.className || ''} style={{ textAlign: head.align || 'left' }}>
                  <Typography className={classes.label}>
                    {head.label}
                  </Typography>
                </th>
              ))}
              {showActions && <th>
                <Typography className={classes.label}>
                  Opciones
                </Typography>
              </th>}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index, arr) => (
              <tr
                key={index}
                className={clsx(classes.rowHover, {
                  [classes.rowError]: rowErrors.includes(index),
                  [classes.rowSelected]: (!!onSelectRow && editingItemId === (row?.id || row)),
                  [classes.selectable]: !!onSelectRow,
                })}
                onClick={handleRowSelected(row)}
              >
                {showCheckbox && <td style={{ textAlign: 'center' }}>
                  <Checkbox
                    checked={row.isSelected}
                    value={row.isSelected}
                    onClick={() => onSelect(row, index)}
                    disabled={row.disabled}
                  />
                </td>}
                {headers.map((head, hIndex) => (
                  <td key={hIndex} style={{ textAlign: head.align || 'left', border: (arr.length === index + 1) && (arr.length === general.ELEMENTOS_POR_PAGINA) && 'none', ...cellStyles }}>
                    {head.transform && head.transform(row, index)}
                    {(!head.transform) && <Typography>{row[head.key] && row[head.key] !== '' ? row[head.key] : '- - -'}</Typography>}
                  </td>
                ))}
                {showActions && (
                  <td className={classes.actions} style={{ border: (arr.length === index + 1) && (arr.length === general.ELEMENTOS_POR_PAGINA) && 'none' }}>
                    {moreActions.map(({ onClick, icon, validate, transform }, mIndex) => (
                      <div key={mIndex}>
                        {(!validate || validate(row)) && (
                          <>
                            {icon ? <IconButton
                              key={mIndex}
                              size="small"
                              className={clsx(classes.btnAdd, classes.btnAction)}
                              onClick={() => onClick(row, index)}>
                              {icon}
                            </IconButton> : <>
                              {transform(row, index)}
                            </>}
                          </>
                        )}
                      </div>
                    ))}
                    {onEdit && <IconButton
                      size="small"
                      className={clsx(classes.btnAdd, classes.btnAction)}
                      onClick={() => onEdit(row, index)}>
                      <BsPencil />
                    </IconButton>}
                    {onDelete && (!hideDelete || !hideDelete(row)) && <IconButton
                      size="small"
                      className={clsx(classes.btnAdd, classes.btnAction)}
                      onClick={(e) => customDelete(e, row, index)}>
                      <FiTrash />
                    </IconButton>}
                  </td>
                )}
              </tr>
            ))}
            {/* Añadir filas vacías si hay menos de minRows filas */}
            {(rows.length > 0) && Array.from({ length: Math.max(minRows - rows.length, 0) }).map((_, index) => (
              <tr key={`vacia-${index}`} style={{ height: 40 }}>
                <td style={{ border: 'none' }} colSpan={headers.length + (showActions ? 1 : 0)} />
              </tr>
            ))}
            {(rows.length === 0) && Array.from({ length: minRows }).map((_, index) => (
              <tr key={`vacia-${index}`} style={{ height: 40 }}>
                <td
                  style={{ border: 'none', textAlign: 'center' }}
                  colSpan={headers.length + (showActions ? 1 : 0)}
                >
                  {((index + 1) === Math.ceil(minRows / 2) && !isPaginatorVisible) && (
                    <Typography
                      component="p"
                      className={classes.label}>
                      {emptyMessage}
                    </Typography>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Paper>
      {
        isPaginatorVisible &&
        <Container style={{ padding: 0, marginTop: 10 }} className={classes.table}>
          {!hidePaginator && <Paginador count={count} />}
        </Container>
      }
    </div>
  );
};

Table.propTypes = {
  headers: propTypes.arrayOf(propTypes.shape({
    label: propTypes.string.isRequired,
    key: propTypes.string,
    transform: propTypes.func,
    align: propTypes.string,
  })),
  rows: propTypes.array,
  showActions: propTypes.bool,
  onEdit: propTypes.oneOfType([propTypes.func, propTypes.bool]),
  onDelete: propTypes.oneOfType([propTypes.func, propTypes.bool]),
  moreActions: propTypes.array,
  showEmptyLabel: propTypes.bool,
  hideDelete: propTypes.func,
  count: propTypes.number,
  hidePaginator: propTypes.bool,
  showCheckbox: propTypes.bool,
  onSelectAll: propTypes.func,
  onSelect: propTypes.func,
  customStyles: propTypes.object,
  cellStyles: propTypes.object,
  rowErrors: propTypes.array,
  emptyMessage: propTypes.string,
  inputOnChange: propTypes.func,
  hideSelectAll: propTypes.bool,
  minRows: propTypes.number,
};

Table.defaultProps = {
  headers: [],
  rows: [],
  showActions: true,
  onEdit: null,
  onDelete: null,
  moreActions: [],
  showEmptyLabel: true,
  hideDelete: null,
  count: 0,
  hidePaginator: false,
  showCheckbox: false,
  onSelectAll: null,
  onSelect: null,
  rowErrors: [],
  emptyMessage: 'NO SE ENCONTRARON REGISTROS',
  inputOnChange: () => { },
  hideSelectAll: false,
  minRows: general.ELEMENTOS_POR_PAGINA,
};

export default React.memo(Table);
