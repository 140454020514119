import React, { useCallback, useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { Box, Button, Divider, Menu, MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';

import Typography from '../Typography';

import { obtenerDiasSemana, obtenerRangoFechas } from '../../utilidades/functions';
import styles from './styles';

const diasEnSemanaKeys = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];

const CalendarioSemanal = ({
	className,
	onClickSemana,
	onSelectDia,
	onChangeSemana,
	temporada,
	semana,
}) => {
	const classes = styles();
  const [semanaSeleccionada, setSemanaSeleccionada] = useState(semana);
	const [anchorEl, setAnchorEl] = useState(null);
	const [calendario, setCalendario] = useState({
		diasEnSemana: [],
		diaActivo: null,
	});

	const semanasEnTemporada = useMemo(() => {
		if ( !temporada.fechaInicio || !temporada.fechaFin ) return [];
		return obtenerRangoFechas(temporada.fechaInicio, temporada.fechaFin, 'YYYY-MM-DD')
	}, [temporada.fechaInicio, temporada.fechaFin]);

	const handleClickSemana = useCallback((event) => {
		if ( temporada.fechaInicio && temporada.fechaFin ) {
			setAnchorEl(event.currentTarget);
		} else {
			onClickSemana(semana);
			setCalendario(prevState => ({
				...prevState,
				diaActivo: null,
			}));
		}
	}, [onClickSemana, temporada.fechaInicio, temporada.fechaFin, semana]);

	const handleClose = useCallback(() => { setAnchorEl(null) }, []);

	const handleChangeSemana = useCallback((fecha) => {
		const semana = moment(fecha).format('W (YYYY)');
		setSemanaSeleccionada(semana);
		onChangeSemana(semana);
		setCalendario(prevState => ({
			...prevState,
			diaActivo: null,
		}));
		handleClose();
	}, [onChangeSemana, handleClose]);

	const generarCalendario = useCallback(() => {
		const diasEnSemana = obtenerDiasSemana(semana, 'DD MMM YYYY', true);
		setCalendario(prev => ({
			...prev,
			diasEnSemana,
			diaActivo: null,
		}));
	}, [semana]);

	const handleClickDia = (dia) => {
		const diaActivo = dia === calendario.diaActivo ? null : dia;
		setCalendario(prevState => ({
			...prevState,
			diaActivo
		}));
		
		onSelectDia(diaActivo ? moment(diaActivo, 'DD MMM YYYY').format('YYYY-MM-DD') : null);
	};

	useEffect(() => {
		generarCalendario();
	}, [generarCalendario]);

	return (
		<Box className={classes.root}>
			<Box className={clsx(classes.paper, className)}>
				<Button onClick={handleClickSemana} className={classes.semana}>
					<Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
						<Typography variant="body1" className={classes.semanaText}>
							Semana
						</Typography>
						<Typography variant="body2" className={classes.semanaValue}>
							{ moment(semana, 'W (YYYY)').format('W') }
						</Typography>
						<Divider className={classes.divider} />
					</Box>
				</Button>
				{
					(temporada.fechaInicio && temporada.fechaFin) && (
					<Menu
						id="calendario-temporada"
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleClose}
						style={{ maxHeight: 350 }}
					>
						{
							semanasEnTemporada.map((option, index) => (
								<MenuItem
									key={moment(option).format('W-YYYY')}
									selected={moment(option).format('W (YYYY)') === semanaSeleccionada}
									onClick={() => handleChangeSemana(option)}
								>
									<Typography variant="body2" className={classes.semanaValue}>
										{
											`${moment(option).format('W')} - ${moment(option).startOf('isoWeek').format('DD MMM YYYY')} / ${moment(option).endOf('isoWeek').format('DD MMM YYYY')}`
										}
									</Typography>
								</MenuItem>
							))
						}
					</Menu>)
				}
				<Box className={classes.dias}>
					{
						calendario.diasEnSemana.map((dia, index) => (
							<Button key={dia} onClick={() => handleClickDia(dia)}>
								<Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
									<Typography variant="body1" className={classes.diasText}>
										{ diasEnSemanaKeys[index] }
									</Typography>
									<Typography variant="body2" className={classes.diasValue}>
										{ moment(dia, 'DD MMM YYYY').format('DD MMM') }
									</Typography>
									{
										(calendario.diaActivo === dia)
											? ( <Divider className={classes.divider} style={{ backgroundColor: '#49B1F2' }} /> )
											: null
									}
								</Box>
							</Button>
						))
					}
				</Box>
			</Box>
		</Box>
	);
};

CalendarioSemanal.propTypes = {
	/** Clase a aplicar al componente */
	className: propTypes.string,
	/** Función a ejectutar al seleccionar dar clic en la semana */
	onClickSemana: propTypes.func,
	/** Función a ejecutar al seleccionar un día del calendario */
	onSelectDia: propTypes.func,
	/** Función a ejecutar al cambiar de semana */
	onChangeSemana: propTypes.func,
	/** Número de la semana a mostrar */
	semana: propTypes.string,
	/** Temporada a configurar en las semanas */
	temporada: propTypes.shape({
		fechaInicio: propTypes.string,
		fechaFin: propTypes.string,
	}),
};

CalendarioSemanal.defaultProps = {
	className: '',
	onClickSemana: () => {},
	onSelectDia: () => {},
	onChangeSemana: () => {},
	semana: moment().isoWeek(),
	temporada: {
		fechaInicio: null,
		fechaFin: null,
	},
};

export default React.memo(CalendarioSemanal);
