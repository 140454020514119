import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    color: theme.palette.grey[500],
    padding: theme.spacing(1),
  },
  contenido: {
    padding: theme.spacing(2),
  },
  acciones: {
    margin: 0,
  },
}));

export default styles;
