import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root:{
    marginTop: 10,
    marginBottom: 20,
  },
  agrupador: {
    marginTop: 20,
    marginInline: 5,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 30,
  },
  btnOcultar: {
    width: 100,
    marginLeft: 'auto',
  },
  texto: {
    fontSize: 13,
    fontWeight: 'bold',
    color: paleta.bar.primary,
    marginRight: 5,
  },
  centerContainer: {
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  graficaTemperatura: {
    marginTop: 10,
  },
  infoClimatica: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  graficosClima: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '85%',
    width: '85%',
  },
  animar: {
    animationDuration: '0.5s',
    animationName: '$aparecer',
    animationTimingFunction: 'cubic-bezier(.26, .53, .74, 1.48)',
    animationFillMode: 'backwards'
  },
  '@keyframes aparecer': {
    '0%': {
      opacity: 0,
      transform: 'scale(0.5, 0.5)'
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1, 1)',
    }
  },
  delay: { animationDelay: '0.1s' },
}));

export default styles;
