/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import Default from '../../contenedores/Default';
import TextField from '../../componentes/TextField';
import Select from '../../componentes/Select';
import axios from '../../configuraciones/axios';
import { findPropertysEmpty, trim } from '../../utilidades/functions';
import endpoints, {
  NIVELESINFECCION
} from '../../configuraciones/endpoints';
import estatus from '../../constantes/estatus';
import { ELEMENTO_NO_ENCONTRADO } from '../../configuraciones/mensajes';

const NivelInfeccion = ({ history, match }) => {
  const [nivelInfeccion, setNivelInfeccion] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const { params: { id } } = match;

  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/nivelesinfeccion',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  const cancelar = useCallback(regresar, []);

  const guardar = useCallback(() => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmNivelInfeccion'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;

    const metodo = id ? 'put' : 'post';

    const objTrim = trim(nivelInfeccion);

    axios[metodo](NIVELESINFECCION, objTrim).then(regresar);
  }, [nivelInfeccion]);

  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [];
    if (id) promesas.push(axios.get(endpoints.base.url(NIVELESINFECCION, id)));

    Promise.all(promesas).then((resultadosPromesas) => {
      const [nivelInfeccionDb] = resultadosPromesas;
      if (nivelInfeccionDb?.id)
        setNivelInfeccion(nivelInfeccionDb);
      else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      } else
        setNivelInfeccion({estatus: true});
      resolve();
    }).catch(reject);
  });

  useEffect(() => {
    consultarDatosIniciales();
  }, [id]);

  return <Default
    titulo={Boolean(id) ? 'Editar Nivel de Infección' : 'Nuevo Nivel de Infección'}
    placeHolder={''}
    mostrarCabeceroFormulario
    guardar={guardar}
    cancelar={cancelar}>
    <Grid container spacing={2} id="frmNivelInfeccion">
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Nombre de Nivel de Infección"
              onChange={setNivelInfeccion}
              name="nombre"
              value={nivelInfeccion.nombre}
              isHandleChange
              inputProps={{ maxLength: 50 }}
              error={formErrors.nombre}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Estatus"
              required
              options={estatus}
              onChange={setNivelInfeccion}
              name="estatus"
              value={nivelInfeccion.estatus}
              error={formErrors.estatus}
              isHandleChange
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(NivelInfeccion);
