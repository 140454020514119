import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { PERFILES } from '../../configuraciones/endpoints';

const cabeceros = [
  { label: 'Nombre', key: 'nombre' },
  { label: 'Tipo perfil', transform: ({ tipoPerfil }) => tipoPerfil?.nombre || '' },
  { label: 'Descripción', key: 'descripcion' },
  { label: 'Estatus', transform: ({ estatus }) => <Estatus activo={estatus} />, },
];

const Usuarios = ({ history, location }) => {

  return <Listados
    titulo="Perfiles"
    placeHolder="Búsqueda por nombre"
    history={history}
    location={location}
    showActions
    cabeceros={cabeceros}
    apiKey={PERFILES}
  />;
};

export default Usuarios;