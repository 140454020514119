import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { IconButton } from '@material-ui/core';
import { toast } from 'react-toastify';
import queryString from 'query-string';

import ListaConfiguracion from '../../componentes/ListaConfiguracion';
import Listados from '../../contenedores/Listados';
import RangoTextFields from '../../componentes/RangoTextFields';

import { RANGO_CONFIG_REGEX } from '../../utilidades/regex';
import { RANGO_INVALIDO } from '../../configuraciones/mensajes';
import { setRegistros } from '../../ducks/listados';
import { ValidarRangos } from '../../utilidades/functions';
import axios from '../../configuraciones/axios';
import paleta from '../../configuraciones/paleta';
import endpoints, {
	CONFIGURACIONES_IRRIGACIONES,
	SITIOS,
} from '../../configuraciones/endpoints';

import styles from './styles';

const DatosIniciales = {
	labelProp: '',
	headerLista: '',
	title: '',
	subTitle: '',
	data: [],
};

const ConfiguracionIrrigaciones = ({ history, location }) => {
	const classes = styles();
	const dispatch = useDispatch();
	const { sitioID } = queryString.parse(location.search);
	const { registros } = useSelector((store) => store.listados);
	const [datosLista, setDatosLista] = useState({ ...DatosIniciales });
	const [formErrors, setFormErrors] = useState([]);

	const consultarDatosIniciales = () =>
		new Promise((resolve, reject) => {
			const promesas = [axios.get(endpoints.base.busqueda(SITIOS))];
			Promise.all(promesas)
				.then((resultadosPromesas) => {
					const [sitiosDb] = resultadosPromesas;
					setDatosLista({
						labelProp: 'nombre',
						headerLista: 'Nombre',
						title: 'Sitios',
						subTitle: 'Configuración de Irrigaciones',
						data: sitiosDb,
					});
					resolve();
				})
				.catch(reject);
		});

	useEffect(() => {
		consultarDatosIniciales();
		return () => {
			dispatch(setRegistros({ rows: [], count: 0 }));
			setFormErrors([]);
		};
	}, [dispatch]);

	const handleChange = (e, index) => {
		const propiedad = e.target.name;
		const nuevoEstado = registros;
		nuevoEstado.rows[index].configuracionesIrrigacion[0] = {
			...nuevoEstado.rows[index].configuracionesIrrigacion[0],
			[propiedad]: e.target.value,
		};
		dispatch(setRegistros(nuevoEstado));
	};

	const toggleConfig = (data, index) => {
		const nuevoEstado = registros;
		const valorActual =
			nuevoEstado.rows[index].configuracionesIrrigacion.length > 0
				? nuevoEstado.rows[index].configuracionesIrrigacion[0]
				: null;
		nuevoEstado.rows[index].configuracionesIrrigacion[0] = valorActual
			? { ...valorActual, estatus: !valorActual.estatus }
			: { ...valorActual, estatus: true };

		if (valorActual?.estatus) {
			setFormErrors(formErrors.filter((fila) => fila !== index));
		}
		dispatch(setRegistros(nuevoEstado));
	};

	const customActions = [
		{
			onClick: toggleConfig,
			transform: (data, index) => {
				if (!data.configuracionesIrrigacion) return null;
				return (
					<IconButton
						style={{ padding: 0 }}
						key="toggleConfig"
						size="medium"
						className={classes.btnAction}
						onClick={() => toggleConfig(data, index)}
					>
						{data.configuracionesIrrigacion.length <= 0 ||
						!data.configuracionesIrrigacion[0].estatus ? (
							<AiOutlineEyeInvisible />
						) : (
							<AiOutlineEye />
						)}
					</IconButton>
				);
			},
		},
	];

	const guardar = async () => {
		try {
			const rowErrors = [];
			const filtro = registros.rows.filter((configuracion) => {
				const { configuracionesIrrigacion } = configuracion;

				if (configuracionesIrrigacion.length > 0) {
					if (!configuracionesIrrigacion[0]?.activo) {
						if (configuracionesIrrigacion[0]?.estatus) {
							return true;
						}
						return false;
					}
					return true;
				}
				return false;
			});

			if (filtro.length <= 0) return null;
			let configuraciones = filtro.map((configuracion) => {
				const { configuracionesIrrigacion } = configuracion;

				if (!configuracionesIrrigacion[0]?.estatus) {
					return {
						...configuracionesIrrigacion[0],
						irrigacionID: configuracion.id,
						sitioID: parseInt(sitioID),
						estatus: false,
					};
				}
				return {
					...configuracionesIrrigacion[0],
					irrigacionID: configuracion.id,
					sitioID: parseInt(sitioID),
				};
			});

			configuraciones.forEach((irrigacion) => {
				const id = ValidarRangos(irrigacion, irrigacion.irrigacionID);
				id && rowErrors.push(registros.rows.findIndex((r) => r.id === id));
			});

			if (rowErrors.length > 0) {
				setFormErrors(rowErrors);
				return toast.error(RANGO_INVALIDO);
			}

			const data = {
				configuracionesIrrigacion: configuraciones,
				sitioID,
			};

			await axios.put(CONFIGURACIONES_IRRIGACIONES, data);
			setFormErrors([]);
		} catch {}
	};

	const cabeceros = [
		{ label: 'Agrupador', transform: ({ agrupador }) => agrupador?.nombre },
		{ label: 'Parámetro', key: 'nombre', align: 'left' },
		{
			label: 'Rango Estado Rojo',
			align: 'center',
			transform: (data, index) => {
				if (!data.configuracionesIrrigacion) return null;
				return (
					<RangoTextFields
						backgroundColor={paleta.configuraciones.rojo}
						borderColor={paleta.configuraciones.rojo}
						names={['rangoEstadoRojoMin', 'rangoEstadoRojoMax']}
						formValues={{
							min: data?.configuracionesIrrigacion[0]?.rangoEstadoRojoMin,
							max: data?.configuracionesIrrigacion[0]?.rangoEstadoRojoMax,
						}}
						activo={data?.configuracionesIrrigacion[0]?.estatus}
            onChange={(e) => handleChange(e, index)}
            inputProps={{ regex: RANGO_CONFIG_REGEX, regexonchange: 'true' }}
					/>
				);
			},
		},
		{
			label: 'Rango Estado amarillo',
			align: 'center',
			transform: (data, index) => {
				if (!data.configuracionesIrrigacion) return null;
				return (
					<RangoTextFields
						backgroundColor={paleta.configuraciones.amarillo}
						borderColor={paleta.configuraciones.borderAmarillo}
						names={['rangoEstadoAmarilloMin', 'rangoEstadoAmarilloMax']}
						formValues={{
							min: data?.configuracionesIrrigacion[0]?.rangoEstadoAmarilloMin,
							max: data?.configuracionesIrrigacion[0]?.rangoEstadoAmarilloMax,
						}}
						activo={data?.configuracionesIrrigacion[0]?.estatus}
            onChange={(e) => handleChange(e, index)}
            inputProps={{ regex: RANGO_CONFIG_REGEX, regexonchange: 'true' }}
					/>
				);
			},
		},
		{
			label: 'Rango Estado verde',
			align: 'center',
			transform: (data, index) => {
				if (!data.configuracionesIrrigacion) return null;
				return (
					<RangoTextFields
						backgroundColor={paleta.configuraciones.verde}
						borderColor={paleta.configuraciones.borderVerde}
						names={['rangoEstadoVerdeMin', 'rangoEstadoVerdeMax']}
						formValues={{
							min: data?.configuracionesIrrigacion[0]?.rangoEstadoVerdeMin,
							max: data?.configuracionesIrrigacion[0]?.rangoEstadoVerdeMax,
						}}
						activo={data?.configuracionesIrrigacion[0]?.estatus}
            onChange={(e) => handleChange(e, index)}
            inputProps={{ regex: RANGO_CONFIG_REGEX, regexonchange: 'true' }}
					/>
				);
			},
		},
	];

	return (
		<Listados
			titulo={`Parámetros de Irrigación`}
			subtitulo={'Rangos de estados'}
			placeHolder="Búsqueda por nombre"
			mostrarLista={<ListaConfiguracion datosLista={datosLista} />}
			history={history}
			location={location}
			cabeceros={cabeceros || []}
			apiKey={CONFIGURACIONES_IRRIGACIONES}
			parametros={{ sitioID: sitioID }}
			showActions={true}
			customActions={customActions}
			mostrarCabeceroListado={false}
			mostrarCabeceroFormulario={true}
			guardar={guardar}
			cancelar={() => {}}
			rowErrors={formErrors}
			customClass={classes.table}
		/>
	);
};

export default ConfiguracionIrrigaciones;
