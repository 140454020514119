import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';

import Mapa from '../Mapa';

import { enumPasos } from '../../../../ducks/temporada';

import styles from './styles';

const FormMapa = () => {
  const classes = styles();

  const mostrarMapa = useSelector((state) => {
    const { pasoEnCurso } = state.temporada.ui.stepper;
    return pasoEnCurso < enumPasos.BASE;
  });

  return (
    <Box flex={1} className={classes.mapa} hidden={!mostrarMapa}>
      <Mapa />
    </Box>
  )
};

export default React.memo(FormMapa);
