import React from 'react';
import { Typography, styled } from '@material-ui/core';

import paleta from '../../configuraciones/paleta';

const Label = styled(Typography)(({ theme, background = paleta.configuraciones.azul }) => ({
  color: theme.palette.getContrastText(background),
  background,
  borderRadius: 5,
  display: 'inline-flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: 0.3,
  opacity: 0.8,
  padding: theme.spacing(0.1, 1),
}));

export default React.memo(Label);
