import paleta from '../configuraciones/paleta';

export const options = {
  contenedor: {
    fillColor: paleta.geocerca.container,
    strokeColor: '#5A6A75',
    strokeWeight: 2,
    strokeOpacity: 1,
    fillOpacity: 0.7,
    zIndex: 10,
  },
  primario: {
    strokeColor: paleta.geocerca.primary,
    fillColor: paleta.geocerca.primary,
    fillOpacity: 0.8,
    strokeWeight: 2,
    zIndex: 15,
  },
  secundario: {
    strokeColor: paleta.geocerca.secondary,
    fillColor: paleta.geocerca.secondary,
    fillOpacity: 0.5,
    strokeWeight: 0,
    zIndex: 20,
  },
  terciario: {
    strokeColor: paleta.geocerca.tertiary,
    fillColor: paleta.geocerca.tertiary,
    fillOpacity: 0.8,
    strokeWeight: 0,
    zIndex: 20,
  },
  activo: {
    fillColor: paleta.geocerca.primary,
    fillOpacity: 0.4,
    strokeColor: paleta.geocerca.primary,
    strokeWeight: 2,
    zIndex: 25,
  },
  disabled: {
    strokeColor: paleta.geocerca.secondary,
    fillColor: paleta.geocerca.secondary,
    fillOpacity: 0.5,
    strokeWeight: 1,
    zIndex: 24,
  },
  hidden: {
    strokeColor: paleta.geocerca.primary,
    fillColor: paleta.geocerca.primary,
    fillOpacity: 0,
    strokeWeight: 0,
    zIndex: 5,
  },
  disabledTerciario: {
    strokeColor: paleta.geocerca.tertiary,
    fillColor: paleta.geocerca.tertiary,
    fillOpacity: 0.4,
    strokeWeight: 1,
    zIndex: 24,
  },
};

export const origenOptions = ({ enUso, disabled }) => {
  if (enUso && disabled) return options.hidden;
  else if (!disabled) return options.contenedor;
  else return options.primario;
}

export const poligonoOptions = ({ activo, readOnly }) => {
  if (activo) return options.activo;
  else return options.disabled;
};

export const divisionOptions = ({ activo, readOnly }) => {
  if (activo) return options.activo;
  if (readOnly) return options.disabled;
  else return options.primario;
};
