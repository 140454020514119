import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  datePickerInput: {
    width: '100%',
  },
  datePickerLabel: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  input: {
    minHeight: 42,
    marginTop: 10,
    backgroundColor: paleta.textField.backgroundColor,
    borderRadius: 8,
    outline: 'none',
    width: '95%',
    padding: '0 10px',
    border: `1px solid ${paleta.textField.border}`,
    '&:hover, &:focus': {
      backgroundColor: paleta.textField.backgroundColorHover,
    },
    '&.disabled': {
      backgroundColor: paleta.textField.backgroundColorDisabled,
    },
    '&.error': {
      border: `1px solid ${paleta.textField.error}`,
    },
  },
  calendario: {
    backgroundColor: 'red',
  }
}));

export default styles;
