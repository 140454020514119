import queryString from 'query-string';

export const PERMISOS = '/permisos';
export const PERFILES = '/perfiles';
export const USUARIOS = '/usuarios';
export const SITIOS = '/sitios';
export const SECCIONES = '/secciones';
export const INVERNADEROS = '/invernaderos';
export const NAVES = '/naves';
export const SESIONES = '/sesiones';
export const TIPO_PERFILES = '/tiposperfil';
export const ACTIVIDADES = '/actividades';
export const FENOLOGIAS = '/fenologias';
export const NIVELESINFECCION = '/nivelesinfeccion';
export const IRRIGACIONES = '/irrigaciones';
export const NIVELESPOLINIZACION = '/nivelespolinizacion';
export const SANEOS = '/saneos';
export const SURCOS = '/surcos';
export const ENFERMEDADES = '/enfermedades';
export const TIPOSENFERMEDADES = '/tiposenfermedad';
export const TABLAS = '/tablas';
export const TRAMPAS = '/trampas';
export const UNIDADES_MEDIDA = '/unidadesmedidas';
export const TIPO_SUELO = '/tiposuelo';
export const AGRUPADORES = '/agrupadores';
export const CULTIVOS = '/cultivos';
export const CAPTURA_POLINIZACION = '/capturapolinizaciones';
export const CAPTURA_SANEOS = '/capturasaneos';
export const CAPTURA_TRAMPAS = '/capturatrampas';
export const CAPTURA_PLANTAS = '/capturaplantas';
export const CAPTURA_IRRIGACIONES = '/capturaIrrigaciones';
export const CAPTURA_NEMATODOS = '/capturasNematodos';
export const ESTADO_MONITOREO = '/estadoMonitoreo';
export const CAPTURA_FENOLOGIAS = '/capturaFenologias';
export const CONFIGURACION_POLINIZACION = '/configuracionPolinizacion';
export const CONFIGURACIONES_PLANTA = '/configuracionPlantas';
export const CONFIGURACIONES_TRAMPA = '/configuracionTrampas';
export const CONFIGURACIONES_FENOLOGIA = '/configuracionFenologias';
export const CONFIGURACIONES_IRRIGACIONES = '/configuracionIrrigacion';
export const TEMPORADAS = '/temporadas';

const endpoints = {
  base: {
    url: (uri, id = '') => `${uri}/${id}`,
    busqueda: (uri, params) => `${uri}/busqueda${queryString.stringify(params)}`,
    coordenadas: (uri, id) => `${uri}/${id}/coordenadas`
  },
  iniciarSesion: () => `${SESIONES}/iniciar`,
  permisosAsignados: (perfilID = '') => `${PERMISOS}/perfil/${perfilID}`,
  fenologiasCapturadas: (naveID = '') => `${FENOLOGIAS}/busqueda/capturaFenologia/${naveID}`,

  plantasNave: (naveID = '') => `${TABLAS}/nave/${naveID}`,
  naveTrampas: (naveID = '') => `${NAVES}/${naveID}/trampas`,
  naveTablas: (naveID = '') => `${NAVES}/${naveID}/tablas`,

  naveCoordenadas: () => `${NAVES}/coordenadas`,
  sitiosCoordenadas: () => `${SITIOS}/coordenadas`,
  invernaderosCoordenadas: () => `${INVERNADEROS}/coordenadas`,

  historialCapturas: (id) => `${CAPTURA_SANEOS}/historial/${id}`,
  historialPlagaEnfermedad: (id) => `${CAPTURA_PLANTAS}/historial/${id}`,

  obtenerDeteccionesPorSemana: (sitioID) => `${SITIOS}/${sitioID}/detecciones`,
  obtenerHistorialClima: (id) => `${SITIOS}/historial-climatico/${id}`,
  obtenerDeteccionesEnNave: (id) => `${NAVES}/${id}/detecciones`,
  obtenerDeteccionesTrampas: () => `${CAPTURA_TRAMPAS}/detecciones`,
  obtenerDeteccionesPlantas: () => `${CAPTURA_PLANTAS}/detecciones`,
  obtenerDeteccionesHistorialTrampas: () => `${CAPTURA_TRAMPAS}/historial`,
  obtenerDeteccionesHistorialPlantas: () => `${CAPTURA_PLANTAS}/historial`,

  obtenerIrrigacionesConfiguradas: (sitioID = '') => `${CONFIGURACIONES_IRRIGACIONES}/tareasIrrigaciones/${sitioID}`,
  obtenerIrrigacionesCapturadas: (sitioID = '') => `${CAPTURA_IRRIGACIONES}/tablero/${sitioID}`,
  obtenerIrrigacionesSemanales: (naveID = '') => `${CAPTURA_IRRIGACIONES}/irrigaciones/${naveID}`,
  obtenerIrrigacionesTipoSuelo: (naveID = '') => `${IRRIGACIONES}/tipoSuelo/${naveID}`,

  obtenerEstadoMonitoreoPolinizacion: (sitioID = '') => `${ESTADO_MONITOREO}/polinizacion/${sitioID}`,
  obtenerEstadoMonitoreoPlantasEnfermedades: (sitioID = '') => `${ESTADO_MONITOREO}/plantasEnfermedades/${sitioID}`,
  obtenerEstadoMonitoreoTrampas: (sitioID = '') => `${ESTADO_MONITOREO}/trampas/${sitioID}`,
  obtenerEstadoMonitoreoFenologias: (sitioID = '') => `${ESTADO_MONITOREO}/fenologias/${sitioID}`,
  obtenerEstadoMonitoreoIrrigaciones: (sitioID = '') => `${ESTADO_MONITOREO}/irrigaciones/${sitioID}`,
  obtenerEstadoMonitoreoSaneos: (sitioID = '') => `${ESTADO_MONITOREO}/saneos/${sitioID}`,

  reporteFenologias: () => `${CAPTURA_FENOLOGIAS}/reporte`,
  reporteFenologiasPlantas: () => `${CAPTURA_FENOLOGIAS}/reporte-plantas`,
  reporteIrrigaciones: () => `${CAPTURA_IRRIGACIONES}/reporte`,
  reportePolinizaciones: () => `${CAPTURA_POLINIZACION}/reporte`,
  reporteNematodos: () => `${CAPTURA_NEMATODOS}/reporte`,
  reporteEnfermedadesPlantas: () => `${CAPTURA_PLANTAS}/reporte`,
  reporteEnfermedadesTrampas: () => `${CAPTURA_TRAMPAS}/reporte`,
  reporteSaneos: () => `${CAPTURA_SANEOS}/reporte`,

  historicoSecciones: () => `${CAPTURA_POLINIZACION}/historico-seccion`,

  openWeatherMapAPI: {
    pronostico: 'https://api.openweathermap.org/data/2.5/onecall',
    timeline: 'https://api.openweathermap.org/data/2.5/onecall/timemachine',
  },

  obtenerNavesYSitios: () => `${SITIOS}/tablero`,
  obtenerTemporadas: (id = '') => `${SITIOS}/temporadas/${id}`,

  detalleTemporada: (id) => `${TEMPORADAS}/${id}/detalles`,
  finalizarTemporada: () => `${TEMPORADAS}/finalizar`,
  obtenerHistorialTemporadas: () => `${TEMPORADAS}/historico`,
  obtenerSitiosHistorico: () => `${SITIOS}/historico`,
  importarConfiguracionTemporada: () => `${TEMPORADAS}/importar`,
};

export default endpoints;
