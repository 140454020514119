import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import moment from 'moment';

import Agrupador from '../Agrupador';
import BarraProgreso from '../BarraProgreso';
import Table from '../Table';
import Typography from '../Typography';

import axios from '../../configuraciones/axios';
import { CAPTURA_NEMATODOS, CAPTURA_SANEOS } from '../../configuraciones/endpoints';
import paleta from '../../configuraciones/paleta';

import styles from './styles';

const NaveSaneos = ({ naveID }) => {
  const classes = styles();
  const { semana } = useSelector(
    ({tablero: { semana }}) => ({ semana }),
    (prev, next) => prev.semana === next.semana
  );
  const [rows, setRows] = useState([]);

  const semanas = useMemo(() => {
    const semanas = [];
    for(let i = 0; i < 5; i++){
      semanas.push(moment(semana, 'W (YYYY)').subtract(i, 'weeks').isoWeek());
    }
    return semanas;
  }, [semana])

  const consultarDatosIniciales = useCallback(async () => {
    const data = [];
    const objNematodos = {};
    const fechaObjetivo = moment(semana, 'W (YYYY)')
      .startOf('isoWeek')
      .format('YYYY-MM-DD');

    const promesas = [
      axios.get(`${CAPTURA_SANEOS}/historial/semanal`, { params: { naveID, fechaObjetivo } }),
      axios.get(`${CAPTURA_NEMATODOS}/historial/semanal`, { params: { naveID, fechaObjetivo } }),
    ];

    const [historialSaneos, historialNematodos] = await Promise.all(promesas);

    for (const historico of historialSaneos) {
      const {id, nombre, total, capturas} = historico;

      let obj = { id, nombre, total: Number(total) };

      for (const semana of semanas) {
        obj = {
          ...obj,
          saneos: {
            ...obj.saneos,
            [semana]: capturas.find(e => e.semana === semana)?.cantidad || 0,
          }
        };
      }

      data.push(obj);
    }

    for (const semana of semanas) {
      objNematodos[semana] = historialNematodos.capturas.find(e => e.semana === semana)?.capturas || 0;
    }

    data.push({
      id: 'nematodos',
      nombre: 'Nematodos',
      saneos: objNematodos,
      total: parseInt(historialNematodos.totalCapturas, 10),
    });
    
    setRows(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [naveID, semana]);

  const cabeceros = useMemo(() => {
    return [
      { label: 'CAUSA', align: 'center', key: 'nombre' },
      { label: `SEMANA ${semanas[4]}`, transform: ({ saneos, total }) => <BarraProgreso mostrarTooltip progreso={saneos[semanas[4]] || 0} total={total} />, },
      { label: `SEMANA ${semanas[3]}`, transform: ({ saneos, total }) => <BarraProgreso mostrarTooltip progreso={saneos[semanas[3]] || 0} total={total} />, },
      { label: `SEMANA ${semanas[2]}`, transform: ({ saneos, total }) => <BarraProgreso mostrarTooltip progreso={saneos[semanas[2]] || 0} total={total} />, },
      { label: `SEMANA ${semanas[1]}`, transform: ({ saneos, total }) => <BarraProgreso mostrarTooltip progreso={saneos[semanas[1]] || 0} total={total} />, },
      { label: `SEMANA ${semanas[0]}`, transform: ({ saneos, total }) => <BarraProgreso mostrarTooltip progreso={saneos[semanas[0]] || 0} total={total} />, },
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [semana])

  useEffect(() => {
    consultarDatosIniciales();
  }, [consultarDatosIniciales]);

  return (
    <Agrupador customClass={classes.agrupador}>
        <Typography variant='h5' bold className={classes.labelTitle}>
          SANEO
        </Typography>
        <Table 
          headers={cabeceros}
          showActions={false}
          hidePaginator
          rows={rows}
          customStyles={{
            height: 'auto',
            maxHeight: '250px',
            minHeight: '250px',
            borderColor: paleta.tabla.bordertd
          }}
          cellStyles={{
            padding: 4
          }}
          emptyMessage="SIN DATOS RECIENTES"
        />
    </Agrupador>
  );
};

NaveSaneos.propTypes = {
  /** Identificador de la nave */
  naveID: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
};

export default React.memo(NaveSaneos);
