import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';

import FormUnificacion from '../components/FormUnificacion';

import { enumPasos } from '../../../ducks/temporada';

const formInvernaderosSelector = (state) => state.temporada.form[enumPasos.INVERNADEROS].data;

const ConfiguracionInvernaderos = () => {
  const invernaderos = useSelector((state) => formInvernaderosSelector(state).byId, shallowEqual);

  return (
    <Box maxWidth="70%">
      <FormUnificacion
        initialValues={invernaderos}
      />
    </Box>
  )
};

export default React.memo(ConfiguracionInvernaderos);
