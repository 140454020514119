/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';

import Default from '../../contenedores/Default';

import CabeceroReportes from '../../componentes/CabeceroReportes';
import ListaConfiguracion from '../../componentes/ListaConfiguracion';
import Tabla from '../../componentes/Table';

import { setRegistros } from '../../ducks/listados';

import axios from '../../configuraciones/axios';
import endpoints, {
  INVERNADEROS,
	NIVELESINFECCION,
	SITIOS,
	SURCOS,
	TABLAS,
} from '../../configuraciones/endpoints';
import general from '../../configuraciones/general';

import { CamposValidados } from '../../utilidades/functions';
import { DatePickerTypes } from '../../constantes/datePickerTypes';


const datosLista = {
	labelProp: 'nombre',
	headerLista: 'Nombre',
	title: 'Reporte de Nematodos',
	data: [
		{
			id: 1,
			nombre: 'Hallazgo de nematodos',
			subTitle: 'Listado por nivel de infección',
		},
	],
};

const cabeceros = [
	{ label: 'SITIO', key: 'sitio', align: 'left' },
	{ label: 'INVERNADERO', key: 'invernadero', align: 'left' },
	{ label: 'NAVE', key: 'Nave', align: 'left' },
	{ label: 'TABLA', key: 'Tabla', align: 'left' },
	{ label: 'SURCO', key: 'Surco', align: 'left' },
	{ label: 'CUADRANTE', key: 'cuadrante', align: 'left' },
	{ label: 'NIVEL DE INFECCIÓN', key: 'infeccion', align: 'left' },
];
const dataInicial = {
	sitios: [],
	naves: [],
	tablas: [],
	surcos: [],
	nivelInfecciones: [],
};

const ReporteNematodos = ({ history }) => {
	const { registros } = useSelector((store) => store.listados);
	const dispatch = useDispatch();
	const location = useLocation();
	const isMounted = useRef(false);
	const paginaActual = useRef(1);
	const [data, setData] = useState({ ...dataInicial });
	const [formData, setFormData] = useState({ ...dataInicial });

	const consultarDatosIniciales = () =>
		new Promise((resolve, reject) => {
			const promesas = [
				axios.get(endpoints.base.busqueda(SITIOS)),
				axios.get(endpoints.base.busqueda(INVERNADEROS)),
				axios.get(endpoints.base.busqueda(TABLAS)),
				axios.get(endpoints.base.busqueda(SURCOS)),
				axios.get(endpoints.base.busqueda(NIVELESINFECCION)),
			];
			Promise.all(promesas)
				.then((resultadoPromesas) => {
					const [
						sitiosBD,
						inverneaderosBD,
						tablasBD,
						surcosBD,
						nivelInfeccionBD,
					] = resultadoPromesas;
					const invernaderosMapeados = [];
					for (const invernaderoPadre of inverneaderosBD) {
						for (const nave of invernaderoPadre.naves) {
							const nav = {
								id: nave.id,
								invernaderoID: nave.invernaderoID,
								sitioID: invernaderoPadre.sitioID,
								nombre: invernaderoPadre.nombre + ' - ' + nave.nombre,
							};
							invernaderosMapeados.push(nav);
						}
					}

					const surcosTabla = surcosBD.map((surco) => {
						const surcos = {
							id: surco.id,
							nombre: `${surco.tabla?.nombre} - ${surco.nombre}`,
							tablaID: surco.tabla?.id
						};
						return surcos;
					});

					surcosTabla.unshift({
						id: 0,
						nombre: 'Todos',
					});
					nivelInfeccionBD.unshift({
						id: 0,
						nombre: 'Todos',
					});

					setData({
						sitios: sitiosBD,
						naves: invernaderosMapeados,
						tablas: tablasBD,
						surcos: surcosTabla,
						nivelInfecciones: nivelInfeccionBD,
					});
					resolve();
				})
				.catch(reject);
		});

	const onChange = ({ target: { name, value } }) => {
		let nuevaData = {
			...formData,
		};
		switch (name) {
			default:
				nuevaData = { ...formData, [name]: value };
				switch (name) {
					case 'sitios':
						const invernaderiosConNaves = data.naves
							.filter((invernadero) =>
								nuevaData.sitios.includes(invernadero.sitioID),
							)
							.map(({ id }) => id);
						nuevaData.naves = nuevaData.naves.filter((invernaderosNave) =>
							invernaderiosConNaves.includes(invernaderosNave),
						);
						break;
					case 'invernaderosNave':
						const tablasNave = data.tablas
							.filter((tabla) => nuevaData.naves.includes(tabla.naveID))
							.map(({ id }) => id);
						nuevaData.tablas = nuevaData.tablas.filter((tablas) =>
							tablasNave.includes(tablas),
						);
						break;

					case 'tablas':
						const surcosTabla = data.surcos
							.filter((surco) => nuevaData.tablas.includes(surco.tablaID))
							.map(({ id }) => id);
						nuevaData.surcos = nuevaData.surcos.filter((surcos) =>
							surcosTabla.includes(surcos),
						);
						break;
					default:
						break;
				}
				break;
		}

		setFormData((currentData) => ({ ...currentData, ...nuevaData }));
		paginaActual.current = 1;
	};

	const onDelete = (id, name) => {
		let nuevaData = {
			...formData,
			[name]: formData[name].filter((busquedaID) => busquedaID !== id),
		};

		const invernaderiosConNaves = data.naves
			.filter((invernadero) => nuevaData.sitios.includes(invernadero.sitioID))
			.map(({ id }) => id);
		nuevaData.naves = nuevaData.naves.filter((invernaderosNave) =>
			invernaderiosConNaves.includes(invernaderosNave),
		);

		const tablasNave = data.tablas
			.filter((tabla) => nuevaData.naves.includes(tabla.naveID))
			.map(({ id }) => id);
		nuevaData.tablas = nuevaData.tablas.filter((tablas) =>
			tablasNave.includes(tablas),
		);

		const surcosTabla = data.surcos
			.filter((surco) => nuevaData.tablas.includes(surco.tablaID))
			.map(({ id }) => id);
		nuevaData.surcos = nuevaData.surcos.filter((surcos) =>
			surcosTabla.includes(surcos),
		);

		setFormData(() => ({
			...nuevaData,
		}));

		paginaActual.current = 1;
	};
	const ValidarData = useCallback(() => CamposValidados(formData), [formData]);

	const handleTodosSelected = useCallback(() => {
		const { naves: invernaderosNave, fecha, tablas } = formData;

		const surcos = formData.surcos.includes(0)
			? data.surcos.filter((surco) => surco.id !== 0).map((surco) => surco.id)
			: formData.surcos;

		const niveles = formData.nivelInfecciones.includes(0)
			? data.nivelInfecciones
					.filter((nivel) => nivel.id !== 0)
					.map((nivel) => nivel.id)
			: formData.nivelInfecciones;

		const params = {
			fecha: moment(fecha).format('W-GGGG'),
			naves: invernaderosNave,
			surcos,
			tablas,
			niveles,
		};

		return params;
	}, [formData, data]);

	const buscar = async () => {
		try {
			if (ValidarData()) return;
			const params = handleTodosSelected();
			const response = await axios.post(endpoints.reporteNematodos(), {
				...params,
				registrosPorPagina: general.ELEMENTOS_POR_PAGINA,
				pagina: paginaActual.current,
			});
			dispatch(setRegistros({ rows: response.rows, count: response.count }));
		} catch {}
	};

	useEffect(() => {
		isMounted.current = true;
		consultarDatosIniciales();

		return () => {
			isMounted.current = false;
			paginaActual.current = 1;
			dispatch(setRegistros({ rows: [], count: 0 }));
		};
	}, []);

	useEffect(() => {
		if (isMounted.current) {
			const query = queryString.parse(location.search);
			if (query.pagina === paginaActual || !query.pagina) return;
			paginaActual.current = query.pagina;
			buscar();
		}
	}, [location]);

	const onSubMenuClick = (id) => {
		if (id === 1) {
			history.push(`/reportes/nematodos`);
		}
	};

	return (
		<Default
			mostrarLista={
				<ListaConfiguracion
					datosLista={datosLista}
					onClick={onSubMenuClick}
					initOpcion={0}
				/>
			}
		>
			<CabeceroReportes
				data={data}
				formData={formData}
				buscar={buscar}
				onChange={onChange}
				onDelete={onDelete}
				handleTodosSelected={handleTodosSelected}
				selects={{
					sitio: { width: 4, show: true },
					invernaderoNave: { width: 4, show: true },
					tabla: { width: 4, show: true },
					surco: { width: 2, show: true },
					nivelInfeccion: { width: 3, show: true },
					fecha: { width: 3, show: true, type: DatePickerTypes.week },
				}}
				endpoint={endpoints.reporteNematodos()}
				method='POST'
			/>
			<Tabla
				headers={cabeceros}
				rows={registros.rows.length > 0 ? registros.rows : []}
				count={registros.count}
				showActions={false}
			/>
		</Default>
	);
};

export default ReporteNematodos;
