import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import isEqual from 'lodash/isEqual';

import Tarjeta from '../Tarjeta';

import axios from '../../configuraciones/axios';
import endpoints from '../../configuraciones/endpoints';

import {
  setMonitoreoActivo,
  setNavesDetectadas,
  clearMonitoreoActivo,
  clearNavesDetectadas
} from '../../ducks/tablero';
import semaforo from '../../constantes/semaforos';

const TableroIrrigaciones = () => {
  const dispatch = useDispatch();
  const { sitioID, monitoreoActivo, navesEnSitio, semana } = useSelector(
    store => store.tablero,
    (prev, next) => {
      return (
        isEqual(prev.navesEnSitio, next.navesEnSitio) &&
        prev.sitioID === next.sitioID &&
        prev.monitoreoActivo.irrigacion === next.monitoreoActivo.irrigacion &&
        prev.monitoreoActivo.id === next.monitoreoActivo.id &&
        prev.semana === next.semana
      );
    }
  );

  const [irrigacionesCapturadas, setIrrigaciones] = useState([]);
  const [cardsData, setCardsData] = useState([]);

  const mapearIrrigaciones = useCallback((irrigaciones, capturas) => {
    
    irrigaciones.forEach((irrigacion) => {
      let totales = [
        {
          idColor: 0,
          color: semaforo.verde,
          porcentaje: 0,
        },
        {
          idColor: 0,
          color: semaforo.amarillo,
          porcentaje: 0,
        },
        {
          idColor: 0,
          color: semaforo.rojo,
          porcentaje: 0,
        },
        {
          idColor: 0,
          color: semaforo.indefinido,
          porcentaje: 0,
        },
      ];

      let navesVerdes = [];
      let navesRojas = [];
      let navesAmarillas = [];  

			const capturasIrrigaciones = capturas.filter(({ capturasIrrigacion }) => capturasIrrigacion.irrigacionID === irrigacion.id);
  
      const navesDistintas = [
        ...new Set(capturasIrrigaciones?.map(({ naveID }) => naveID)),
      ];

      navesDistintas.forEach((naveID) => {
        const totalCapturas = capturasIrrigaciones
          .filter(({ capturasIrrigacion }) => capturasIrrigacion.naveID === naveID)
          .map(({ capturasIrrigacion }) => capturasIrrigacion.valor);

        const promedio = capturasIrrigaciones
          .filter(({ capturasIrrigacion }) => capturasIrrigacion.naveID === naveID)
          .map(({ capturasIrrigacion }) => parseFloat(capturasIrrigacion.valor))
          .reduce((a, b) => (parseFloat(a) || 0) + (parseFloat(b) || ''), 0) / totalCapturas.length;

        const {
          rangoEstadoRojoMin,
          rangoEstadoRojoMax,
          rangoEstadoAmarilloMin,
          rangoEstadoAmarilloMax,
          rangoEstadoVerdeMin,
          rangoEstadoVerdeMax,
        } = irrigacion.configuracionesIrrigacion[0];
        if (
          promedio >= rangoEstadoVerdeMin &&
          promedio <= rangoEstadoVerdeMax
        ) {
          totales[0].idColor++;
          totales[0].porcentaje = (totales[0].idColor * 100) / totalCapturas.length;
          navesVerdes.push({ 
            naveID, 
            estado: 'Verde', 
            irrigacion, 
            promedio: parseFloat(promedio).toFixed(2)
          });
        }
        if (
          promedio >= rangoEstadoAmarilloMin &&
          promedio <= rangoEstadoAmarilloMax
        ) {
          totales[1].idColor++;
          totales[1].porcentaje = (totales[1].idColor * 100) / totalCapturas.length;
          navesAmarillas.push({ 
            naveID, 
            estado: 'Amarillo', 
            irrigacion, 
            promedio: parseFloat(promedio).toFixed(2)
          });
        }
        if (promedio >= rangoEstadoRojoMin && promedio <= rangoEstadoRojoMax) {
          totales[2].idColor++;
          totales[2].porcentaje = (totales[2].idColor * 100) / totalCapturas.length;
          navesRojas.push({ 
            naveID, 
            estado: 'Rojo', 
            irrigacion, 
            promedio: parseFloat(promedio).toFixed(2)
          });
        }
      });

      const cantidadNavesRojas = [...new Set(navesRojas.map(({ naveID }) => naveID))].length;
      const cantidadNavesAmarillas = [...new Set(navesAmarillas.map(({ naveID }) => naveID))].length;
      const cantidadNavesVerdes = [...new Set(navesVerdes.map(({ naveID }) => naveID))].length;

      const navesGrises = (navesEnSitio?.length || 0) - (cantidadNavesRojas + cantidadNavesAmarillas + cantidadNavesVerdes);
      totales[3].idColor = navesGrises;
			totales[3].porcentaje = navesGrises;
      
      totales.sort((a, b) => {
        if (a.idColor > b.idColor) {
          return -1;
        }
        if (a.idColor < b.idColor) {
          return 1;
        }
        return 0;
      });

      irrigacion.totales = totales;
      irrigacion.navesRojas = navesRojas;
      irrigacion.navesAmarillas = navesAmarillas;
      irrigacion.navesVerdes = navesVerdes;
    });
    return irrigaciones;
  }, [navesEnSitio]);

  const consultarDatosIniciales = useCallback(() => {
    if ( sitioID && semana ) {
      const fechaObjetivo = moment(semana, 'W (YYYY)')
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
  
      const promesas = [
        axios.get(endpoints.obtenerIrrigacionesConfiguradas(sitioID)),
        axios.get(endpoints.obtenerIrrigacionesCapturadas(sitioID), {
          params: { fechaObjetivo },
        }),
      ];
  
      Promise.all(promesas)
        .then(([configuracionesIrrigacionesDb, irrigacionesCapturadasDb]) => {
          const data = mapearIrrigaciones(configuracionesIrrigacionesDb, irrigacionesCapturadasDb);

          setIrrigaciones(data);
          setCardsData(data.map(item => ({
            id: item.id,
            title: 'Irrigación',
            semana: fechaObjetivo,
            extraInfo: `${item.nombreAgrupador || ''} ${item.nombre}`,
            progreso: item.totales.map(item => ({
              id: item.color,
              valor: item.idColor,
              color: item.color,
            }))
          })));
        })
        .catch();
    }
  }, [mapearIrrigaciones, semana, sitioID]);

  const handleClick = useCallback(({id}) => {
    const data = irrigacionesCapturadas?.filter(
      (irrigacion) => irrigacion?.id === id
    );
    const naves = data[0].navesRojas.concat(
      data[0].navesAmarillas,
      data[0].navesVerdes
    );

    if ( monitoreoActivo.irrigacion && monitoreoActivo.id === id ) {
      batch(() => {
        dispatch(clearNavesDetectadas());
        dispatch(clearMonitoreoActivo());
      });
    } else {
      const navesDetectadas = naves.map((nave) => ({
        id: nave.naveID,
        estado: nave.estado,
        extraInfo: `${nave.irrigacion?.nombreAgrupador} ${nave.irrigacion?.nombre} : ${nave.promedio}`,
      }));
      batch(() => {
        dispatch(setNavesDetectadas(navesDetectadas));
        dispatch(setMonitoreoActivo({ id, irrigacion: true }));
      })
    }
  }, [dispatch, irrigacionesCapturadas, monitoreoActivo.id, monitoreoActivo.irrigacion]);

  useEffect(() => {
    consultarDatosIniciales();

    return () => {
      batch(() => {
        dispatch(clearNavesDetectadas());
        dispatch(clearMonitoreoActivo());

        setIrrigaciones([]);
        setCardsData([]);
      });
    }
  }, [consultarDatosIniciales, dispatch]);

  return (
    <Fragment>
      {
        cardsData.map((item) => (
          <Tarjeta key={item.id} data={item} onClick={handleClick} activo={ item.id === monitoreoActivo.id } />
        ))
      }
    </Fragment>
  );
};

export default TableroIrrigaciones;
