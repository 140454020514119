import React, { useCallback, useEffect, useReducer } from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import { Badge, Chip, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import clsx from 'clsx';

import Agrupador from '../Agrupador';
import Chart from '../Chart';
import Typography from '../Typography';

import axios from '../../configuraciones/axios';
import paleta from '../../configuraciones/paleta';
import endpoints, { CAPTURA_POLINIZACION, NIVELESPOLINIZACION } from '../../configuraciones/endpoints';

import styles from './styles';

const types = {
  setInformacion: 'SET_INFORMACION',
  setSeccionActiva: 'SET_SECCION_ACTIVA',
  clearSeccionActiva: 'CLEAR_SECCION_ACTIVA',
};

const initState = {
  datosGrafica: {
    labels: [],
    datasets: [
      {
        label: "Porcentaje Efectivo",
        data: [],
        borderColor: paleta.graficos.lineSecondary,
        backgroundColor: paleta.graficos.lineSecondary,
        borderWidth: 3,
        lineTension: 0.4,
      },
      {
        label: "Nivel",
        data: [],
        borderColor: paleta.graficos.lineTertiary,
        backgroundColor: paleta.graficos.lineTertiary,
        borderWidth: 3,
      },
    ],
  },
  configuracionGrafica: {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      crosshair: false,
      title: { display: false },
      legend: { display: false },
      datalabels: { display: false },
      tooltip: {
        usePointStyle: true,
        callbacks: {
          title: (tooltipItems) => {
            const [semana, anio] = tooltipItems[0].label.split('-');
            return `Semana ${semana} del ${anio}`
          },
          label: (tooltipItem) => {
            const { formattedValue, dataset } = tooltipItem;
            const { label } = dataset;
            const newLabel = `\t${label}: ${formattedValue}${(label === 'Porcentaje Efectivo') ? '%' : ''}`;

            return newLabel;
          },
        }
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          modifierKey: 'shift',
          threshold: 5,
        },
        zoom: {
          mode: 'x',
          drag: {
            enabled: true,
            backgroundColor: paleta.zoomBackgroundColor,
          },
        },
        limits: {
          y: { min: 0, max: 100, minRange: 50 }
        },
      },
    },
    scales: {
      y: {
        labels: ["100", "50", "0"],
        position: "left",
        stack: "polinizacion",
        stackWeight: 2,
        ticks: {
          color: paleta.graficos.lineSecondary,
          font: { size: 11 },
          stepSize: 50,
        },
        min: 0,
        max: 100,
      },
      x: {
        ticks: {
          minRotation: 30,
          align: "end",
        },
      },
    },
    transitions: {
      zoom: {
        animation: {
          duration: 1000,
          easing: 'easeOutCubic'
        }
      }
    },
  },
  infoSemanaActual: {
    porcentajeEfectivo: 0,
    porcentajeVisitas: 0,
    nivelGeneral: '---',
    niveles: [],
  },
  secciones: [],
  seccionActiva: {
    id: null,
    nombre: '',
    grafico: {
      labels: [],
      datasets: [],
    }
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case types.setInformacion: {
      return {
        ...state,
        datosGrafica: action.datosGrafica,
        configuracionGrafica: action.configuracionGrafica,
        secciones: action.secciones,
        infoSemanaActual: {
          porcentajeEfectivo: action.infoSemanaActual.porcentajeEfectivo || 0,
          porcentajeVisitas: action.infoSemanaActual.porcentajeVisitas || 0,
          nivelGeneral: action.infoSemanaActual.nivelGeneral || '---',
          niveles: action.infoSemanaActual.niveles || [],
          estado: action.infoSemanaActual.estado || '',
        },
        seccionActiva: {
          id: null,
          nombre: '',
          grafico: {
            labels: action.datosGrafica.labels,
            datasets: [],
          }
        },
      };
    }
    case types.setSeccionActiva: {
      return {
        ...state,
        seccionActiva: {
          id: action.seccion.id,
          nombre: action.seccion.nombre,
          grafico: {
            ...state.seccionActiva.grafico,
            datasets: action.datasets,
          }
        },
      };
    }
    case types.clearSeccionActiva: {
      return {
        ...state,
        seccionActiva: {
          id: null,
          nombre: '',
          grafico: {
            labels: state.datosGrafica.labels,
            datasets: [],
          }
        },
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const ListaSecciones = ({ secciones, onSeleccionarSeccion, idActivo }) => {
  const classes = styles();

  const onClick = (seccion) => {
    return () => {
      if (onSeleccionarSeccion) onSeleccionarSeccion(idActivo === seccion.id ? null : seccion);
    }
  };

  if (!secciones.length) return (
    <Typography variant="body1">No se encontraron secciones con capturas.</Typography>
  );

  return (
    secciones.map(({ id, nombre, estado }) => (
      <Chip
        key={id}
        label={"Sección " + nombre}
        size="small"
        className={classes.chip}
        style={{
          backgroundColor: idActivo === id
            ? paleta.setLightenssHSL(paleta.rangos(estado?.toLowerCase()), 30)
            : paleta.rangos(estado?.toLowerCase())
        }}
        onClick={onClick({ id, nombre })}
      />
    ))
  );
};

const NavePolinizaciones = ({ naveID }) => {
  const classes = styles();

  const { sitioID, semana } = useSelector(
    store => store.tablero,
    (prev, next) => (
      prev.sitioID === next.sitioID &&
      prev.semana === next.semana &&
      isEqual(prev.temporada, next.temporada)
    )
  );

  const [state, dispatch] = useReducer(reducer, initState);
  const {
    datosGrafica,
    configuracionGrafica,
    infoSemanaActual,
    secciones,
    seccionActiva,
  } = state;

  const consultarDatos = useCallback(async () => {
    try {
      if ( !sitioID || !naveID || !semana ) return;

      const fechaObjetivo = moment(semana, 'W (YYYY)').startOf('isoWeek').format('YYYY-MM-DD');

      const [{ capturas, secciones }, niveles] = await Promise.all([
        axios.get(CAPTURA_POLINIZACION, { params: { naveID, sitioID, fechaObjetivo }}),
        axios.get(endpoints.base.busqueda(NIVELESPOLINIZACION))
      ]);

      // Niveles
      const nivelesPolinizacion = niveles.filter(nivel => !nivel.esNinguno);
      const nivelCero = niveles.find(nivel => nivel.esNinguno);
      nivelesPolinizacion.sort((a, b) => b.nombre.localeCompare(a.nombre, 'es', { numeric: true }));
      const nivelesOrdenados = [...nivelesPolinizacion, nivelCero];

      const anotacionesNiveles = nivelesOrdenados.reduce((acc, nivel, idx) => {
        if ( idx !== 0 && idx !== nivelesOrdenados.length - 1 ) {
          acc[nivel.id] = {
            type: "line",
            scaleID: "y2",
            value: nivel.nombre,
            borderColor: paleta.graficos.lineTertiary,
            borderDash: [4, 4],
            borderWidth: 1.5,
          }
        }
        return acc;
      }, {});

      const configuracionGrafica = {
        ...initState.configuracionGrafica,
        plugins: {
          ...initState.configuracionGrafica.plugins,
          annotation: {
            annotations: {
              ...anotacionesNiveles,
            },
          },
        },
        scales: {
          ...initState.configuracionGrafica.scales,
          y2: {
            type: "category",
            labels: nivelesOrdenados.map(nivel => nivel.nombre),
            position: "right",
            stack: "polinizacion",
            stackWeight: 2,
            ticks: {
              color: paleta.graficos.lineTertiary,
              font: {
                size: 11,
              },
            },
          },
        }
      };

      const datosGrafica = {
        labels: capturas.map((c) => c.fecha),
        datasets: [
          {
            label: "Porcentaje Efectivo",
            data: capturas.map(({ porcentajeEfectivo = null }) => porcentajeEfectivo),
            borderColor: paleta.graficos.lineSecondary,
            backgroundColor: paleta.graficos.lineSecondary,
            borderWidth: 3,
            lineTension: 0.4,
          },
          {
            label: "Nivel",
            data: capturas.map(({ nivelGeneral = null }) => nivelGeneral),
            borderColor: paleta.graficos.lineTertiary,
            backgroundColor: paleta.graficos.lineTertiary,
            borderWidth: 3,
            yAxisID: 'y2',
            stepped: true,
            position: 'left',
          },
        ],
      };

      dispatch({
        type: types.setInformacion,
        datosGrafica,
        configuracionGrafica,
        secciones,
        infoSemanaActual: capturas.find((g) => g.fecha === moment(semana, 'W (GGGG)').format('W-GGGG')) || initState.infoSemanaActual
      });
    } catch {
      dispatch({ type: types.setInformacion, ...initState });
    };
  }, [sitioID, naveID, semana]);

  const onClickSeccion = useCallback(async (seccion) => {
    try {
      if (seccion) {
        const historial = await axios.get(endpoints.historicoSecciones(), { params: { naveID, sitioID, seccionID: seccion.id } });
        const datasets = [
          {
            label: "Porcentaje Efectivo",
            data: historial.map((h) => h.porcentajeEfectivo),
            borderColor: paleta.graficos.lineSecondary,
            backgroundColor: paleta.graficos.lineSecondary,
            borderWidth: 3,
            lineTension: 0.4,
          },
          {
            label: "Nivel",
            data: historial.map((h) => h.nivelGeneral),
            borderColor: paleta.graficos.lineTertiary,
            backgroundColor: paleta.graficos.lineTertiary,
            borderWidth: 3,
            yAxisID: 'y2',
            stepped: true,
            position: 'left',
          },
        ];
        dispatch({ type: types.setSeccionActiva, seccion, datasets });
      } else {
        dispatch({ type: types.clearSeccionActiva });
      }
    } catch {
      dispatch({ type: types.clearSeccionActiva });
    }
  }, [naveID, sitioID]);

  useEffect(() => {
    consultarDatos();
  }, [consultarDatos]);

  return (
    <Agrupador customClass={classes.agrupador}>
      <Grid container justifyContent="flex-start" alignItems="center">
        <Typography variant='h6' bold className={classes.tituloModulo}>
          POLINIZACIÓN
        </Typography>
      </Grid>

      <Grid container className={classes.informacion}>
        <table width="100%">
          <thead>
            <tr>
              <th className={classes.label}>Porcentaje de visitas</th>
              <th className={classes.label}>Porcentaje de efectividad</th>
              <th className={classes.label}>Nivel General</th>
              {
                infoSemanaActual?.niveles.map((nivel) => (
                  <th key={nivel.id} className={classes.label}>
                    <Badge
                      overlap="rectangular"
                      variant="dot"
                      invisible={!nivel.esEfectivo}
                      classes={{ badge: classes.badge }}
                      title={nivel.esEfectivo ? 'Nivel Efectivo' : ''}
                    >
                      <Typography variant="caption" className={classes.label}>{ nivel.nombre }</Typography>
                    </Badge>
                  </th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.valor}>{ infoSemanaActual.porcentajeVisitas }%</td>
              <td className={clsx(classes.valor, classes.estadoGeneral)} style={{ backgroundColor: paleta.rangos(infoSemanaActual.estado?.toLowerCase()) }}>
                { infoSemanaActual.porcentajeEfectivo }%
              </td>
              <td className={classes.valor}>{ infoSemanaActual.nivelGeneral }</td>
              {
                infoSemanaActual.niveles.map((nivel) => (
                  <td key={nivel.id} className={classes.valor}>{ nivel.porcentaje }%</td>
                ))
              }
            </tr>
          </tbody>
        </table>
      </Grid>

      <Grid container className={classes.secciones}>
        <Typography variant="subtitle1" className={classes.label}>Secciones</Typography>
        <Grid item className={classes.estados}>
          <ListaSecciones secciones={secciones} onSeleccionarSeccion={onClickSeccion} idActivo={seccionActiva?.id} />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <div style={{ maxWidth: '100%' }}>
          <Chart
            title={`Histórico de polinizaciones ${seccionActiva.id ? `- Sección ${seccionActiva.nombre}` : ''}`}
            type="line"
            width="100%"
            height={270}
            showLegend
            legendStyles={{ justifyContent: "flex-start", paddingBottom: '20px' }}
            data={seccionActiva.id ? seccionActiva.grafico : datosGrafica}
            options={configuracionGrafica}
            staticYAxis={false}
            containerID={`nave-polinizaciones-${naveID}`}
            containerClass={classes.chartContainerClass}
          />
        </div>
      </Grid>
    </Agrupador>
  );
};

NavePolinizaciones.propTypes = {
  /** Identificador de la nave */
  naveID: propTypes.string.isRequired,
};

export default React.memo(NavePolinizaciones);
