import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import { Divider, Paper, Grid} from '@material-ui/core';

import Typography from '../Typography';

import styles from './style';

const TablaInfo = ({ tabla }) => {
  const classes = styles();
  const data = [
    { label: tabla?.hectareas || '---', subTitle: 'Tamaño (Hectáreas)', key: 'id' },
    { label: tabla?.tipoSuelo?.nombre || '---', subTitle: 'Tipo Suelo', key: 'id'},
    { label: tabla?.variedad || '---', subTitle: 'Variedad', key: 'id'},
    { label: tabla?.temporadaCultivo?.length > 0 ? moment(tabla.temporadaCultivo[0].fechaTransplante).isoWeek() : "---", subTitle: 'Semana Transplante', key: 'id' },
    { label: tabla?.temporadaCultivo?.length > 0 ? moment(tabla.temporadaCultivo[0].fechaCultivo).isoWeek() : "---", subTitle: 'Semana Cultivo', key: 'id'},
    { label: tabla?.temporadaCultivo?.length > 0 ? moment(tabla.temporadaCultivo[0].fechaCorte).isoWeek() : "---", subTitle: 'Semana Cosecha', key: 'id'},
  ];

	return(
    <>
      <Paper
        className={classes.root}
        square
        variant="outlined"
        elevation={1}
      >
        <Grid align="left" container className={classes.titleContainer}>
          {
            data.map((option, index) => (
              <Grid item container xs key={index}>
                <Grid item xs>
                  <Typography variant="subtitle2" className={classes.title}>
                    {option.label || ''} 
                  </Typography>
                  <Typography variant="subtitle2" className={classes.subTitle}>
                    {option.subTitle || ''}
                  </Typography>
                </Grid>
                  {index !== data.length - 1 && (
                    <Divider
                      orientation="vertical"
                      flexItem
                      className={classes.divider}
                    />
                  )}
              </Grid>
          ))}
          {data.length === 0 && (
            <h1>Sin elementos para mostrar</h1>
          )}
        </Grid>
      </Paper>
    </>
	)
}

TablaInfo.propTypes = {
  /** Objeto con la información de la tabla */
  tabla: propTypes.object,
};

export default React.memo(TablaInfo);