import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import moment from 'moment';

import CustomSelect from '../../componentes/Select';
import DatePickerRange from '../../componentes/DatePickerRange';
import SearchBox from '../../componentes/SearchBox';
import Table from '../../componentes/Table';
import Typography from '../../componentes/Typography';
import Wrapper from '../../contenedores/Wrapper';

import axios from '../../configuraciones/axios';
import endpoints from '../../configuraciones/endpoints';

import styles, { VerDetallesButton } from './styles';

const cabeceros = [
  {
    label: 'Nombre',
    key: 'nombre',
  },
  {
    label: 'Fecha de Fin',
    transform: ({ fechaFin }) => moment(fechaFin).endOf('day').format('LL'),
  },
  {
    label: 'Fecha de Inicio',
    transform: ({ fechaInicio }) => moment(fechaInicio).startOf('day').format('LL'),
  },
  {
    label: 'Sitio',
    key: 'sitio',
  },
  {
    label: 'Acciones',
    transform: ({ id }) => <VerDetallesButton id={id} />,
  },
];

const TemporadasHistorico = ({ history, location }) => {
  const classes = styles();

  const abortControllerRef = useRef(new AbortController());
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [historial, setHistorial] = useState({ count: 0, rows: [] });
  const [sitios, setSitios] = useState([]);

  const consultarHistorial = useCallback(async () => {
    try {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;

      const consultas = [
        axios.get(endpoints.obtenerHistorialTemporadas(), {
          signal,
          params: Object.fromEntries(query),
        }),
        axios.get(endpoints.obtenerSitiosHistorico(), { signal }),
      ];

      const [historial, sitios] = await Promise.all(consultas);

      setHistorial(historial);
      setSitios([
        { id: '', nombre: 'Todos' },
        ...sitios,
      ]);
    } catch (error) {
      if (error.isCancel) return;
    }
  }, [query]);

  const handleChangeSelect = useCallback((e) => {
    const { name, value } = e.target;

    if (value === null || value === '' || value === undefined) query.delete(name);
    else query.set(name, value);

    history.push({ search: query.toString() });
  }, [history, query]);

  const handleChangeRangoFechas = useCallback(({ inicio, fin }) => {
    const fechaInicio = inicio ? moment(inicio).startOf('day').format('YYYY-MM-DD') : '';
    const fechaFin = fin ? moment(fin).endOf('day').format('YYYY-MM-DD') : '';

    if (fechaInicio) query.set('fechaInicio', fechaInicio);
    else query.delete('fechaInicio');

    if (fechaFin) query.set('fechaFin', fechaFin);
    else query.delete('fechaFin');

    history.push({ search: query.toString() });
  }, [history, query]);

  useEffect(() => {
    consultarHistorial();
    return () => abortControllerRef.current.abort();
  }, [consultarHistorial]);

  return (
    <Wrapper classes={{ main: classes.wrapper }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap" gridGap={40}>
        <Typography component="h2">
          Histórico de Temporadas
          <Typography variant="body1" component="small" className={classes.small}>
            {historial.count}
            {' '}
            {historial.count === 1 ? 'elemento' : 'elementos'}
          </Typography>
        </Typography>
        <Box display="flex" alignItems="center" flexWrap="wrap" gridGap={10}>
          <SearchBox
            name="q"
            placeholder="Buscar por nombre"
            className={clsx(classes.input, classes.search)}
          />
          <CustomSelect
            label="Sitios"
            options={sitios}
            className={clsx(classes.input, classes.select)}
            name="sitio"
            valueProp="id"
            labelProp="nombre"
            value={Number(query.get('sitio')) || ''}
            onChange={handleChangeSelect}
            placeHolder="Todos"
          />
          <DatePickerRange
            label="Fecha"
            emptyMessage="Cualquier fecha"
            className={clsx(classes.input, classes.date)}
            onChange={handleChangeRangoFechas}
          />
        </Box>
      </Box>
      <Box marginTop={5}>
        <Table
          count={historial.count}
          rows={historial.rows}
          customClass={classes.tabla}
          emptyMessage="No se encontraron temporadas en el historial"
          headers={cabeceros}
          showActions={false}
        />
      </Box>
    </Wrapper>
  )
}

export default React.memo(TemporadasHistorico);
