import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: paleta.sidebar.active,
    border: `1px solid ${paleta.textField.border}`,
    overflowY: 'auto',
    borderRadius: '10px',
    marginBottom: 20,
    opacity: 0,
    animation: '$fadeInUp 500ms ease-in-out 500ms forwards',
  },
  contenedor: {
    display: 'flex',
    minHeight: '40vh',
    flexDirection: 'column',
  },
  contenedorVacio: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    minWidth: '100%',
  },
  '@keyframes fadeInUp': {
    '0%': {
      opacity: 0,
      transform: 'translateY(20px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
}));

export default styles;
