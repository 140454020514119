import React, { useCallback, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Dialog from '../../../../componentes/CustomDialog';
import Table from '../../../../componentes/Table';
import Typography from '../../../../componentes/Typography'

import {
  cargarFormUnificacion,
  enumPasos,
  seleccionarInvernadero,
  seleccionarTodosInvernaderos,
  setDisabledFormUnificacion,
  unificarInvernaderos,
  validarSeleccionUnificacion,
} from '../../../../ducks/temporada';

import { Boton } from '../../styles';

const formSitioSelector = (state) => state.temporada.form[enumPasos.SITIOS].data;
const formUnificacionSelector = (state) => state.temporada.formUnificacion;

const cabeceros = [{ key: 'nombre', label: 'Nombre' }];

const FormUnificacion = ({ initialValues }) => {

  const dispatch = useDispatch();

  const [mostrarConfirmar, setMostrarConfirmar] = useState(false);
  const [confirmarUnificacion, setMostrarConfirmarUnificacion] = useState({
    abierto: false,
    defaultValue: '',
    invernaderos: [],
  });

  const sitios = useSelector((state) => Object.values(formSitioSelector(state).byId), shallowEqual);
  const invernaderos = useSelector((state) => Object.values(formUnificacionSelector(state).byId), shallowEqual);
  const unificacionActiva = useSelector((state) => !formUnificacionSelector(state).disabled, shallowEqual);

  const seleccionar = useCallback((({ id }) => {
    dispatch(seleccionarInvernadero(id));
  }), [dispatch]);

  const seleccionarTodos = useCallback((sitioId) => (valor) => {
    dispatch(seleccionarTodosInvernaderos({ sitioId, valor }));
  }, [dispatch]);

  const iniciarUnificacion = useCallback(() => {
    setMostrarConfirmar(true);
    dispatch(setDisabledFormUnificacion(false))
  }, [dispatch]);

  const cancelarUnificacion = useCallback(() => {
    dispatch(setDisabledFormUnificacion(true));
  }, [dispatch]);

  const asignarNombreUnificacion = useCallback(() => {
    const resultado = dispatch(validarSeleccionUnificacion());
    if (resultado) {
      const { nombre, invernaderosAfectados } = resultado;
      setMostrarConfirmarUnificacion({
        abierto: true,
        defaultValue: nombre,
        invernaderos: invernaderosAfectados,
      });
    }
  }, [dispatch]);

  const unificar = useCallback((nombre) => {
    dispatch(unificarInvernaderos(nombre, confirmarUnificacion.invernaderos));
    setMostrarConfirmarUnificacion((prev) => ({ ...prev, abierto: false }));
  }, [confirmarUnificacion.invernaderos, dispatch]);

  const cancelarConfirmacionUnificacion = useCallback(() => {
    setMostrarConfirmarUnificacion((prev) => ({ ...prev, abierto: false }));
  }, []);

  useEffect(() => {
    dispatch(cargarFormUnificacion(initialValues));
  }, [dispatch, initialValues]);

  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      {/* Aviso de incio de unificación */}
      <Dialog
        abierto={mostrarConfirmar}
        titulo="Unificación de invernaderos"
        descripcion="Entrará en el proceso de unificación de invernaderos. Seleccione un invernadero del listado para poder editar su geolocalización."
        onAceptar={useCallback(() => setMostrarConfirmar(false), [])}
        autoFocusAceptar
      />
      {/* Confirmación de unificación */}
      <Dialog
        abierto={confirmarUnificacion.abierto}
        titulo="Unificación de invernaderos"
        inputLabel="Nombre del invernadero"
        typeInput
        defaultValue={confirmarUnificacion.defaultValue}
        onAceptar={unificar}
        onCancelar={cancelarConfirmacionUnificacion}
      />
      {/* Listado de invernaderos */}
      <Box maxHeight="58vh" overflow="auto">
        {sitios.map(({ id, nombre }) => (
          <Box key={id} display="flex" flexDirection="column" gridGap={10} maxHeight="58vh" marginBottom={2}>
            <Box>
              <Typography variant="subtitle1">
                <b>
                  {sitios.length > 1
                    ? `Invernaderos del sitio ${nombre}`
                    : 'Invernaderos'
                  }
                </b>
              </Typography>
            </Box>
            <Box>
              <Table
                headers={cabeceros}
                rows={invernaderos.filter((invernadero) => String(invernadero.sitioId) === String(id))}
                showCheckbox
                showActions={false}
                hidePaginator
                customStyles={{ maxHeight: '54vh', minHeight: 'initial' }}
                onSelect={seleccionar}
                onSelectAll={seleccionarTodos(id)}
                hideSelectAll={unificacionActiva}
                minRows={0}
              />
            </Box>
          </Box>
        ))}
      </Box>
      {/* Botones de acción */}
      <Box display="flex" justifyContent="space-between">
        {unificacionActiva ? (
          <>
            <Boton label="Confirmar" onClick={asignarNombreUnificacion} />
            <Boton border label="Cancelar" onClick={cancelarUnificacion} />
          </>
        ) : (
          <Boton label="Unificar invernaderos" onClick={iniciarUnificacion} />
        )}
      </Box>
    </Box>
  )
}

export default React.memo(FormUnificacion);
