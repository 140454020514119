import React, { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { NAVES } from '../../configuraciones/endpoints';
import { getInvernaderos, getSitios } from '../../ducks/listados';

const cabeceros = [
  { label: 'Código', key: 'id' },
  { label: 'Nombre', key: 'nombre' },
  { label: 'Invernadero', transform: ({ invernadero }) => invernadero?.nombre || '' },
  { label: 'Sitio', transform: ({ invernadero }) => invernadero?.sitio?.nombre || '' },
  { label: 'Estatus', transform: ({ estatus }) => <Estatus activo={estatus} />, },
];

const Naves = ({ history, location }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    batch(() => {
      dispatch(getSitios())
      dispatch(getInvernaderos())
    });
  }, [dispatch]);

  return <Listados
    titulo="Naves"
    placeHolder="Búsqueda por nombre"
    history={history}
    location={location}
    showActions
    cabeceros={cabeceros}
    apiKey={NAVES}
    filtrosBusqueda={{ sitio: true, invernadero: true }}
  />;
};

export default Naves;
