import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  table: {
    borderRadius: 0,
    borderTop: 'none',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderTopRightRadius: 8,
  },
  btnAction: {
    margin: theme.spacing(0.5),
    textAlign: 'center',
  }
}));

export default styles;

