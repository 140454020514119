import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import paleta from '../../configuraciones/paleta';

const MultiPorgressBar = ({ data }) => {
  const total = useMemo(() => data.reduce((acc, cur) => acc + cur.valor, 0), [data]);
  const porcentajes = useMemo(() => data.filter(item => item.valor > 0).map(item => ({ ...item, porcentaje: item.valor / total * 100 })), [data, total]);

  return (
    <div style={{
      width: `100%`,
      height: '10px',
      borderRadius: '6px',
      display: 'flex',
      alignItems: 'center',
    }}>
      {
        porcentajes.map((item, index) => {
          const primerElemento = index === 0;
          const ultimoElemento = index === porcentajes.length - 1;
          return (
            <div key={item.id} style={{
              backgroundColor: paleta.configuraciones[item.color],
              border: '1px inset #fff',
              width: `${item.porcentaje}%`,
              height: '10px',
              borderTopLeftRadius: primerElemento ? '6px' : '0px',
              borderBottomLeftRadius: primerElemento ? '6px' : '0px',
              borderTopRightRadius: ultimoElemento ? '6px' : '0px',
              borderBottomRightRadius: ultimoElemento ? '6px' : '0px',
              transition: 'width 200ms ease-in-out',
            }}></div>
          )
        })
      }
    </div>
  )
};

MultiPorgressBar.propTypes = {
  /** Datos del componente */
  data: propTypes.arrayOf(propTypes.shape({
    /** Id del elemento */
    id: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
    /** Porcentaje del elemento */
    valor: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
    /** Color del elemento */
    color: propTypes.string.isRequired,
  })).isRequired,
};

export default React.memo(MultiPorgressBar);
