import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';

import { enumPasos } from '../../../ducks/temporada';

import FormTemporada from '../components/FormTemporada';

const baseFormSelector = (state) => state.temporada.form[enumPasos.BASE];

const ConfiguracionTemporada = () => {
  const temporadas = useSelector((state) => baseFormSelector(state).data.allIds, shallowEqual);

  return (
    <Box display="flex" flexDirection="column" gridGap={30}>
      {temporadas.map((id) => (
        <FormTemporada key={id} id={id} />
      ))}
    </Box>
  )
};

export default React.memo(ConfiguracionTemporada);
