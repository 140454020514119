/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { toast } from 'react-toastify';

import Default from '../../contenedores/Default';
import Select from '../../componentes/Select';
import TextField from '../../componentes/TextField';
import Typography from '../../componentes/Typography';

import axios from '../../configuraciones/axios';
import endpoints, {
  NIVELESPOLINIZACION
} from '../../configuraciones/endpoints';
import { ELEMENTO_NO_ENCONTRADO } from '../../configuraciones/mensajes';
import { findPropertysEmpty, trim } from '../../utilidades/functions';
import estatus from '../../constantes/estatus';
import styles from './styles';

const NivelPolinizacion = ({ history, match }) => {
  const classes = styles();

  const [nivelPolinizacion, setNivelPolinizacion] = useState({});
  const [esNivelCero, setEsNivelCero] = useState(false);
  const [esNivelEfectivo, setEsNivelEfectivo] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const { params: { id } } = match;

  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/nivelespolinizacion',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  const cancelar = useCallback(regresar, []);

  const guardar = useCallback(() => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmNivelPolinizacion'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;

    const metodo = id ? 'put' : 'post';

    const objTrim = trim({...nivelPolinizacion, esNinguno: esNivelCero, esEfectivo: esNivelEfectivo});

    axios[metodo](NIVELESPOLINIZACION, objTrim).then(regresar).catch(() => {});
  }, [nivelPolinizacion, esNivelCero, esNivelEfectivo, id]);

  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [];
    if (id) promesas.push(axios.get(endpoints.base.url(NIVELESPOLINIZACION, id)));

    Promise.all(promesas).then((resultadosPromesas) => {
      const [nivelPolinizacionDb] = resultadosPromesas;
      if (nivelPolinizacionDb?.id) {
        const { esNinguno, esEfectivo, ...rest } = nivelPolinizacionDb;
        setNivelPolinizacion(rest);
        setEsNivelCero(esNinguno);
        setEsNivelEfectivo(esEfectivo);
      }
      else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      } else
        setNivelPolinizacion({estatus: true});
      resolve();
    }).catch(reject);
  });

  useEffect(() => {
    consultarDatosIniciales();
  }, [id]);

  return <Default
    titulo={Boolean(id) ? 'Editar Nivel de Polinización' : 'Nuevo Nivel de Polinización'}
    placeHolder={''}
    mostrarCabeceroFormulario
    guardar={guardar}
    cancelar={cancelar}>
    <Grid container spacing={2} id="frmNivelPolinizacion">
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Nombre de Nivel de Polinización"
              onChange={setNivelPolinizacion}
              name="nombre"
              value={nivelPolinizacion.nombre}
              isHandleChange
              inputProps={{ maxLength: 50 }}
              error={formErrors.nombre}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Estatus"
              required
              options={estatus}
              onChange={setNivelPolinizacion}
              name="estatus"
              value={nivelPolinizacion.estatus}
              error={formErrors.estatus}
              isHandleChange
            />
          </Grid>
          <Grid item container direction="column">
            <Typography variant="subtitle2" className={classes.subtitle}>Configuración</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  name='esNinguno'
                  checked={esNivelCero}
                  onChange={(e) => setEsNivelCero(e.target.checked)}
                />
              }
              label="Nivel 0 (Ausencia de polinización)"
            />
            <Typography variant="caption" className={classes.caption}>
              El nivel 0 indica la <b>ausencia</b> de polinización en la planta.
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  name='esEfectivo'
                  checked={esNivelEfectivo}
                  onChange={(e) => setEsNivelEfectivo(e.target.checked)}
                />
              }
              label="Nivel efectivo"
            />
            <Typography variant="caption" className={classes.caption}>
              El nivel efectivo indica que el nivel de polinización es el <b>adecuado</b> para la planta.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(NivelPolinizacion);
