import React, { useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { toast } from 'react-toastify';
import { BsFillGearFill } from 'react-icons/bs';
import { FiTrash } from 'react-icons/fi';
import pointInPolygon from 'point-in-polygon';

import Agrupador from '../Agrupador';
import Confirmacion from '../Confirmacion';
import Estatus from '../Estatus';
import HeaderSubcatalogo from '../HeaderSubcatalogo';
import Mapa from '../Mapa';
import RightDrawer from '../RightDrawer';
import Select from '../Select';
import Tabla from '../Table';
import TextField from '../TextField';

import estatus from '../../constantes/estatus';
import { NOMBRE_TRAMPA_REPETIDO, TRAMPA_FUERA_POLIGONO } from '../../configuraciones/mensajes';
import paleta from '../../configuraciones/paleta';

import { NUMBER_REGEX } from '../../utilidades/regex';
import { findPropertysEmpty, obtenerCentroide } from '../../utilidades/functions';

import styles from './styles';

const NaveTrampas = ({
  mostrar, guardar, cancelar,
  trampas, trampasActivas,
  tablaID, naveID,
  naveCoordenadas
}) => {
  const classes = styles();
  const cabeceros = [
    { label: 'Nombre', key: 'nombre' },
    { label: 'Estatus', transform: ({ estatus }) => <Estatus activo={estatus} />, },
    {
      label: '', transform: (e, index) => <IconButton onClick={() => configurar(index)}>
        <BsFillGearFill color={e.latitud ? paleta.bar.user : paleta.bar.secondary} />
      </IconButton>
    }
  ];
  const [nuevasTrampas, setNuevasTrampas] = useState([]);
  const [indexEdicion, setIndexEdicion] = useState(0);
  const [anchorConfirmacion, setAnchorConfirmacion] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const centro = useMemo(() => obtenerCentroide(naveCoordenadas.map((vertice) => ({ x: vertice.latitud, y: vertice.longitud }))), [naveCoordenadas]);

  const validarNombresRepetidos = () => {
    let todosValidos = true;
    const edicionTrampas = [...nuevasTrampas];
    let index = 0;
    for (const s of edicionTrampas) {
      const indexExiste = nuevasTrampas.findIndex(({ nombre, activo }) => nombre === s.nombre && activo && s.activo);
      s.repetido = indexExiste >= 0 && indexExiste !== index;
      if (todosValidos && s.repetido) todosValidos = false;
      index++;
    }
    setNuevasTrampas(edicionTrampas);
    return todosValidos;
  };

  const agregar = () => {
    const nuevaTrampa = {
      activo: true,
      nombre: `${nuevasTrampas.filter(({ activo }) => activo).length + 1}`,
      estatus: true,
      naveID,
    };
    if (tablaID) nuevaTrampa.tablaID = tablaID;
    setNuevasTrampas((current) => [...current, nuevaTrampa]);
    setIndexEdicion(nuevasTrampas.length);
  };

  const customDelete = (event) => setAnchorConfirmacion(event.currentTarget);
  const cancelDelete = () => setAnchorConfirmacion(null);
  const configurar = (index) => setIndexEdicion(index);

  const acceptDelete = () => {
    eliminar();
    cancelDelete();
  };

  const eliminar = () => {
    if (nuevasTrampas.filter(({ activo }) => activo)[indexEdicion].id) {
      manejadorTrampa({ target: { name: 'activo', value: false } });
    } else {
      const edicionTrampas = [...nuevasTrampas];
      const trampasActivas = edicionTrampas.filter(({ activo }) => activo);
      const indexEliminar = edicionTrampas.indexOf(trampasActivas[indexEdicion]);
      edicionTrampas.splice(indexEliminar, 1);
      setNuevasTrampas(edicionTrampas);
    }
  }

  const manejadorTrampa = (e) => {
    const edicionTrampas = [...nuevasTrampas];
    edicionTrampas.filter(({ activo }) => activo)[indexEdicion][e.target.name] = e.target.value;
    setNuevasTrampas(edicionTrampas);
  };

  const customGuardar = () => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmNaveTrampas'), true);
    setFormErrors({ ...errors, ...regex });

    if (totalErrors > 0 || totalRegex > 0) return;

    let valido = validarNombresRepetidos();
    if (!valido) {
      return toast.warning(NOMBRE_TRAMPA_REPETIDO);
    }

    for (const trampa of nuevasTrampas.filter(({ activo }) => activo)) {
      valido = pointInPolygon(
        [trampa.latitud, trampa.longitud],
        naveCoordenadas.map(({ latitud, longitud }) => ([latitud, longitud])));
      if (!valido) break;
    }
    if (!valido) return toast.warning(TRAMPA_FUERA_POLIGONO);

    setIndexEdicion(-1);
    guardar(nuevasTrampas);
  };

  const setMarcador = ({ lat, lng }) => {
    manejadorTrampa({ target: { name: 'latitud', value: lat } });
    manejadorTrampa({ target: { name: 'longitud', value: lng } });
  };

  useEffect(() => {
    setNuevasTrampas(structuredClone(trampas));
  }, [trampas, mostrar]);

  return (
    <RightDrawer
      paperClassName={classes.paperDrawer}
      isOpen={mostrar}
      header={<HeaderSubcatalogo
        titulo="Trampas"
        subtitulo="Configuración"
        guardar={customGuardar}
        cerrar={() => {setIndexEdicion(-1); cancelar();}}
        agregar={agregar}
        textField={{
          label: 'Cantidad de trampas',
          disabled: true,
          name: 'txtCantidadTrampas',
          value: nuevasTrampas.filter(({ activo }) => activo).length || '0'
        }}
      />}>
      <Confirmacion
        anchor={anchorConfirmacion}
        onClose={cancelDelete}
        onAccept={acceptDelete}
      />
      <Grid container spacing={4}>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <Tabla
            headers={cabeceros}
            rows={nuevasTrampas.filter(({ activo }) => activo)}
            hidePaginator
            showActions={false}
          />
        </Grid>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <Agrupador label="Configuración / Geolocalización">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Mapa
                  esPoligono={false}
                  ocultarBusqueda
                  ocultarEliminar
                  poligonos={ naveCoordenadas.length > 0 ? [
                    {
                      path: naveCoordenadas.map(coordenadas => ({lat: coordenadas.latitud, lng: coordenadas.longitud})),
                      options: {
                        strokeColor: paleta.geocerca.tertiary,
                        fillColor: paleta.geocerca.tertiary,
                        clickable: true,
                      }
                    }
                  ] : []}
                  iconoMarcador={{
                    url: '/assets/images/bug_rojo.svg',
                    scale: 1,
                  }}
                  bloquearClick={!Boolean(nuevasTrampas.filter(({ activo }) => activo)[indexEdicion])}
                  onChangeMarcador={setMarcador}
                  marcador={{ lat: nuevasTrampas.filter(({ activo }) => activo)[indexEdicion]?.latitud, lng: nuevasTrampas.filter(({ activo }) => activo)[indexEdicion]?.longitud }}
                  marcadores={nuevasTrampas.filter(({ activo, latitud, longitud }) => activo && latitud && longitud).filter((e, index) => index !== indexEdicion)
                    .map(({ latitud, longitud }) => ({
                      lat: latitud,
                      lng: longitud,
                      icon: {
                        url: '/assets/images/bug_azul.svg',
                        scale: 1,
                      }
                    }))}
                  otrosMarcadores={trampasActivas.map(({ id, latitud, longitud }) => ({
                      id,
                      lat: latitud,
                      lng: longitud,
                      icon: {
                        url: '/assets/images/bug_gris.svg',
                        scale: 1,
                      }
                    }))}
                  defaultCenter={centro ? {
                    lat: centro.x,
                    lng: centro.y,
                  } : null}
                  zoom={18}
                />
              </Grid>
              {Boolean(nuevasTrampas.filter(({ activo }) => activo)[indexEdicion]) && <Grid item xs={12}>
                <Grid container spacing={4} alignItems="flex-end" id="frmNaveTrampas">
                  <Grid item xs={5}>
                    <TextField
                      label="Nombre trampa"
                      name="nombre"
                      value={nuevasTrampas.filter(({ activo }) => activo)[indexEdicion]?.nombre || ''}
                      onChange={manejadorTrampa}
                      error={formErrors.nombre}
                      required
                      inputProps={{ maxLength: 5, regexonchange: 'true', regex: NUMBER_REGEX }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Select
                      label="Estatus"
                      required
                      options={estatus}
                      onChange={manejadorTrampa}
                      name="estatus"
                      value={nuevasTrampas.filter(({ activo }) => activo)[indexEdicion]?.estatus}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={customDelete}>
                      <FiTrash />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>}
            </Grid>
          </Agrupador>
        </Grid>
      </Grid>
    </RightDrawer>
  );
};

NaveTrampas.propTypes = {
  mostrar: propTypes.bool.isRequired,
  guardar: propTypes.func.isRequired,
  cancelar: propTypes.func.isRequired,
  trampas: propTypes.array.isRequired,
  naveID: propTypes.oneOfType([propTypes.string, propTypes.number]),
  naveCoordenadas: propTypes.array,
};

NaveTrampas.defaultProps = {
  naveID: null,
  naveCoordenadas: [],
};

export default React.memo(NaveTrampas);
