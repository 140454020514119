import React from 'react';
import { styled, Switch } from '@material-ui/core';

import paleta from '../../configuraciones/paleta';

const CustomSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    zIndex: 1,
    color: paleta.bar.primary,
    '&:hover': {
      backgroundColor: `${paleta.bar.primary}22`,
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: paleta.bar.primary,
  },
}));

export default React.memo(CustomSwitch);
