import React, { useCallback, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Divider,
  Paper,
  Chip,
  List,
  ListItem,
  Grid,
  Box,
} from '@material-ui/core';
import { IoIosArrowDown } from 'react-icons/io';
import moment from 'moment';
import isEqual from 'lodash/isEqual';

import Agrupador from '../Agrupador';
import Chart from '../Chart';
import Select from '../Select';
import Typography from "../Typography";

import axios from '../../configuraciones/axios';
import endpoints, { CAPTURA_FENOLOGIAS } from '../../configuraciones/endpoints';
import paleta from '../../configuraciones/paleta';
import configuraciones from '../../configuraciones/general';
import { moda, obtenerRangoFechas } from '../../utilidades/functions';

import styles from './styles';

const crearAnotacion = (x = 0) => {
  return {
    type: 'line',
    borderColor: paleta.graficos.barPrimary,
    borderDash: [6, 6],
    borderWidth: 2,
    value: x,
    scaleID: 'x',
  }
};

const categoryScale = {
  type: "category",
  labels: ["Vegetativo", "Generativo", "Balanceado"],
  position: "left",
  stack: "estados",
  offset: true,
  ticks: {
    callback: (val, index) => {
      if ( val === 0 ) return 'V'
      else if ( val === 1 ) return 'G'
      else return 'B';
    }
  }
};

const linearScale = {
  type: 'linear',
  position: 'left',
};

const options = (indexSemana = 0, yScale = categoryScale, tooltipText = ' Estado ') => ({
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    title: { display: false },
    legend: { display: false },
    datalabels: { display: false },
    crosshair: false,
    annotation: {
      drawTime: 'beforeDraw',
      annotations: {
        line1: crearAnotacion(indexSemana),
      }
    },
    tooltip: {
      callbacks: {
        title: (tooltipItems) => {
          return 'Semana ' + tooltipItems[0].label
        },
        label: (tooltipItems) => {
          return tooltipText + tooltipItems.formattedValue;
        }
      }
    },
  },
  scales: {
    y: {
      ...yScale,
    },
  },
});

const opcionesEstado = {
  "Balanceado": {valor: 'Balanceado', color: paleta.configuraciones.balanceado},
  "Generativo": {valor: 'Generativo', color: paleta.configuraciones.generativo},
  "Vegetativo": {valor: 'Vegetativo', color: paleta.configuraciones.vegetativo},
  "No definido": {valor: '', color: 'transparent'},
};

const dataSetConfig = {
  borderColor: paleta.graficos.barSelected,
  backgroundColor: paleta.graficos.barSelected,
  borderWidth: 3,
  lineTension: 0.0,
  stepped: 'middle',
};

const initState = {
  capturas: [],
  capturasSemanales: [],
  semanas: [],
  plantas: [],
  fenologias: [],
  estadoGeneral: { valor: '', color: 'transparent' },
};

const initFenologia = { id: null, nombre: '' };

const NaveFenologias = ({ naveID }) => {
  const classes = styles();
  const { sitioID, semana, temporada } = useSelector(
    ({ tablero: { sitioID, semana, temporada }}) => ({ sitioID, semana, temporada }),
    (prev, next) => (
      prev.sitioID === next.sitioID &&
      prev.cultivoID === next.cultivoID &&
      prev.semana === next.semana &&
      isEqual(prev.temporada, next.temporada)
    )
  );

  const [plantaID, setPlantaID] = useState('');
  const [fenologia, setFenologia] = useState(initFenologia);
  const [semanaObjetivo, setSemanaObjetivo] = useState(moment(semana, 'W (YYYY)').format('W-YYYY'));

  const [data, setData] = useState(initState);
  const [chartData, setChartData] = useState({
    data: {
      labels: [],
      datasets: [{
        data: [],
        ...dataSetConfig,
      }],
    },
    options: options(0),
  });

  const actualizarGrafico = useCallback((capturas, semanas) => {
    const dataset = [];
    const datasetNumeros = [];
    let tieneConfiguracion = true;

    let filtroCapturas = [...capturas];
    if ( plantaID ) filtroCapturas = filtroCapturas.filter(c => c.plantaID === plantaID);
    if ( fenologia.id ) {
      filtroCapturas = filtroCapturas.filter(c => c.fenologiaID === fenologia.id);
      tieneConfiguracion = ( filtroCapturas[0]?.tieneConfiguracion === false ) ? false : true;
    }

    semanas.forEach((semana) => {
      const capturasSemanal = filtroCapturas.filter(captura => captura.fecha === semana);
      const estado = moda(capturasSemanal.map(c => c.estado).filter(c => c), configuraciones.prioridadesFenologias);

      if ( tieneConfiguracion ) {
        dataset.push({
          valor: (estado === 'No definido') ? NaN : estado,
          semana,
        });
      } else {
        datasetNumeros.push({
          valor: Number(capturasSemanal.map(c => c.valor)),
          semana,
        });
      }
    });

    if ( datasetNumeros.length > 0 ) {
      setChartData(currentState => {
        return {
          ...currentState,
          data: {
            labels: semanas,
            datasets: [{
              ...currentState.data.datasets[0],
              data: datasetNumeros.map(d => d.valor),
              stepped: false,
            }],
          },
          options: options(semanas.findIndex(s => s === semana), linearScale, ' Valor: '),
        }
      });
    } else {
      setChartData(currentState => {
        return {
          ...currentState,
          data: {
            labels: semanas,
            datasets: [{
              ...currentState.data.datasets[0],
              data: dataset.map(d => d.valor),
              stepped: 'middle',
            }],
          },
          options: options(semanas.findIndex(s => s === semana), categoryScale),
        }
      });
    }
  }, [fenologia.id, plantaID, semana]);

  const consultarPlantas = useCallback(async () => {
    try {
      const plantasEnTablas = [];
      const tabla = await axios.get(endpoints.plantasNave(naveID));
      tabla.forEach(({ nombre, plantas }) => {
        plantasEnTablas.push(plantas.map(planta => ({ id: planta.id, nombre: `${nombre} - ${planta.nombre}` })));
      });

      const plantasNave = plantasEnTablas.flat();
      const plantas = [
        { id: '', nombre: `Todas las ${plantasNave.length} plantas (Promedio)` },
        ...plantasNave,
      ];

      setData( d => ({ ...d, plantas }) );
    } catch {}
  }, [naveID]);

  const consultaCapturas = useCallback(async () => {
    try {
      const capturas = await axios.get(endpoints.base.url(CAPTURA_FENOLOGIAS), {
        params: { naveID, sitioID, plantaID }
      });
      const semanas = obtenerRangoFechas(temporada.fechaInicio, temporada.fechaFin, 'W-YYYY', true);

      setData( d => ({ ...d, capturas, semanas }) );
    } catch {};
  }, [plantaID, sitioID, naveID, temporada]);

  const filtrarDatos = useCallback(() => {
    const capturasSemanales = data.capturas.filter(c => c.fecha === semanaObjetivo);
    capturasSemanales.sort((a, b) => {
      const k1 = a.estado ? 1 : 0
      const k2 = b.estado ? 2 : 0
      return k2 - k1
    });

    if ( data.capturas.length > 0 ) {
      const estado = moda(capturasSemanales.map(c => c.estado).filter(c => c), configuraciones.prioridadesFenologias);
      setData(d => ({
        ...d,
        capturasSemanales,
        estadoGeneral: opcionesEstado[estado] || initState.estadoGeneral,
      }));
      actualizarGrafico([...data.capturas], [...data.semanas]);
    } else {
      setData( d => ({ ...d, capturasSemanales, estadoGeneral: initState.estadoGeneral }) );
      actualizarGrafico([], [...data.semanas]);
    }
  }, [data.capturas, data.semanas, semanaObjetivo, actualizarGrafico]);

  const onPlantaChange = (e) => setPlantaID(e.target.value);
  const onSemanaChange = (e) => (e && e.value) && setSemanaObjetivo(e.label);
  const onFenologiaClick = (id, nombre) => setFenologia(current => current.id === id ? initFenologia : { id, nombre });

  useEffect(() => {
    consultarPlantas();
    setPlantaID('');
  }, [consultarPlantas]);

  useEffect(() => {
    if ( sitioID && naveID ) {
      consultaCapturas();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultaCapturas]);

  useEffect(() => {
    if ( data.capturas && data.semanas ) {
      filtrarDatos();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtrarDatos]);

  useEffect(() => {
    if ( semana ) {
      setSemanaObjetivo(moment(semana, 'W (YYYY)').format('W-YYYY'));
    }
  }, [semana]);

  return (
    <Agrupador customClass={classes.agrupador}>
      <Grid container className={classes.titleContainer}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Grid className={classes.headerContainer}>
            <Typography bold className={classes.labelTitle}>
              FENOLOGÍA
            </Typography>
            <Select
              classNameInput={classes.input}
              IconComponent={(props) => (
                <IoIosArrowDown
                  {...props}
                  color={paleta.bar.secondary}
                  size={20}
                />
              )}
              options={data.plantas}
              labelProp="nombre"
              name="plantaID"
              placeHolder={`Todas las 0 Plantas (Promedio)`}
              value={plantaID}
              onChange={onPlantaChange}
              style={{marginTop: 0}}
              displayTextStyles={{
                paddingLeft: 5,
                color: paleta.bar.secondary,
                fontWeight: '300',
                letterSpacing: 0.4
              }}
            />
          </Grid>
          <Paper
            className={classes.detailTable}
            variant="outlined"
            elevation={2}
          >
            <Grid className={classes.tableHeader}>
              <Typography bold className={classes.titleTable}>ESTADO</Typography>
              {
                data.estadoGeneral && (
                  <Chip
                    className={classes.chip}
                    style={{ backgroundColor: data?.estadoGeneral?.color }}
                    label={<Typography bold className={classes.chipText}>{data?.estadoGeneral?.valor}</Typography>}
                    disabled={true}
                    size="small"
                  />
                )
              }
            </Grid>
            <Grid className={classes.contenedor}>
              <List style={{ maxHeight: "100%", overflow: "auto" }}>
                {
                  data.capturasSemanales.length > 0
                  ?
                  data.capturasSemanales.map((captura) => (
                    <Grid key={captura.fenologiaID}>
                      <ListItem className={classes.listItem} onClick={() => {onFenologiaClick(captura.fenologiaID, captura.fenologia)}}>
                        <Typography
                          className={classes.listText}
                          style={{
                            color: fenologia.id === captura.fenologiaID
                              ? paleta.graficos.barSelected
                              : paleta.textField.text
                          }}
                        >
                          {captura.fenologia}
                        </Typography>
                        <Box style={{ mariginLeft: 'auto', marginRight: '10%' }}>
                          <Box style={{ display: 'inline-block', width: 40 }}>
                            <Typography
                              className={classes.listText}
                              style={{
                                marginRight: 3,
                                color: fenologia.id === captura.fenologiaID
                                  ? paleta.graficos.barSelected
                                  : paleta.textField.text
                              }}
                            >
                              {
                                captura.valor || 0
                              }
                            </Typography>
                          </Box>
                          <Typography
                            className={classes.listText}
                            style={{
                              width: 10,
                              minWidth: 10,
                              color: fenologia.id === captura.fenologiaID
                                ? paleta.graficos.barSelected
                                : paleta.textField.text
                            }}
                          >
                            {
                              captura.estado
                              ? `(${captura.estado[0]})`
                              : '\u00A0\u00A0\u00A0\u00A0'
                            }
                          </Typography>
                        </Box>
                      </ListItem>
                      <Divider className={classes.dividerList} />
                    </Grid>
                  ))
                  :
                  <ListItem><Typography style={{fontWeight: 600, color: paleta.textField.text}}>Sin elementos para mostrar</Typography></ListItem>
                }
              </List>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} className={classes.grafica}>
          <Typography className={classes.chartTitle}>
            {
              fenologia.id
                ? fenologia.nombre
                : semana ? 'Semana ' + moment(semanaObjetivo, 'W-YYYY').format('W (YYYY)') : 'Sin selección'
            }
          </Typography>
          <Grid style={{ marginTop: 25 }}>
            <Chart
              type="line"
              data={chartData.data}
              options={chartData.options}
              handleClickElement={onSemanaChange}
              height={330}
              staticYAxis={false}
              scrollTo={moment(semana, 'W (YYYY)').format('W-YYYY')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Agrupador>
  );
};

NaveFenologias.propTypes = {
  /** Identificador de la nave */
  naveID: propTypes.string.isRequired,
};

export default React.memo(NaveFenologias);
