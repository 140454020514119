/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useDebugValue, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import propTypes from 'prop-types';
import moment from 'moment';
import axios from '../configuraciones/axios';
import general from '../configuraciones/general';
import endpoints, {
	INVERNADEROS,
	NAVES,
	SITIOS,
} from '../configuraciones/endpoints';
import { setRegistros } from '../ducks/listados';
import { getWeeksFormatted, getWeeks } from '../utilidades/functions';
import { VALIDAR_SELECCION_REPORTE } from '../configuraciones/mensajes';

const dataInicial = {
	sitios: [],
	invernaderos: [],
  naves: [],
};

const semanaActual = moment().isoWeek();

export const useFetchReporte = (initEndpoints, reporteEndpoint, formatoFecha = 'W-GGGG') => {
	const dispatch = useDispatch();
	const location = useLocation();
	const isMounted = useRef(false);
	const paginaActual = useRef(1);

	const [data, setData] = useState({
		...dataInicial,
		semanas: [],
		semanasSinFormato: [],
	});
	const [formData, setFormData] = useState({
		...dataInicial,
		semana: semanaActual,
		...Object.keys(initEndpoints).reduce((acc, key) => {
			acc[key] = [];
			return acc;
		}, {}),
	});

	const consultarDatosIniciales = async () => {
		try {
			const promesas = [
				axios.get(endpoints.base.busqueda(SITIOS)),
				axios.get(endpoints.base.busqueda(INVERNADEROS)),
				axios.get(endpoints.base.busqueda(NAVES)),
				...Object.values(initEndpoints).map((endpoint) => axios.get(endpoint)),
			];
			const [sitiosBD, inverneaderosBD, navesBD, ...rest] = await Promise.all(
				promesas,
			);
			const restBD = [];
			rest.forEach((data, index) => {
				restBD.push(
					data.map((el) => ({ id: el.id, nombre: el.nombre, ...el })).flat(),
				);
				restBD[index].unshift({ id: 0, nombre: 'Todos' });
			});

			setData({
				sitios: sitiosBD,
				invernaderos: inverneaderosBD,
				naves: navesBD,
				semanas: getWeeksFormatted(),
				semanasSinFormato: getWeeks(),
				...restBD.reduce((acc, data, index) => {
					acc[Object.keys(initEndpoints)[index]] = data;
					return acc;
				}, {}),
			});
		} catch (error) {
			return error;
		}
	};

  const onChange = ({ target: { name, value } }) => {
		let nuevaData = {
			...formData,
		};

		nuevaData = { ...formData, [name]: value };

		if (name === 'sitios') {
			const invernaderosDisponibles = data.invernaderos
				.filter((invernadero) => nuevaData.sitios.includes(invernadero.sitioID))
				.map(({ id }) => id);
			nuevaData.invernaderos = nuevaData.invernaderos.filter((invernadero) =>
				invernaderosDisponibles.includes(invernadero),
			);

			const navesEnSitio = data.naves
				.filter((nave) => nuevaData.invernaderos.includes(nave.invernaderoID))
				.map(({ id }) => id);
			nuevaData.naves = nuevaData.naves.filter((nave) =>
				navesEnSitio.includes(nave),
			);
		}

		if (name === 'invernaderos') {
			const navesEnInveradero = data.naves
				.filter((nave) => nuevaData.invernaderos.includes(nave.invernaderoID))
				.map(({ id }) => id);
			nuevaData.naves = nuevaData.naves.filter((nave) =>
				navesEnInveradero.includes(nave),
			);
		}

		setFormData((currentData) => ({ ...currentData, ...nuevaData }));
		paginaActual.current = 1;
	};

	const onDelete = (id, name) => {
		let nuevaData = {
			...formData,
			[name]: formData[name].filter((busquedaID) => busquedaID !== id),
		};
		const invernaderosDisponibles = data.invernaderos
			.filter((invernadero) => nuevaData.sitios.includes(invernadero.sitioID))
			.map(({ id }) => id);
		nuevaData.invernaderos = nuevaData.invernaderos.filter((invernadero) =>
			invernaderosDisponibles.includes(invernadero),
		);

		const navesEnSitio = data.naves
			.filter((nave) => nuevaData.invernaderos.includes(nave.invernaderoID))
			.map(({ id }) => id);
		nuevaData.naves = nuevaData.naves.filter((nave) =>
			navesEnSitio.includes(nave),
		);

		if (data.secciones) {
			const navesEnSecciones = data.secciones
				.filter((seccion) => nuevaData.naves.includes(seccion.naveID))
				.map(({ id }) => id);
			nuevaData.secciones = nuevaData.secciones.filter((seccion) =>
				navesEnSecciones.includes(seccion),
			);
		}

		setFormData((currentData) => ({
			...nuevaData,
		}));

		paginaActual.current = 1;
  };

	const validarSeleccion = useCallback(() => {
		let esInvalido;
    Object.keys(formData).forEach((key) => {
			if (formData[key].length === 0 || formData[key] === '') esInvalido = true;
    });
		return esInvalido && toast.error(VALIDAR_SELECCION_REPORTE);
	}, [formData]);

	const buscar = async () => {
		try {
			if (validarSeleccion()) return;
			const { sitios, naves, semana, fecha, ...rest } = formData;
			const params = Object.keys(initEndpoints).reduce((acc, key) => {
				if (rest[key].length > 0) {
					if (rest[key].includes(0)) {
						acc[key] = data[key]
							.filter(({ id }) => id !== -1)
							.map(({ id }) => id);
					} else {
						acc[key] = rest[key];
					}
				}
				return acc;
      }, {});

			const response = await axios.post(reporteEndpoint, {
				...params,
				sitios,
				naves,
				fecha: moment(fecha).format(formatoFecha),
				registrosPorPagina: general.ELEMENTOS_POR_PAGINA,
				pagina: paginaActual.current,
			});

			dispatch(setRegistros({ rows: response.rows, count: response.count }));
		} catch {}
	};

	useEffect(() => {
		if (isMounted.current) {
			const query = queryString.parse(location.search);
			if (query.pagina === paginaActual || !query.pagina) return;
			paginaActual.current = query.pagina;
			buscar();
		}
	}, [location]);

	useEffect(() => {
		isMounted.current = true;
		consultarDatosIniciales();

		return () => {
			isMounted.current = false;
			paginaActual.current = 1;
			dispatch(setRegistros({ rows: [], count: 0 }));
		};
	}, []);

	useDebugValue('Fetch Reporte');

	return {
		data,
		formData,
		onChange,
		buscar,
		onDelete,
	};
};

useFetchReporte.propTypes = {
	/** Endpoints de consulta de filtros */
	initEndpoints: propTypes.objectOf(propTypes.string).isRequired,
	/** Endpoint principal para busqueda y exportación */
	reporteEndpoint: propTypes.string.isRequired,
};
