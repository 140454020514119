import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { toast } from 'react-toastify';
import RightDrawer from '../RightDrawer';
import HeaderSubcatalogo from '../HeaderSubcatalogo';
import Tabla from '../Table';
import TextField from '../TextField';
import { findPropertysEmpty } from '../../utilidades/functions';
import { NOMBRE_SECCION_REPETIDO } from '../../configuraciones/mensajes';
import styles from './styles';
import axios from '../../configuraciones/axios';
import { AGRUPADORES } from '../../configuraciones/endpoints';

const IrrigacionAgrupador = ({ mostrar, guardar, cancelar, agrupadores, tipoSuelo, irrigacionID }) => {
  const classes = styles();
  const cabeceros = [
    {
      label: 'Código',
      key: 'id'
    },
    {
      label: 'Nombre agrupador',
      transform: ({ nombre, repetido }, index) => (
        <TextField
          name="nombre"
          onChange={(e) => manejadorSeccion(e, index)}
          value={nombre}
          required
          error={(formErrors.nombre && (!nombre || nombre === '')) || repetido}
          inputProps={{ maxLength: 50, regexonchange: 'true', }}
          className={classes.input}
        />
      ),
    },
  ];
  const [formErrors, setFormErrors] = useState({});
  const [nuevosAgrupadores, setNuevosAgrupadores] = useState([]);

  const validarNombresRepetidos = () => {
    let todosValidos = true;
    const edicionSecciones = [...nuevosAgrupadores];
    let index = 0;
    for (const s of edicionSecciones) {
      const indexExiste = nuevosAgrupadores.findIndex(({ nombre, activo }) => nombre?.toString().trim() === s.nombre?.toString().trim() && activo && s.activo);
      s.repetido = indexExiste >= 0 && indexExiste !== index;
      if (todosValidos && s.repetido) todosValidos = false;
      index++;
    }
    setNuevosAgrupadores(edicionSecciones);
    return todosValidos;
  };

  const agregar = () => {
    const agrupador = {
      activo: true,
      nombre: `Agrupador ${nuevosAgrupadores.filter(({ activo }) => activo).length + 1}`,
      tipoSueloID: tipoSuelo.id,
      estatus: true,
    };
    if (irrigacionID) agrupador.irrigacionID = irrigacionID;
    setNuevosAgrupadores((current) => [...current, agrupador]);
  };

  const eliminar = (row) => {
    if (row.id) {
      axios['delete'](`${AGRUPADORES}/${row.id}`)
      .then(() => {
        manejadorSeccion({ target: { name: 'activo', value: false } }, row.index);
      })
      .catch((resp) => toast.error(resp))
    } else {
      const edicionSecciones = [...nuevosAgrupadores];
      const seccionesActivas = edicionSecciones.filter(({ activo }) => activo);
      const indexEliminar = edicionSecciones.indexOf(seccionesActivas[row.index]);
      edicionSecciones.splice(indexEliminar, 1);
      setNuevosAgrupadores(edicionSecciones);
    }
  }

  const manejadorSeccion = (e, index) => {
    const edicionSecciones = [...nuevosAgrupadores];
    edicionSecciones.filter(({ activo }) => activo)[index][e.target.name] = e.target.value;
    setNuevosAgrupadores(edicionSecciones);
  };

  const customGuardar = () => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmAgrupadores'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;
    const validos = validarNombresRepetidos();
    if (!validos) {
      return toast.warning(NOMBRE_SECCION_REPETIDO);
    }
    guardar(nuevosAgrupadores);
  };

  useEffect(() => {
    setNuevosAgrupadores([...agrupadores])
  }, [agrupadores, mostrar]);

  return (
    <RightDrawer
      paperClassName={classes.paperDrawer}
      isOpen={mostrar}
      header={
        <HeaderSubcatalogo
            titulo="Agrupadores de Irrigaciones"
            subtitulo={`Suelo ${tipoSuelo?.nombre || 'N/A'}`}
            guardar={customGuardar}
            cerrar={cancelar}
            agregar={agregar}
            mostrarTextField={false}
        />
      }>
      <div id="frmAgrupadores">
        <Tabla
          headers={cabeceros}
          rows={nuevosAgrupadores.filter(({ activo }) => activo)}
          hidePaginator
          onDelete={eliminar}
        />
      </div>
    </RightDrawer>
  );
};

IrrigacionAgrupador.propTypes = {
  /** Indica si el modal se muestra */
  mostrar: propTypes.bool.isRequired,
  /** Función que se ejecuta al hacer clic en guardar */
  guardar: propTypes.func.isRequired,
  /** Función que se ejecuta al hacer clic en cancelar */
  cancelar: propTypes.func.isRequired,
  /** Arreglo con la información de los agrupadores */
  agrupadores: propTypes.array.isRequired,
  /** Objeto con la información del tipo de suelo */
  tipoSuelo: propTypes.object.isRequired,
  /** Identificador de la irrigación */
  irrigacionID: propTypes.oneOfType([propTypes.string, propTypes.number]),
};

IrrigacionAgrupador.defaultProps = {
  irrigacionID: null,
};

export default IrrigacionAgrupador;
