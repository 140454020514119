import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  imagenView:{
    cursor: 'pointer',
    backgroundColor: '#2196f3',
    color: '#FFFF',
    width: 100,
    height: 25,
    fontWeight: 'bold',
    fontSize: 13,
  }
}));

export default styles;
