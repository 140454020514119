import React from 'react';
import { Grid } from '@material-ui/core';

import TableroIrrigaciones from '../TableroIrrigaciones';
import TableroFenologias from '../TableroFenologias';
import TableroPolinizaciones from '../TableroPolinizaciones';

import styles from './styles';

const TableroMonitorizacion = () => {
  const classes = styles();

  return (
    <Grid container className={classes.root}>
      <TableroPolinizaciones />
      <TableroFenologias />
      <TableroIrrigaciones />
    </Grid>
  )
}

export default React.memo(TableroMonitorizacion);
