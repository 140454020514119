import React, { useCallback, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Chart as ChartJS, Interaction } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { IoMdArrowDropup } from 'react-icons/io';
import { toast } from 'react-toastify';
import axiosIntance from 'axios';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import isEqual from 'lodash/isEqual';
import clsx from 'clsx';
import moment from 'moment';

import Agrupador from '../Agrupador';
import TarjetaClima from '../TarjetaClima';
import Typography from '../Typography';

import axios from '../../configuraciones/axios';
import endpoints from '../../configuraciones/endpoints';
import paleta from '../../configuraciones/paleta';
import general from '../../configuraciones/general';
import { CLIMA_HISTORIAL_ERROR } from '../../configuraciones/mensajes';

import { max, min, obtenerDiasDeSemana } from '../../utilidades/functions';
import { CrosshairPlugin, Interpolate } from '../../utilidades/chartjs-crosshair';

import styles from './styles';

ChartJS.register(CrosshairPlugin);
Interaction.modes.interpolate = Interpolate

const options = {
  responsive: true,
  interaction: { intersect: false, mode: 'index' },
  plugins: {
    legend: { display: false },
    datalabels: {
      color: paleta.graficos.barPrimary,
      font: { size: 14, weight: 'bold' },
      align: 'right',
    },
    tooltip: {
      enabled: false,
      mode: 'interpolate',
      intersect: false
    },
    crosshair: {
      line: {
        color: paleta.graficos.barPrimary,
        width: 2,
        dashPattern: [5, 5],
      },
      sync: {
        enabled: true,
        group: 3,
        suppresTooltips: false,
      },
      snapping: {
        enabled: true,
      }
    }
  },
  scales: { x: { display: false } },
  elements: {
    point: {
      pointHoverBorderColor: paleta.graficos.barPrimary,
      hoverRadius: 5,
    },
  }
};

const formatter = (value, context) => {
  const { dataset, active } = context;
  const index = dataset.data.findIndex(datasetValue => datasetValue === value);

  if ( !active ) return '';

  

  const label = dataset.labels[index] || '';
  return moment(label, 'HH:mm').format('HH:mm a');
};

const GraficoClima = React.memo(({ labels, data, color, onHoverData, displayDataLabels }) => {
  return (
    <Chart
      type="line"
      data={{
        labels: labels,
        datasets: [{
          label: 'Húmedad',
          data: data,
          borderColor: color,
          backgroundColor: `${color}DD`,
          labels: labels,
          hoverBackgroundColor: (context) => {
            const { index } = context;
            onHoverData(index);
            return paleta.graficos.barPrimary;
          },
          datalabels: { display: displayDataLabels, formatter },
          tension: 0.1,
        }]
      }}
      options={options}
      height={70}
    />
  )
});

GraficoClima.propTypes = {
  labels: propTypes.array.isRequired,
  data: propTypes.array.isRequired,
  color: propTypes.string.isRequired,
  onHoverData: propTypes.func.isRequired,
  displayDataLabels: propTypes.bool,
};

GraficoClima.defaultProps = {
  displayDataLabels: false,
};

const PronosticoClima = () => {
  const classes = styles();
  const { sitioID, sitioCoordenadas, semana } = useSelector(
    store => store.tablero,
    (prev, next) => 
      prev.sitioID === next.sitioID &&
      prev.semana === next.semana &&
      isEqual(prev.sitioCoordenadas, next.sitioCoordenadas)
  )

  const temperaturaRef = React.useRef(null);
  const humedadRef = React.useRef(null);

  const [mostrarGraficos, setMostrarGraficos] = useState(null);
  const [datosSemanales, setDatosSemanales] = useState({});
  const [datosClima, setDatosClima] = useState({
    temperatura: [],
    humedad: [],
    labels: [],
  });

  const obtenerPrediccionClima = useCallback(async () => {
    try {
      const { data = {} } = await axiosIntance(endpoints.openWeatherMapAPI.pronostico, {
        params: {
          lat: sitioCoordenadas[0].latitud,
          lon: sitioCoordenadas[0].longitud,
          appid: general.OPEN_WEATHER_KEY,
          exclude: 'current,hourly',
          units: 'metric',
          lang: 'es',
        },
      });
      return data.daily.map((data) => ({
        ...data,
        fecha: moment.unix(data.dt).format('YYYY-MM-DD'),
      })) || [];
    } catch (error) {
      throw error;
    }
  }, [sitioCoordenadas]);

  const obtenerHistorialClima = useCallback(async () => {
    try {
      if ( sitioID && semana ) {
        const fechaObjetivo = moment(semana, 'W (YYYY)').startOf('isoWeek').format('YYYY-MM-DD');
        const [historial, pronostico] = await Promise.all([
          axios(endpoints.obtenerHistorialClima(sitioID), { params: { fechaObjetivo } }),
          obtenerPrediccionClima(),
        ]);

        const numeroSemana = moment(semana, 'W (YYYY)').isoWeek();
        const anio = moment(semana, 'W (YYYY)').year();

        const diasDeLaSemana = obtenerDiasDeSemana(numeroSemana, anio).reduce((acc, val) => ({
          ...acc,
          [val]: [],
        }), {});

        const datosSemanales = historial
          .reduce((acc, val) => ({
          ...acc,
          [val.fechaRegistro]: [
            ...(acc[val.fechaRegistro] || []),
            val
          ],
        }), diasDeLaSemana);

        const diasSinDatos = Object.keys(diasDeLaSemana).filter(key => !datosSemanales[key].length);

        for (const fecha of diasSinDatos) {
          const data = pronostico.find(({ fecha: fechaPronostico }) => fechaPronostico === fecha);
          if ( !data ) continue;
          const { temp, humidity, weather } = data;

          datosSemanales[fecha] = {
            fecha,
            temperatura: {
              min: temp.min,
              max: temp.max,
            },
            humedad: humidity,
            estado : weather[0]?.main,
            pronostico: true,
          }
        }

        setDatosSemanales(datosSemanales);
      }
    } catch (error) {
      toast.error(CLIMA_HISTORIAL_ERROR);
    } finally {
      setDatosClima({
        temperatura: [],
        humedad: [],
        labels: [],
      });
      setMostrarGraficos(null);
    }
  }, [sitioID, obtenerPrediccionClima, semana]);

  const setHistorialClima = useCallback((fecha) => {
    setMostrarGraficos(fecha);

    const datosDelDia = datosSemanales[fecha] || [];
    if (!Array.isArray(datosDelDia) || datosDelDia.length === 0) return;

    const datos = datosDelDia.reduce((acc, { hora, temperatura, humedad }) => {
      acc.temperatura.push(temperatura);
      acc.humedad.push(humedad);
      acc.labels.push(hora);
      return acc;
    }, { temperatura: [], humedad: [], labels: [] });

    setDatosClima(datos);
  }, [datosSemanales]);

  const onHoverData = useCallback((dataIndex) => {
    if ( !temperaturaRef.current || !humedadRef.current ) return;

    temperaturaRef.current.innerHTML = datosClima.temperatura[dataIndex] || 0;
    humedadRef.current.innerHTML = datosClima.humedad[dataIndex] || 0;
  }, [datosClima]);

  useEffect(() => {
    obtenerHistorialClima();
  }, [obtenerHistorialClima]);

  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item xs={12}>

        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          {
            Object.entries(datosSemanales).map(([key, value], index) => {
              let data = {};
              if (Array.isArray(value)) {
                data = {
                  fecha: key,
                  temperatura: {
                    min: min(value.map(({temperatura}) => temperatura)),
                    max: max(value.map(({temperatura}) => temperatura)),
                  },
                  humedad: max(value.map(({humedad}) => humedad)),
                  estado: value[value.length - 1]?.estado,
                }
              } else {
                data = value;
              }

              return (
                <TarjetaClima
                  key={key}
                  fecha={key}
                  onClick={setHistorialClima}
                  temperatura={{
                    min: data.temperatura.min,
                    max: data.temperatura.max,
                  }}
                  humedad={data.humedad}
                  estado={data.estado}
                  activo={moment(moment().format('YYYY-MM-DD')).isSame(key)}
                  selected={key === mostrarGraficos}
                  disabled={moment().isSameOrBefore(key)}
                  animar
                  animationDelay={index}
                />
              )
            })
          }
        </Grid>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} className={classes.graficosClima}>
            {
              mostrarGraficos && (
                <>

                  {/* TEMPERATURA */}
                  <Agrupador customClass={clsx(classes.agrupador, classes.animar)}>
                      <Box className={classes.infoClimatica}>
                        <Typography variant="body2" className={classes.texto} style={{ color: paleta.graficos.linePrimary }}>Temperatura: <span ref={temperaturaRef}>0</span>°</Typography>
                        <Button className={classes.btnOcultar} onClick={() => setMostrarGraficos(null)}>
                          <Typography variant="body2" className={classes.texto}>Ocultar</Typography>
                          <IoMdArrowDropup size={20} color={paleta.bar.primary}/>
                        </Button>
                      </Box>
                      <GraficoClima
                        labels={datosClima.labels}
                        data={datosClima.temperatura}
                        color={paleta.graficos.linePrimary}
                        onHoverData={onHoverData}
                        displayDataLabels
                      />
                  </Agrupador>

                  {/* HÚMEDAD */}
                  <Agrupador customClass={clsx(classes.agrupador, classes.animar, classes.delay)}>
                    <Box className={classes.infoClimatica}>
                      <Typography variant="body2" className={classes.texto} style={{ color: paleta.graficos.lineSecondary, lineHeight: 3 }}>Húmedad: <span ref={humedadRef}>0</span>%</Typography>
                    </Box>
                    <GraficoClima
                      labels={datosClima.labels}
                      data={datosClima.humedad}
                      color={paleta.graficos.lineSecondary}
                      onHoverData={onHoverData}
                    />
                  </Agrupador>

                </>
              )
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(PronosticoClima);
