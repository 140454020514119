import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  btnAction: {
    margin: theme.spacing(0.5),
    textAlign: 'center',
  }
}));

export default styles;

