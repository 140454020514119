import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  label: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  paper: {
    backgroundColor: paleta.sidebar.active,
    border: `1px solid ${paleta.textField.border}`,
    padding: theme.spacing(5, 2)
  },
}));

export default styles;
