/* eslint-disable import/no-anonymous-default-export */

const SET_TOKEN = 'SET_TOKEN';
const SET_USER = 'SET_USER';
const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const STORAGE = 'scouter-app';

const user = JSON.parse(localStorage.getItem(STORAGE) || sessionStorage.getItem(STORAGE) || '{}');

const initalState = {
  isAuth: Boolean(user.usuario),
  privilegios: [],
  token: user.token || '',
  user: user.usuario || {},
};

export default (state = initalState, { type, payload }) => {
  switch (type) {
    case SET_TOKEN:
      return { ...state, token: payload, isAuth: true, };
    case SET_USER:
      return { ...state, user: payload, isAuth: true, };
    case REMOVE_TOKEN:
      return { ...state, token: '', user: {}, isAuth: false };
    default:
      return state;
  }
};

export const setToken = (payload) => (dispatch) => {
  localStorage.setItem(STORAGE, payload);
  dispatch({ type: SET_TOKEN, payload, });
};

export const setUser = (payload) => ({ type: SET_USER, payload });

export const removeToken = () => {
  localStorage.removeItem(STORAGE);
  sessionStorage.removeItem(STORAGE);
  return {
    type: REMOVE_TOKEN,
  }
}