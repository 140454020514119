import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  paper: {
    boxSizing: 'content-box',
    alignSelf: 'center',
    justifySelf: 'center',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: paleta.sidebar.backgroundColor,
    border: `1px solid ${paleta.textField.border}`,
    height: '60px',
    marginTop: 8,
    marginBottom: 8,
    borderRadius: '6px',
    width: '70%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  semana: {
    textAlign: 'center',
    width: '16.66%',
    margin: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingVertical: theme.spacing(1),
  },
  semanaText: {
    color: paleta.bar.secondary,
    fontSize: '15px',
    fontWeight: 'bold',
  },
  semanaValue: {
    color: paleta.bar.secondary,
    fontSize: '13px',
    fontWeight: 'bold',
  },
  divider: {
    backgroundColor: paleta.graficos.barPrimary,
    height: '4px',
    borderRadius: 20,
    width: '100%',
  },
  dias: {
    textAlign: 'center',
    width: '83.33%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  diasText: {
    color: '#9ea8b1',
    fontSize: '13px',
    fontWeight: 'bold',
  },
  diasValue: {
    color: paleta.bar.secondary,
    paddingLeft: '5px',
    paddingRight: '5px',
    fontSize: '11px',
    fontWeight: 'bold',
  },
  contenedorBotones: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  icono: {
    padding: 0,
  }
}));

export default styles;