import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	progressBar: {
		height: 6,
		borderRadius: 5,
	},
	colorPrimary: {
		backgroundColor:
			theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
	},
	bar: {
		borderRadius: 5,
		backgroundColor: paleta.graficos.barPrimary,
	},
	monitoreo: {
		borderRadius: 5,
		backgroundColor: paleta.configuraciones.verde,
	},
	arrow: {
		'&:before': {
			boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
		},
		color: '#000',
	},
	tooltip: {
		backgroundColor: paleta.sidebar.backgroundColor,
		color: '#000',
		borderRadius: 6,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
	},
	tooltipContainer: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'left'
	},
	tooltipContent: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'right',
		minWidth: 28,
	},
	tooltipContentIcon: {
		alignItems: 'center',
		display: 'flex',
		marginBottom: 3
	}
}));

export default styles;
