import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { NIVELESPOLINIZACION } from '../../configuraciones/endpoints';

const cabeceros = [
  { label: 'Código', key: 'id' },
  { label: 'Nombre', key: 'nombre' },
  { label: 'Estatus', transform: ({ estatus }) => <Estatus activo={estatus} /> },
];

const NivelesPolinizacion = ({ history, location }) => {

  return <Listados
    titulo="Niveles de Polinización"
    placeHolder="Búsqueda por nombre"
    history={history}
    location={location}
    showActions
    cabeceros={cabeceros || []}
    apiKey={NIVELESPOLINIZACION}
  />;
};

export default NivelesPolinizacion;