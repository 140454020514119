import moment from 'moment';

/* eslint-disable import/no-anonymous-default-export */
const SET_NAVES = 'SET_NAVES';
const SET_SITIO = 'SET_SITIO';
const SET_TEMPORADA = 'SET_TEMPORADA';
const CLEAR_TEMPORADA = 'CLEAR_TEMPORADA';

const SET_ENFERMEDAD = 'SET_ENFERMEDAD';
const CLEAR_ENFERMEDAD = 'CLEAR_ENFERMEDAD';
const SET_NAVES_DETECTADAS = 'SET_NAVES_DETECTADAS';
const CLEAR_NAVES_DETECTADAS = 'CLEAR_NAVES_DETECTADAS';

const SET_PARAMETROS = 'SET_PARAMETROS';
const SET_FECHASSEMANALES = 'SET_FECHASSEMANALES';
const SET_SEMANA = 'SET_SEMANA';
const SET_LGENDCHART = 'SET_LGENDCHART';

const SET_MONITOREO_ACTIVO = 'SET_MONITOREO_ACTIVO';
const CLEAR_MONITOREO_ACTIVO = 'CLEAR_MONITOREO_ACTIVO';

const SET_GRAFICO_MACRO_ACTIVO = 'SET_GRAFICO_MACRO_ACTIVO';
const CLEAR_GRAFICO_MACRO_ACTIVO = 'CLEAR_GRAFICO_MACRO_ACTIVO';


const initialState = {
  // Detalles del sitio
  sitioID: '',
  sitioOrigenID: '',
  sitioCoordenadas: [],
  temporada: null,
  navesEnSitio: [],
  enfermedad: { id: '', nombre: '', esTrampa: false },
  parametros: { matutinos: [], vespertinos: [] },
  fecha: '',
  nombre: '',
  semana: moment().format('W (GGGG)'),
  naves: [],
  navesDetectadas: null,
  
  // Interfaz
  legend: {
    id: '',
  },
  monitoreoActivo: {
    polinizacion: false,
    fenologia: false,
    irrigacion: false,
    id: null,
  },

  // Graficos
  graficoMacroActivo: 'DETECCIONES',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_NAVES:
      return { ...state, naves: payload };
    case SET_SITIO:
      return {
        ...state,
        sitioID: payload.id,
        sitioCoordenadas: payload.coordenadas,
        navesEnSitio: payload.navesEnSitio,
        ...(payload.sitioOrigenID ? { sitioOrigenID: payload.sitioOrigenID } : {})
      };
    case SET_ENFERMEDAD:
      return {
        ...state,
        enfermedad: {
          ...state.enfermedad,
          ...payload,
        },
      };
    case CLEAR_ENFERMEDAD:
      return {
        ...state,
        enfermedad: { id: '', esTrampa: false },
      };
    case SET_SEMANA:
      return { ...state, semana: payload };
    case SET_LGENDCHART:
      return { ...state, legend: payload };
    case SET_PARAMETROS:
      return { ...state, parametros: payload };
    case SET_FECHASSEMANALES:
      return { ...state, fecha: payload };
    case SET_NAVES_DETECTADAS:
      return {
        ...state,
        navesDetectadas: payload,
      };
    case CLEAR_NAVES_DETECTADAS:
      return {
        ...state,
        navesDetectadas: null,
      };
    case SET_MONITOREO_ACTIVO:
      return {
        ...state,
        monitoreoActivo: {
          id: null,
          polinizacion: false,
          fenologia: false,
          irrigacion: false,
          ...payload,
        },
      };
    case CLEAR_MONITOREO_ACTIVO:
      return {
        ...state,
        monitoreoActivo: initialState.monitoreoActivo,
      };
    case SET_TEMPORADA:
      return {
        ...state,
        temporada: payload,
        semana: moment(payload.fechaFin).isSameOrBefore(moment())
          ? moment(payload.fechaFin).format('W (GGGG)')
          : moment().format('W (GGGG)'),
      };
    case CLEAR_TEMPORADA:
      return { ...state, temporada: null };
    case SET_GRAFICO_MACRO_ACTIVO:
      return {
        ...state,
        graficoMacroActivo: payload,
      };
    case CLEAR_GRAFICO_MACRO_ACTIVO:
      return {
        ...state,
        graficoMacroActivo: initialState.graficoMacroActivo,
      };
    default:
      return state;
  }
};

export const setNaves = (payload) => ({ type: SET_NAVES, payload });
export const setSitio = (payload) => ({ type: SET_SITIO, payload });
export const setTemporada = (payload) => ({ type: SET_TEMPORADA, payload });
export const clearTemporada = (payload) => ({ type: CLEAR_TEMPORADA });

export const setPlagaEnfermedadID = (payload) => ({ type: SET_ENFERMEDAD, payload });
export const clearEnfermedad = (payload) => ({ type: CLEAR_ENFERMEDAD });
export const setNavesDetectadas = (payload) => ({ type: SET_NAVES_DETECTADAS, payload });
export const clearNavesDetectadas = (payload) => ({ type: CLEAR_NAVES_DETECTADAS, payload });

export const setParametros = (payload) => ({ type: SET_PARAMETROS, payload });
export const setFecha = (payload) => ({ type: SET_FECHASSEMANALES, payload });
export const setSemanaTablero = (payload) => ({ type: SET_SEMANA, payload });
export const setLegend = (payload) => ({ type: SET_LGENDCHART, payload });
export const setMonitoreoActivo = (payload) => ({ type: SET_MONITOREO_ACTIVO, payload });
export const clearMonitoreoActivo = () => ({ type: CLEAR_MONITOREO_ACTIVO });

export const setGraficoMacroActivo = (payload) => ({ type: SET_GRAFICO_MACRO_ACTIVO, payload });
export const clearGraficoMacroActivo = () => ({ type: CLEAR_GRAFICO_MACRO_ACTIVO });