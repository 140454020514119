import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { es } from 'date-fns/locale';
import { format, isSameDay } from 'date-fns';
import { KeyboardDatePicker, Day } from '@material-ui/pickers';
import { MdOutlineTrendingFlat } from 'react-icons/md';
import { useTheme } from '@material-ui/core';

import Typography from '../Typography';

import styles from './styles';

const RangeToolbar = React.memo(({ fechas, setOpenView }) => {
  const classes = styles();

  const contenedorRef = useRef(null);

  const cambiarVista = useCallback((vista) => () => {
    setOpenView(vista);
  }, [setOpenView]);

  return (
    <Box className={classes.toolbar} maxWidth="100%" position="relative" ref={contenedorRef}>
      <Box paddingTop={2} paddingX={1} width={310} display="flex" alignItems="center" justifyContent="center" gridGap={5}>
        <Typography className={classes.toolbarTitle}>
          Fecha:
        </Typography>
        <Button
          variant="text"
          onClick={cambiarVista('month')}
          className={classes.toolbarButton}
        >
          <Typography className={classes.toolbarTitle}>
            {new Date().toLocaleString('es', { month: 'long' })}
          </Typography>
        </Button>
        <Button
          variant="text"
          className={classes.toolbarButton}
          onClick={cambiarVista('year')}
        >
          <Typography className={classes.toolbarTitle}>
            {new Date().toLocaleString('es', { year: 'numeric' })}
          </Typography>
        </Button>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" padding={1} gridGap={12}>
        <Typography className={classes.toolbarDateText}>
          Desde
          <br />
          {
            fechas?.inicio
              ? format(fechas.inicio, 'EEE, d MMM yyyy', { locale: es })
              : '. . .'
          }
        </Typography>
        <MdOutlineTrendingFlat color="#FFF" size={24} />
        <Typography className={classes.toolbarDateText}>
          Hasta
          <br />
          {
            fechas?.fin
              ? format(fechas.fin, 'EEE, d MMM yyyy', { locale: es })
              : '. . .'
          }
        </Typography>
      </Box>
    </Box>
  );
});

export const DatePickerRange = ({
  value,
  label,
  className,
  emptyMessage,
  disabled,
  onChange,
}) => {
  const classes = styles();
  const tema = useTheme();

  const ordenRef = useRef('inicio');

  const [open, setOpen] = useState(false);
  const [fechas, setFechas] = useState({
    inicio: value.inicio,
    fin: value.fin,
  });

  const inputValue = useMemo(() => {
    let texto = '';
    if (fechas.inicio) {
      texto += format(fechas.inicio, 'dd/MM/yyyy', { locale: es });
    }
    if (fechas.inicio && fechas.fin) {
      texto += ' - ';
    }
    if (fechas.fin) {
      texto += format(fechas.fin, 'dd/MM/yyyy', { locale: es });
    }
    return texto;
  }, [fechas.fin, fechas.inicio]);

  const abrirDatePicker = useCallback(() => {
    setOpen(true);
  }, []);

  const cerrarDatePicker = useCallback(() => {
    setOpen((prev) => prev && false);
    onChange(fechas);
  }, [fechas, onChange]);

  const onCambiarSeleccionarFecha = useCallback((date) => {
    setFechas((prev) => {
      const { inicio, fin } = prev;
      const currentOrder = ordenRef.current;

      // Resetear la selección si la fecha seleccionada es igual a inicio o fin.
      if (isSameDay(date, inicio)) {
        return { inicio: null, fin };
      }
      if (isSameDay(date, fin)) {
        return { inicio, fin: null };
      }

      // Si ninguna fecha está seleccionada, establecer la fecha seleccionada como inicio.
      if (!inicio && !fin) {
        ordenRef.current = 'fin';
        return { inicio: date, fin: null };
      }

      // Si solo inicio está seleccionado, definir la lógica de selección de fin.
      if (inicio && !fin) {
        if (date < inicio) {
          // Si la fecha seleccionada es menor que inicio, reasignarla a inicio.
          return { inicio: date, fin: inicio };
        }
        // Si la fecha seleccionada es mayor o igual a inicio, establecerla como fin.
        ordenRef.current = 'inicio';
        return { inicio, fin: date };
      }

      // Si solo fin está seleccionado, definir la lógica de selección de inicio.
      if (!inicio && fin) {
        if (date > fin) {
          // Si la fecha seleccionada es mayor que fin, reasignarla a fin.
          return { inicio: fin, fin: date };
        }
        // Si la fecha seleccionada es menor o igual a fin, establecerla como inicio.
        ordenRef.current = 'fin';
        return { inicio: date, fin };
      }

      // Intercambiar las fechas si están fuera de orden.
      if (currentOrder === 'inicio') {
        if (date < inicio) {
          return { inicio: date, fin };
        }
        return { inicio, fin: date };
      } else {
        if (date > fin) {
          return { inicio, fin: date };
        }
        return { inicio: date, fin };
      }
    });

    // Alternar el orden de selección.
    ordenRef.current = ordenRef.current === 'inicio' ? 'fin' : 'inicio';
  }, []);

  useEffect(() => {
    setFechas(value);
  }, [value]);

  return (
    <Box className={className}>
      {label && (
        <Typography className={classes.label}>
          {label}
        </Typography>
      )}
      <KeyboardDatePicker
        disabled={disabled}
        open={open}
        variant="inline"
        fullWidth
        onChange={onCambiarSeleccionarFecha}
        onClick={abrirDatePicker}
        onClose={cerrarDatePicker}
        views={['year', 'month', 'date']}
        ToolbarComponent={(props) => (
          <RangeToolbar {...props} fechas={fechas} />
        )}
        InputProps={{
          disableUnderline: true,
          disabled: true,
          value: inputValue,
          classes: {
            root: classes.input,
            input: classes.nativeInput,
          },
          placeholder: emptyMessage,
        }}
        KeyboardButtonProps={{
          'aria-label': 'Rango de fechas',
        }}
        renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
          const estaDentroDelRango = (fechas.inicio && fechas.fin) && (day > fechas.inicio && day < fechas.fin);
          const esDiaInicio = isSameDay(day, fechas.inicio);
          const esDiaFin = isSameDay(day, fechas.fin);

          const estilos = {};

          if (estaDentroDelRango) {
            estilos.backgroundColor = tema.palette.primary.main + '20';
            estilos.color = '#000';
            estilos.borderTop = `1px dashed ${tema.palette.primary.main}`;
            estilos.borderBottom = `1px dashed ${tema.palette.primary.main}`;
            estilos.borderTopLeftRadius = 0;
            estilos.borderBottomLeftRadius = 0;
            estilos.borderTopRightRadius = 0;
            estilos.borderBottomRightRadius = 0;
          }

          if (esDiaInicio || esDiaFin) {
            estilos.backgroundColor = tema.palette.primary.main;
            estilos.color = '#FFF';
            estilos.borderTopLeftRadius = esDiaInicio ? 5 : 0;
            estilos.borderBottomLeftRadius = esDiaInicio ? 5 : 0;
            estilos.borderTopRightRadius = esDiaFin ? 5 : 0;
            estilos.borderBottomRightRadius = esDiaFin ? 5 : 0;
          }

          return (
            <Day
              {...dayComponent.props}
              selected={false}
              style={{
                width: 40,
                height: 40,
                margin: '2px 0',
                ...estilos,
              }}
            />
          );
        }}
        PopoverProps={{
          classes: {
            paper: classes.datePickerPaper,
          }
        }}
      />
    </Box>
  );
};

DatePickerRange.propTypes = {
  /** Valor del input */
  value: PropTypes.shape({
    inicio: PropTypes.instanceOf(Date),
    fin: PropTypes.instanceOf(Date),
  }),
  /** Label del input */
  label: PropTypes.string,
  /** Clase del input */
  className: PropTypes.string,
  /** Mensaje a mostrar si no hay fechas */
  emptyMessage: PropTypes.string,
  /** Deshabilitar el input */
  disabled: PropTypes.bool,
  /** Función a ejecutar al cambiar el valor */
  onChange: PropTypes.func,
};

DatePickerRange.defaultProps = {
  value: {
    inicio: null,
    fin: null,
  },
  label: '',
  className: '',
  emptyMessage: '',
  disabled: false,
  onChange: () => {},
};

export default React.memo(DatePickerRange);
