import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    marginInline: 5,
    backgroundColor: paleta.sidebar.active,
    border: `1px solid ${paleta.textField.border}`,
    padding: theme.spacing(1, 1),
    borderRadius: '7px',
  },
  titleContainer: {
    marginTop: 5,
  },
  title: {
    marginLeft: 6,
    fontSize: 14,
    color: '#0F0E0E',
    fontWeight: 600,
    width: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  subTitle: {
    marginLeft: 6,
    fontWeight: 600,
    fontSize: 11,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  detail: {
    borderRadius: 5,
    paddingBottom: 6,
    paddingTop: 1,
    textAlign: 'left',
    backgroundColor: paleta.bar.details,
    border: `0px solid ${paleta.bar.border}`,
  },
  divider: {
    width: '2px',
    marginRight: 5,
    marginLeft: 5,
    paddingTop: 5,
    borderWidth: 1,
    height: '40px',
    borderStyle: 'dashed',
    borderColor: paleta.bar.point,
    backgroundColor:paleta.bar.details,
    [theme.breakpoints.down('700')]: {
      width: '0px',
      marginLeft: 7,
      paddingTop: 5,
      borderColor: paleta.bar.details,
      backgroundColor:paleta.bar.details,
    }
  },
  table: {
    borderRadius: 5,
    paddingTop: 1,
    marginBottom: 10,
    paddingBottom:10,
    textAlign: 'left',
    backgroundColor: paleta.bar.details,
    border: `0px solid ${paleta.bar.border}`,
  },
  detailTable: {
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 3,
    paddingTop: 1,
    marginTop: 16,
    textAlign: 'left',
    backgroundColor: paleta.bar.details,
    border: `1px solid #C8CBCD`,
  },
  grafica: {
    textAlign: 'left',
    width: '90%',
    backgroundColor: paleta.bar.details,
    border: `0px solid ${paleta.bar.point}`,
  },
  titleTableContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleDetail: {
    display: 'flex',
  },
  titleTable: {
    marginLeft: 10,
    marginTop: 16,
    fontSize: 15,
    color: '#05314E',
    fontWeight: 'bolder',
    '& .subtitle': {
    },
  },
  chip:{
    backgroundColor: '#49B1F2',
    color: '#FFFF',
    fontWeight: 700,
  },
  contenedor:{
    backgroundColor: '#FFFF',
    width: '100%',
    height: 300,
    padding:8,
    marginTop: 15,
  },
  dividerList: {
    border: '1px',
    borderStyle: 'dashed',
    borderColor: '#E8EBEC',
    backgroundColor: '#FFFF',
  },
  mapa: {
    height: 615,
    padding: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2),
    }
  },
}));

export default styles;
