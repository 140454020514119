import React from 'react';
import propTypes from 'prop-types';
import { Tooltip, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { GiInfo } from 'react-icons/gi';
import Typography from '../Typography';
import { regexValid } from '../../utilidades/regex';
import styles from './styles';

const TextField = ({
  label, value, name, type, onChange, isHandleChange,
  inputProps, required, error, tooltip, rows, helperText,
  placeHolder, className, onKeyDown, disabled,
  inputClassName, inputStyles, simbolo, onClick, onFocus
}) => {
  const classes = styles();
  const customOnChange = (e) => {
    if (regexValid(e) && onChange)
      if (isHandleChange)
        onChange((current) => ({ ...current, [name]: e.target.value }));
      else
        onChange(e);
  };

  return (
    <div className={clsx(classes.root, className)}>
      {label && (
        <Typography className={classes.label}>
          {label}
          {required && ' * '}
          {tooltip !== '' && (
            <Tooltip title={<Typography style={{ fontSize: 14 }}>{tooltip}</Typography>}>
              <IconButton size="small">
                <GiInfo />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      )}
      {type !== 'textarea' ?
        <div>
          <input
            className={clsx(classes.input, inputClassName, { [classes.error]: error, disabled })}
            style={inputStyles}
            value={value || ''}
            name={name}
            type={type}
            onChange={customOnChange}
            required={required}
            placeholder={placeHolder}
            onKeyDown={onKeyDown}
            onClick={onClick}
            onFocus={onFocus}
            disabled={disabled}
            {...inputProps}
          />
            {simbolo}
        </div>
        : <textarea
          className={clsx(classes.input, inputClassName, { [classes.error]: error, disabled })}
          value={value || ''}
          name={name}
          type={type}
          rows={rows}
          onChange={customOnChange}
          required={required}
          placeholder={placeHolder}
          onKeyDown={onKeyDown}
          onClick={onClick}
          onFocus={onFocus}
          disabled={disabled}
          style={{
            ...inputStyles,
            resize: 'none',
            padding: 5,
          }}
          {...inputProps}
        />}
      {helperText && helperText !== '' && <Typography
        className={clsx(classes.helperText, { error })}
        component="p">
        {helperText}
      </Typography>}
    </div>
  );
};

TextField.propTypes = {
  label: propTypes.string,
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),
  name: propTypes.string.isRequired,
  type: propTypes.string,
  onChange: propTypes.func,
  isHandleChange: propTypes.bool,
  inputProps: propTypes.object,
  required: propTypes.bool,
  error: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  tooltip: propTypes.string,
  rows: propTypes.number,
  helperText: propTypes.string,
  placeHolder: propTypes.string,
  className: propTypes.string,
  onKeyDown: propTypes.func,
  disabled: propTypes.bool,
  inputClassName: propTypes.string,
  inputStyles: propTypes.object,
};

TextField.defaultProps = {
  label: '',
  value: '',
  type: 'text',
  isHandleChange: false,
  inputProps: {},
  required: false,
  error: false,
  tooltip: '',
  rows: 1,
  helperText: '',
  placeHolder: '',
  className: '',
  onKeyDown: null,
  disabled: false,
  style: {}
};

export default React.memo(TextField, (prev, next) => {
  return prev.value === next.value &&
    prev.error === next.error &&
    prev.helperText === next.helperText &&
    prev.disabled === next.disabled &&
    prev.tooltip === next.tooltip &&
    prev.onChange === next.onChange;
});
