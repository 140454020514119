import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { NIVELESINFECCION } from '../../configuraciones/endpoints';

const cabeceros = [
  { label: 'Código', key: 'id' },
  { label: 'Nivel', key: 'nombre' },
  { label: 'Estatus', transform: ({ estatus }) => <Estatus activo={estatus} /> },
];

const NivelesInfeccion = ({ history, location }) => {

  return <Listados
    titulo="Niveles de Infección"
    placeHolder="Búsqueda por nombre"
    history={history}
    location={location}
    showActions
    cabeceros={cabeceros || []}
    apiKey={NIVELESINFECCION}
  />;
};

export default NivelesInfeccion;