import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Provider } from 'react-redux';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Spinner from './componentes/Spinner';
import Routes from './configuraciones/rutas';
import TemaDefault from './temas/default';
import store from './ducks';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={TemaDefault}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <CssBaseline />
          <ToastContainer />
          <Routes />
          <Spinner />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
