import React, { useCallback, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import moment from 'moment';
import { IoMdArrowDropright, IoMdArrowDropleft } from 'react-icons/io';

import MultiPorgressBar from '../MultiProgressBar';
import Typography from '../Typography';

import styles from './styles';
import paleta from '../../configuraciones/paleta';

const Tarjeta = ({ data, onClick, activo, onSemanaChange, temporada }) => {
  const classes = styles();
  const [fecha, setFecha] = useState(moment().isoWeek(data.semana).format('YYYY-MM-DD'));

  const onClickNext = useCallback((e, previous) => {;
    e.stopPropagation();

    const fechaObjetivo = moment(fecha).add(previous ? -1 : 1, 'week');

    setFecha(fechaObjetivo.format('YYYY-MM-DD'));
    onSemanaChange(fechaObjetivo.format('YYYY-MM-DD'));
  }, [fecha, onSemanaChange]);

  useEffect(() => {
    if ( !activo ) {
      setFecha(moment(data.semana).format('YYYY-MM-DD'));
    }
  }, [onSemanaChange, activo, data.semana]);

  useEffect(() => {
    data.semana && setFecha(data.semana);
  }, [data.semana]);

  return (
    <Paper
      className={clsx(classes.root, { active: activo })}
      elevation={3}
      onClick={() => onClick(data, activo)}
    >
      <Grid container spacing={0}>

        {/* Información textual */}
        <Grid item className={classes.textContainer}>
          {/* Nombre Actividad */}
          <Grid className={classes.titleContainer}>
            <Typography className={clsx(classes.title, { active: activo })} bold>
              { data.title }
            </Typography>
            <Box className={classes.semanaContainer}>
              { (temporada && activo) && (
                  <IconButton
                    onClick={(e) => onClickNext(e, true)}
                    className={classes.btnChange}
                    disabled={
                      moment(fecha).isBetween(
                        moment(temporada.fechaInicio).startOf('isoWeek'),
                        moment(temporada.fechaInicio).endOf('isoWeek')
                      )
                    }
                  >
                    <IoMdArrowDropleft />
                  </IconButton>
                )
              }
              <Box display="flex" flexDirection="column">
                <Typography className={clsx(classes.subtitle, { active: activo })}>
                  Sem { moment(fecha).format('WW') }
                </Typography>
                <Typography className={clsx(classes.auxtext, { active: activo })}>
                  { moment(fecha).format('Y').toUpperCase() }
                </Typography>
              </Box>
              { (temporada && activo) && (
                  <IconButton
                    onClick={onClickNext}
                    className={classes.btnChange}
                    disabled={
                      moment(fecha).isBetween(
                        moment().startOf('isoWeek'),
                        moment().endOf('isoWeek')
                      )
                      ||
                      moment(fecha).isBetween(
                        moment(temporada.fechaFin).startOf('isoWeek'),
                        moment(temporada.fechaFin).endOf('isoWeek')
                      )
                    }
                  >
                    <IoMdArrowDropright />
                  </IconButton>
                )
              }
            </Box>
          </Grid>

          {/* Detalles de la actividad */}
          <Grid>
            {
              (data?.progreso && data.progreso.length > 0) ? (
                <Grid className={classes.infoContainer}>
                  {
                    data.progreso?.map(item => (
                      <Typography
                        key={item.id}
                        variant='subtitle2'
                        className={clsx(classes.text, classes.mainInfo, { active: activo })}
                        style={{ color: paleta.configuraciones[item.color] }}
                        bold
                      >
                        { item.valor }
                      </Typography>
                    ))
                  }
                </Grid>
              ) : (
                <Typography
                  component="p"
                  className={clsx(classes.text, classes.noData, { active: activo })}
                  bold
                >
                  Sin datos recientes
                </Typography>
              )
            }
            {
              data.extraInfo ? (
                <Typography
                  component="p"
                  className={clsx(classes.text, classes.subInfo, { active: activo })}
                  bold
                >
                  { data.extraInfo }
                </Typography>
              ) : (
                <br />
              )
            }
          </Grid>
        </Grid>

        {/* Información gráfica */}
        {
          (data?.progreso && data.progreso.length > 0) && (
            <Grid item className={classes.progressContainer}>
                <MultiPorgressBar data={data.progreso} />
            </Grid>
          )
        }
      </Grid>
    </Paper>
  )
};

Tarjeta.propTypes = {
  /** Datos del componente */
  data: propTypes.shape({
    /** Titulo de la tarjeta */
    title: propTypes.string.isRequired,
    /** Semana de la tarjeta */
    semana: propTypes.string.isRequired,
    /** Informacion extra de la tarjeta */
    extraInfo: propTypes.string,
    /** Información del porcentaje */
    progreso: propTypes.arrayOf(propTypes.shape({
      /** Id del elemento */
      id: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
      /** Porcentaje del elemento */
      valor: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
      /** Color del elemento */
      color: propTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  /** Función que se ejecuta al hacer click en la tarjeta */
  onClick: propTypes.func.isRequired,
  /** Indica si la tarjeta esta seleccionada */
  activo: propTypes.bool,
  /** Función para cambiar de semana */
  onSemanaChange: propTypes.func,
  /** Temporada */
  temporada: propTypes.shape({
    /** Id de la temporada */
    id: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
    /** Fecha de inicio de la temporada */
    fechaInicio: propTypes.string.isRequired,
    /** Fecha de fin de la temporada */
    fechaFin: propTypes.string.isRequired,
  }),
};

Tarjeta.defaultProps = {
  activo: false,
  onSemanaChange: () => {},
  temporada: null,
  semana: null,
};

export default Tarjeta;
