import React from 'react';
import propTypes from 'prop-types';
import { Drawer, Grid, } from '@material-ui/core';
import clsx from 'clsx';
import styles from './styles';

const RightDrawer = ({
  isOpen, children, header, paperClassName,
}) => {
  const classes = styles();
  return (
    <Drawer
      open={isOpen}
      anchor="right"
      hideBackdrop
      keepMounted={false}
      classes={{
        paper: clsx(classes.paper, paperClassName)
      }}>
      <div style={{ flex: 3 }}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            {header}
          </Grid>
          <Grid item xs={12} className={classes.childrenContainer}>
            {children}
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
};

RightDrawer.propTypes = {
  /** Indica si el drawer esta abierto */
  isOpen: propTypes.bool,
  /** Elemento que mostrara dentro del drawer */
  children: propTypes.oneOfType([propTypes.element, propTypes.arrayOf(propTypes.element), propTypes.node]).isRequired,
  /** Clase para paper principal */
  paperClassName: propTypes.string,
  header: propTypes.element.isRequired,
};

RightDrawer.defaultProps = {
  isOpen: false,
  paperClassName: '',
};

export default RightDrawer;
