import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  input: {
    aspectRatio: '14/8',
    borderRadius: 8,
    color: paleta.configuraciones.texto,
    fontSize: '11px',
    marginTop: 0,
    minHeight: 10,
    textAlign: 'center',
    width: 51,
    '& :hover': {
      backgroundColor: '#fff'
    }
  },
  guion: {
    fontSize: 12,
    padding: 6,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold'
  }
}));

export default styles;
