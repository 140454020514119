import { GEOLOCALIZADOS } from "../constantes/tipoMarcadorPYE";

/* eslint-disable import/no-anonymous-default-export */
const SET_DETALLES = 'SET_DETALLES';
const CLEAR_DETALLES = 'CLEAR_DETALLES';
const SET_UBICACION_DETECCIONES = 'SET_UBICACION_DETECCIONES';
const SET_MODAL_OPEN = 'SET_MODAL_OPEN';
const SET_MODAL_CLOSE = 'SET_MODAL_CLOSE';
const SET_EVIDENCIAS = 'SET_EVIDENCIAS';
const CLEAR_EVIDENCIAS = 'CLEAR_EVIDENCIAS';
const SET_TIPO_MARCADOR_PYE = 'SET_TIPO_MARCADOR_PYE';

const initialState = {
  // Propiedades de la Nave
  nombre: '',
  invernadero: { nombre: '' },
  hectareas: '',
  // Propiedades del listado de Imagenes (Evidencias)
  isModalOpen: false,
  evidencia: [],
  // Propiedades para el mapa
  centro: null,
  marcador: [],
  ubicacion: [],
  ubicacionDetecciones: [],
  tipoMarcadorPYE: GEOLOCALIZADOS,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DETALLES:
      return {
        ...state,
        ...payload
      };
    case CLEAR_DETALLES:
      return {
        ...initialState
      };
    case SET_UBICACION_DETECCIONES:
      return {
        ...state,
        ubicacionDetecciones: payload
      };
    case SET_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: true
      };
    case SET_MODAL_CLOSE:
      return {
        ...state,
        isModalOpen: false
      };
    case SET_EVIDENCIAS:
      return {
        ...state,
        evidencia: payload
      };
    case CLEAR_EVIDENCIAS:
      return {
        ...state,
        evidencia: []
      };
    case SET_TIPO_MARCADOR_PYE:
      return {
        ...state,
        tipoMarcadorPYE: payload
      };
    default:
      return state;
  }
};

export const setDetallesNave = (payload) => ({ type: SET_DETALLES, payload });
export const clearDetallesNave = () => ({ type: CLEAR_DETALLES });
export const setUbicacionDetecciones = (payload) => ({ type: SET_UBICACION_DETECCIONES, payload });
export const setModalOpen = () => ({ type: SET_MODAL_OPEN });
export const setModalClose = () => ({ type: SET_MODAL_CLOSE });
export const setEvidencias = (payload) => ({ type: SET_EVIDENCIAS, payload });
export const clearEvidencias = () => ({ type: CLEAR_EVIDENCIAS });
export const setTipoMarcadorPYE = (payload) => ({ type: SET_TIPO_MARCADOR_PYE, payload });