import { InputBase, ListSubheader, makeStyles, MenuItem, Select, withStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';
import React from 'react';
import Typography from '../Typography';
import clsx from 'clsx';

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: `${paleta.bar.primary}`,
  },
  toolbarContainer: {
    display: 'flex',
  },
  btnSalirContainer: {
    flex: '1',
    display: 'inline-grid',
  },
  btnSalir: {
    fontSize: 13,
    borderRadius: 5,
    color: paleta.bar.primary,
    width: 120,
    minWidth: 120,
    transition: 'none',

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '&:hover > span': {
      textDecoration: 'underline',
    }
  },
  logo: {
    aspectRatio: '1/1',
    height: 22,
  },
  label: {
    marginTop: 3,
    fontFamily: 'Designer',
    color: paleta.textField.backgroundColor,
    fontSize: 24,
  },
  input:{
    marginTop: -6,
    fontSize: 13,
    fontWeight: 'bold',
    minHeight: 42,
    height: 42,
    color: '#FFFF',
    outline: 'none',
    width: '100%',
    padding: '0 10px',
    '& fieldset': {
      border: `0px solid ${paleta.textField.border} !important`,
      color: '#FFFF',
    },
    '&:hover, &:focus': {
      backgroundColor: paleta.bar.primary,
      '& fieldset': {
        border: `0px solid ${paleta.textField.border} !important`,
      },
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `12px solid ${paleta.textField.border}`,
    },
    '& .MuiSelect-root': {
      minHeight: 40,
      height: 40,
      marginTop: 4,
      padding: '1px !important',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    }
  },
  divider: {
    alignSelf: 'center',
    backgroundColor: paleta.bar.iconoBorder,
    display: 'flex',
    height: 20,
  },
  textoBlanco: {
    color: '#FFF',
    backgroundColor: 'transparent',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: 13.5,
    fontWeight: 'bold',
    boxSizing: 'border-box',

    '&.empty': {
      fontWeight: 'normal',
    },

    '&.disabled': {
      color: 'transparent',
    },

    transition: theme.transitions.create(['color'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
  },
  comboBox: {
    width: 200,
  },
  comboBoxIcon: {
    color: 'white',
    boxSizing: 'border-box',
    marginTop: 2,
    marginRight: 8,
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
  },
  menuList: {
    maxHeight: 300,
  },
  menuItem: {
    fontSize: 13,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  menuSubheader: {
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: '34px',
    pointerEvents: 'none',
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderLeft: '1px solid #FFFF',
    minWidth: 125,
    maxWidth: 170,
    color: '#FFFF',
    position: 'relative',
    backgroundColor: 'transparent',
    padding: '8px 24px 4px 12px',
    paddingRight: '34px !important',
    margin: '6px 0px 6px 0px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.100)',
    },
  },
}))(InputBase);

export const ComboBox = React.memo(({
  disabled = false,
  grupoKey,
  name = '',
  onChange = () => { },
  opciones = [],
  placeholder = 'Seleccione una opción',
  valor = '',
  valueProp = 'id'
}) => {
  const classes = styles();
  return (
    <Select
      classes={{
        icon: classes.comboBoxIcon,
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null,
        classes: {
          list: classes.menuList,
          paper: classes.comboBox
        },
      }}
      disabled={disabled}
      displayEmpty
      input={<BootstrapInput />}
      name={name}
      onChange={onChange}
      value={valor}
      renderValue={(value) => {
        const opcion = grupoKey
          ? opciones.flatMap(({[grupoKey]: elementos}) => elementos).find(({ [valueProp]: id }) => id === value)
          : opciones.find(({ [valueProp]: id }) => id === value);
        return (
          <Typography className={clsx(classes.textoBlanco, { empty: !opcion, disabled })}>
            {opcion?.nombre || placeholder}
          </Typography>
        );
      }}
    >
      {opciones.length === 0 && (
        <MenuItem value="" disabled>
          <Typography className={classes.menuItem}>
            <Typography component="span">
              No hay opciones
            </Typography>
          </Typography>
        </MenuItem>
      )}
      {!grupoKey && opciones.map((opcion, index) => (
        <MenuItem key={index} value={opcion[valueProp]}>
          <Typography className={classes.menuItem}>
            <Typography component="b">
              {index + 1}&nbsp; - &nbsp;
            </Typography>
            <Typography component="span">
              {opcion.nombre}
            </Typography>
          </Typography>
        </MenuItem>
      ))}
      {grupoKey && opciones.flatMap((opcion) => {
        const elementos = opcion[grupoKey] || [];
        return [
          <ListSubheader
            key={'grupo_' + opcion.id}
            disableSticky
            id={`group_${opcion.id}`}
            className={clsx(classes.menuSubheader, classes.menuItem)}
          >
            <Typography component="b">
              {opcion.nombre}
            </Typography>
          </ListSubheader>,
          ...elementos.map((elemento, index) => (
            <MenuItem key={`nave_` + elemento[valueProp]} value={elemento[valueProp]}>
              <Typography className={classes.menuItem}>
                <Typography component="b">
                  {index + 1}&nbsp; - &nbsp;
                </Typography>
                <Typography component="span">
                  {elemento.nombre}
                </Typography>
              </Typography>
            </MenuItem>
          ))
        ];
      })}
    </Select>
  );
});

export default styles;
