import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  permisosContainer: {
    marginTop: 10,
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: -5
  },
  tablaPermisosSitios: {
    marginTop: 10,
  }
}));

export default styles;
