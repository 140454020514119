import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';

import CustomSelect from '../../../componentes/Select';
import Dialog from '../../../componentes/CustomDialog';
import FormDivisor from '../components/FormDivisor';
import Typography from '../../../componentes/Typography';

import {
  actualizarSitioOrigen,
  eliminarSitio,
  enumPasos,
  guardarDivisionesSitio,
} from '../../../ducks/temporada';

import styles from '../styles';
import { useParams } from 'react-router-dom';
import TextField from '../../../componentes/TextField';

const formSitioSelector = (state) => state.temporada.form[enumPasos.SITIOS];
const dataSitioSelector = (state) => state.temporada.data.sitios;

const ConfiguracionSitio = () => {
  const classes = styles();

  const dispatch = useDispatch();
  const { id: esEdicion } = useParams();

  const errores = useSelector(state => formSitioSelector(state).errores, shallowEqual);
  const sitiosDB = useSelector((state) => Object.values(state.temporada.data.sitios.byId), shallowEqual);
  const sitiosDivisiones = useSelector((state) => {
    const { sitioOrigenId, byId } = formSitioSelector(state).data;
    const { [sitioOrigenId]: _, ...divisiones } = byId;
    return divisiones;
  }, shallowEqual);
  const sitioOrigen = useSelector(state => {
    const { sitioOrigenId } = formSitioSelector(state).data;
    const byId = dataSitioSelector(state).byId;

    if (!sitioOrigenId) return { id: '', nombre: '', invernaderos: [] };

    const sitio = byId[sitioOrigenId];

    return {
      id: sitioOrigenId,
      nombre: sitio.nombre,
      invernaderos: sitio.invernaderos,
    }
  }, shallowEqual);

  const [mostrarConfirmar, setMostrarConfirmar] = useState(0);

  const onChange = useCallback((e) => {
    const { value } = e.target;

    if (sitioOrigen.id) {
      setMostrarConfirmar(value);
      return;
    }

    dispatch(actualizarSitioOrigen(value));
  }, [dispatch, sitioOrigen.id]);

  const onAceptarCambioSitio = useCallback(() => {
    dispatch(actualizarSitioOrigen(mostrarConfirmar));
    setMostrarConfirmar(0);
  }, [dispatch, mostrarConfirmar]);

  const onCancelarCambioSitio = useCallback(() => {
    setMostrarConfirmar(0);
  }, []);

  const guardarDivision = useCallback((divisiones) => {
    dispatch(guardarDivisionesSitio(divisiones));;
  }, [dispatch]);

  const eliminarDivision = useCallback(({ id }) => {
    dispatch(eliminarSitio(id));
  }, [dispatch]);

  return (
    <Box
      alignItems="flex-start"
      display="flex"
      flexDirection="column"
      gridGap={20}
      maxWidth="75%"
    >
      {/* Modal de confirmación */}
      <Dialog
        abierto={!!mostrarConfirmar}
        titulo="¿Estas seguro que deseas cambiar el sitio de origen?"
        descripcion="Una vez cambiado el sitio de origen, se perderán las divisiones realizadas así como los datos asociados a las mismas."
        onAceptar={onAceptarCambioSitio}
        onCancelar={onCancelarCambioSitio}
      />
      {/* Sitio de origén */}
      <Box>
        <Box mb={-1}>
          <Typography variant="subtitle1">
            <b>Sitio</b>
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gridGap={10}>
          {(!!esEdicion) ? (
            <TextField
              className={classes.select}
              name="sitioOrigenNombre"
              value={sitioOrigen.nombre}
              disabled
            />
          ) : (
            <CustomSelect
              name="sitioOrigenId"
              labelProp="nombre"
              valueProp="id"
              value={sitioOrigen.id}
              onChange={onChange}
              options={sitiosDB}
              placeHolder="Seleccione un sitio"
              className={classes.select}
              error={errores?.sitioOrigenId}
            />
          )}
          <Typography variant="body2">
            <b>Nota:</b> Si el sitio de origen tiene más de un invernadero, se podrán realizar divisiones.
          </Typography>
        </Box>
      </Box>
      {/* Formulario de divisiones */}
      <Box width="100%">
        {(sitioOrigen.id && sitioOrigen.invernaderos.length > 1) && (
          <FormDivisor
            initialValues={sitiosDivisiones}
            onConfirmar={guardarDivision}
            onEliminarDisabled={eliminarDivision}
          />
        )}
      </Box>
    </Box>
  )
};

export default ConfiguracionSitio;
