import {
  createStore, applyMiddleware, compose,
  combineReducers,
} from 'redux';
import thunk from 'redux-thunk';
import user from './user';
import ajax from './ajax';
import appbar from './appbar';
import tablero from './tablero';
import nave from './nave';
import listados from './listados';
import configuraciones from './configuraciones';
import temporada from './temporada';

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  user,
  ajax,
  appbar,
  listados,
  tablero,
  nave,
  configuraciones,
  temporada,
});

const store = createStore(
  reducers,
  storeEnhancers(applyMiddleware(thunk)),
);

export default store;
