import React from 'react';
import propTypes from 'prop-types';
import clsx from 'clsx';

import { Grid, Paper } from '@material-ui/core';
import Typography from '../Typography';
import styles from './styles';

const Agrupador = ({ children, label, customClass }) => {
  const classes = styles();

  return (
    <Grid container spacing={1}>
      {
        (label)
        &&
        <Grid item xs={12}>
          <Typography className={classes.label}>{label}</Typography>
        </Grid>
      }
      <Grid item xs={12}>
        <Paper className={clsx(classes.paper, customClass)}>
          {children}
        </Paper>
      </Grid>
    </Grid>
  );
};

Agrupador.propTypes = {
  /** Componente hijo a renderizar */
  children: propTypes.node.isRequired,
  /** Titulo/Label del agrupador */
  label: propTypes.string,
  /** Clase de CSS para personalizar contenedor */
  customClass: propTypes.string,
};

Agrupador.defaultProps = {
  label: '',
  customClass: '',
};

export default Agrupador;
