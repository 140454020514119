import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    marginBottom: 10,
    marginTop: -35,
  },
  paper: {
    marginTop: 2,
    color: theme.palette.text.secondary,
  },
  btn: {
    marginTop: 30,
    width: '95%',
  },
  table: {
    height: 'auto',
    maxHeight: '60vh',
    minHeight: '250px',
    borderRadius: 0,
    borderTop: 'none',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderTopRightRadius: 8,
  },
  btnAction: {
    margin: theme.spacing(0.5),
    textAlign: 'center',
  },
  datePickerInput: {
    width: '100%',
  },
  datePickerLabel: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  input: {
    minHeight: 42,
    marginTop: 10,
    backgroundColor: paleta.textField.backgroundColor,
    borderRadius: 8,
    outline: 'none',
    width: '95%',
    padding: '0 10px',
    border: `1px solid ${paleta.textField.border}`,
    '&:hover, &:focus': {
      backgroundColor: paleta.textField.backgroundColorHover,
    },
    '&.disabled': {
      backgroundColor: paleta.textField.backgroundColorDisabled,
    },
  },
  calendario: {
    backgroundColor: 'red',
  }
}));

export default styles;
