/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import Default from '../../contenedores/Default';
import TextField from '../../componentes/TextField';
import Select from '../../componentes/Select';
import axios from '../../configuraciones/axios';
import { findPropertysEmpty, trim } from '../../utilidades/functions';
import endpoints, {
  SANEOS
} from '../../configuraciones/endpoints';
import estatus from '../../constantes/estatus';
import requerido from '../../constantes/requerido';
import { ELEMENTO_NO_ENCONTRADO } from '../../configuraciones/mensajes';

const Saneo = ({ history, match }) => {
  const [saneo, setSaneo] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const { params: { id } } = match;

  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/saneos',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  const cancelar = useCallback(regresar, []);

  const guardar = useCallback(() => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmSaneo'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;

    const metodo = id ? 'put' : 'post';

    const objTrim = trim(saneo);
    
    axios[metodo](SANEOS, objTrim).then(regresar);
  }, [saneo]);

  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [];
    if (id) promesas.push(axios.get(endpoints.base.url(SANEOS, id)));

    Promise.all(promesas).then((resultadosPromesas) => {
      const [saneoDb] = resultadosPromesas;
      if (saneoDb?.id)
        setSaneo(saneoDb);
      else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      } else
        setSaneo({estatus: true, requerido: true});
      resolve();
    }).catch(reject);
  });

  useEffect(() => {
    consultarDatosIniciales();
  }, [id]);

  return <Default
    titulo={Boolean(id) ? 'Editar Saneo' : 'Nuevo Saneo'}
    placeHolder={''}
    mostrarCabeceroFormulario
    guardar={guardar}
    cancelar={cancelar}>
    <Grid container spacing={2} id="frmSaneo">
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Nombre de Saneo"
              onChange={setSaneo}
              name="nombre"
              value={saneo.nombre}
              isHandleChange
              inputProps={{ maxLength: 50 }}
              error={formErrors.nombre}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Captura Obligatoria"
              required
              options={requerido}
              onChange={setSaneo}
              name="requerido"
              value={saneo.requerido}
              error={formErrors.requerido}
              isHandleChange
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Estatus"
              required
              options={estatus}
              onChange={setSaneo}
              name="estatus"
              value={saneo.estatus}
              error={formErrors.estatus}
              isHandleChange
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(Saneo);
