import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  subtitle: {
    color: paleta.bar.elements,
    marginTop: 3,
    fontSize: 11,
    marginLeft: 10
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      gap: theme.spacing(2),
      width: '100%',
    },
  },
  btnContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'block',
    },
  },
  btnAdd: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  textField: {
    width: 250,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  textFieldInput: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  select: {
    width: 150,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
  },
  selectSpan: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  btn: {
    margin: '0 2px',
    paddingInline: 20,
    minHeight: 38,
    height: 38,
    fontSize: 12,
  },
}));

export default styles;