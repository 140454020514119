import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';

import HeaderSubcatalogo from '../HeaderSubcatalogo';
import RightDrawer from '../RightDrawer';
import TablaInfo from '../TablaInfo';
import TextField from '../TextField';

import {
  FECHA_CULTIVO_MAYOR_FECHA_TRANSPLANTE,
  FECHA_TRANSPLANTE_MAYOR_FECHA_CORTE
} from '../../configuraciones/mensajes';

import styles from './styles';

const TablaTemporada = ({ mostrar, guardar, cancelar, tabla }) => {
  const classes = styles();
  const [temporadaCultivo, setTemporadaCultivo] = useState({});

  const customGuardar = () => {
    const momentFechaCultivo = moment(temporadaCultivo.fechaCultivo);
    const momentFechaTransplante = moment(temporadaCultivo.fechaTransplante);
    const momentFechaCorte = moment(temporadaCultivo.fechaCorte);

    if (momentFechaCultivo.isSameOrAfter(momentFechaTransplante)) return toast.error(FECHA_CULTIVO_MAYOR_FECHA_TRANSPLANTE);
    else if (momentFechaTransplante.isSameOrAfter(momentFechaCorte)) return toast.error(FECHA_TRANSPLANTE_MAYOR_FECHA_CORTE);

    guardar(temporadaCultivo);
  };

  useEffect(() => {
    setTemporadaCultivo({
      ...tabla?.temporadaCultivo[0] || {},
      tablaID: tabla?.id,
    });
  }, [tabla, mostrar]);

  return (
    <RightDrawer
      header={<HeaderSubcatalogo titulo="Temporada" subtitulo="Configuración" guardar={() => customGuardar()} cerrar={cancelar} mostrarTextField={false} />}
      isOpen={mostrar}
      paperClassName={classes.paperDrawer}
    >
      <TablaInfo tabla={tabla} />
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              name="fechaCultivo"
              type="date"
              label="Fecha de Cultivo"
              onChange={setTemporadaCultivo}
              isHandleChange
              value={temporadaCultivo.fechaCultivo || ''}
              required
              className={classes.mt3}
            />
            <TextField
              name="fechaCorte"
              type="date"
              label="Fecha Inicio Corte"
              onChange={setTemporadaCultivo}
              isHandleChange
              value={temporadaCultivo.fechaCorte || ''}
              required
              className={classes.mt3}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              name="fechaTransplante"
              label="Fecha de Transplante"
              type="date"
              onChange={setTemporadaCultivo}
              isHandleChange
              value={temporadaCultivo.fechaTransplante || ''}
              required
              className={classes.mt3}
            />
          </Grid>
        </Grid>
      </Grid>
    </RightDrawer>
  );
};

TablaTemporada.propTypes = {
  mostrar: propTypes.bool.isRequired,
  guardar: propTypes.func.isRequired,
  cancelar: propTypes.func.isRequired,
  tabla: propTypes.object.isRequired,
};

export default React.memo(TablaTemporada);
