import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: `${paleta.bar.primary}`,
  },
  agrupador: {
    marginInline: 5,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 30,
    border: 'none',
  },
  select: {
    marginTop: -2,
    color: 'red',
    fontSize:14,
    height: '80%',
    marginLeft: '2vh',
    fontWeight: 'bold',
    width: '100%',
    background: paleta.bar.details,
    '& fieldset': {
      border: `0px solid #FFFF !important`,
      boxShadow: "none"
    },
    '&:hover, &:focus': {
      backgroundColor: paleta.bar.details,
      '& fieldset': {
        border: `1px solid ${paleta.bar.details} !important`,
        boxShadow: "none"
      },
      boxShadow: "none"
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid #FFFF`,
      boxShadow: "none"
    },
    '& .MuiSelect-root': {
      minHeight: 42,
      height: 42,
      padding: '0px !important',
      display: 'flex',
      alignItems: 'center',
    },
  },
  labelTitle: {
    color: paleta.textField.text,
    fontSize: '16px',
    fontWeight: 'bold',
  },
  divider: {
    borderColor: paleta.bar.elements,
    borderTop: "dashed 1.5px",
    width: '100%',
    marginTop: 20,
    marginBottom: 20
  },
  lineChartContainer: {
    width: '100%',
    height: '33vh',
    overflowX: 'scroll',
  },
  lineChart: {
    minWidth: '2000px',
    maxHeight: '33vh',
  },
  calendario: {
    height: '0px',
    transform: 'translateY(-100%)',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'transform 200ms ease-in-out, opacity 200ms ease-in-out, height 200ms ease-in-out',
    '&.active': {
      height: '66px',
      transform: 'translateY(0)',
      opacity: 1,
      pointerEvents: 'all',
    },
  }
}));

export default styles;