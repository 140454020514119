import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  boton: {
    textTransform: 'none',
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '14px',
    display: 'flex',
    '& span': {
      color: paleta.sidebar.backgroundColor,
      '&.icono': {
        marginLeft: 10,
      },
    },
    '&:hover': {
      backgroundColor: paleta.bar.secondary,
      borderColor: paleta.bar.secondary,
    },
    '&.Mui-disabled': {
      backgroundColor: paleta.bar.primary,
      opacity: 0.7,
      cursor: 'not-allowed',
    },
  },
  botonMenu: {
    padding: 0,
    width: 30,
    minWidth: 30,
  },
  contenedorMenu: {
    marginTop: 5,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
    boxShadow: `rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px`,
  },
  contenedorMenuItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  }
}));

export default styles;
