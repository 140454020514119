import React, { useMemo } from 'react';
import { Box, Divider, makeStyles, styled } from '@material-ui/core';

import Table from '../../componentes/Table';
import Typography from '../../componentes/Typography';
import Button from '../../componentes/Button';

const styles = makeStyles((theme) => ({
  labelTabla: {
    marginTop: 5,
    fontSize: 12,
    fontWeight: 'bold',
  },
  celdaTabla: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  snapItem: {
    scrollSnapAlign: 'start',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  acordion: {
    width: '95%',
  },
  divisor: {
    width: '95%',
  }
}));

const Celda = React.memo(({ children }) => (
  <Typography className={styles().celdaTabla}>
    {children}
  </Typography>
));

export const Cabecero = React.memo(({ texto, regresar }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography component="h2">
        {texto}
      </Typography>
      <Button
        border
        label="Regresar"
        onClick={regresar}
      />
    </Box>
  );
});

export const ScrollContenedor = styled('div')(({ theme }) => ({
  flex: 0.3,
  overflowY: 'scroll',
  scrollSnapType: 'y mandatory',
}));

export const Seccion = React.memo(
  React.forwardRef(({ children, nombre, ...props }, ref) => (
    <Box
      mb={3}
      gridGap={10}
      display="flex"
      flexDirection="column"
      className={styles().snapItem}
      ref={ref}
      {...props}
    >
      {
        nombre && (
          <Typography component="h3">
            {nombre}
          </Typography>
        )
      }
      <Divider classes={{ root: styles().divisor }} />
      {children}
    </Box>
  ))
);

export const Tabla = React.memo(({ nombre, rows }) => {
  const cabeceros = useMemo(() => [{
    key: 'nombre',
    label: 'Nombre',
    transform: ({ nombre }) => <Celda>{nombre}</Celda>,
  }], []);

  return (
    <Box width="95%" display="flex" flexDirection="column" gridGap={11}>
      <Typography variant="subtitle1" className={styles().labelTabla}>
        {nombre}
      </Typography>
      <Table
        rows={rows}
        headers={cabeceros}
        hidePaginator
        showActions={false}
        minRows={0}
      />
    </Box>
  );
});

export default styles;
