import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import { Button, Grid } from '@material-ui/core';
import { 
  WiThunderstorm,
  WiDaySunny,
  WiCloudy,
  WiSnow,
  WiDayShowers,
  WiDayRain,
  WiNa
} from 'react-icons/wi';
import clsx from 'clsx';

import Typography from '../Typography';

import { capitalizarPalabras } from '../../utilidades/functions';

import styles from './styles';

const Iconos = React.memo(({ estado, activo }) => {
  const classes = styles();

  switch(estado) {
    case 'Thunderstorm':
      return <WiThunderstorm size={30} className={clsx(classes.icon, { active: activo })} />;
    case 'Rain':
      return <WiDayShowers   size={30} className={clsx(classes.icon, { active: activo })} />;
    case 'Drizzle':
      return <WiDayRain      size={30} className={clsx(classes.icon, { active: activo })} />;
    case 'Snow':
      return <WiSnow         size={30} className={clsx(classes.icon, { active: activo })} />;
    case 'Clear':
      return <WiDaySunny     size={30} className={clsx(classes.icon, { active: activo })} />;
    case 'Clouds':
      return <WiCloudy       size={30} className={clsx(classes.icon, { active: activo })} />;
    default:
      return <WiNa size={30} className={clsx(classes.icon, { active: activo })} />;
  }
});

const TarjetaClima = ({
  temperatura,
  humedad,
  estado,
  fecha,
  activo,
  disabled,
  selected,
  onClick,
  animar,
  animationDelay
}) => {
  const classes = styles();

  return (
    <Grid
      item
      className={clsx({[classes.animar]: animar})}
      style={{ animationDelay: `0.${animationDelay}s` }}
    >
      <Button onClick={() => onClick(fecha)} disabled={disabled} 
        className={clsx(classes.paper, {
          active: activo,
          disabled: disabled,
          selected: selected
        })} 
      >
        <Grid container spacing={0}>
          {/* Icono */}
          <Grid item className={classes.iconContainer}>
            <Iconos estado={estado} activo={activo} />
          </Grid>

          <Grid item>
            {/* Fecha */}
            <Typography
              className={clsx(classes.title, {
                active: activo,
              })}
              bold
              display='block'
              aling="right"
            >
              {  capitalizarPalabras(moment(fecha).format("ddd MMM DD YYYY")) }
            </Typography>

            {/* Temperatura */}
            <Typography
              className={classes.subTitle}
              variant='subtitle2'
              display='block'
              aling="left"
            >
              {
                (temperatura)
                  ? `${ Math.round(temperatura.max) || '-' }° / ${ Math.round(temperatura.min) || '-' }° ~ ${ humedad || '-' }%`
                  : 'N / A'
              }
            </Typography>
          </Grid>
        </Grid>
      </Button>
    </Grid>
  )
}

TarjetaClima.propTypes = {
  /** Fecha */
  fecha: propTypes.string.isRequired,
  /** Temperatura del dia */
  temperatura: propTypes.shape({
    /** Temperatura máxima */
    min: propTypes.number.isRequired,
    /** Temperatura mínima */
    max: propTypes.number.isRequired,
  }),
  /** Húmedad del día */
  humedad: propTypes.number.isRequired,
  /** Indica si esta seleccionado */
  selected: propTypes.bool,
  /** Nombre de la condición climática */
  estado: propTypes.string,
  /** Indica si esta activo */
  activo: propTypes.bool,
  /** Funcion que se ejecuta al hacer click */
  onClick: propTypes.func,
  /** Indica si esta deshabilitado */
  disabled: propTypes.bool,
  /** Indica si el elemento se anima al aparecer */
  animar: propTypes.bool,
  /** Indica el delay para animar la aparición de la tarjeta */
  animationDelay: propTypes.number,
};

TarjetaClima.defaultProps = {
  estado: null,
  temperatura: null,
  selected: false,
  activo: false,
  onClick: () => {},
  disabled: false,
  animar: false,
  animationDelay: 0
};

export default React.memo(TarjetaClima);
