import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: `${paleta.bar.primary}`,
  },
  agrupador: {
    marginInline: 5,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 30,
  },
  labelTitle: {
    color: paleta.textField.text,
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
}));

export default styles;