/* eslint-disable import/no-anonymous-default-export */
const SET_SITIOID = 'SET_SITIOID';

const initialState = {
  sitioID: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SITIOID:
      return { ...state, sitioID: payload };
    default:
      return state;
  }
};

export const setSitioID = (payload) => ({ type: SET_SITIOID, payload });
