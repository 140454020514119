import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box';
import { MdOutlineChevronRight } from 'react-icons/md';
import { styled } from '@material-ui/core';

import styles, { Boton, Menu, MenuItem, Titulo } from './styles';
import paleta from '../../configuraciones/paleta';

const Cuerpo = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gridGap: 5,
});

const Panel = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gridGap: 6,
});

const Accion = React.memo(({ children }) => children);

const TarjetaListado = React.memo(({ children, titulo }) => {
  const classes = styles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const arrayOfChildren = Array.isArray(children) ? children : [children]
  const cuerpos = arrayOfChildren.filter((child) => child.type === Cuerpo);
  const paneles = arrayOfChildren.filter((child) => child.type === Panel);
  const acciones = arrayOfChildren.filter((child) => child.type === Accion);

  return (
    <Box
      border={`2px solid ${paleta.textField.border}`}
      borderRadius={8}
      className={classes.animacion}
      display="inline-flex"
      justifyItems="space-between"
      padding={1}
    >
      <Box flex={1} display="flex" flexDirection="column" padding={1} gridGap={8} justifyContent="space-between">
        {/* Titulo */}
        {titulo && (
          typeof titulo === 'string' ? (
            <Titulo variant="subtitle1" component="h2">
              {titulo}
            </Titulo>
          ) : (
            titulo
          )
        )}
        {/* Contenido */}
        <Box>
          {cuerpos}
        </Box>
        {/* Acciones */}
        {
          (acciones.length > 0) && (
            <Box>
              <Boton
                disableRipple
                disableFocusRipple
                disableTouchRipple
                variant="text"
                onClick={handleClick}
                id={`menu-${titulo}`}
                aria-controls={open ? `menu-${titulo}` : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                startIcon={<MdOutlineChevronRight
                  color={paleta.bar.primary}
                  size={20}
                  style={{
                    transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
                    transition: 'transform 250ms',
                  }} />
                }
              >
                Opciones
              </Boton>
              <Menu
                id={`menu-${titulo}`}
                MenuListProps={{
                  'aria-labelledby': `menu-${titulo}`,
                  role: 'listbox',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {
                  acciones.map((accion, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        handleClose();
                        if (accion.props.onClick) {
                          accion.props.onClick();
                        }
                      }}
                      divider={accion.props?.divider}
                      disabled={accion.props?.disabled}
                    >
                      {accion.props.children}
                    </MenuItem>
                  ))
                }
              </Menu>
            </Box>
          )
        }
      </Box>

      {/* Paneles */}
      {
        paneles.length && (
          <Box display="flex" flexDirection="column" gridGap={10}>
            {paneles}
          </Box>
        )
      }
    </Box>
  )
});

TarjetaListado.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  titulo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.node,
}

TarjetaListado.defaultProps = {
  id: null,
  titulo: '',
  children: null,
}

export default Object.assign(TarjetaListado, {
  Cuerpo,
  Panel,
  Accion,
});
