import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      margin: '0px 2px',
      width:90
    },
  },
}));

export default styles;
