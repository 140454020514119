/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import propTypes from 'prop-types';
import { FaFilePdf } from 'react-icons/fa';
import { SiMicrosoftexcel } from 'react-icons/si';
import Confirmacion from '../Confirmacion';
import styles from './styles';
import { exportarDocumento } from '../../utilidades/functions';

const ExportarDocumentos = ({ open, onClose, documento }) => {
	const classes = styles();
	
	const exportar = (tipoArchivo) => {
		if (tipoArchivo === 'pdf' || tipoArchivo === 'excel') {
			exportarDocumento({
				params: documento?.params,
				endpoint: documento?.endpoint,
				method: documento?.method,
				tipoArchivo,
			});
		}
	};

	return (
		<Confirmacion
			mensaje='Seleccion el tipo de archivo'
			labelPrimary='PDF'
			labelSecondary='EXCEL'
			iconPrimary={<FaFilePdf size={16} />}
			iconSecondary={<SiMicrosoftexcel size={16} />}
			anchor={open}
			onClose={onClose}
			onAccept={() => exportar('pdf')}
			customClickClose={() => exportar('excel')}
			customClass={classes.modal}
		/>
	);
};

ExportarDocumentos.propTypes = {
	open: propTypes.any,
	onClose: propTypes.func.isRequired,
	documento: propTypes.object,
};

ExportarDocumentos.defaultProps = {
	open: null,
	onAccept: null,
	customClickClose: null,
};

export default ExportarDocumentos;
