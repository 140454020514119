import React from 'react';
import propTypes from 'prop-types';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Dialog,
  Divider,
} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { IoMdClose } from 'react-icons/io';

import Typography from '../Typography';

import styles from './styles';

const Modal = ({
  actions,
  children,
  handleClose,
  open,
  showActions,
  titulo,
  maxWidth
}) => {
  const classes = styles();
  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      onClose={handleClose}
      open={open}
      TransitionComponent={Fade}
    >
      <DialogTitle disableTypography className={classes.root}>
        <Typography component="h2">{titulo}</Typography>
        {handleClose ? (
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <IoMdClose />
          </IconButton>
        ) : null}
      </DialogTitle>
      <Divider orientation='horizontal' />
      <DialogContent className={classes.contenido}>{children}</DialogContent>
      {showActions && (
        <DialogActions className={classes.acciones}>{actions}</DialogActions>
      )}
    </Dialog>
  );
};

Modal.propTypes = {
  /** Componente hijo a renderizar */
  children: propTypes.node.isRequired,
  /** Titulo/Label del modal */
  titulo: propTypes.string,
  /** Funcion para cerrar el modal */
  handleClose: propTypes.func,
  /** Flag para mostrar los botones de accion */
  showActions: propTypes.bool,
  /** Componente de acciones */
  actions: propTypes.node,
  /** Estilos personalizados */
  classes: propTypes.shape({
    /** Estilos del contenedor */
    contenedor: propTypes.string,
  }),
  /** Tamano del modal */
  maxWidth: propTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
};

Modal.defaultProps = {
  titulo: '',
  showActions: false,
  actions: null,
  handleClose: null,
  classes: {},
  maxWidth: 'md',
};

export default Modal;
