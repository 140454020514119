import React, { useEffect, useRef, useState, useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Backdrop, CircularProgress, Divider, FormControlLabel, FormGroup, Grid } from '@material-ui/core';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import clsx from 'clsx';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

import Chart from '../Chart';
import RefreshControl from '../RefreshControl';
import Switch from '../CustomSwitch';
import Typography from '../Typography';

import {
  clearMonitoreoActivo,
  clearNavesDetectadas,
  setNavesDetectadas,
  setPlagaEnfermedadID,
} from '../../ducks/tablero';
import axios from '../../configuraciones/axios';
import endpoints from '../../configuraciones/endpoints';
import paleta from '../../configuraciones/paleta';

import { obtenerRangoFechas } from '../../utilidades/functions';
import { PLANTAS, TRAMPAS } from '../../constantes/tipoDeteccion';
import tooltipOptions from '../../constantes/chartTooltipOptions';

import styles from './styles';

const barChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    crosshair: false,
    title: {
      display: false
    },
    datalabels: {
      display: false,
    },
    tooltip: {
      ...tooltipOptions,
      callbacks: {
        label: (tooltipItem) => {
          const { dataset, formattedValue } = tooltipItem;
          return `${dataset.label}: ${formattedValue} nave(s) `;
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      autoSkip: false,
      maxRotation: 360,
      minRotation: 360,
      ticks: {
        major: {
          enabled: true,
        },
        font: function(context) {
          if (context.tick && context.tick.major) {
            return {
              weight: 'bold'
            };
          }
        }
      },
      afterBuildTicks: ({ ticks }) => {        
        ticks.forEach(t => { t.major = false; });
        return ticks;
      },
    },
    y: {
      beginAtZero: true,
      stacked: true,
      ticks: {
        beginAtZero: true,
        callback: function(value) {if (value % 1 === 0) {return value;}}
      },
    }
  },
  parsing: {
    xAxisKey: 'deteccion',
  },
  interaction: {
    intersect: false,
    mode: 'index',
  }
};

const lineChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  interaction: { intersect: false },
  plugins: {
    title: { display: false },
    datalabels: { display: false },
    crosshair: false,
    tooltip: {
      callbacks: {
        title: (tooltipItems) => 'Semana ' + tooltipItems[0].label,
      }
    },
  },
  scales: {
    y: {
      min: 0,
      ticks: {
        beginAtZero: true,
        callback: function(value) {if (value % 1 === 0) {return value;}}
      }
    },
  },
  parsing: {
    yAxisKey: 'id'
  }
};

const DETECCIONES_TABLERO = 'detecciones-tablero';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const GraficasDetecciones = () => {
  const classes = styles();

  const { sitioID, enfermedad, temporada, semana } = useSelector(
    store => store.tablero,
    (prev, next) => (
      prev.sitioID === next.sitioID &&
      prev.enfermedad.id === next.enfermedad.id &&
      prev.enfermedad.esTrampa === next.enfermedad.esTrampa &&
      prev.temporada.fechaInicio === next.temporada.fechaInicio &&
      prev.temporada.fechaFin === next.temporada.fechaFin &&
      prev.semana === next.semana
    )
  );
  const dispatch = useDispatch();
  const chartRef = useRef(null);

  const [ uiOptions, setUiOptions ] = useState({
    estaRefrescando: false,
    soloDetectadas: false,
  });
  const [ barChart, setBarChart ] = useState({
    data: null,
    options: barChartOptions,
  });
  const [ lineChart, setLineChart ] = useState({
    data: null,
    options: lineChartOptions,
  });

  const consultarDatos = useCallback(async () => {
    try {
      setUiOptions(prevState => ({ ...prevState, estaRefrescando: true }));
      if ( sitioID && semana ) {
        const cacheData = JSON.parse(localStorage.getItem(DETECCIONES_TABLERO)) || {};
        if ( cacheData.sitioID === sitioID && cacheData.semana === semana ) {
          setBarChart(prev => ({ ...prev, data: cacheData.datos }));
          return;
        }

        const enTrampas = [];
        const detecciones = [];
  
        const fechaObjetivo = moment(semana, 'W (YYYY)')
          .startOf('isoWeek')
          .format('YYYY-MM-DD');
  
        const {
          enfermedades,
          deteccionesTrampas,
          deteccionesPlantas,
        } = await axios.get(endpoints.obtenerDeteccionesPorSemana(sitioID), {
          params: { fechaObjetivo },
        });
  
        for (const enfermedad of enfermedades) {
          if ( enfermedad.tipoEnfermedadID === 2 ) {
            enTrampas.push({
              id: enfermedad.id,
              deteccion: enfermedad.nombre + ' (Trampas)',
              esTrampa: true,
            })
          }
          detecciones.push({
            id: enfermedad.id,
            deteccion: enfermedad.nombre,
            esTrampa: false,
          })
        }
  
        detecciones.push(...enTrampas);
  
        for (const deteccion of detecciones) {
          let captura;
          if (deteccion.esTrampa)
            captura = Object.entries(deteccionesTrampas).find((key) => parseInt(key, 10) === deteccion.id)[1] || null;
          else
            captura = Object.entries(deteccionesPlantas).find((key) => parseInt(key, 10) === deteccion.id)[1] || null;
  
          deteccion.verde = captura.verde;
          deteccion.amarillo = captura.amarillo;
          deteccion.rojo = captura.rojo;
          deteccion.indefinido = captura.indefinido;
          deteccion.total = captura.verde + captura.amarillo + captura.rojo;
          deteccion.naves = captura.naves || [];
        }
  
        detecciones.sort((anterior, actual) => {
          if (actual.total > anterior.total) {
            return 1;
          } else if (actual.total < anterior.total) {
            return -1;
          } else {
            return 0;
          }
        });
  
        const barChartData = {
          datasets: [
            {
              label: 'Estado Rojo',
              data: detecciones,
              backgroundColor: paleta.configuraciones.rojo,
              parsing: { yAxisKey: 'rojo' }
            },
            {
              label: 'Estado Amarillo',
              data: detecciones,
              backgroundColor: paleta.configuraciones.amarillo,
              parsing: { yAxisKey: 'amarillo' }
            },
            {
              label: 'Estado Verde',
              data: detecciones,
              backgroundColor: paleta.configuraciones.verde,
              parsing: { yAxisKey: 'verde' }
            },
            {
              label: 'Sin estado',
              data: detecciones,
              backgroundColor: paleta.configuraciones.gris,
              parsing: { yAxisKey: 'indefinido' }
            },
          ]
        };
  
        setBarChart({
          data: barChartData,
          options: barChartOptions,
        });
        localStorage.setItem(DETECCIONES_TABLERO, JSON.stringify({
          sitioID,
          semana,
          datos: barChartData,
        }));
      }
    } finally {
      setLineChart({ data: null, options: lineChartOptions });
      setUiOptions({
        estaRefrescando: false,
        soloDetectadas: false,
      });
    }
  }, [sitioID, semana]);

  const obtenerHistorial = useCallback(async (deteccion, indexEnfermedades) => {
    try {
      let deteccionesActuales, deteccionesPasadas;

      const naves = deteccion.naves.map((nave) => {
        const totalDetecciones = nave.detalles.reduce((acc, curr) => acc + parseFloat(curr.cantidadDetecciones), 0);
        return {
          id: nave.naveID,
          estado: nave.estado,
          parametro: nave.parametro,
          extraInfo: `${totalDetecciones} detecciones`,
          nombreDeteccion: deteccion.deteccion,
          mostrarTooltip: true,
          detalles: nave.detalles,
          tipoDeteccion: deteccion.esTrampa ? TRAMPAS : PLANTAS,
        };
      });
      
      if (deteccion.esTrampa) {
        const respuesta = await axios.get(endpoints.obtenerDeteccionesHistorialTrampas(), {
          params: { sitioID, enfermedadID: deteccion.id },
        });
        deteccionesActuales = respuesta.deteccionesActuales;
        deteccionesPasadas = respuesta.deteccionesPasadas;
      } else {
        const respuesta = await axios.get(endpoints.obtenerDeteccionesHistorialPlantas(), {
          params: { sitioID, enfermedadID: deteccion.id },
        });
        deteccionesActuales = respuesta.deteccionesActuales;
        deteccionesPasadas = respuesta.deteccionesPasadas;
      }

      const newBarChartOptions = cloneDeep(barChartOptions);
      const newLineChartOptions = cloneDeep(lineChartOptions);
  
      const datasetTemporadaActual = [];
      const datasetTemporadaPasada = [];

      const semanas = obtenerRangoFechas(temporada.fechaInicio, temporada.fechaFin, 'W-YYYY', true, false);

      for (const semana of semanas) {
        const semanaTemporadaPasada = moment(semana, 'W-YYYY').subtract(1, 'year').format('W-YYYY');
        datasetTemporadaActual.push({
          valor: deteccionesActuales[semana] || 0,
          semana: semana,
        });
        datasetTemporadaPasada.push({
          valor: deteccionesPasadas[semanaTemporadaPasada] || 0,
          semana: semana,
        });
      }

      newBarChartOptions.scales.x.afterBuildTicks = (axis) => {
        const { ticks } = axis;
        ticks.forEach(t => {
          t.major = indexEnfermedades === t.value;
        });
        return ticks;
      };
      newLineChartOptions.scales.y.suggestedMax =  Math.max(...datasetTemporadaActual.map(val => val.valor)) + (Math.max(...datasetTemporadaActual.map(val => val.valor)) * 0.2);

      setLineChart({
        options: newLineChartOptions,
        data: {
          labels: semanas,
          nombreDeteccion: deteccion.deteccion,
          datasets: [
            {
              label: 'Naves',
              data: datasetTemporadaActual.map(val => val.valor),
              borderColor: paleta.graficos.linePrimary,
              pointBackgroundColor: datasetTemporadaActual.map(() => '#FFF'),
              pointHoverBackgroundColor: datasetTemporadaActual.map(() => paleta.graficos.linePrimary),
              pointBorderColor: datasetTemporadaActual.map(() => paleta.graficos.linePrimary),
              borderWidth: 2,
              pointRadius: 3,
              pointHoverRadius: 8,
              cubicInterpolationMode: 'monotone',
              tension: 0.4,
            },
            {
              label: 'Naves (Temporada pasada)',
              data: datasetTemporadaPasada.map(val => val.valor),
              borderColor: paleta.graficos.lineSecondary,
              pointBackgroundColor: datasetTemporadaActual.map(() => '#FFF'),
              pointHoverBackgroundColor: datasetTemporadaActual.map(() => paleta.graficos.lineSecondary),
              pointBorderColor: datasetTemporadaPasada.map(() => paleta.graficos.lineSecondary),
              borderWidth: 2,
              pointRadius: 3,
              pointHoverRadius: 8,
              cubicInterpolationMode: 'monotone',
              tension: 0.4,
            },
          ],
        }
      });
      setBarChart(prev => ({
        ...prev,
        options: newBarChartOptions,
      }));
      batch(() => {
        dispatch(clearMonitoreoActivo());
        dispatch(setPlagaEnfermedadID({
          id: deteccion.id,
          esTrampa: deteccion.esTrampa,
          nombre: deteccion.deteccion,
        }));
        dispatch(setNavesDetectadas(naves));
      });
    } catch { };
  }, [sitioID, temporada, dispatch]);

  const onClickBar = useCallback((event) => {
    const { current: chart } = chartRef;
    const element = getElementAtEvent(chart, event);

    if (!chart || !element.length ) return;

    const { datasetIndex, index } = element[0];
    const deteccion = barChart.data.datasets[datasetIndex].data[index];
    obtenerHistorial(deteccion, index);
  }, [barChart, obtenerHistorial]);

  const onRefreshData = useCallback(() => {
    localStorage.removeItem(DETECCIONES_TABLERO);
    batch(() => {
      dispatch(clearMonitoreoActivo());
      dispatch(clearNavesDetectadas());
    });
    consultarDatos();
  }, [dispatch, consultarDatos]);

  const toggleSoloDetectadas = useCallback(({ target: { checked } }) => {
    const cacheData = JSON.parse(localStorage.getItem(DETECCIONES_TABLERO)) || {};
    setUiOptions(prevState => ({ ...prevState, soloDetectadas: checked }));
    if (!checked) {
      setBarChart(prev => ({
        ...prev,
        data: cacheData.datos,
      }));
    } else {
      const nuevoDataset = structuredClone(cacheData.datos.datasets);
      nuevoDataset.pop();

      const datasetSinValoresCero = nuevoDataset.map((dataset) => {
        return {
          ...dataset,
          data: dataset.data
            .filter((info) => (
              info.verde > 0 || 
              info.amarillo > 0 || 
              info.rojo > 0
            )),
        }
      });

      setBarChart(prev => ({
        ...prev,
        data: {
          ...prev.data,
          datasets: datasetSinValoresCero,
        },
      }));
    }
  }, []);

  const onClickVertice = useCallback(async (row) => {
    try {
      if (!row) return;
      
      let detecciones = [];
      const { label, datasetIndex, dataIndex } = row;
      
      if (enfermedad.esTrampa) {
        detecciones = await axios.get(endpoints.obtenerDeteccionesTrampas(), {
          params: {
            sitioID,
            enfermedadID: enfermedad.id,
            fecha: label,
            anioPasado: datasetIndex === 1 ? 1 : 0,
          },
        });
      } else {
        detecciones = await axios.get(endpoints.obtenerDeteccionesPlantas(), {
          params: {
            sitioID,
            enfermedadID: enfermedad.id,
            fecha: label,
            anioPasado: datasetIndex === 1 ? 1 : 0,
          },
        });
      }

      const naves = detecciones.map((nave) => {
        const totalDetecciones = nave.detalles.reduce((acc, curr) => acc + parseFloat(curr.cantidadDetecciones), 0);

        return {
          id: nave.naveID,
          estado: nave.estado,
          parametro: nave.parametro,
          extraInfo: `${totalDetecciones} detecciones`,
          nombreDeteccion: enfermedad.nombre,
          mostrarTooltip: !!nave.parametro,
          detalles: nave.detalles,
          tipoDeteccion: nave.esTrampa ? TRAMPAS : PLANTAS,
        };
      });

      setLineChart(prev => ({
        ...prev,
        data: {
          ...prev.data,
          datasets: [
            {
              ...prev.data.datasets[0],
              pointBackgroundColor: prev.data.datasets[0].data.map((val, index) => {
                if (index === dataIndex && datasetIndex === 0) return paleta.bar.primary;
                return paleta.graficos.linePrimary;
              }),
              pointBorderColor: prev.data.datasets[0].data.map((val, index) => {
                if (index === dataIndex && datasetIndex === 0) return paleta.bar.primary;
                return paleta.graficos.linePrimary;
              }),
            },
            {
              ...prev.data.datasets[1],
              pointBackgroundColor: prev.data.datasets[0].data.map((val, index) => {
                if (index === dataIndex && datasetIndex === 1) return paleta.bar.primary;
                return paleta.graficos.lineSecondary;
              }),
              pointBorderColor: prev.data.datasets[0].data.map((val, index) => {
                if (index === dataIndex && datasetIndex === 1) return paleta.bar.primary;
                return paleta.graficos.lineSecondary;
              }),
            }
          ]
        },
      }));
      dispatch(setNavesDetectadas(naves));
    } catch(error) {
      console.error(error);
    }
  }, [enfermedad.esTrampa, enfermedad.id, enfermedad.nombre, dispatch, sitioID]);

  useEffect(() => {
    consultarDatos();

    return () => {
      dispatch(clearMonitoreoActivo());
      dispatch(clearNavesDetectadas());
    }
  }, [consultarDatos, dispatch]);

  if ( !barChart.data ) return null;

  return (
    <Grid container className={classes.container}>
      {/* Información general */}
      <Grid container>

        {/* Opciones del grafico de barras */}
        <Grid item container className={classes.actionsContainer}>
          <Typography variant="body1" className={classes.label}>
            Distribución de detecciones
          </Typography>
          <Grid item className={classes.actionsContainer}>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={uiOptions.soloDetectadas} />}
                label="Mostrar solo detectadas"
                labelPlacement="start"
                onChange={toggleSoloDetectadas}
              />
            </FormGroup>
            <Divider orientation="vertical" flexItem />
            <RefreshControl 
              className={classes.refreshButton}
              onClick={onRefreshData}
              isRefreshing={uiOptions.estaRefrescando}
              mostrarUltimaActualizacion
            />
          </Grid>
        </Grid>

        {/* Grafico de barras */}
        <Grid container>
          <Backdrop open={uiOptions.estaRefrescando} className={classes.backdrop}>
            <Typography variant="body1" className={classes.label}>
              <CircularProgress color="inherit" size={30} />
            </Typography>
          </Backdrop>
          <Bar ref={chartRef} onClick={onClickBar} options={barChart.options} data={barChart.data} />
        </Grid>

      </Grid>

      <Divider className={clsx(classes.divider, { active: (lineChart.data) })} />
      
      {/* Información detallada */}
      <Grid container className={clsx(classes.historial, { active: (lineChart.data) })}>
        <Grid style={{ width: '100%' }}>
          {
            lineChart.data &&
            <Chart
              style
              title={`Tendencia - ${lineChart?.data?.nombreDeteccion || ''}`}
              type="line"
              options={lineChart.options}
              data={lineChart.data}
              handleClickElement={onClickVertice}
              showLegend
              scrollTo={moment(semana, 'W (YYYY)').format('W-YYYY')}
            />
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(GraficasDetecciones);
