import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { toast } from 'react-toastify';

import HeaderSubcatalogo from '../HeaderSubcatalogo';
import RightDrawer from '../RightDrawer';
import Tabla from '../Table';
import TextField from '../TextField';
import Select from '../Select';

import { findPropertysEmpty } from '../../utilidades/functions';
import { NUMBER_REGEX } from '../../utilidades/regex';
import estatus from '../../constantes/estatus';
import { NOMBRE_SURCO_REPETIDO } from '../../configuraciones/mensajes';

import styles from './styles';

const TablaSurco = ({ mostrar, guardar, cancelar, surcos, tablaID }) => {
  const classes = styles();
  const cabeceros = [
    {
      label: 'Código',
      key: 'id'
    },
    {
      label: 'Nombre',
      transform: ({ nombre, repetido }, index) => (
        <TextField
          name="nombre"
          onChange={(e) => manejadorSurco(e, index)}
          value={nombre}
          required
          inputProps={{ maxLength: 5, regexonchange: 'true', regex: NUMBER_REGEX }}
          error={(formErrors.nombre && (!nombre || nombre === '')) || repetido}
          className={classes.input}
        />
      ),
    },
    {
      label: 'Estatus',
      transform: (s, index) => (
        <Select
          required
          options={estatus}
          onChange={(e) => manejadorSurco(e, index)}
          name="estatus"
          value={s.estatus}
          error={formErrors.estatus && !s.hasOwnProperty('estatus')}
          style={{minWidth: 125}}
        />
      )
    },
  ];
  const [formErrors, setFormErrors] = useState({});
  const [nuevosSurcos, setNuevosSurcos] = useState([]);
  const [anchorConfirmacion, setAnchorConfirmacion] = useState(null);

  const handleConfirmacion = (event) => {
		setAnchorConfirmacion(event.currentTarget);
	};

	const handleCancelar = () => {
		setAnchorConfirmacion(null);
	};

  const validarNombresRepetidos = () => {
    let todosValidos = true;
    const edicionSurcos = [...nuevosSurcos];
    let index = 0;
    for (const s of edicionSurcos) {
      const indexExiste = nuevosSurcos.findIndex(({ nombre, activo }) => nombre === s.nombre && activo && s.activo);
      s.repetido = indexExiste >= 0 && indexExiste !== index;
      if (todosValidos && s.repetido) todosValidos = false;
      index++;
    }
    setNuevosSurcos(edicionSurcos);
    return todosValidos;
  };

  const agregar = () => {
    const surco = {
      activo: true,
      nombre: `${nuevosSurcos.filter(({ activo }) => activo).length + 1}`,
      estatus: true,
      isSelected: false,
    };
    if (tablaID) surco.tablaID = tablaID;
    setNuevosSurcos((current) => [...current, surco]);
  };

	const eliminarTodoSurcos = () => {
		const edicionSurcos = [...nuevosSurcos];
		const surcosActivos = edicionSurcos.map((surco) => {
			if (surco.isSelected) {
				return {
					...surco,
					activo: false,
					estatus: false,
				};
			}
			return surco;
		});
		setNuevosSurcos(surcosActivos);
		setAnchorConfirmacion(null);
	};

  const seleccionarTodos = (flag) => {
    const edicionSurcos = [...nuevosSurcos];
    const surcosActivos = edicionSurcos.map((surcos) => {
      return {
        ...surcos,
        isSelected: !flag
      }
    });
    setNuevosSurcos(surcosActivos);
  };

  const seleccionarSurco = (row) => {
    const index = nuevosSurcos.findIndex((surco) => surco.id === row.id);
    if (index !== -1) {
      const edicionSurcos = [...nuevosSurcos];
      edicionSurcos[index].isSelected = !edicionSurcos[index].isSelected;
      setNuevosSurcos(edicionSurcos);
    }
  }

  const manejadorSurco = (e, index) => {
    const edicionSurco = [...nuevosSurcos];
    edicionSurco.filter(({ activo }) => activo)[index][e.target.name] = e.target.value;
    setNuevosSurcos(edicionSurco);
  };

  const customGuardar = () => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmSurcos'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;
    const validos = validarNombresRepetidos();
    if (!validos) {
      return toast.warning(NOMBRE_SURCO_REPETIDO);
    }

    guardar(nuevosSurcos);
  };

	useEffect(() => {
		setNuevosSurcos(
			[...surcos].map((surcos) => {
				return {
					...surcos,
					isSelected: false,
				};
			}),
		);
	}, [surcos, mostrar]);

  return (
    <RightDrawer
      paperClassName={classes.paperDrawer}
      isOpen={mostrar}
      header={<HeaderSubcatalogo
        titulo="Surcos"
        subtitulo="Configuración"
        guardar={customGuardar}
        cerrar={cancelar}
        agregar={agregar}
        eliminar={eliminarTodoSurcos}
        anchor={anchorConfirmacion}
        onClose={handleCancelar}
        openConfirmacion={handleConfirmacion}
        textField={{
          label: 'Cantidad de surcos',
          disabled: true,
          name: 'txtCantidadSurcos',
          value: nuevosSurcos.filter(({ activo }) => activo).length
        }}
      />}>
      <div id="frmSurcos">
        <Tabla
          showCheckbox
          headers={cabeceros}
          rows={nuevosSurcos.filter(({ activo }) => activo)}
          hidePaginator
          showActions={false}
          onSelectAll={seleccionarTodos}
          onSelect={seleccionarSurco}
        />
      </div>
    </RightDrawer>
  );
};

TablaSurco.propTypes = {
  mostrar: propTypes.bool.isRequired,
  guardar: propTypes.func.isRequired,
  cancelar: propTypes.func.isRequired,
  surcos: propTypes.array.isRequired,
  tablaID: propTypes.oneOfType([propTypes.string, propTypes.number]),
};

TablaSurco.defaultProps = {
  tablaID: null,
};

export default TablaSurco;
