import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

import Sidebar from '../../componentes/Sidebar';

import styles from './styles';

const Wrapper = ({ children, classes: customClasses, raw }) => {
  const classes = styles();

  return (
    <Box component="div" className={clsx(classes.root, customClasses.root, { raw })}>
      { !raw && <Sidebar /> }
      <Box component="main" className={clsx(classes.main, customClasses.main, { raw })}>
        { children }
      </Box>
    </Box>
  )
};

Wrapper.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape({
    root: PropTypes.string,
    main: PropTypes.string,
  }).isRequired,
  raw: PropTypes.bool,
};

Wrapper.defaultProps = {
  children: null,
  classes: {
    root: '',
    main: '',
  },
  raw: false,
};

export default React.memo(Wrapper);
