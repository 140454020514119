import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: paleta.sidebar.backgroundColor,
    display: 'flex',
    height: '92vh',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '82vh',
    },
    [theme.breakpoints.only('xs')]: {
      maxHeight: '85vh',
    },
    '&.raw': {
      height: '100%',
    },
  },
  main: {
    paddingBlock: theme.spacing(4),
    paddingInline: theme.spacing(6),
    width: '100%',
    gap: theme.spacing(5),

    '&.raw': {
      padding: theme.spacing(0, 4),
    },
  },
}));

export default styles;
