import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import pointInPolygon from 'point-in-polygon';

import Agrupador from '../Agrupador';
import HeaderSubcatalogo from '../HeaderSubcatalogo';
import Mapa from '../Mapa';
import RightDrawer from '../RightDrawer';

import { 
  POLIGONO_DENTRO_INVERNDERO,
  POLIGONO_FUERA_SITIO, POLIGONO_VACIO
} from '../../configuraciones/mensajes';
import paleta from '../../configuraciones/paleta';

import styles from './styles';

const NaveGeolocalizacion = ({
  mostrar, 
  guardar,
  cancelar,
  naveID,
  poligonos,
  setPoligonoInicial,
  poligonoInicial,
}) => {
  const classes = styles();
  const [poligono, setPoligono] = useState(null);

  const validarPoligono = () => {
    let valido = true;
    let mensaje = '';
    const dibujo = poligono.state.polygon.getPath().getArray().map(({ lat, lng }) => {
      const punto = { latitud: lat(), longitud: lng() };
      if (naveID) punto.naveID = naveID;
      return punto;
    });

    
    if (dibujo.length < 3) {
      toast.warning(POLIGONO_VACIO);
      return null;
    }
    
    for (const [index, poligonoInvernadero] of poligonos.entries()) {
      const poligonoContenedor = poligonoInvernadero.path.map(({ lat, lng }) => ([lat, lng]));
      for (const point of dibujo) {
        const puntoDentro = pointInPolygon([point.latitud, point.longitud], poligonoContenedor);
        if (!puntoDentro && index === 0) {
          valido = false;
          mensaje = POLIGONO_FUERA_SITIO;
          break;
        } else if (puntoDentro && index !== 0) {
          mensaje = POLIGONO_DENTRO_INVERNDERO;
          valido = false
        }

        if (!valido) break;
      }
    }

    if (!valido)
      toast.warning(mensaje);
    return valido ? dibujo : null;
  }

  const customGuardar = () => {
    const coordenadas = validarPoligono();
    if ( coordenadas ) {
      setPoligonoInicial([]);
      guardar(coordenadas);
    }
  }

  return (
    <RightDrawer
      paperClassName={classes.paperDrawer}
      isOpen={mostrar}
      header={
        <HeaderSubcatalogo
          titulo="Geolocalización"
          subtitulo="Configuración"
          guardar={customGuardar}
          cerrar={cancelar}
          textField={{
            label: 'Estado',
            disabled: true,
            name: 'txtEstado',
            value: (poligonoInicial.length > 0) ? 'Configurado' : 'Sin configurar' 
          }}
        />
      }
    >
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <Agrupador label="Configuración / Geolocalización">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Mapa
              setRef={setPoligono}
              poligonos={poligonos}
              drawPolygonOptions={{
                strokeColor: paleta.geocerca.primary,
                fillColor: paleta.geocerca.primary,
              }}
              poligonoInicial={poligonoInicial}
              defaultCenter={poligonos.length > 0 ? poligonos[0].path[0] : null}
              zoom={17}
            />
          </Grid>
        </Grid>
      </Agrupador>
    </Grid>
    </RightDrawer>
  );
};

NaveGeolocalizacion.propTypes = {
  /** Indica si el modal se muestra */
  mostrar: propTypes.bool.isRequired,
  /** Función que se ejecuta al hacer clic en guardar */
  guardar: propTypes.func.isRequired,
  /** Función que se ejecuta al hacer clic en cancelar */
  cancelar: propTypes.func.isRequired,
  /** Identificador de la nave */
  naveID: propTypes.oneOfType([propTypes.string, propTypes.number]),
  /** Arreglo con las coordenadas de los poligonos */
  poligonos: propTypes.array,
  /** Arreglo de las coordenadas del poligono inicial */
  poligonoInicial: propTypes.array,
};

NaveGeolocalizacion.defaultProps = {
  naveID: null,
};

export default NaveGeolocalizacion;