import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

import Boton from '../Button';
import Modal from '../Modal';
import TextField from '../TextField';
import Typography from '../Typography';

const Dialog = ({
  titulo,
  descripcion,
  abierto,
  typeInput,
  defaultValue,
  inputLabel,
  autoFocusAceptar,
  onAceptar,
  onCancelar,
}) => {
  const [inputValue, setInputValue] = React.useState(() => defaultValue);
  const [error, setError] = React.useState(false);

  const onChange = React.useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const manejadorAceptar = React.useCallback((e) => {
    e.preventDefault();

    if (typeInput && !inputValue) {
      setError(true);
    } else {
      onAceptar(inputValue);
    }

  }, [inputValue, onAceptar, typeInput]);

  useEffect(() => {
    setInputValue(defaultValue);
    setError(false);
  }, [abierto, defaultValue]);

  return (
    <Modal
      open={abierto}
      handleClose={onCancelar}
      titulo={titulo}
      maxWidth="sm"
    >
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        width="100%"
        padding={1}
        gridGap={20}
        onSubmit={manejadorAceptar}
      >
        {
          descripcion && (
            <Box>
              <Typography variant="body1" component="h6">
                {descripcion}
              </Typography>
            </Box>
          )
        }
        {
          typeInput && (
            <Box display="flex" flexDirection="column" width="100%">
              <TextField
                label={inputLabel}
                type="text"
                name="valor"
                value={inputValue}
                onChange={onChange}
                inputStyles={{ width: '100%' }}
                error={error}
              />
            </Box>
          )
        }

        {/* Acciones */}
        <Box display="flex" gridGap={10} justifyContent="flex-end">
          <Boton isSubmit label="Aceptar" autoFocus={autoFocusAceptar && abierto} />
          { onCancelar && <Boton border onClick={onCancelar} label="Cancelar" /> }
        </Box>
      </Box>
    </Modal>
  )
};

Dialog.propTypes = {
  abierto: PropTypes.bool.isRequired,
  titulo: PropTypes.string.isRequired,
  descripcion: PropTypes.string,
  typeInput: PropTypes.bool,
  inputLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  autoFocusAceptar: PropTypes.bool,
  onAceptar: PropTypes.func.isRequired,
  onCancelar: PropTypes.func,
};

Dialog.defaultProps = {
  descripcion: '',
  typeInput: false,
  inputLabel: '',
  defaultValue: '',
  autoFocusAceptar: false,
  onCancelar: null,
};

export default React.memo(Dialog);
