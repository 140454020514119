const tooltipOptions = {
  padding: 10,
  bodySpacing: 10,
  bodyFont: {
    family: 'Avenir',
    size: 12,
    weight: '600',
  },
  boxPadding: 5,
  titleFont: {
    family: 'Avenir Bold',
    size: 13,
  }
};

export default tooltipOptions;