import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  paperDrawer: {
    minWidth: '80%',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  input: {
    [theme.breakpoints.only('xs')]: {
      width: 70,
    },
  },
  txtID: {
    width: 100,
  }
}));

export default styles;
