import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%'
  },
  title: {
    fontWeight: 'bold',
    lineHeight: 2,
  },
  wrapper: {
    position: 'absolute',
    left: 0,
    top: 0,
    pointerEvents: 'none',
  },
  scrollableContainer: {
    width: '100%',
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
  },
  chartAxis: {
    position: 'absolute',
    left: 0,
    top: 0,
    pointerEvents: 'none',
  },
  legends: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',

    '& > ul': {
      width: '100%',
      gap: 20,
    },
  }
}));

export default styles;