import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  paperDrawer: {
    minWidth: '80%',
  },
  txtID: {
    width: 100,
  },
  paper: {
    border: '1px solid #E6EBF5',
    overflow: 'visible !important',
    boxShadow: '0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d !important',
    '& ul': {
      position: 'relative',
    },
    height: 110,
    minHeight: 110,
    width: 220,
    padding: 10,
  },
  text: {
    fontWeight: 600,
  },
  arrow: {
    position: 'absolute',
    display: 'block',
    width: 8.48528137,
    height: 8.48528137,
    overflow: 'hidden',
    background: '0 0',
    pointerEvents: 'none',
    right: -8.4,
    bottom: '50%',
    transform: 'rotate(-90deg)',
    '& span': {
      boxShadow: '3px 3px 7px #00000012',
      transform: 'translateY(-4.24264069px) rotate(45deg)',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'block',
      width: 6,
      height: 6,
      margin: 'auto',
      backgroundColor: '#FFF',
      content: "' '",
      pointerEvents: 'auto',
      border: '1px solid #E6EBF5',
    },
  },
  btn: {
    margin: theme.spacing(0, 0.5),
    minWidth: 60,
    width: 60,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      margin: '0px 2px'
    },
  },
}));

export default styles;
