import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  root: {
  },
  contenedorMapa: {
    height: '60vh',
  }
}));

export default styles;
