import React, { useMemo, forwardRef, Fragment } from 'react';
import propTypes from 'prop-types';
import { CgMathPercent } from 'react-icons/cg';
import { Box, LinearProgress, Tooltip, Zoom } from '@material-ui/core';
import Typography from '../Typography';
import styles from './styles';

const ContentTooltip = forwardRef((props, ref) => {
	const classes = styles();

	return (
		<div {...props} ref={ref} className={classes.tooltipContainer}>
			<div className={classes.tooltipContent}>
				<Typography style={{ fontSize: 11 }}>
					{parseFloat(props.progreso).toFixed(2)}
				</Typography>
			</div>
			<div className={classes.tooltipContentIcon} variant="button">
				<CgMathPercent size={15} />
			</div>
		</div>
	);
});

const BarraProgreso = ({
	total,
	progreso,
	monitoreo,
	progresoSinFormato,
	detalleMonitoreo,
	mostrarTooltip,
}) => {
	const classes = styles();

	const valorFormateado = useMemo(() => {
		if (total) {
			return ((progreso - 0) * 100) / (total - 0)
		} else {
			return 0
		}
	}, [progreso, total]);

	return (
		<Box display="flex" alignItems="center">
			<Box width="100%" mr={1} className={classes.root}>
				<LinearProgress
					variant="determinate"
					classes={{
						root: classes.progressBar,
						colorPrimary: classes.colorPrimary,
						bar: monitoreo ? classes.monitoreo : classes.bar,
					}}
					value={progresoSinFormato ? parseInt(progreso) : valorFormateado}
				/>
			</Box>
			<Box minWidth={55}>
			{
				mostrarTooltip
				?
				<Tooltip
					arrow
					classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
					TransitionComponent={Zoom}
					title={
						<Fragment>
							<Typography variant="overline" bold>
								{
									monitoreo
									?
									`${detalleMonitoreo?.capturas || 0} CAPTURAS DE ${detalleMonitoreo?.registros || 0}`
									:
									`${progreso} DE ${total}`
								}
							</Typography>
						</Fragment>
					}
				>
					<ContentTooltip progreso={monitoreo ? progreso : valorFormateado} />
				</Tooltip>
				:
				<Typography variant="body2" color="textSecondary">
					{monitoreo ? '' : progreso}
				</Typography>
			}
			</Box>
		</Box>
	);
};

BarraProgreso.propTypes = {
	/** Progreso de la barra */
	progreso: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
	/** Valor total de la barra de progreso */
	total: propTypes.number,
	/** Valor de la tabla estado monitoreo */
	monitoreo: propTypes.bool,
	/** Indica si se mostrará un tooltip */
	mostrarTooltip: propTypes.bool,
};

BarraProgreso.defaultProps = {
	monitoreo: false,
	mostrarTooltip: false,
};

export default React.memo(BarraProgreso);
