import semaforo from '../constantes/semaforos';

const paleta = {
  textField: {
    text: '#657381',
    textBlack: '#33404E',
    backgroundColor: '#F5F8FA',
    backgroundColorHover: '#EEF3F7',
    backgroundColorDisabled: '#EAF0F6',
    error: '#e74c3c',
    border: '#E1E7ED',
  },
  bar: {
    primary: '#092b48',
    secondary: '#4B5D6E',
    border: 'rgba(36, 36, 36, 0.1)',
    icono: '#33404E',
    iconoBorder: '#E1E7ED',
    user: '#409A8E',
    elements: '#8CA0B6',
    details: '#EBEFF2',
    point: '#BAB6B6',
    pronostico: '#E9EEFA',
    loading: '#003973'
  },
  sidebar: {
    active: '#EAF0F6',
    backgroundColor: '#FFF',
  },
  activo: {
    primary: '#7C98B6',
    pronostico: '#2053d2'
  },
  tabla: {
    bordertd: '#CBD6E2',
    rowError: '#ffbaba',
  },
  geocerca: {
    primary: '#0073e6',
    secondary: '#D80000',
    tertiary: '#ffd426',
    container: '#90A0B0',
    cuadrante: '#F5F8FA',
  },
  graficos: {
    barPrimary: '#fa6385',
    barSelected: '#48affa',
    dataLabel: '#2e323a',
    dataLabelText: '#dedfe0',
    linePrimary: '#6996b3',
    lineSecondary: '#7bc8a4',
    lineTertiary: '#f4a261',
  },
  configuraciones: {
    rojo: 'hsl(347, 100%, 63%)',
    [semaforo.rojo]: 'hsl(347, 100%, 63%)',

    amarillo: 'hsl(42, 100%, 60%)',
    [semaforo.amarillo]: 'hsl(42, 100%, 60%)',

    verde: 'hsl(180, 70%, 48%)',
    [semaforo.verde]: 'hsl(180, 70%, 48%)',

    azul: 'hsl(210, 100%, 50%)',

    gris: 'hsl(210, 10%, 80%)',
    [semaforo.indefinido]: 'hsl(210, 10%, 80%)',

    generativo: 'hsl(347, 100%, 63%)',
    [semaforo.generativo]: 'hsl(347, 100%, 63%)',

    vegetativo: 'hsl(30, 96%, 62%)',
    [semaforo.vegetativo]: 'hsl(30, 96%, 62%)',

    balanceado: 'hsl(180, 70%, 48%)',
    [semaforo.balanceado]: 'hsl(180, 70%, 48%)',

    disabled: '#DFE5EA',
    texto: '#4B5D6E',

    stroke: {
      Verde: 'hsl(180, 70%, 65%)',
      Amarillo: 'hsl(42, 100%, 70%)',
      Rojo: 'hsl(347, 100%, 70%)',
      Azul: 'hsl(210, 100%, 65%)',
      Gris: 'hsl(210, 10%, 60%)',
      Indefinido: 'hsl(210, 10%, 60%)',
      Balanceado: 'hsl(180, 70%, 65%)',
      Vegetativo: 'hsl(30, 96%, 70%)',
      Generativo: 'hsl(347, 100%, 70%)',
    },

    label: {
      rojo: '#fc2651',
      amarillo: '#f7d33e',
      verde: '#52d9b1',
      indefinido: '#7F8A95',
      generativo: '#fc2651',
      vegetativo: '#fa9c3c',
      balanceado: '#52d9b1',
    }
  },
  estatusTemporadas: {
    proxima: '#0080ff',
    enCurso: '#ffc233',
    finalizada: '#218838',
    disabled: '#7F8A95',
  },
  changeOpacity: (hslStr, opactity) => {
    const arr = hslStr.match(/\d+/g)?.map(Number);
    if (!arr) return hslStr;
    const [hue, saturation, lightness] = arr;
    const hslObject = { hue, saturation, lightness };
    hslObject.lightness = opactity;
    return `hsl(${hslObject.hue}, ${hslObject.saturation}%, ${hslObject.lightness}%)`;
  },
  rangos: (color) => {
    if(color === 'rojo') return 'hsl(347, 100%, 63%)';
    if(color === 'amarillo') return 'hsl(42, 100%, 60%)';
    if(color === 'verde') return 'hsl(180, 70%, 48%)';
    return '#7F8A95';
  },
  setAlphaHSL: (hslStr, alpha) => {
    const arr = hslStr.match(/\d+/g)?.map(Number);
    if (!arr) return hslStr;
    const [hue, saturation, lightness] = arr;
    const hslObject = { hue, saturation, lightness };
    hslObject.alpha = alpha;
    return `hsla(${hslObject.hue}, ${hslObject.saturation}%, ${hslObject.lightness}%, ${hslObject.alpha})`;
  },
  setLightenssHSL: (hslStr, lightness) => {
    const arr = hslStr.match(/\d+/g)?.map(Number);
    if (!arr) return hslStr;
    const [hue, saturation] = arr;
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  },
  zoomBackgroundColor: '#cad3db90',
};

export default paleta;
