import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  paperDrawer: {
    minWidth: '50%',
  },
  txtID: {
    width: 100,
  },
  paper: {
    border: '1px solid #E6EBF5',
    overflow: 'visible !important',
    boxShadow: '0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d !important',
    '& ul': {
      position: 'relative',
    },
    height: 110,
    minHeight: 110,
    width: 220,
    padding: 10,
  },
  text: {
    fontWeight: 600,
  },
  arrow: {
    position: 'absolute',
    display: 'block',
    width: 8.48528137,
    height: 8.48528137,
    overflow: 'hidden',
    background: '0 0',
    pointerEvents: 'none',
    right: -8.4,
    bottom: '50%',
    transform: 'rotate(-90deg)',
    '& span': {
      boxShadow: '3px 3px 7px #00000012',
      transform: 'translateY(-4.24264069px) rotate(45deg)',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'block',
      width: 6,
      height: 6,
      margin: 'auto',
      backgroundColor: '#FFF',
      content: "' '",
      pointerEvents: 'auto',
      border: '1px solid #E6EBF5',
    },
  },
  btn: {
    margin: theme.spacing(0, 0.5),
    minWidth: 60,
    width: 60,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      margin: '0px 2px'
    },
  },


  title: {
    fontWeight: 600,
    fontSize: 16,
  },
  groupedOutlinedHorizontal: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderColor: paleta.textField.border,
    borderWidth: 2,

    '&:hover': {
      borderColor: paleta.textField.border,
      borderWidth: 2,
    },
  },
  contentRoot: {
    padding: theme.spacing(2),
    borderRight: `2px solid ${paleta.textField.border}`,
    borderLeft: `2px solid ${paleta.textField.border}`,
    borderBottom: `2px solid ${paleta.textField.border}`,
    height: '100%',
  },
  tabButton: {
    color: paleta.textField.text,
    fontWeight: 600,
    fontSize: 13,

    '&.active': {
      color: paleta.bar.primary,
      backgroundColor: paleta.textField.backgroundColorHover,
    },
    '&.error': {
      color: paleta.textField.error,

      '& > *': {
        color: paleta.textField.error,
      },
    },
  },
  iconTab: {
    marginBottom: 5,
  },
  titleContainer: {
    padding: theme.spacing(4, 2),
  },
  contentTitle: {
    fontWeight: 600,
    fontSize: 14,
    display: 'inline-block',
    color: paleta.textField.text,
  },
  marcador: {
    aspectRatio: '1/1',
    width: 25,
    height: 25,
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: 15,
  },
  marcadorAmarillo: {
    backgroundColor: 'yellow',
    marginTop: 4,
  },
  marcadorBlue: {
    marginTop: -4,
    backgroundColor: 'blue',
  },
  marcadorRosa: {
    marginTop: -4,
    backgroundColor: 'magenta',
  },
  marcadorVerde: {
    marginTop: -4,
    backgroundColor: 'green',
  },
  marcadorRojo: {
    marginTop: -4,
    backgroundColor: 'red',
  },
  mapa: {
    height: '50vh',
    width: '100%',
  },
  error: {
    color: paleta.textField.error,
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',

    '& button': {
      alignSelf: 'flex-start',
      marginLeft: 20,
    },
  },
  instruccionContainer: {
    padding: theme.spacing(0, 5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  stepTitle: {
    height: 0,
    transition: 'height 0.5s',
  },
  stepError: {
    color: paleta.textField.error,
    height: 18,
  }
}));

export default styles;
