import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';
const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: `${paleta.bar.primary}`,
  },
  agrupador: {
    marginInline: 5,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 30,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 10
  },
  labelTitle: {
    color: paleta.textField.text,
    fontSize: '16px',
    fontWeight: 'bold',
    paddingBottom: theme.spacing(1),
    display: 'inline-block'
  },
  input:{
    marginTop: -10,
    color: 'red',
    fontSize:14,
    height: '80%',
    marginLeft: '2vh',
    fontWeight: 'bold',
    width: '100%',
    background: paleta.bar.details,
    '& fieldset': {
      border: `0px solid #FFFF !important`,
      boxShadow: "none"
    },
    '&:hover, &:focus': {
      backgroundColor: paleta.bar.details,
      '& fieldset': {
        border: `1px solid ${paleta.bar.details} !important`,
        boxShadow: "none"
      },
      boxShadow: "none"
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid #FFFF`,
      boxShadow: "none"
    },
    '& .MuiSelect-root': {
      minHeight: 42,
      height: 42,
      padding: '0px !important',
      display: 'flex',
      alignItems: 'center',
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 15,
  },
  title: {
    marginLeft: 30,
    fontSize: 15,
    color: '#0F0E0E',
    fontWeight: 600
  },
  table: {
    borderRadius: 5,
    paddingTop: 1,
    marginBottom: 10,
    paddingBottom:10,
    textAlign: 'left',
    backgroundColor: paleta.bar.details,
    border: `0px solid ${paleta.bar.border}`,
  },
  detailTable: {
    borderRadius: 8,
    textAlign: 'left',
    backgroundColor: paleta.bar.details,
    border: `1px solid #C8CBCD`,
  },
  grafica: {
    paddingLeft: 20,
  },
  chartTitle: {
    color: paleta.textField.text,
    fontSize: 14,
    fontWeight: 600,
    paddingLeft: '10%',
    marginBottom: 15,
  },
  titleTable: {
    fontSize: 13,
    color: paleta.textField.text,
  },
  chip:{
    backgroundColor: '#49B1F2',
    width: 100,
    height: 25,
  },
  chipText: {
    color: '#FFF',
    textTransform: 'uppercase',
  },
  contenedor:{
    backgroundColor: '#FFFF',
    width: '100%',
    height: 300,
    paddingTop:5,
    paddingBottom:5,
    borderTop: `1px solid ${paleta.tabla.bordertd}`,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  dividerList: {
    border: '1px',
    borderStyle: 'dashed',
    borderColor: '#E8EBEC',
    backgroundColor: '#FFFF',
  },
  listItem: {
    cursor: 'pointer',
    justifyContent: 'space-between',
    transition: 'background 200ms',
    '&:hover': {
      backgroundColor: '#00000011',
    },
    '&:active': {
      backgroundColor: '#00000033',
    },
  },
  listText: {
    transition: 'color 200ms, fontWeight 200ms',
  },
}));

export default styles;
