import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { IconButton } from '@material-ui/core';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { toast } from 'react-toastify';

import AgrupadorListados from '../../contenedores/AgrupadorListados';
import ListaConfiguracion from '../../componentes/ListaConfiguracion';
import RangoTextFields from '../../componentes/RangoTextFields';

import { setRegistros } from '../../ducks/listados';

import axios from '../../configuraciones/axios';
import endpoints, { 
  CONFIGURACIONES_FENOLOGIA,
  CULTIVOS, 
  SITIOS
} from '../../configuraciones/endpoints';
import paleta from '../../configuraciones/paleta';

import { RANGO_CONFIG_REGEX } from '../../utilidades/regex';
import { parseFloatProperties } from '../../utilidades/functions';

import styles from './style';

const validarRangos = ({
  rangoEstadoGenerativoMin,
  rangoEstadoGenerativoMax,
  rangoEstadoBalanceadoMin,
  rangoEstadoBalanceadoMax,
  rangoEstadoVegetativoMin,
  rangoEstadoVegetativoMax,
  fenologiaID,
}) => {
  if ( !rangoEstadoBalanceadoMin && !rangoEstadoBalanceadoMax && 
       !rangoEstadoGenerativoMin && !rangoEstadoGenerativoMax && 
       !rangoEstadoVegetativoMin && !rangoEstadoVegetativoMax
  ) return fenologiaID;
  if ( rangoEstadoGenerativoMin >= rangoEstadoGenerativoMax ) return fenologiaID;
  if ( rangoEstadoBalanceadoMin >= rangoEstadoBalanceadoMax ) return fenologiaID;
  if ( rangoEstadoVegetativoMin >= rangoEstadoVegetativoMax ) return fenologiaID;

  else if ( rangoEstadoGenerativoMin >= rangoEstadoBalanceadoMin && rangoEstadoGenerativoMin <= rangoEstadoBalanceadoMax ) return fenologiaID;
  else if ( rangoEstadoGenerativoMax >= rangoEstadoBalanceadoMin && rangoEstadoGenerativoMax <= rangoEstadoBalanceadoMax ) return fenologiaID;
  else if ( rangoEstadoGenerativoMin >= rangoEstadoVegetativoMin && rangoEstadoGenerativoMin <= rangoEstadoVegetativoMax ) return fenologiaID;
  else if ( rangoEstadoGenerativoMax >= rangoEstadoVegetativoMin && rangoEstadoGenerativoMax <= rangoEstadoVegetativoMax ) return fenologiaID;

  else if ( rangoEstadoBalanceadoMin >= rangoEstadoVegetativoMin && rangoEstadoBalanceadoMin <= rangoEstadoVegetativoMax ) return fenologiaID;
  else if ( rangoEstadoBalanceadoMax >= rangoEstadoVegetativoMin && rangoEstadoBalanceadoMax <= rangoEstadoVegetativoMax ) return fenologiaID;
  else if ( rangoEstadoBalanceadoMin >= rangoEstadoGenerativoMin && rangoEstadoBalanceadoMin <= rangoEstadoGenerativoMax ) return fenologiaID;
  else if ( rangoEstadoBalanceadoMax >= rangoEstadoGenerativoMin && rangoEstadoBalanceadoMax <= rangoEstadoGenerativoMax ) return fenologiaID;

  else if ( rangoEstadoVegetativoMin >= rangoEstadoBalanceadoMin && rangoEstadoVegetativoMin <= rangoEstadoBalanceadoMax ) return fenologiaID;
  else if ( rangoEstadoVegetativoMax >= rangoEstadoBalanceadoMin && rangoEstadoVegetativoMax <= rangoEstadoBalanceadoMax ) return fenologiaID;
  else if ( rangoEstadoVegetativoMin >= rangoEstadoGenerativoMin && rangoEstadoVegetativoMin <= rangoEstadoGenerativoMax ) return fenologiaID;
  else if ( rangoEstadoVegetativoMax >= rangoEstadoGenerativoMin && rangoEstadoVegetativoMax <= rangoEstadoGenerativoMax ) return fenologiaID;
  
  return;
}

const { configuraciones: { generativo, vegetativo, balanceado } } = paleta;

const ConfiguracionFenologias = ({ history, location }) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { sitioID } =  queryString.parse(location.search);
  const { registros } = useSelector(store => store.listados);

  const [tabs, setTabs] = useState({});
  const [cultivos, setCultivos] = useState([]);
  const [datosLista, setDatosLista] = useState({
    labelProp: '',
    headerLista: '',
    title: '',
    subTitle: '',
    data: [],
  });
  const [formErrors, setFormErrors] = useState([]);

  const handleTabChange = (key) => {
    setFormErrors([]);
  }

  const toggleConfig = (data, index) => {
    const nuevoEstado = registros;
    const valorActual = (nuevoEstado.rows[index]['configuracionesFenologia'].length > 0)
      ? nuevoEstado.rows[index]['configuracionesFenologia'][0]
      : null;
    
    nuevoEstado.rows[index]['configuracionesFenologia'][0] = (valorActual) 
      ? { ...valorActual, estatus: !valorActual.estatus }
      : { ...valorActual, estatus: true };
        
    if ( valorActual?.estatus ){
      setFormErrors(formErrors.filter(fila => fila !== index));
    }

    dispatch(setRegistros(nuevoEstado));
  }

  const customActions = [{
    onClick: toggleConfig,
    transform: (data, index) => {
      if ( !data.configuracionesFenologia ) return null;
      return (
        <IconButton
          style={{padding: 0}}
          key="toggleConfig"
          size="medium"
          className={classes.btnAction}
          onClick={() => toggleConfig(data, index)}
        >
          {
            data.configuracionesFenologia.length <= 0 || !data.configuracionesFenologia[0].estatus
            ?
            <AiOutlineEyeInvisible />
            :
            <AiOutlineEye />
          }
        </IconButton>
      )
    }
  }];

  const guardar = async (tabActivo) => {
    try {
      const rowErrors = [];
      
      const filtro = registros.rows.filter(configuracion => {
        if ( configuracion.configuracionesFenologia.length > 0 ){
          if ( !configuracion.configuracionesFenologia[0]?.activo ){
            if ( configuracion.configuracionesFenologia[0]?.estatus ) {
              return true;
            }
            return false;
          }
          return true;
        }
        return false;
      });

      if ( filtro.length <= 0 ) return;

      const configuraciones = filtro.map(configuracion => {
        if ( !configuracion.configuracionesFenologia[0].estatus ){
          return {
            fenologiaID: configuracion.id,
            sitioID: sitioID,
            cultivoID: tabActivo,
            estatus: false,
          }
        }
        return {
          ...parseFloatProperties(configuracion.configuracionesFenologia[0]),
          fenologiaID: configuracion.id,
          sitioID: sitioID,
          cultivoID: tabActivo,
        }
      });

      configuraciones.forEach(configuracion => {
        const id = configuracion.estatus && validarRangos(configuracion);
        id && rowErrors.push(registros.rows.findIndex(r => r.id === id));
      });

      if ( rowErrors.length > 0 ) {
        setFormErrors(rowErrors);
        return toast.error(`Rangos de estado inválidos.`);
      }

      const data = {
        configuracionesFenologia: configuraciones,
        sitioID: sitioID,
        cultivoID: tabActivo,
      }
      await axios.put(CONFIGURACIONES_FENOLOGIA, data);
      setFormErrors([]);
    } catch {}
  };

  const consultarDatosIniciales = async () => {
    try {
      const promesas = [
        axios.get(endpoints.base.busqueda(SITIOS)),
        axios.get(endpoints.base.busqueda(CULTIVOS)),
      ];
      const [ sitiosDb, cultivosDb ] = await Promise.all(promesas);

      setDatosLista({
        labelProp: "nombre",
        headerLista: "Nombre",
        title: "Sitios",
        subTitle: "Configuración de fenologías",
        data: sitiosDb,
      });
      setCultivos(cultivosDb);
    
    } catch {}
  };

  useEffect(() => {
    consultarDatosIniciales();

    return () => {
      dispatch(setRegistros({rows: [], count: 0}));
      setFormErrors([]);
    }
  }, [dispatch]);

  useEffect(() => {
    const handleChange = (e, index) => {
      const propiedad = e.target.name;
      const nuevoEstado = registros;
      nuevoEstado.rows[index].configuracionesFenologia[0] = {
        ...nuevoEstado.rows[index].configuracionesFenologia[0],
        [propiedad]: e.target.value,
      };
      dispatch(setRegistros(nuevoEstado));
    };
    const formatearTabs = (prv, cur) => {
      return {
        ...prv,
        [cur.id]: {
          name: cur.nombre,
          apiKey: CONFIGURACIONES_FENOLOGIA,
          params: { cultivoID: cur.id },
          cabeceros: [
            { label: 'Parámetro', key: 'nombre' },
            { 
              label: 'Estado Generativo',
              align: 'center',
              transform: (data, index) => {
                if ( !data.configuracionesFenologia ) return null;
                return (
                  <RangoTextFields 
                    backgroundColor={generativo}
                    names={['rangoEstadoGenerativoMin', 'rangoEstadoGenerativoMax']}
                    formValues={{
                      min: data?.configuracionesFenologia[0]?.rangoEstadoGenerativoMin,
                      max: data?.configuracionesFenologia[0]?.rangoEstadoGenerativoMax
                    }}
                    activo={data?.configuracionesFenologia[0]?.estatus}
                    onChange={(e) => handleChange(e, index)}
                    inputProps={{ regex: RANGO_CONFIG_REGEX, regexonchange: 'true' }}
                  />
                )
              }
            },
            { 
              label: 'Estado Balanceado',
              align: 'center',
              transform: (data, index) => {
                if ( !data.configuracionesFenologia ) return null;
                return (
                  <RangoTextFields 
                    backgroundColor={balanceado}
                    names={['rangoEstadoBalanceadoMin', 'rangoEstadoBalanceadoMax']}
                    formValues={{
                      min: data?.configuracionesFenologia[0]?.rangoEstadoBalanceadoMin,
                      max: data?.configuracionesFenologia[0]?.rangoEstadoBalanceadoMax
                    }}
                    activo={data?.configuracionesFenologia[0]?.estatus}
                    onChange={(e) => handleChange(e, index)}
                    inputProps={{ regex: RANGO_CONFIG_REGEX, regexonchange: 'true' }}
                  />
                )
              }
            },
            { 
              label: 'Estado Vegetativo',
              align: 'center',
              transform: (data, index) => {
                if ( !data.configuracionesFenologia ) return null;
                return (
                  <RangoTextFields 
                    backgroundColor={vegetativo}
                    names={['rangoEstadoVegetativoMin', 'rangoEstadoVegetativoMax']}
                    formValues={{
                      min: data?.configuracionesFenologia[0]?.rangoEstadoVegetativoMin,
                      max: data?.configuracionesFenologia[0]?.rangoEstadoVegetativoMax
                    }}
                    activo={data?.configuracionesFenologia[0]?.estatus}
                    onChange={(e) => handleChange(e, index)}
                    inputProps={{ regex: RANGO_CONFIG_REGEX, regexonchange: 'true' }}
                  />
                )
              }
            },
          ]
        }
      }
    }

    setTabs(cultivos.reduce(formatearTabs, {}));
  }, [cultivos, dispatch, registros, sitioID]);

  return (
    <AgrupadorListados 
      titulo={"Fenologías por Cultivos"}
      subtitulo={"Rangos de estados"}
      placeHolder="Búsqueda por nombre"
      mostrarLista={<ListaConfiguracion datosLista={datosLista} />}
      history={history}
      location={location}
      tabsData={tabs}
      rowErrors={formErrors}
      customActions={customActions}
      guardar={guardar}
      handleTabChange={handleTabChange}
    />
  );
};

export default React.memo(ConfiguracionFenologias);