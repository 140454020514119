/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';

import CabeceroReportes from '../../componentes/CabeceroReportes';
import ListaConfiguracion from '../../componentes/ListaConfiguracion';
import Tabla from '../../componentes/Table';

import { useFetchReporte } from '../../hooks/useFetchReporte';

import { setRegistros } from '../../ducks/listados';

import { CamposValidados } from '../../utilidades/functions';

import general from '../../configuraciones/general';
import Default from '../../contenedores/Default';
import axios from '../../configuraciones/axios';
import endpoints, {
	NIVELESPOLINIZACION,
	SECCIONES,
} from '../../configuraciones/endpoints';

import { DatePickerTypes } from '../../constantes/datePickerTypes';

const datosLista = {
	labelProp: 'nombre',
	headerLista: 'Nombre',
	title: 'Reporte de Polinizaciones',
	data: [
		{
			id: 1,
			nombre: 'Polinizaciones detallado',
			subTitle: 'Listado por nivel.',
		},
		{
			id: 2,
			nombre: 'Polinizaciones agrupado',
			subTitle: 'Listado por nivel.',
		},
	],
};

const cabeceros = [
	{ label: 'SITIO', key: 'sitio', align: 'left' },
	{ label: 'INVERNADERO', key: 'invernadero', align: 'left' },
	{ label: 'NAVE', key: 'Nave', align: 'left' },
	{ label: 'SECCIÓN', key: 'Seccion', align: 'left' },
	{ label: 'NIVEL', key: 'Nivel', align: 'left' },
	{ label: 'FLORES', key: 'flores', align: 'left' },
];

const ReportePolinizacionDetallado = ({ history }) => {
  const dispatch = useDispatch();
	const { registros } = useSelector((store) => store.listados);

	const location = useLocation();
	const isMounted = useRef(false);
  const paginaActual = useRef(1);

	const { data, formData, onChange, onDelete } = useFetchReporte(
		{
			secciones: endpoints.base.busqueda(SECCIONES),
			niveles: endpoints.base.busqueda(NIVELESPOLINIZACION),
		},
		endpoints.reportePolinizaciones(),
	);

	const validarData = useCallback(() => CamposValidados(formData), [formData]);

	const handleTodosSelected = useCallback(() => {
		const { naves, fecha } = formData;
		const secciones = formData.secciones.includes(0)
			? data.secciones
					.filter((seccion) => seccion.id !== 0)
					.map((seccion) => seccion.id)
			: formData.secciones;

		const niveles = formData.niveles.includes(0)
			? data.niveles.filter((nivel) => nivel.id !== 0).map((nivel) => nivel.id)
			: formData.niveles;

		const params = {
			naves,
			niveles,
      secciones,
      fecha: moment(fecha).format('W-GGGG'),
		};
		return params;
	}, [formData, data]);

	const buscar = async () => {
		try {
      if (validarData()) return;

			const params = handleTodosSelected();
			const response = await axios.post(endpoints.reportePolinizaciones(), {
				...params,
				registrosPorPagina: general.ELEMENTOS_POR_PAGINA,
				pagina: paginaActual.current,
      });

			dispatch(setRegistros({ rows: response.rows, count: response.count }));
		} catch {}
	};

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
			paginaActual.current = 1;
			dispatch(setRegistros({ rows: [], count: 0 }));
		};
	}, []);

	useEffect(() => {
		if (isMounted.current) {
			const query = queryString.parse(location.search);
			if (query.pagina === paginaActual || !query.pagina) return;
			paginaActual.current = query.pagina;
			buscar();
		}
	}, [location]);

	const onSubMenuClick = (id) => {
		if (id === 1) {
			history.push(`/reportes/polinizacion/detallado`);
		} else {
			history.push(`/reportes/polinizacion/agrupado`);
		}
	};

	return (
		<Default
			mostrarLista={
				<ListaConfiguracion
					datosLista={datosLista}
					onClick={onSubMenuClick}
					initOpcion={0}
				/>
			}
		>
			<CabeceroReportes
				data={data}
				formData={formData}
				buscar={buscar}
				onChange={onChange}
				onDelete={onDelete}
				handleTodosSelected={handleTodosSelected}
				selects={{
					sitio: { width: 4, show: true },
					invernadero: { width: 4, show: true },
					nave: { width: 4, show: true },
					seccion: { width: 2, show: true },
					nivel: { width: 3, show: true },
					fecha: { width: 3, show: true, type: DatePickerTypes.week },
				}}
				endpoint={endpoints.reportePolinizaciones()}
				method='POST'
			/>
			<Tabla
				headers={cabeceros}
				rows={registros.rows.length > 0 ? registros.rows : []}
				count={registros.count}
				showActions={false}
			/>
		</Default>
	);
};

export default ReportePolinizacionDetallado;
