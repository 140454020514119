import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import moment from 'moment';
import { IconButton, Slide } from '@material-ui/core';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import useDebounce from '../../../../hooks/useDebounce';

import Button from '../../../../componentes/Button';
import CustomSelect from '../../../../componentes/Select';
import Modal from '../../../../componentes/Modal';
import Table from '../../../../componentes/Table';
import TextField from '../../../../componentes/TextField';
import Typography from '../../../../componentes/Typography';

import TemporadaDetalles from '../../../TemporadaDetalles';

import axios from '../../../../configuraciones/axios';
import endpoints from '../../../../configuraciones/endpoints';

import styles from './styles';

const AyudaTemporadas = ({ abierto, onCancelar }) => {
  const classes = styles();

  const history = useHistory();
  const abortControllerRef = useRef(new AbortController());

  const [temporadaID, setTemporadaID] = useState(null);
  const [temporadaSeleccionada, setTemporadaSeleccionada] = useState(null);
  const [sitios, setSitios] = useState([]);
  const [historial, setHistorial] = useState({ count: 0, rows: [] });
  const [txtBusqueda, setTxtBusqueda] = useState('');
  const [filtros, setFiltros] = useState({
    q: '',
    sitio: '',
    pagina: 1,
    registrosPorPagina: '',
  });
  const debouncedBusqueda = useDebounce(txtBusqueda, 300);

  const consultarHistorial = useCallback(async () => {
    try {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;

      const consultas = [
        axios.get(endpoints.obtenerHistorialTemporadas(), {
          signal,
          params: filtros,
        }),
        axios.get(endpoints.obtenerSitiosHistorico(), { signal }),
      ];

      const [historial, sitios] = await Promise.all(consultas);

      setHistorial(historial);
      setSitios([
        { id: '', nombre: 'Todos' },
        ...sitios,
      ]);
    } catch (error) {
      if (error.isCancel) return;
    }
  }, [filtros]);

  const handleChangeBusqueda = useCallback((e) => {
    setTxtBusqueda(e.target.value);
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;

    setFiltros(prev => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const verDetalle = useCallback((id) => () => {
    setTemporadaID(id);
  }, []);

  const onSeleccionarTemporada = useCallback(({ id }) => {
    setTemporadaSeleccionada(id);
  }, []);

  const cabeceros = useMemo(() => [
    {
      label: 'Nombre',
      key: 'nombre',
    },
    {
      label: 'Fecha de Fin',
      transform: ({ fechaFin }) => moment(fechaFin).endOf('day').format('LL'),
    },
    {
      label: 'Fecha de Inicio',
      transform: ({ fechaInicio }) => moment(fechaInicio).startOf('day').format('LL'),
    },
    {
      label: 'Sitio',
      key: 'sitio',
    },
    {
      label: '',
      transform: ({ id }) => (
        <IconButton style={{ padding: 0 }} onClick={verDetalle(id)}>
          <MdOutlineRemoveRedEye />
        </IconButton>
      )
    }
  ], [verDetalle]);

  const cargarHistorial = useCallback(() => {
    const ruta = '/temporadas/formulario';
    history.push({
      pathname: ruta,
      state: { temporadaPlantillaID: temporadaSeleccionada },
    });
  }, [history, temporadaSeleccionada]);

  useEffect(() => {
    consultarHistorial();
    return () => abortControllerRef.current.abort();
  }, [consultarHistorial]);

  useEffect(() => {
    setFiltros(prev => ({
      ...prev,
      q: debouncedBusqueda,
    }));
  }, [debouncedBusqueda]);

  return (
    <Modal
      open={abierto}
      handleClose={onCancelar}
      titulo="Seleccione una temporada"
    >
      {
        temporadaID
          ? (
            <Slide direction="left" in mountOnEnter unmountOnExit>
              <Box height={600} width="100%" maxHeight={600} overflow="auto">
                <TemporadaDetalles id={temporadaID} onRegresar={verDetalle(null)} />
              </Box>
            </Slide>
          ) : (
            <>
              <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap" gridGap={40} px={2}>
                <Typography component="h2">
                  Histórico de Temporadas
                  <Typography variant="body1" component="small" className={classes.small}>
                    {historial.count}
                    {' '}
                    {historial.count === 1 ? 'elemento' : 'elementos'}
                  </Typography>
                </Typography>
                <Box display="flex" alignItems="center" flexWrap="wrap" gridGap={10}>
                  <TextField
                    name="q"
                    label="Nombre"
                    placeholder="Buscar por nombre"
                    className={clsx(classes.input, classes.search)}
                    onChange={handleChangeBusqueda}
                    value={txtBusqueda}
                  />
                  <CustomSelect
                    label="Sitios"
                    options={sitios}
                    className={clsx(classes.input, classes.select)}
                    name="sitio"
                    valueProp="id"
                    labelProp="nombre"
                    value={filtros.sitio}
                    onChange={handleChange}
                    placeHolder="Todos"
                  />
                </Box>
              </Box>
              <Box marginTop={5} px={2}>
                <Table
                  rows={historial.rows}
                  customClass={classes.tabla}
                  emptyMessage="No se encontraron temporadas en el historial"
                  headers={cabeceros}
                  showActions={false}
                  hidePaginator
                  minRows={5}
                  onSelectRow={onSeleccionarTemporada}
                  editingItemId={temporadaSeleccionada}
                />
              </Box>
              {
                temporadaSeleccionada && (
                  <Box display="flex" flex={1} justifyContent="center" gridGap={10} marginTop={4}>
                    <Button label="Seleccionar" onClick={cargarHistorial} />
                    <Button border label="Cancelar" onClick={() => setTemporadaSeleccionada(null)} />
                  </Box>
                )
              }
            </>
          )
      }
    </Modal>
  )
};

export default React.memo(AyudaTemporadas);
