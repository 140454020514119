import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  datePickerPaper: {
    '& .MuiPickersBasePicker-pickerView': {
      marginBottom: 10,
    }
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
  },
  toolbarTitle: {
    color: '#FFF',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 'bold',
  },
  toolbarButton: {
    padding: 0,
    width: 'auto',
    minWidth: 0,
    height: 'auto',
  },
  yearPicker: {
    height: 310,
    overflow: 'auto',
  },
  toolbarDateText: {
    marginTop: 3,
    color: '#FFF',
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  label: {
    marginTop: 5,
    fontSize: 12,
    fontWeight: 'bold',
  },
  input: {
    minHeight: 42,
    marginTop: 10,
    backgroundColor: paleta.textField.backgroundColor,
    borderRadius: 5,
    outline: 'none',
    width: '95%',
    padding: '0 10px',
    border: `1px solid ${paleta.textField.border}`,
    '&:hover, &:focus': {
      backgroundColor: paleta.textField.backgroundColorHover,
    },
    '&.disabled': {
      backgroundColor: paleta.textField.backgroundColorDisabled,
    },
    '&.error': {
      border: `1px solid ${paleta.textField.error}`,
    },
  },
  nativeInput: {
    color: paleta.textField.textBlack,
    '&::placeholder': {
      color: 'initial',
    },
  },
}));



export default styles;
