import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Chip } from '@material-ui/core';
import moment from 'moment';

import Default from '../../contenedores/Default';
import Tabla from '../../componentes/Table';

import { useFetchReporte } from '../../hooks/useFetchReporte';

import CabeceroReportes from '../../componentes/CabeceroReportes';
import ListaConfiguracion from '../../componentes/ListaConfiguracion';

import endpoints, { IRRIGACIONES } from '../../configuraciones/endpoints';
import { DatePickerTypes } from '../../constantes/datePickerTypes';

const cabeceros = [
	{ label: 'SITIO', key: 'sitio', align: 'left' },
	{ label: 'INVERNADERO', key: 'invernadero', align: 'left' },
	{ label: 'NAVE', key: 'nave', align: 'left' },
	{ label: 'TIPO SUELO', key: 'suelo', align: 'left' },
	{ label: 'AGRUPADOR', key: 'agrupador', align: 'left' },
	{ label: 'PARÁMETRO', key: 'parametro', align: 'left' },
	{ label: 'REGISTRO', key: 'registro', align: 'left' },
	{ label: 'PERIODO', key: 'periodo', align: 'left' },
	{ label: 'FECHA', key: 'fecha', align: 'left' },
	{
		label: 'Estado',
		key: 'estado',
		align: 'center',
		transform: (data) => {
			if (!data) return null;
			return (
				<Chip
					style={{
						backgroundColor: data?.estado?.color,
						color: '#FFFF',
						width: 100,
						height: 25,
						fontWeight: 'bold',
						fontSize: 13,
					}}
					label={`${data?.estado?.valor}`}
					size='small'
				/>
			);
		},
	},
];

const datosLista = {
	labelProp: 'nombre',
	headerLista: 'Nombre',
	title: 'Reporte de Irrigaciones',
	data: [
		{
			id: 1,
			nombre: 'Registro détallado',
			subTitle: 'Listado por parametro',
		},
	],
};

const ReporteIrrigaciones = ({ history }) => {
	const { registros } = useSelector((store) => store.listados);
	const { data, formData, buscar, onChange, onDelete } = useFetchReporte(
		{ parametros: endpoints.base.busqueda(IRRIGACIONES) },
		endpoints.reporteIrrigaciones(),
	);
	const { parametros = [] } = data;
	const handleTodosSelected = useCallback(() => {
    const { sitios, naves, fecha } = formData;

		const parametrosData = formData.parametros.includes(0)
			? parametros.filter((parametro) => parametro.id !== 0).map((parametro) => parametro.id)
      : formData.parametros;

		const params = {
			sitios,
			naves,
      parametros: parametrosData,
      fecha: moment(fecha).format('W-GGGG'),
		};
		return params;
	}, [formData, parametros]);

	const onSubMenuClick = (id) => {
		if (id === 1) {
			history.push(`/reportes/irrigaciones`);
		}
	};

	return (
		<Default
			mostrarLista={
				<ListaConfiguracion
					datosLista={datosLista}
					onClick={onSubMenuClick}
					initOpcion={0}
				/>
			}
		>
			<CabeceroReportes
				data={data}
				formData={formData}
				buscar={buscar}
				onChange={onChange}
				onDelete={onDelete}
				handleTodosSelected={handleTodosSelected}
				selects={{
					sitio: { width: 4, show: true },
					invernadero: { width: 4, show: true },
					nave: { width: 4, show: true },
					parametro: { width: 4, show: true, options: parametros },
					fecha: { width: 4, show: true, type: DatePickerTypes.week },
				}}
				endpoint={endpoints.reporteIrrigaciones()}
				method='POST'
			/>
			<Tabla
				headers={cabeceros}
				rows={registros.rows.length > 0 ? registros.rows : []}
				count={registros.count}
				showActions={false}
			/>
		</Default>
	);
};

export default ReporteIrrigaciones;
