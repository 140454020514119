import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import debounce from 'lodash/debounce';
import moment from 'moment';

import CustomAccordion from '../../componentes/Accordion';
import Mapa from './components/Mapa';
import TextField from '../../componentes/TextField';
import Wrapper from '../../contenedores/Wrapper';

import axios from '../../configuraciones/axios';
import endpoints from '../../configuraciones/endpoints';

import styles, { Cabecero, ScrollContenedor, Seccion, Tabla } from './styles';

const TemporadaDetalles = ({ id, history, location = {}, onRegresar }) => {
  const classes = styles();
  const { id: temporadaID = id } = useParams();

  const estaDesplazandoRef = useRef(false);
  const scrollContenedorRef = useRef(null);
  const seccionesRef = useRef([]);
  const abortControllerRef = useRef(new AbortController());

  const [naveSeleccionadas, setNaveSeleccionadas] = useState([]);
  const [seccionVisible, setSeccionVisible] = useState(0);
  const [tablaSeleccionada, setTablaSeleccionada] = useState([]);

  const [{
    nombre,
    fechaInicio,
    fechaFin,
    sitios,
    invernaderos,
    naves,
    tablas,
  }, setDetalles] = useState({
    nombre: '',
    fechaInicio: '',
    fechaFin: '',
    estatus: '',
    sitios: [],
    invernaderos: [],
    naves: [],
    tablas: [],
  });

  const estatus = useMemo(() => {
    const fechaInicioMoment = moment(fechaInicio).startOf('day');
    const fechaFinMoment = moment(fechaFin).endOf('day');
    const fechaActual = moment();

    if (fechaActual.isSameOrAfter(fechaInicioMoment) && fechaActual.isSameOrBefore(fechaFinMoment)) return 'Activa';
    else if (moment().isAfter(fechaFinMoment)) return 'Finalizado';
    else return 'Inactivo';
  }, [fechaInicio, fechaFin]);

  const regresar = useCallback(() => {
    if (onRegresar) return onRegresar();

    const query = new URLSearchParams(location.search);
    const esHistorico = query.get('historico');
    history.push(`/temporadas/${esHistorico ? 'historico' : 'listado'}`);
  }, [history, location.search, onRegresar]);

  const consultarDetalles = useCallback(async () => {
    try {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;

      const {
        temporada,
        sitios,
        invernaderos,
        naves,
        tablas,
      } = await axios.get(endpoints.detalleTemporada(temporadaID), { signal });

      setDetalles({
        nombre: temporada.nombre,
        fechaInicio: temporada.fechaInicio,
        fechaFin: temporada.fechaFin,
        estatus: temporada.estatus,
        sitios,
        invernaderos,
        naves,
        tablas,
      });
    } catch (error) {
      if (error.isCancel) return;
    }
  }, [temporadaID]);

  const scrollASeccion = useCallback((acumulador) => {
    const elementos = seccionesRef.current;
    if (seccionVisible < elementos.length - 1 || seccionVisible > 0) {
      const siguienteElemento = elementos[seccionVisible + acumulador];
      if (siguienteElemento) {
        siguienteElemento.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [seccionVisible]);

  const expandirAcordionNave = useCallback((id) => () => {
    setNaveSeleccionadas((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  }, []);

  const expandirAcordionTabla = useCallback((id) => () => {
    setTablaSeleccionada((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  }, []);

  // Manejador de eventos
  // Evento Scroll
  useEffect(() => {
    const manejadorScroll = debounce(() => {
      const estaDespalzandose = estaDesplazandoRef.current;
      const contenedor = scrollContenedorRef.current;
      const secciones = seccionesRef.current;

      if (estaDespalzandose || !contenedor) return;
      estaDesplazandoRef.current = true;
      requestAnimationFrame(() => {
        const containerRect = contenedor.getBoundingClientRect();
        const viewportTop = containerRect.top - 16;

        for (let i = 0; i < secciones.length; i++) {
          const seccion = secciones[i];
          const seccionRect = seccion.getBoundingClientRect();

          if (seccionRect.top >= viewportTop) {
            const index = seccion.getAttribute('data-index');
            setSeccionVisible(Number(index));
            break;
          }
        }

        estaDesplazandoRef.current = false;
      });
    }, 250);
    const contenedor = scrollContenedorRef.current;
    contenedor?.addEventListener('scroll', manejadorScroll);

    return () => {
      contenedor?.removeEventListener('scroll', manejadorScroll);
    };
  }, []);

  // Evento KeyDown
  useEffect(() => {
    function manejadorKeyDown(e) {
      if (e.keyCode === 40) {
        e.preventDefault();
        scrollASeccion(1);
      }
      if (e.keyCode === 38) {
        e.preventDefault();
        scrollASeccion(-1);
      }
    }

    document.addEventListener('keydown', manejadorKeyDown);

    return () => {
      document.removeEventListener('keydown', manejadorKeyDown);
    };
  }, [scrollASeccion]);

  // Consultar detalles
  useEffect(() => {
    consultarDetalles();

    return () => {
      abortControllerRef.current.abort();
    }
  }, [consultarDetalles]);

  if (!temporadaID) return <Redirect to="/temporadas/listado" />

  return (
    <Wrapper raw={id}>
      <Box display="flex" flexDirection="column" flex={1} height="92%" gridGap={15}>
        <Cabecero regresar={regresar} texto="Detalles de la temporada" />

        <Box flex={1} maxHeight="100%" display="flex" gridGap={50}>
          <ScrollContenedor ref={scrollContenedorRef}>
            {/* Configuración Inicial */}
            <Seccion nombre="Configuración inicial" ref={(el) => (seccionesRef.current[0] = el)} data-index={0}>
              <TextField name="nombre" label="Nombre de la temporada" value={nombre} disabled />
              <TextField name="fechaInicio" label="Fecha inicio" value={moment(fechaInicio).startOf('day').format('LL')} disabled />
              <TextField name="fechaFin" label="Fecha fin" value={moment(fechaFin).endOf('day').format('LL')} disabled />
              <TextField name="estatus" label="Estatus" value={estatus} disabled />
            </Seccion>

            {/* Configuración del sitio */}
            <Seccion nombre="Configuración del sitio" ref={(el) => (seccionesRef.current[1] = el)} data-index={1}>
              {
                sitios.length > 1
                  ? (
                    <Tabla
                      nombre="Divisiones del sitio"
                      rows={sitios}
                    />
                  )
                  : (<TextField name="sitio" label="Sitio" value={sitios[0]?.nombre} disabled />)
              }
            </Seccion>

            {/* Configuración de invernaderos */}
            <Seccion nombre="Configuración de invernaderos" ref={(el) => (seccionesRef.current[2] = el)} data-index={2}>
              <Tabla
                nombre="Invernaderos"
                rows={invernaderos}
              />
            </Seccion>

            {/* Configuración de naves */}
            <Seccion nombre="Configuración de naves">
              {
                naves.map((nave, i) => {
                  const id = `nave_${nave.id}`;
                  const index = i + 3;
                  return (
                    <div
                      key={id}
                      className={classes.snapItem}
                      ref={(el) => (seccionesRef.current[index] = el)}
                      data-index={index}
                    >
                      <TextField name={`nombre_${id}`} label="Nombre de la nave" value={nave.nombre} disabled />
                      <TextField name={`tipoSuelo_${id}`} label="Tipo de suelo" value={nave.tipoSuelo.nombre} disabled />
                      {(Array.isArray(nave.valvulas) && nave.valvulas.length > 0) && <TextField name={`cantidadValvulas_${id}`} label="Cantidad de válvulas" value={nave.valvulas.length} disabled />}
                      <TextField name={`especificacionSuelo_${id}`} label="Especificación del suelo" value={nave.especificacionSuelo} disabled />
                      <TextField name={`cantidadPlantas_${id}`} label="Cantidad de plantas" value={String(nave.cantidadPlantas)} disabled />
                      <TextField name={`cantidadSecciones_${id}`} label="Cantidad de secciones" value={String(nave.cantidadSecciones)} disabled />
                      <Box display="flex" gridGap={10} justifyContent="space-between" width="95%">
                        <TextField name={`heactareas_${id}`} label="Hectáreas" value={String(nave.hectareas)} disabled />
                        <TextField name={`periodosRiego_${id}`} label="Periodos de riego" value={String(nave.cantidadPeriodosRiegos)} disabled />
                      </Box>
                    </div>
                  );
                })
              }
            </Seccion>

            {/* Configuración de tablas */}
            <Seccion
              nombre="Configuración de tablas"
              ref={(el) => (seccionesRef.current[naves.length + 3] = el)}
              data-index={naves.length + 3}
            >
              {naves.map((nave) => {
                const tablasNave = tablas.filter((tabla) => tabla.naveID === nave.id);
                return (
                  <CustomAccordion
                    key={nave.id}
                    title={`Tablas de la nave: ${nave.nombre}`}
                    expanded={naveSeleccionadas.includes(nave.id)}
                    onClick={expandirAcordionNave(nave.id)}
                    classes={{ root: classes.acordion }}
                  >
                    <Box display="flex" flexDirection="column" gridGap={10}>
                      {tablasNave.map((tabla) => {
                        const id = `tabla_${tabla.id}`;
                        return (
                          <CustomAccordion
                            key={tabla.id}
                            title={tabla.nombre}
                            expanded={tablaSeleccionada.includes(tabla.id)}
                            onClick={expandirAcordionTabla(tabla.id)}
                          >
                            <Seccion>
                              <TextField name={`nombre_${id}`} label="Nombre de la tabla" value={tabla.nombre} disabled />
                              <TextField name={`cultivo_${id}`} label="Cultivo" value={tabla.cultivo.nombre} disabled />
                              <TextField name={`variedad_${id}`} label="Variedad" value={tabla.variedad} disabled />
                              {(tabla.temporadaCultivo && tabla.temporadaCultivo.length > 0) && (
                                <>
                                  <TextField name={`fechaCultivo_${id}`} label="Fecha de cultivo" value={moment(tabla.temporadaCultivo[0].fechaCultivo).startOf('day').format('LL')} disabled />
                                  <TextField name={`fechaTransplante_${id}`} label="Fecha de transplante" value={moment(tabla.temporadaCultivo[0].fechaTransplante).startOf('day').format('LL')} disabled />
                                  <TextField name={`fechaCorte_${id}`} label="Fecha de inicio de corte" value={moment(tabla.temporadaCultivo[0].fechaCorte).startOf('day').format('LL')} disabled />
                                </>
                              )}
                            </Seccion>
                          </CustomAccordion>
                        );
                      })}
                      {tablasNave.length === 0 && <TextField value="Aún no se han registrado tablas" disabled />}
                    </Box>
                  </CustomAccordion>
                );
              })}
            </Seccion>
          </ScrollContenedor>

          <Mapa
            flex={0.7}
            sitios={sitios}
            invernaderos={invernaderos}
            naves={naves}
            tablas={tablas}
            seccionVisible={seccionVisible}
            navesSeleccionadas={naveSeleccionadas}
            tablasSeleccionadas={tablaSeleccionada}
          />
        </Box>
      </Box>
    </Wrapper>
  )
}

export default React.memo(TemporadaDetalles);
