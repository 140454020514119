import React, { useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { Button as MuiButton, Box, Tooltip } from '@material-ui/core';
import clsx from 'clsx';

import Typography from '../Typography';

import styles from './styles';

const Button = ({
  label, name, icono,
  classesCustom, border, variant,
  isSubmit, value, disabled, autoFocus,
  tooltipMsj,
  onClick,
}) => {
  const classes = styles();
  const buttonRef = useRef(null);

  useEffect(() => {
    if (autoFocus && buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <div className={classes.root}>
      <Tooltip title={tooltipMsj}>
        <MuiButton
          ref={buttonRef}
          className={clsx(classes.boton, classesCustom.boton, classesCustom.active, { [classes.border]: border })}
          name={name}
          onClick={onClick}
          type={isSubmit ? 'submit' : 'button'}
          value={value}
          variant={variant}
          disabled={disabled}
        >
          <Box flex={1} className={classes.box}>
            {icono}
            {label !== '' && <Typography className={clsx({ icono: Boolean(icono) })}>{label}</Typography>}
          </Box>
        </MuiButton>
      </Tooltip>
    </div>
  );
};

Button.propTypes = {
  label: propTypes.string,
  name: propTypes.string,
  icono: propTypes.element,
  estilo: propTypes.object,
  classesCustom: propTypes.shape({
    boton: propTypes.string,
    icono: propTypes.string,
    texto: propTypes.string,
  }),
  onClick: propTypes.func,
  border: propTypes.bool,
  isSubmit: propTypes.bool,
  value: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
  ]),
  tooltipMsj: propTypes.string,
  variant: propTypes.string,
  disabled: propTypes.bool,
  autoFocus: propTypes.bool,
}

Button.defaultProps = {
  label: '',
  variant: 'contained',
  icono: null,
  classesCustom: {},
  onClick: null,
  border: false,
  isSubmit: false,
  value: '',
  tooltipMsj: '',
  disabled: false,
  autoFocus: false,
}

export default React.memo(Button);