import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { IRRIGACIONES } from '../../configuraciones/endpoints';

const cabeceros = [
  { label: 'Código', key: 'id' },
  { label: 'Nombre', key: 'nombre' },
  { label: 'Tipo Captura', key: 'tipoCaptura' },
  { label: 'Tipo Suelo', transform: ({ tipoSuelo }) => tipoSuelo?.nombre},
  { label: 'Obligatorio', transform: ({ requerido }) => requerido ? 'SI' : 'NO' },
  { label: 'Estatus', transform: ({ estatus }) => <Estatus activo={estatus} /> },
];

const Irrigaciones = ({ history, location }) => {

  return <Listados
    titulo="Irrigaciones"
    placeHolder="Búsqueda por nombre"
    history={history}
    location={location}
    showActions
    cabeceros={cabeceros || []}
    apiKey={IRRIGACIONES}
  />;
};

export default Irrigaciones;