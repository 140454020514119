/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import Default from '../../contenedores/Default';
import TextField from '../../componentes/TextField';
import Select from '../../componentes/Select';
import axios from '../../configuraciones/axios';
import endpoints, { UNIDADES_MEDIDA } from '../../configuraciones/endpoints';
import { ELEMENTO_NO_ENCONTRADO } from '../../configuraciones/mensajes';
import estatus from '../../constantes/estatus';
import { findPropertysEmpty, trim } from '../../utilidades/functions';

const UnidadMedida = ({ history, match }) => {
  const [formErrors, setFormErrors] = useState({});
  const [unidadMedida, setUnidadMedida] = useState({});
  const { params: { id } } = match;

  const regresar = () => {
    const { location: { state: { pagina } } } = history;

    history.push({
      pathname: '/catalogos/unidadesmedida',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  const cancelar = useCallback(regresar, []);

  const guardar = useCallback(() => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmUnidadMedida'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;

    const metodo = id ? 'put' : 'post';
    
    const obj = trim(unidadMedida);
    const { sitioID, ...rest } = obj;
    
    axios[metodo](UNIDADES_MEDIDA, rest).then(regresar);
  }, [unidadMedida]);

  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [];
    if (id) promesas.push(axios.get(endpoints.base.url(UNIDADES_MEDIDA, id)));

    Promise.all(promesas).then((resultadosPromesas) => {
      const [unidadMedidaDb] = resultadosPromesas;
      if (unidadMedidaDb?.id) {
        setUnidadMedida({ ...unidadMedidaDb });
      } else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      } else
        setUnidadMedida({estatus: true});
      resolve();
    }).catch(reject);
  });

  useEffect(() => {
    consultarDatosIniciales();
  }, [id]);

  return <Default
    titulo={Boolean(id) ? 'Editar Unidad de Medida' : 'Nueva Unidad de Medida'}
    placeHolder={''}
    mostrarCabeceroFormulario
    guardar={guardar}
    cancelar={cancelar}>
    <Grid container spacing={2} id="frmUnidadMedida">
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Código de unidad de medida"
              name="id"
              value={unidadMedida.id}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nombre"
              onChange={setUnidadMedida}
              name="nombre"
              value={unidadMedida.nombre}
              isHandleChange
              inputProps={{ maxLength: 50 }}
              error={formErrors.nombre}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nombre corto"
              onChange={setUnidadMedida}
              isHandleChange
              name="nombreCorto"
              value={unidadMedida.nombreCorto}
              inputProps={{ maxLength: 25 }}
              error={formErrors.nombreCorto}
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Descripción"
              onChange={setUnidadMedida}
              isHandleChange
              name="descripcion"
              value={unidadMedida.descripcion}
              inputProps={{ maxLength: 100 }}
              error={formErrors.descripcion}
              type="textarea"
              rows={9}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              style={{ marginTop: 3 }}
              label="Estatus"
              options={estatus}
              onChange={setUnidadMedida}
              name="estatus"
              value={unidadMedida.estatus}
              error={formErrors.estatus}
              isHandleChange
              required
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(UnidadMedida);