/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import md5 from 'md5';

import Agrupador from '../../componentes/Agrupador';
import Default from '../../contenedores/Default';
import Select from '../../componentes/Select';
import Tabla from '../../componentes/Table';
import TextField from '../../componentes/TextField';
import Typography from '../../componentes/Typography';

import axios from '../../configuraciones/axios';
import { ELEMENTO_NO_ENCONTRADO, NO_MODIFICAR_PERMISO_SITIO } from '../../configuraciones/mensajes';
import endpoints, {
  USUARIOS, PERFILES, SITIOS, INVERNADEROS, NAVES,
} from '../../configuraciones/endpoints';

import { findPropertysEmpty, trim } from '../../utilidades/functions';
import { EMAIL_REGEX, NO_SPACES } from '../../utilidades/regex';

import estatus from '../../constantes/estatus';
import { APP, WEB } from '../../constantes/tipoPerfiles';

import styles from './styles';

const cabeceros = [
  { label: 'ID', key: 'sitioID' },
  { label: 'Sitio', key: 'nombre' },
];

const Usuario = ({ history, match }) => {
  const classes = styles();

  const { user } = useSelector((state) => state.user);

  const [formErrors, setFormErrors] = useState({});
  const [usuario, setUsuario] = useState({});
  const [perfilSeleccionado, setPerfilSeleccionado] = useState({});
  const accesosIniciales = { sitios: [], invernaderos: [], naves: [] };
  const [accesos, setAccesos] = useState({ ...accesosIniciales });
  const [perfiles, setPerfiles] = useState([]);
  const [sitios, setSitios] = useState([]);
  const [invernaderos, setInvernaderos] = useState([]);
  const [naves, setNaves] = useState([]);

  const [permisosSitios, setPermisosSitios] = useState([]);
  const { params: { id } } = match;

  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/usuarios',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  const cancelar = useCallback(regresar, []);

  const guardar = useCallback(() => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmUsuario'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;

    const metodo = id ? 'put' : 'post';

    const obj = trim(usuario);
    const nuevoObj = {
      ...obj,
      usuarioNaves: accesos.naves.map(naveID => {
        const nave = { naveID };
        if (id) nave.usuarioID = id;
        return nave;
      }),
      usuarioSitios: permisosSitios
        .filter(({ isSelected }) => isSelected)
        .map(({ sitioID }) => ({
          sitioID,
          ...(id ? { usuarioID: id } : {}) ,
        })),
    };

    if (!nuevoObj.password || nuevoObj.password === '') delete (nuevoObj.password);
    else nuevoObj.password = md5(nuevoObj.password);
    axios[metodo](USUARIOS, nuevoObj).then(regresar);
  }, [usuario, accesos, permisosSitios]);

  const mapearAccesos = (usuarioDb, accesosDb) => {
    if (!usuarioDb.id) return;
    const usuarioAccesos = {
      naves: usuarioDb.usuarioNaves.map(({ naveID }) => naveID),
      invernaderos: [],
      sitios: [],
    };
    usuarioAccesos.invernaderos = accesosDb.invernaderos
      .filter(({ id }) => accesosDb.naves
        .filter(({ id }) => usuarioAccesos.naves.includes(id))
        .map(({ invernaderoID }) => invernaderoID).includes(id))
      .map(({ id }) => id);
    usuarioAccesos.sitios = accesosDb.sitios
      .filter(({ id }) => accesosDb.invernaderos
        .filter(({ id }) => usuarioAccesos.invernaderos.includes(id))
        .map(({ sitioID }) => sitioID).includes(id))
      .map(({ id }) => id);

    setAccesos(usuarioAccesos);
  };

  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.base.busqueda(PERFILES)),
      axios.get(endpoints.base.busqueda(INVERNADEROS)),
      axios.get(endpoints.base.busqueda(NAVES)),
      axios.get(endpoints.base.url(SITIOS)),
    ];
    if (id) promesas.push(axios.get(endpoints.base.url(USUARIOS, id)));

    Promise.all(promesas).then((resultadosPromesas) => {
      const [perfilesDb, invernaderosDb, navesDb, { rows: sitiosDb }, usuarioDb] = resultadosPromesas;
      setPerfiles(perfilesDb);
      setInvernaderos(invernaderosDb);
      setNaves(navesDb);
      setSitios(sitiosDb);

      if (usuarioDb?.id) {
        const { usuariosSitios, esAdministrador, ...rest } = usuarioDb;
        setUsuario({
          ...rest,
          usuarioSitios: user.esAdministrador ? usuariosSitios : [],
          esAdministrador: user.esAdministrador ? esAdministrador : undefined,
        });
        mapearAccesos(usuarioDb, {
          naves: navesDb,
          sitios: sitiosDb,
          invernaderos: invernaderosDb,
        });

        const usuarioSitios = usuarioDb.usuarioSitios.map(({ sitioID }) => sitioID);
        setPermisosSitios(sitiosDb.map(sitio => ({
          sitioID: sitio.id,
          nombre: sitio.nombre,
          isSelected: usuarioSitios.includes(sitio.id),
        })));
      } else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      } else {
        setUsuario({});
        setPermisosSitios(sitiosDb.map(sitio => ({
          sitioID: sitio.id,
          nombre: sitio.nombre,
          isSelected: false,
        })));
      }
      resolve();
    }).catch(reject);
  });

  const seleccionarTodosPermisosSitios = useCallback((flag) => {
    if ( usuario.esAdministrador ) {
      toast.warning(NO_MODIFICAR_PERMISO_SITIO, { toastId: NO_MODIFICAR_PERMISO_SITIO });
      return;
    };
    setPermisosSitios(prev => prev.map(sitio => ({ ...sitio, isSelected: !flag })));
  }, [ usuario.esAdministrador ]);

  const seleccionarPermisoSitio = useCallback((row) => {
    if ( usuario.esAdministrador ) {
      toast.warning(NO_MODIFICAR_PERMISO_SITIO, { toastId: NO_MODIFICAR_PERMISO_SITIO });
      return;
    };

    const index = permisosSitios.findIndex(({ sitioID }) => sitioID === row.sitioID);
    if (index !== -1) {
      const nuevosPermisos = [...permisosSitios];
      nuevosPermisos[index].isSelected = !nuevosPermisos[index].isSelected;
      setPermisosSitios(nuevosPermisos);
    }
  }, [ usuario.esAdministrador, permisosSitios ]);

  const cambiarAccesos = ({ target: { name, value } }) => {
    const nuevosAcceos = { ...accesos, [name]: name === 'sitios' ? [value] : value };
    nuevosAcceos.invernaderos = nuevosAcceos.invernaderos
      .filter((inv) => invernaderos.filter(({ sitioID }) => nuevosAcceos.sitios.includes(sitioID))
        .map(({ id }) => id).includes(inv));
    nuevosAcceos.naves = nuevosAcceos.naves
      .filter((nav) => naves.filter(({ invernaderoID }) => nuevosAcceos.invernaderos.includes(invernaderoID))
        .map(({ id }) => id).includes(nav));
    setAccesos({ ...nuevosAcceos });
  };

  const changeEsAdministrador = useCallback((e) => {
    setUsuario(prev => ({ ...prev, esAdministrador: e.target.checked }));
    setPermisosSitios(prev => prev.map(sitio => ({ ...sitio, isSelected: e.target.checked })));
  }, []);

  useEffect(() => {
    const perfil = perfiles.find(({ id }) => id === usuario.perfilID);
    setPerfilSeleccionado(perfil || {});
  }, [usuario.perfilID, perfiles]);

  useEffect(() => {
    consultarDatosIniciales();
  }, [id]);

  return <Default
    titulo={Boolean(id) ? 'Editar Usuario' : 'Nuevo Usuario'}
    placeHolder={''}
    mostrarCabeceroFormulario
    guardar={guardar}
    cancelar={cancelar}>
    <Grid container spacing={2} id="frmUsuario">
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Nombre de usuario"
              onChange={setUsuario}
              name="nombreCorto"
              value={usuario.nombreCorto}
              isHandleChange
              inputProps={{ maxLength: 50, regex: NO_SPACES, regexonchange: 'true' }}
              error={formErrors.nombreCorto}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Contraseña"
              onChange={setUsuario}
              type="password"
              name="password"
              value={usuario.password || ''}
              isHandleChange
              inputProps={{ maxLength: 50 }}
              error={formErrors.password}
              required={!Boolean(id)}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Perfil"
              required
              options={perfiles}
              labelProp="nombre"
              onChange={({ target: { value } }) => {
                setUsuario((current) => ({ ...current, perfilID: value }));
                setAccesos({ ...accesosIniciales });
              }}
              name="perfilID"
              value={usuario.perfilID || ''}
              error={formErrors.perfilID}
              customLabel={({ nombre, tipoPerfil }) => `${nombre} - ${tipoPerfil?.nombre || ''}`}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Estatus"
              required
              options={estatus}
              onChange={setUsuario}
              name="estatus"
              value={usuario.estatus}
              error={formErrors.estatus}
              isHandleChange
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Nombre de empleado"
              onChange={setUsuario}
              name="nombre"
              value={usuario.nombre}
              isHandleChange
              inputProps={{ maxLength: 100 }}
              error={formErrors.nombre}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Código de empleado"
              onChange={setUsuario}
              name="codigoEmpleado"
              value={usuario.codigoEmpleado}
              isHandleChange
              inputProps={{ maxLength: 5, regex: NO_SPACES, regexonchange: 'true' }}
              error={formErrors.codigoEmpleado}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Dígito verificador"
              onChange={setUsuario}
              name="digitoVerificador"
              value={usuario.digitoVerificador}
              isHandleChange
              inputProps={{ maxLength: 1, }}
              error={formErrors.digitoVerificador}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Puesto"
              onChange={setUsuario}
              name="puesto"
              value={usuario.puesto}
              isHandleChange
              inputProps={{ maxLength: 50, }}
              error={formErrors.puesto}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Correo electrónico"
              onChange={setUsuario}
              name="correo"
              value={usuario.correo}
              isHandleChange
              inputProps={{ regex: EMAIL_REGEX, regexonsubmit: 'true', maxLength: 50 }}
              error={formErrors.correo}
              helperText={formErrors.correo}
            />
          </Grid>
        </Grid>
      </Grid>
      {(perfilSeleccionado?.tipoPerfil?.id === APP &&
        user.esAdministrador
      ) &&
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Agrupador label="Acceso/Permiso">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Select
                  label="Sitio"
                  required
                  options={sitios.filter(({ estatus }) => estatus)}
                  onChange={cambiarAccesos}
                  name="sitios"
                  value={accesos.sitios}
                  labelProp="nombre"
                  error={formErrors.sitios}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label="Invernadero"
                  required
                  options={invernaderos.filter(({ sitioID }) => accesos.sitios.includes(sitioID))}
                  onChange={cambiarAccesos}
                  name="invernaderos"
                  value={accesos.invernaderos}
                  labelProp="nombre"
                  multiple
                  error={formErrors.invernaderos}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label="Nave"
                  required
                  options={naves.filter(({ invernaderoID }) => accesos.invernaderos.includes(invernaderoID))}
                  onChange={cambiarAccesos}
                  name="naves"
                  value={accesos.naves}
                  labelProp="nombre"
                  multiple
                  error={formErrors.naves}
                />
              </Grid>
            </Grid>
          </Agrupador>
        </Grid>}
      {(perfilSeleccionado?.tipoPerfil?.id === WEB &&
        user.esAdministrador
      ) && (
        <Grid item className={classes.permisosContainer} lg={4} md={4} sm={12} xs={12}>
          <Grid item xs={12}>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <Typography className={classes.label}>Designar como Administrador</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={usuario.esAdministrador} onChange={changeEsAdministrador} name="esAdministrador" />
                  }
                  label="Administrador"
                />
              </FormGroup>
              <FormHelperText>¡Atención! Al designar al usuario como <b>Administrador</b> este tendrá acceso a todos los sitios y no podrá ser eliminado.</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item className={classes.permisosContainer} xs={12}>
            <Grid item xs={12}>
              <Typography className={classes.label}>Permisos para sitios</Typography>
            </Grid>
            <Tabla
              customClass={classes.tablaPermisosSitios}
              hidePaginator
              showActions={false}
              showCheckbox
              headers={cabeceros}
              rows={permisosSitios}
              onSelectAll={seleccionarTodosPermisosSitios}
              onSelect={seleccionarPermisoSitio}
              emptyMessage="SIN REGISTROS PARA MOSTRAR"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  </Default>;
};

export default React.memo(Usuario);