import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  root: {
    marginTop: 5,
  },
  '@global': {
    '.MuiPopover-paper': {
      justifyContent: 'flex-end',
    },
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  input: {
    minHeight: 42,
    height: 42,
    marginTop: 10,
    backgroundColor: paleta.textField.backgroundColor,
    borderRadius: 5,
    outline: 'none',
    width: '95%',
    padding: '0 10px',
    '& fieldset': {
      border: `1px solid ${paleta.textField.border} !important`,
    },
    '&:hover, &:focus': {
      backgroundColor: paleta.textField.backgroundColorHover,
      '& fieldset': {
        border: `1px solid ${paleta.textField.border} !important`,
      },
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${paleta.textField.border}`,
    },
    '& .MuiSelect-root': {
      minHeight: 42,
      height: 42,
      padding: '0px !important',
      display: 'flex',
      alignItems: 'center',
    },
  },
  li: {
    backgroundColor: 'transparent !important',
    whiteSpace: 'wrap',
    '&:hover': {
      backgroundColor: `${paleta.textField.backgroundColorHover} !important`,
    },
    '&.Mui-selected': {
      fontWeight: 600,
    },
  },
  error: {
    '& .MuiInputBase-root': {
      border: `1px solid ${paleta.textField.error}`,
    },
  },
  helperText: {
    marginTop: 5,
    marginLeft: 8,
    '&.error': {
      color: paleta.textField.error,
    },
  },
  iconDelete: {
    color: paleta.sidebar.backgroundColor,
    width: 18,
  },
  chipContainer: {
    display: 'flex',
    marginTop: 1,
    gap: 3,
  },
  chipItem: {
    height: 28,
    backgroundColor: '#66aea4',
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: 11,
    textTransform: 'capitalize',

    '& .MuiChip-label': {
      marginTop: '2px !important',
    },
  },
  ghostChip: {
    visibility: 'hidden',
    position: 'absolute',
  },
}));

export default styles;
