import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { FENOLOGIAS } from '../../configuraciones/endpoints';

const cabeceros = [
  { label: 'Código', key: 'id' },
  { label: 'Nombre', key: 'nombre' },
  { label: 'Tipo Captura', key: 'tipoCaptura' },
  { label: 'Obligatorio', transform: ({ requerido }) => requerido ? 'SI' : 'NO' },
  { label: 'Estatus', transform: ({ estatus }) => <Estatus activo={estatus} /> },
];

const Fenologias = ({ history, location }) => {

  return <Listados
    titulo="Fenologías"
    placeHolder="Búsqueda por nombre"
    history={history}
    location={location}
    showActions
    cabeceros={cabeceros || []}
    apiKey={FENOLOGIAS}
  />;
};

export default Fenologias;