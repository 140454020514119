import React, { useCallback, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, useMediaQuery } from '@material-ui/core';
import { IoMdAddCircleOutline } from 'react-icons/io';

import Accordion from '../Accordion';
import Boton from '../Button';
import Select from '../Select';
import TextField from '../TextField';
import Typography from '../Typography';

import styles from './styles';
import paleta from '../../configuraciones/paleta';

const CabeceroListado = ({
  titulo,
  subtitulo,
  placeHolder,
  buscar,
  agregar,
  esListado,
  guardar,
  cancelar,
  customStyles,
  filtrosCabeceros,
}) => {
  const classes = styles();
  const matches = useMediaQuery('(max-width:960px)');
  const { sitios, invernaderos, naves } = useSelector((state) => state.listados);

  const [txtBusqueda, setTxtBusqueda] = useState('');
  const [txtBusquedaAnterior, setTxtBusquedaAnterior] = useState('');
  const [filtros, setFiltros] = useState({
    sitioID: '',
    invernaderoID: '',
    naveID: '',
  });

  const [filtrosData, setFiltrosData] = useState({
    sitios: [{ value: '', label: 'Todos' }],
    invernaderos: [{ value: '', label: 'Todos' }],
    naves: [{ value: '', label: 'Todos' }],
  });

  const manejadorKeyDown = (e) => {
    if (e.key === "Enter" && buscar)
      if (txtBusquedaAnterior !== txtBusqueda) {
        buscar(txtBusqueda);
        setTxtBusquedaAnterior(txtBusqueda);
      }
  };

  const onChangeFiltros = useCallback((e) => {
    const { name, value } = e.target;
    const nuevosFiltros = { ...filtros, [name]: value };
    if (name === 'sitioID') {
      nuevosFiltros.invernaderoID = '';
      nuevosFiltros.naveID = '';
    }
    if (name === 'invernaderoID') {
      nuevosFiltros.naveID = '';
    }
    setFiltros(nuevosFiltros);

    if (buscar) {
      buscar(txtBusqueda, nuevosFiltros);
    }
  }, [buscar, txtBusqueda, filtros]);

  useEffect(() => {
    if (filtrosCabeceros && (sitios.length > 0 || invernaderos.length > 0 || naves.length > 0)) {
      const sitiosData = sitios.map((sitio) => ({
        value: sitio.id,
        label: sitio.nombre,
      }));
      const invernaderosData = invernaderos
        .filter((invernadero) => (invernadero.sitioID === filtros.sitioID) || !filtros.sitioID)
        .map((invernadero) => ({
          value: invernadero.id,
          label: invernadero.nombre,
        }));

      const invernaderosIDs = invernaderosData.map((invernadero) => invernadero.value);
      const navesEnSitio = naves.filter((nave) => invernaderosIDs.includes(nave.invernaderoID));
      const navesDataEnSitio = navesEnSitio
        .filter((nave) => (nave.invernaderoID === filtros.invernaderoID) || !filtros.invernaderoID)
        .map((nave) => ({
          value: nave.id,
          label: nave.nombre,
        }));

      setFiltrosData({
        sitios: [{ value: '', label: 'Todos' }, ...sitiosData],
        invernaderos: [{ value: '', label: 'Todos' }, ...invernaderosData],
        naves: [{ value: '', label: 'Todos' }, ...navesDataEnSitio],
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtrosCabeceros, sitios, invernaderos, naves]);

  return (
    <div>
      <Box flex={1} className={classes.root}>
        <div className={classes.titleContainer}>
          <Typography
            component="h2"
            className={classes.title}
            style={customStyles}
          >
            {titulo}
          </Typography>
          <Typography className={classes.subtitle}>{subtitulo}</Typography>
        </div>
        {esListado && (
          <Box flex={1} className={classes.actionContainer}>
            {buscar && (
              <TextField
                name="txtBusqueda"
                placeHolder={placeHolder}
                className={classes.textField}
                inputClassName={classes.textFieldInput}
                onChange={({ target: { value } }) => setTxtBusqueda(value)}
                value={txtBusqueda}
                onKeyDown={manejadorKeyDown}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
              />
            )}
            {!matches && (
              <>
                {filtrosCabeceros.sitio && (
                  <Select
                    name="sitioID"
                    label="Sitio"
                    placeHolder="Sitio"
                    placeHolderColor={paleta.textField.text}
                    className={classes.select}
                    displayTextStyles={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                    onChange={onChangeFiltros}
                    value={filtros.sitioID}
                    valueProp="value"
                    options={filtrosData.sitios}
                  />
                )}
                {filtrosCabeceros.invernadero && (
                  <Select
                    name="invernaderoID"
                    label="Invernadero"
                    placeHolder="Invernadero"
                    placeHolderColor={paleta.textField.text}
                    className={classes.select}
                    displayTextStyles={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                    onChange={onChangeFiltros}
                    value={filtros.invernaderoID}
                    valueProp="value"
                    options={filtrosData.invernaderos}
                  />
                )}
                {filtrosCabeceros.nave && (
                  <Select
                    name="naveID"
                    label="Nave"
                    placeHolder="Nave"
                    placeHolderColor={paleta.textField.text}
                    className={classes.select}
                    displayTextStyles={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                    onChange={onChangeFiltros}
                    value={filtros.naveID}
                    valueProp="value"
                    options={filtrosData.naves}
                  />
                )}
              </>
            )}
            {agregar && (
              <Box className={classes.btnContainer}>
                <Boton
                  icono={<IoMdAddCircleOutline size={18} />}
                  label="Agregar"
                  onClick={agregar}
                  classesCustom={{
                    boton: classes.btnAdd,
                  }}
                />
              </Box>
            )}
            {matches && (
              <Accordion
                id="accordion-filtros"
                title="Más filtros"
              >
                {filtrosCabeceros.sitio && (
                  <Select
                    name="sitioID"
                    label="Sitio"
                    placeHolder="Sitio"
                    placeHolderColor={paleta.textField.text}
                    className={classes.select}
                    displayTextStyles={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                    onChange={onChangeFiltros}
                    value={filtros.sitioID}
                    valueProp="value"
                    options={filtrosData.sitios}
                  />
                )}
                {filtrosCabeceros.invernadero && (
                  <Select
                    name="invernaderoID"
                    label="Invernadero"
                    placeHolder="Invernadero"
                    placeHolderColor={paleta.textField.text}
                    className={classes.select}
                    displayTextStyles={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                    onChange={onChangeFiltros}
                    value={filtros.invernaderoID}
                    valueProp="value"
                    options={filtrosData.invernaderos}
                  />
                )}
                {filtrosCabeceros.nave && (
                  <Select
                    name="naveID"
                    label="Nave"
                    placeHolder="Nave"
                    placeHolderColor={paleta.textField.text}
                    className={classes.select}
                    displayTextStyles={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                    onChange={onChangeFiltros}
                    value={filtros.naveID}
                    valueProp="value"
                    options={filtrosData.naves}
                  />
                )}
              </Accordion>
            )}
          </Box>
        )}
        {!esListado && (
          <Box flex={1} className={classes.actionContainer} gridGap={10}>
            {buscar && (
              <TextField
                name="txtBusqueda"
                placeHolder={placeHolder}
                className={classes.textField}
                onChange={({ target: { value } }) => setTxtBusqueda(value)}
                value={txtBusqueda}
                onKeyDown={manejadorKeyDown}
              />
            )}
            {guardar && (
              <Boton onClick={guardar} label="Guardar" />
            )}
            {cancelar && (
              <Boton onClick={cancelar} label="Cancelar" border />
            )}
          </Box>
        )}
      </Box>
    </div>
  );
};

CabeceroListado.propTypes = {
  titulo: propTypes.string.isRequired,
  subtitulo: propTypes.string,
  placeHolder: propTypes.string,
  buscar: propTypes.func,
  agregar: propTypes.func,
  esListado: propTypes.bool,
  guardar: propTypes.func,
  cancelar: propTypes.func,
  customStyles: propTypes.object,
  customCabecero: propTypes.node,
  filtrosCabeceros: propTypes.oneOfType([propTypes.bool, propTypes.shape({
    sitio: propTypes.bool,
    invernadero: propTypes.bool,
    nave: propTypes.bool,
  })]),
};

CabeceroListado.defaultProps = {
  subtitulo: '',
  placeHolder: '',
  buscar: null,
  agregar: null,
  esListado: true,
  guardar: null,
  cancelar: null,
  customCabecero: null,
  filtrosCabeceros: {
    sitio: false,
    invernadero: false,
    nave: false,
  },
};

export default React.memo(CabeceroListado);
