import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: 'calc(100vh - 130px)',
    padding: '0px 0px 0px 8px',
  },
  instancias: {
    height: '100%',
    margin: '8px 0px',
    scrollBehavior: 'smooth',
    overflowY: 'auto',
  },
  mapa: {
    maxHeight: '95vh',
    padding: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2),
    }
  },
  img: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  contenedorVacio: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  }
}));

export default styles;
