import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { INVERNADEROS } from '../../configuraciones/endpoints';
import { getSitios } from '../../ducks/listados';

const cabeceros = [
  { label: 'Código', key: 'id' },
  { label: 'Nombre', key: 'nombre' },
  { label: 'Sitio', transform: ({ sitio }) => sitio?.nombre || '' },
  { label: 'Estatus', transform: ({ estatus }) => <Estatus activo={estatus} />, },
];

const Invernaderos = ({ history, location }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSitios())
  }, [dispatch]);

  return <Listados
    titulo="Invernaderos"
    placeHolder="Búsqueda por nombre"
    history={history}
    location={location}
    showActions
    cabeceros={cabeceros}
    apiKey={INVERNADEROS}
    filtrosBusqueda={{ sitio: true }}
  />;
};

export default Invernaderos;