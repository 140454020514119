import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFFFFF',
    borderBottom: `2px solid ${paleta.bar.border}`,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: 50,
    width: 55,
  },
  tab: {
    height: '64px',
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  iconoContainer: {
    borderRight: `2px solid ${paleta.bar.iconoBorder}`,
    paddingRight: 10,
    marginRight: 10,
  },
  user: {
    color: `${paleta.bar.user} !important`,
    textTransform: 'none',
    '& span': {
      color: `${paleta.bar.user} !important`,
      textTransform: 'capitalize',
    },
  },
}));

export default styles;
