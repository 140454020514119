import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
import { BiTrashAlt } from 'react-icons/bi';

import Button from '../../../../componentes/Button';

import {
  actualizarCoordenadasDivision,
  actualizarCoordenadasUnificacion,
  agregarCoordenadaDivision,
  agregarCoordenadaUnificacion,
  enumPasos,
  obtenerCoordenadasSitio,
} from '../../../../ducks/temporada';

import { calcularAreaDelimitacion } from '../../../../utilidades/functions';
import { divisionOptions, options, origenOptions, poligonoOptions } from '../../../../constantes/poligonosOptions';

import general from '../../../../configuraciones/general';

import styles from './styles';

const libraries = ['drawing'];

const dataInvernaderosSelector = (state) => state.temporada.data.invernaderos;
const formUnificadorSelector = (state) => state.temporada.formUnificacion;
const formSitiosSelector = (state) => state.temporada.form[enumPasos.SITIOS].data;
const formInvernaderosSelector = (state) => state.temporada.form[enumPasos.INVERNADEROS].data;
const formNavesSelector = (state) => state.temporada.form[enumPasos.NAVES].data;

const Poligono = React.memo(({ id, selector, options, onClick }) => {
  const coordenadas = useSelector(state => {
    const { coordenadas = [] } = selector(state)?.byId[id] ?? {};
    return coordenadas;
  }, shallowEqual);

  return (
    <Polygon
      name="poligono"
      path={coordenadas}
      options={options}
      onClick={onClick}
    />
  );
});

const PoligonoEditable = React.memo(({
  id,
  selector,
  readOnly,
  onActualizarPoligono,
  onEliminarPoligono,
}) => {
  const classes = styles();
  const poligonoRef = useRef(null);

  const coordenadas = useSelector(state => {
    const { coordenadas = [] } = selector(state)?.byId[id] ?? {};
    return coordenadas;
  }, shallowEqual);

  const estaSeleccionado = useSelector(state => selector(state).byId[id]?.isSelected, shallowEqual);
  const onLoad = useCallback((polygon) => {
    poligonoRef.current = polygon;
  }, []);

  const manejadorActualizarCoordenadas = useCallback(() => {
    const coordenadas = poligonoRef.current
      ?.getPath()
      ?.getArray()
      .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));

    if (coordenadas) onActualizarPoligono({ id, coordenadas });
  }, [id, onActualizarPoligono]);

  const manejadorEliminarCoordenadas = useCallback(() => {
    onEliminarPoligono(id);
  }, [id, onEliminarPoligono]);

  return (
    <React.Fragment>
      {
        (estaSeleccionado && !readOnly) && (
          <Button
            name='eliminar'
            icono={<BiTrashAlt color='#666666' size={22} />}
            classesCustom={{ boton: classes.boton }}
            onClick={manejadorEliminarCoordenadas}
          />
        )
      }
      <Polygon
        paths={coordenadas}
        onLoad={onLoad}
        {...((estaSeleccionado && !readOnly) && {
          draggable: true,
          editable: true,
          onMouseUp: manejadorActualizarCoordenadas,
        })}
        options={poligonoOptions({ activo: estaSeleccionado, readOnly })}
      />
    </React.Fragment>
  );
});

const FormPoligono = React.memo(({
  dataSelector,
  formSelector,
  disabled,
  onActualizarPoligono,
  onEliminarPoligono,
  onAgregarVertice,
}) => {
  const contenedorKeys = useSelector(state => dataSelector(state).allIds, shallowEqual);
  const poligonosKeys = useSelector(state => formSelector(state).allIds, shallowEqual);
  const isEnable = useSelector(state => !formSelector(state)?.disabled, shallowEqual);
  const editingItemId = useSelector(state => formSelector(state)?.editingItemId, shallowEqual);

  const agregarVertice = useCallback((id) => {
    return ({ latLng: { lat, lng } }) => {
      const coordenadas = { lat: lat(), lng: lng() };
      onAgregarVertice({ id, coordenadas });
    };
  }, [onAgregarVertice]);

  return (
    <React.Fragment>
      {
        contenedorKeys.map((id) => (
          <Poligono
            key={id}
            id={id}
            selector={dataSelector}
            options={options.contenedor}
            {...(isEnable && editingItemId) && {onClick: agregarVertice(id)}}
          />
        ))
      }
      {
        poligonosKeys.map((id) => (
          <PoligonoEditable
            key={id}
            id={id}
            readOnly={disabled || !isEnable}
            selector={formSelector}
            onActualizarPoligono={onActualizarPoligono}
            onEliminarPoligono={onEliminarPoligono}
          />
        ))
      }
    </React.Fragment>
  );
});

const OverlayFormInvernaderos = React.memo(() => {
  const dispatch = useDispatch();

  const agregarVertice = useCallback(({ coordenadas }) => {
    dispatch(agregarCoordenadaUnificacion(coordenadas));
  }, [dispatch]);

  const eliminarCoordenadasInvernadero = useCallback((id) => {
    dispatch(actualizarCoordenadasUnificacion({ id, coordenadas: [] }));
  }, [dispatch]);

  const actualizarCoordenadasInvernadero = useCallback(({ id, coordenadas }) => {
    dispatch(actualizarCoordenadasUnificacion({ id, coordenadas }));
  }, [dispatch]);

  return (
    <React.Fragment>
      <FormPoligono
        dataSelector={formSitiosSelector}
        formSelector={formUnificadorSelector}
        onActualizarPoligono={actualizarCoordenadasInvernadero}
        onAgregarVertice={agregarVertice}
        onEliminarPoligono={eliminarCoordenadasInvernadero}
      />
    </React.Fragment>
  );
});

const OverlayFormNaves = React.memo(() => {
  return (
    <React.Fragment>
      <FormPoligono
        dataSelector={formInvernaderosSelector}
        formSelector={formNavesSelector}
        disabled
      />
    </React.Fragment>
  );
});

const Division = React.memo(({ id, activo, disabled }) => {
  const classes = styles();

  const polygonRef = useRef(null);
  const dispatch = useDispatch();

  const coordenadas = useSelector((state) => {
    const division = !disabled
      ? state.temporada.formDivisor.byId[id]
      : state.temporada.form[enumPasos.SITIOS].data.byId[id];

    return division ? division.coordenadas : [];
  }, shallowEqual);

  const onLoad = useCallback((polygon) => {
    polygonRef.current = polygon;
  }, []);

  const onEditarPoligono = useCallback(() => {
    const coordenadas = polygonRef.current
      ?.getPath()
      ?.getArray()
      .map(latLng => {
        return { lat: latLng.lat(), lng: latLng.lng() };
      });
    dispatch(actualizarCoordenadasDivision({ id, coordenadas }));
  }, [dispatch, id]);

  const onEliminarPoligono = useCallback(() => {
    dispatch(actualizarCoordenadasDivision({id, coordenadas: []}));
  }, [dispatch, id]);

  return (
    <React.Fragment>
      {
        (activo && !disabled) && (
          <Button
            name='eliminar'
            icono={<BiTrashAlt color='#666666' size={22} />}
            classesCustom={{ boton: classes.boton }}
            onClick={onEliminarPoligono}
          />
        )
      }
      <Polygon
        paths={coordenadas}
        {...((activo && !disabled) && {
          draggable: true,
          editable: true,
          onLoad: onLoad,
          onMouseUp: onEditarPoligono,
        })}
        options={divisionOptions({ activo, readOnly: disabled })}
      />
    </React.Fragment>
  );
});

const OrigenOverlay = React.memo(() => {
  const dispatch = useDispatch();

  const divisionId = useSelector(state => state.temporada.formDivisor.editingItemId, shallowEqual);
  const enUso = useSelector(state => state.temporada.formDivisor.allIds.length > 0, shallowEqual);
  const disabled = useSelector(state => state.temporada.formDivisor.disabled, shallowEqual);

  const coordenadas = useSelector(state => {
    const id = state.temporada.form[enumPasos.SITIOS].data.sitioOrigenId;
    const sitio = state.temporada.data.sitios.byId[id];
    return sitio ? sitio.coordenadas : [];
  }, shallowEqual);

  const invernaderos = useSelector(state => {
    const id = state.temporada.form[enumPasos.SITIOS].data.sitioOrigenId;
    const sitio = state.temporada.data.sitios.byId[id];
    return sitio ? sitio.invernaderos : [];
  }, shallowEqual);

  const agregarVertice = useCallback(({ latLng: { lat, lng } }) => {
    const coordenada = { lat: lat(), lng: lng() };
    dispatch(agregarCoordenadaDivision({ id: divisionId, coordenada }));
  }, [dispatch, divisionId]);

  return (
    <React.Fragment>
      <Polygon
        paths={coordenadas}
        options={origenOptions({ enUso, disabled })}
        {...((divisionId && !disabled) && {onClick: agregarVertice})}
      />
      {
        invernaderos.map((id) => (
          <Poligono
            key={id}
            id={id}
            selector={dataInvernaderosSelector}
            options={options.secundario}
          />
        ))
      }
    </React.Fragment>
  );
});

const DivisionesOverlay = React.memo(() => {
  const formDisabled = useSelector(state => state.temporada.formDivisor.disabled, shallowEqual);
  const divisiones = useSelector(state => {
    const formDisabled = state.temporada.formDivisor.disabled;
    const divisiones = state.temporada.formDivisor.allIds;
    const divisionesBD = state.temporada.form[enumPasos.SITIOS].data.allIds;

    return formDisabled ? divisionesBD : divisiones;
  }, shallowEqual);

  const divisionActiva = useSelector(state => state.temporada.formDivisor.editingItemId, shallowEqual);

  return (
    <React.Fragment>
      {
        divisiones.map((id) => (
          <Division
            key={id}
            id={id}
            activo={id === divisionActiva}
            editable={!formDisabled}
          />
        ))
      }
    </React.Fragment>
  );
});

const Mapa = () => {
  const classes = styles();
  const dispatch = useDispatch();

  const mapRef = useRef(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: general.MAPS_API_KEY,
    libraries,
  });

  const sitioId = useSelector(state => state.temporada.form[enumPasos.SITIOS].data.sitioOrigenId, shallowEqual);
  const pasoEnCurso = useSelector(state => state.temporada.ui.stepper.pasoEnCurso, shallowEqual);

  const onLoad = useCallback(function callback(map) {
    try {
      const coordenadas = dispatch(obtenerCoordenadasSitio(sitioId));
      const bounds = new window.google.maps.LatLngBounds();
      coordenadas.forEach((path) => bounds.extend(path));

      map.fitBounds(bounds);
      mapRef.current = map;
    } catch (error) {
      console.error('Error al cargar el mapa:', error);
    }
  }, [dispatch, sitioId])

  const onUnmount = useCallback(function callback(map) {
    mapRef.current = null;
  }, [])

  const areaDelimitacion = useMemo(() => {
    if (!mapRef.current || !sitioId) return null;

    const coordenadas = dispatch(obtenerCoordenadasSitio(sitioId));
    return calcularAreaDelimitacion(coordenadas);
  }, [dispatch, sitioId]);

  /** Centrar mapa en el área de delimitación */
  useEffect(() => {
    if (!mapRef.current || !sitioId) return;

    const coordenadas = dispatch(obtenerCoordenadasSitio(sitioId));
    const bounds = new window.google.maps.LatLngBounds();
    coordenadas.forEach((path) => bounds.extend(path));

    mapRef.current.fitBounds(bounds);
  }, [dispatch, sitioId]);

  if (!isLoaded) return null;

  return (
    <GoogleMap
      id='map-temporadas'
      mapContainerClassName={classes.root}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        mapTypeId: 'satellite',
        streetViewControl: false,
        restriction: areaDelimitacion,
        mapTypeControl: false,
      }}
    >
      {pasoEnCurso < enumPasos.INVERNADEROS && (<>
        <OrigenOverlay />
        <DivisionesOverlay />
      </>) }
      {pasoEnCurso === enumPasos.INVERNADEROS && (
        <OverlayFormInvernaderos />
      )}
      {pasoEnCurso === enumPasos.NAVES && (
        <OverlayFormNaves />
      )}
    </GoogleMap>
  );
};

Mapa.propTypes = {
}

Mapa.defaultProps = {
}

export default React.memo(Mapa);
