import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: paleta.bar.primary,
    borderRadius: 3,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(0.5),
    position: 'relative',
    width: 100,
    zIndex: 1,
  },
  text: {
    color: paleta.textField.backgroundColor,
    fontWeight: '600',
    textAlign: 'center',
  },
  extraText: {
    display: 'block',
    color: paleta.textField.backgroundColor,
    fontSize: '12px',
    textAlign: 'center',
    paddingTop: 5,
  },
  activo: {
    backgroundColor: paleta.graficos.barPrimary,
    '&::before': {
      backgroundColor: paleta.graficos.barPrimary,
    }
  },
  inactivo: {
    backgroundColor: paleta.bar.primary,
    '&::before': {
      backgroundColor: paleta.bar.primary,
    }
  },
  cardContainer: {
    backgroundColor: paleta.textField.backgroundColor,
    padding: theme.spacing(2),
    minWidth: 275,
    transition: 'width 0.5s ease-in-out',
  },
  cardTitle: {
    fontSize: '13px',
    fontWeight: '800',
    letterSpacing: '0.3px',
    marginBottom: 5,
  },
  cardSubtitle: {
    fontSize: '12px',
    fontWeight: '300',
    letterSpacing: '0.3px',
    textTransform: 'capitalize',
  },
  list: {
    animation: '$fadeIn 500ms ease-in-out',
    paddingInline: 0,
    margin: 0,
  },
  listItem: {
    paddingInline: 0,
    margin: 0,
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    }
  },
  resultado: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  resultadoFecha: {
    fontSize: '12px',
    fontWeight: '300',
    letterSpacing: '0.3px',
    lineHeight: 1,
    display: 'inline-block',
    color: paleta.textField.text,
    textTransform: 'capitalize',
  },
  formula: {
    display: 'inline-block',
    fontSize: '12px',
    letterSpacing: '0.3px',
    lineHeight: 1,
  },
}));

export default styles;
