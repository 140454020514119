import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  agrupador: {
    marginInline: 5,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 30,
  },
  labelTitle: {
    color: paleta.textField.text,
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  titleContainer: {
    marginTop: 5,
  },
  title: {
    marginLeft: 30,
    fontSize: 15,
    color: '#0F0E0E',
    fontWeight: 600
  },
  legends: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  table: {
    borderRadius: 5,
    paddingTop: 1,
    marginBottom: 10,
    paddingBottom:10,
    textAlign: 'left',
    backgroundColor: paleta.sidebar.active,
    border: `0px solid ${paleta.bar.border}`,
  },
  calendario: {
    borderRadius: 6,
    marginLeft: 6,
    marginRight: 6,
    paddingTop: 1,
    marginBottom: 10,
    paddingBottom:10,
    backgroundColor: "#FFFF",
    border: `0px solid ${paleta.bar.border}`,
  },
  grafica: {
    overflowX: 'scroll',
    position: 'relative',
    backgroundColor:paleta.sidebar.active,
    minWidth: 360,
    maxHeight: 250,
    [theme.breakpoints.down('678px')]: {
      overflowX: 'scroll',
      position: 'relative',
      minWidth: 300,
      maxHeight: 300,
    }
  },
  grafica2: {
    backgroundColor: paleta.sidebar.active,
    minWidth: 300,
  },
  titleDetail: {
    display: 'flex',
  },
  titleTable: {
    fontSize: 12,
    color: '#05314E',
    fontWeight: 'bolder',
    '& .subtitle': {
    },
  },
  titleHeader: {
    marginLeft: 30,
    marginTop: 26,
    fontSize: 15,
    color: '#0F0E0E',
    fontWeight: 600
  },
  divider: {
    width: '2px',
    marginLeft: 7,
    marginRight: 7.5,
    marginTop: 22,
    paddingTop: 5,
    borderWidth: 1,
    height: '32vh',
    borderStyle: 'dashed',
    borderColor: paleta.bar.point,
    backgroundColor:paleta.bar.details,
    [theme.breakpoints.down('md')]: {
      width: '0px',
      height: '0vh',
      borderColor: paleta.bar.details,
      backgroundColor:paleta.bar.details,
    }
  },
  emptyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    paddingTop: 1,
    paddingBottom: 1,
    backgroundColor: paleta.sidebar.active,
    border: `2px solid ${paleta.bar.border}`,
    height: 200,
  },
  emptyMessage: {
    color: paleta.textField.text,
    fontSize: '14px',
  }
}));

export default styles;
