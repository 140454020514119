import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    color: paleta.configuraciones.disabled,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    justifyContent: 'center',
    minWidth: 400,
    minHeight: 150,
  },
  icon: {
    color: paleta.configuraciones.disabled,
  },
  empty: {
    fontSize: 18,
    fontWeight: 'bold',
    color: paleta.configuraciones.disabled,
  },
  imageList: {
    width: 500,
    height: 450,
    transform: 'translateZ(0)',
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

export default styles;
