import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    marginInline: 5,
    backgroundColor: paleta.sidebar.active,
    border: `1px solid ${paleta.textField.border}`,
    padding: theme.spacing(2, 1),
    borderRadius: '7px',
    display: 'flex',
    flexDirection: 'column',
  },
  tablasBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  labelTitle: {
    color: paleta.textField.text,
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  tablaName: {
    color: paleta.bar.primary,
    fontSize: '14px',
    fontWeight: 'bold',
    paddingInline: theme.spacing(2),
  },
  title: {
    marginLeft: 6,
    fontSize: 14,
    color: '#0F0E0E',
    fontWeight: 600,
    width: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  subtitle: {
    marginLeft: 6,
    fontWeight: 600,
    fontSize: 11,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  itemContainer: {
    border: `1px solid ${paleta.textField.border}`,
    borderRadius: 5,
    paddingBlock: theme.spacing(1),
  },
  itemList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    justifyContent: 'flex-start',
  },
  item: {
    flex: '1 1 auto',
    padding: theme.spacing(1),
    position: 'relative',
    '&:not(:last-child)::after': {
      borderRight: `2px dashed ${theme.palette.divider}`,
      bottom: 0,
      content: '""',
      position: 'absolute',
      right: theme.spacing(-1),
      top: '10%',
      width: 1,
      height: '80%',
    },
    [theme.breakpoints.between(1174, 1400)]: {
      '&:nth-child(5)::after': {
        borderRight: 'none',
      },
    },
    [theme.breakpoints.between(960, 1174)]: {
      '&:nth-child(4)::after': {
        borderRight: 'none',
      },
      '&:nth-child(5)': {
        flex: '0.385',
      },
    }
  },
}));

export default styles;
