import React, { useCallback, useState } from 'react';
import propTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { IoIosArrowDown } from 'react-icons/io';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { isSameDay, startOfWeek, endOfWeek } from 'date-fns';
import { es } from 'date-fns/locale';

import Boton from '../Button';
import ExportarDocumentos from '../ExportarDocumento';
import Select from '../Select';
import Typography from '../Typography';

import { CamposValidados, customCabecero } from '../../utilidades/functions';
import { DatePickerTypes } from '../../constantes/datePickerTypes';

import styles from './styles';

const CabeceroReportes = ({
	data,
	formData,
	buscar,
	onChange,
	onDelete,
	handleTodosSelected,
	endpoint,
	selects,
	method
}) => {
	const classes = styles();
	const [formErrors] = useState({});
	const [anchorConfirmacion, setAnchorConfirmacion] = useState(null);
	const [documento, setDocumento] = useState({});
	const cabecero = customCabecero(selects);
	const {
		sitios, invernaderos, naves,
		tablas, plantas, fenologias, parametros,
		enfermedades, surcos, trampas, saneos,
		secciones, niveles, nivelInfecciones
	} = data;

	const ValidarData = useCallback(() => CamposValidados(formData), [formData]);

	const exportar = (event) => {
		if (ValidarData()) return;
		const params = handleTodosSelected();
		setDocumento({ params, endpoint, method});
		setAnchorConfirmacion(event.currentTarget);
	};

	const onClose = () => {
		setAnchorConfirmacion(null);
  };

  const fechaChange = (date) => {
    onChange({ target: { name: 'fecha', value: date } });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
      <div className={classes.root}>
        <Grid container spacing={1}>
          {cabecero?.sitio.show && (
            <Grid item xs={12} sm={cabecero.sitio.width}>
              <Select
                className={classes.textField}
                label='Sitio'
                required
                options={sitios}
                onChange={onChange}
                name='sitios'
                value={formData.sitios}
                multiple
                onDelete={onDelete}
                labelProp='nombre'
                error={formErrors.sitios}
                IconComponent={(props) => <IoIosArrowDown {...props} size={20} />}
              />
            </Grid>
          )}
          {cabecero.invernadero.show && (
            <Grid item xs={12} sm={cabecero.invernadero.width}>
              <Select
                className={classes.textField}
                label='Invernadero'
                required
                options={invernaderos.filter(({ sitioID }) => formData.sitios.includes(sitioID))}
                onChange={onChange}
                name='invernaderos'
                value={formData.invernaderos || []}
                labelProp='nombre'
                multiple
                onDelete={onDelete}
                error={formErrors.invernaderos}
                IconComponent={(props) => <IoIosArrowDown {...props} size={20} />}
              />
            </Grid>
          )}
          {cabecero.nave.show && (
            <Grid item xs={12} sm={cabecero.nave.width}>
              <Select
                className={classes.textField}
                label='Nave'
                required
                options={naves.filter(({ invernaderoID }) => formData.invernaderos.includes(invernaderoID))}
                onChange={onChange}
                name='naves'
                value={formData.naves || []}
                labelProp='nombre'
                multiple
                onDelete={onDelete}
                error={formErrors.naves}
                IconComponent={(props) => <IoIosArrowDown {...props} size={20} />}
              />
            </Grid>
          )}
          {cabecero.invernaderoNave.show && (
            <Grid item xs={12} sm={cabecero.invernaderoNave.width}>
              <Select
                className={classes.textField}
                label='Invernadero - Nave'
                required
                options={ naves ? naves.filter(({ sitioID }) => formData.sitios.includes(sitioID)) : []}
                onChange={onChange}
                name='naves'
                value={formData.naves || []}
                labelProp='nombre'
                multiple
                onDelete={onDelete}
                error={formErrors.naves}
                IconComponent={(props) => <IoIosArrowDown {...props} size={20} />}
              />
            </Grid>
          )}
          {cabecero.trampa.show && (
            <Grid item xs={12} sm={cabecero.trampa.width}>
              <Select
                className={classes.textField}
                label='Trampas'
                required
                options={trampas ? trampas.filter(({ naveID, id }) => formData.naves.includes(naveID) || id === 0) : []}
                onChange={onChange}
                name='trampas'
                value={formData.trampas || []}
                multiple
                onDelete={onDelete}
                labelProp='nombre'
                error={formErrors.trampas}
                IconComponent={(props) => <IoIosArrowDown {...props} size={20} />}
              />
            </Grid>
          )}
          {cabecero.tabla.show && (
            <Grid item xs={12} sm={cabecero.tabla.width}>
              <Select
                className={classes.textField}
                label='Tablas'
                required
                options={ tablas ? tablas.filter(({ id, nave }) => formData.naves.includes(nave?.id) || id === 0) : []}
                labelProp='nombre'
                onChange={onChange}
                name='tablas'
                multiple
                onDelete={onDelete}
                value={formData['tablas'] || []}
                error={formErrors['tablas']}
                IconComponent={(props) => <IoIosArrowDown {...props} size={20} />}
              />
            </Grid>
          )}
          {cabecero.fenologia.show && (
            <Grid item xs={12} sm={cabecero.fenologia.width}>
              <Select
                className={classes.textField}
                label='Fenologías'
                required
                options={fenologias || []}
                labelProp='nombre'
                onChange={onChange}
                name='fenologias'
                multiple
                onDelete={onDelete}
                value={formData['fenologias'] || []}
                error={formErrors['fenologias']}
                IconComponent={(props) => <IoIosArrowDown {...props} size={20} />}
              />
            </Grid>
          )}
          {cabecero.planta.show && (
            <Grid item xs={12} sm={cabecero.planta.width}>
              <Select
                className={classes.textField}
                label='Plantas'
                required
                options={plantas ? plantas.filter(({ tablaID, id }) => formData.tablas.includes(tablaID) || id === 0) : []}
                labelProp='nombre'
                onChange={onChange}
                name='plantas'
                multiple
                onDelete={onDelete}
                value={formData['plantas'] || []}
                error={formErrors['plantas']}
                IconComponent={(props) => <IoIosArrowDown {...props} size={20} />}
              />
            </Grid>
          )}
          {cabecero.parametro.show && (
            <Grid item xs={12} sm={cabecero.parametro.width}>
              <Select
                className={classes.textField}
                label='Parámetros'
                required
                options={parametros || []}
                labelProp='nombre'
                onChange={onChange}
                name='parametros'
                multiple
                onDelete={onDelete}
                value={formData['parametros'] || []}
                error={formErrors['parametros']}
                IconComponent={(props) => <IoIosArrowDown {...props} size={20} />}
              />
            </Grid>
          )}
          {cabecero.surco.show && (
            <Grid item xs={12} sm={cabecero.surco.width}>
              <Select
                className={classes.textField}
                label='Surco'
                required
                options={surcos ? surcos.filter(({ tablaID, id }) => formData.tablas.includes(tablaID) || id === 0) : []}
                labelProp='nombre'
                onChange={onChange}
                name='surcos'
                multiple
                onDelete={onDelete}
                value={formData.surcos || []}
                error={formErrors.surcos}
                IconComponent={(props) => <IoIosArrowDown {...props} size={20} />}
              />
            </Grid>
          )}
          {cabecero.enfermedad.show && (
            <Grid item xs={12} sm={cabecero.enfermedad.width}>
              <Select
                className={classes.textField}
                label='Plaga/Enfermedad'
                required
                options={enfermedades || []}
                labelProp='nombre'
                onChange={onChange}
                name='enfermedades'
                multiple
                onDelete={onDelete}
                value={formData.enfermedades || []}
                error={formErrors.enfermedades}
                IconComponent={(props) => <IoIosArrowDown {...props} size={20} />}
              />
            </Grid>
          )}
          {cabecero.saneo.show && (
            <Grid item xs={12} sm={cabecero.saneo.width}>
              <Select
                className={classes.textField}
                label='Saneo'
                required
                options={saneos || []}
                labelProp='nombre'
                onChange={onChange}
                name='saneos'
                multiple
                onDelete={onDelete}
                value={formData.saneos || []}
                error={formErrors.saneos}
                IconComponent={(props) => <IoIosArrowDown {...props} size={20} />}
              />
            </Grid>
          )}
          {cabecero.seccion.show && (
            <Grid item xs={12} sm={cabecero.seccion.width}>
              <Select
                className={classes.textField}
                label='Seccion'
                required
                options={secciones ? secciones.filter(({ id, naveID }) => formData.naves.includes(naveID) || id === 0) : []}
                onChange={onChange}
                name='secciones'
                value={formData.secciones || []}
                multiple
                onDelete={onDelete}
                labelProp='nombre'
                error={formErrors.secciones}
                IconComponent={(props) => <IoIosArrowDown {...props} size={20} />}
              />
            </Grid>
          )}
          {cabecero.nivel.show && (
            <Grid item xs={12} sm={cabecero.nivel.width}>
              <Select
                className={classes.textField}
                label='Nivel'
                required
                options={niveles || []}
                labelProp='nombre'
                onChange={onChange}
                name='niveles'
                multiple
                onDelete={onDelete}
                value={formData.niveles || []}
                error={formErrors.niveles}
                IconComponent={(props) => <IoIosArrowDown {...props} size={20} />}
              />
            </Grid>
          )}
          {cabecero.nivelInfeccion.show && (
            <Grid item xs={12} sm={cabecero.nivelInfeccion.width}>
              <Select
                className={classes.textField}
                label='Nivel de infección'
                required
                options={nivelInfecciones || []}
                labelProp='nombre'
                onChange={onChange}
                name='nivelInfecciones'
                multiple
                onDelete={onDelete}
                value={formData.nivelInfecciones || []}
                error={formErrors.nivelInfecciones}
                IconComponent={(props) => <IoIosArrowDown {...props} size={20} />}
              />
            </Grid>
          )}
          {cabecero.fecha.show && (
            <Grid item xs={12} sm={cabecero.fecha.width} style={{ marginTop: 5 }}>
              <Typography className={classes.datePickerLabel}>Semana *</Typography>
              <KeyboardDatePicker
                id="semanaAnio"
                value={formData.fecha}
                variant="inline"
                disableToolbar
                format={cabecero.fecha.type === DatePickerTypes.week ? "'Semana' II 'del' RRRR" : "dd 'de' MMMM 'del' yyyy"}
                onChange={fechaChange}
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  disabled: true,
                  classes: {
                    root: classes.input,
                  },
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
                  if (cabecero.fecha.type === DatePickerTypes.week) {
                    const start = startOfWeek(selectedDate, { weekStartsOn: 1 });
                    const end = endOfWeek(selectedDate, { weekStartsOn: 1 });
                    const dayIsBetween = day >= start && day <= end;

                    return React.cloneElement(dayComponent, {
                      style: {
                        ...dayComponent.style,
                        backgroundColor: dayIsBetween ? '#66aea4' : undefined,
                        color: dayIsBetween ? '#fff' : undefined,
                      },
                    });
                  }

                  const sameDate = isSameDay(day, selectedDate);
                  return React.cloneElement(dayComponent, {
                    style: {
                      ...dayComponent.style,
                      backgroundColor: sameDate ? '#66aea4' : undefined,
                      color: sameDate ? '#fff' : undefined,
                    },
                  });
                }}
                disableFuture
                maxDateMessage="La fecha debe ser menor a la fecha actual"
              />
            </Grid>
          )}
          <Grid item xs={12} sm={2}>
            <Boton
              onClick={buscar}
              label='Buscar'
              classesCustom={{ boton: classes.btn }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Boton
              onClick={(e) => exportar(e)}
              label='Exportar'
              classesCustom={{ boton: classes.btn }}
              border
            />
            <ExportarDocumentos
              open={anchorConfirmacion}
              onClose={onClose}
              documento={documento}
            />
          </Grid>
        </Grid>
      </div>
    </MuiPickersUtilsProvider>
	);
};

CabeceroReportes.propTypes = {
	/* datos de los selects */
	data: propTypes.object,
	/* Datos del los formularios */
	formData: propTypes.object,
	/* Función para buscar */
	buscar: propTypes.func,
	/* Función para obtener datos */
	onChange: propTypes.func,
	/* Función para elminar datos */
	onDelete: propTypes.func,
	/* Función donde recibe los parametros para buscar*/
	handleTodosSelected : propTypes.func,
	/* Endpoint del reporte */
	endpoint:  propTypes.string,
	/* Objeto para mostrar los selects */
	selects: propTypes.object,
	/* Metodo de la petición */
	method: propTypes.string
};

CabeceroReportes.defaultProps = {
	data: {},
	formData: {},
	buscar: null,
	onChange: null,
	onDelete: null,
	handleTodosSelected: null,
	endpoint: '',
	selects: {},
};

export default CabeceroReportes;
