import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Divider, Step, StepLabel, Stepper as MuiStepper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

import Wrapper from '../../contenedores/Wrapper';

import Switch from '../../componentes/SwitchRender';
import Typography from '../../componentes/Typography';

import ConfiguracionTemporada from './ConfiguracionTemporada';
import ConfiguracionSitio from './ConfiguracionSitio';
import ConfiguracionInvernaderos from './ConfiguracionInvernaderos';
import ConfiguracionNaves from './ConfiguracionNaves';

import FormMapa from './components/FormMapa';

import {
  enumPasos,
  submitFormulario,
  precargarDatos,
  restablecerFormulario,
  regresarFormulario,
} from '../../ducks/temporada';

import styles, { Boton } from './styles';

const Header = React.memo(({ esNuevo = true }) => (
  <Box>
    <Typography component="h2">
      { esNuevo ? 'Creación de temporada' : 'Actualización de temporada' }
    </Typography>
    <Typography component="p" variant="subtitle1">
      {
        esNuevo
          ? 'La creación de una temporada es el primer paso para comenzar a trabajar con el sistema.'
          : 'La actualización de una temporada te permite ajustar los detalles y parámetros según sea necesario para reflejar cambios o nuevas condiciones en el sistema.'
      }
    </Typography>
  </Box>
));

const Acciones = React.memo(({ history }) => {
  const dispatch = useDispatch();
  const pasoEnCurso = useSelector((state) => state.temporada.ui.stepper.pasoEnCurso);
  const pasos = useSelector((state) => state.temporada.ui.stepper.pasos, shallowEqual);

  const onContinuar = useCallback(async () => {
    const regresar = await dispatch(submitFormulario());
    if (regresar) {
      history.replace('/temporadas/listado');
    }
  }, [dispatch, history]);

  const onRegresar = useCallback(() => {
    dispatch(regresarFormulario());
  }, [dispatch]);

  const onCancelar = useCallback(() => {
    history.push('/temporadas/listado');
  }, [history]);

  return (
    <Box component="div" display="flex" gridGap={5}>
      <Boton
        label={pasoEnCurso === pasos.length - 1 ? 'Guardar' : 'Continuar'}
        onClick={onContinuar}
      />
      {
        pasoEnCurso > 0
          ? <Boton border label="Regresar" onClick={onRegresar} />
          : <Boton border label="Cancelar" onClick={onCancelar} />
      }
    </Box>
  );
});

const Stepper = React.memo(() => {
  const classes = styles();

  const pasos = useSelector((state) => state.temporada.ui.stepper.pasos, shallowEqual);
  const pasoEnCurso = useSelector((state) => state.temporada.ui.stepper.pasoEnCurso);
  const errores = useSelector((state) => {
    const { id, errores } = state.temporada.form[pasoEnCurso];
    const existenErrores = Object.keys(errores).length > 0;
    return existenErrores ? id : null;
  });

  return (
    <MuiStepper activeStep={pasoEnCurso} classes={{ root: classes.stepper }}>
      {
        pasos.map(({ id, nombre }) => (
          <Step key={id}>
            <StepLabel
              error={errores === id}
              classes={{
                label: clsx(classes.stepLabel),
                active: classes.stepLabel,
                iconContainer: classes.stepLabel,
              }}
            >
              {nombre}
            </StepLabel>
          </Step>
        ))
      }
    </MuiStepper>
  );
});

const Formularios = React.memo(() => {
  const pasoEnCurso = useSelector((state) => state.temporada.ui.stepper.pasoEnCurso);
  return (
    <Box flex={1}>
      <Switch condition={pasoEnCurso}>
        <Switch.Case key={enumPasos.SITIOS} when={enumPasos.SITIOS}>
          <ConfiguracionSitio />
        </Switch.Case>
        <Switch.Case key={enumPasos.INVERNADEROS} when={enumPasos.INVERNADEROS}>
          <ConfiguracionInvernaderos />
        </Switch.Case>
        <Switch.Case key={enumPasos.NAVES} when={enumPasos.NAVES}>
          <ConfiguracionNaves />
        </Switch.Case>
        <Switch.Case key={enumPasos.BASE} when={enumPasos.BASE}>
          <ConfiguracionTemporada />
        </Switch.Case>
        <Switch.Default>
          {null}
        </Switch.Default>
      </Switch>
    </Box>
  );
});

const Temporada = ({ history, location }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { temporadaPlantillaID } = location?.state || {};

  const cargarDatosIniciales = useCallback(async () => {
    const datosPrecargados = await dispatch(precargarDatos(id, temporadaPlantillaID));
    if (!datosPrecargados) {
      history.replace('/temporadas/listado');
    }
  }, [dispatch, history, id, temporadaPlantillaID]);

  useEffect(() => {
    cargarDatosIniciales();
    return () => {
      dispatch(restablecerFormulario());
    };
  }, [cargarDatosIniciales, dispatch]);

  return (
    <Wrapper>
      <Box flex={1} display="flex" justifyContent="space-between" marginBottom={2}>
        <Header esNuevo={!id} />
        <Acciones history={history} />
      </Box>

      <Divider />

      <Box flex={1} marginTop={3} marginBottom={3}>
        <Stepper />
      </Box>

      <Box flex={1} display="flex" height="70vh" overflow="auto" paddingY={1}>
        <Formularios />
        <FormMapa />
      </Box>
    </Wrapper>
  )
};

export default React.memo(Temporada);
