/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, createRef } from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import pointInPolygon from 'point-in-polygon';
import Default from '../../contenedores/Default';
import TextField from '../../componentes/TextField';
import Select from '../../componentes/Select';
import Agrupador from '../../componentes/Agrupador';
import Mapa from '../../componentes/Mapa';
import axios from '../../configuraciones/axios';
import { findPropertysEmpty, trim } from '../../utilidades/functions';
import endpoints, { INVERNADEROS } from '../../configuraciones/endpoints';
import estatus from '../../constantes/estatus';
import {
  POLIGONO_DENTRO_INVERNDERO, POLIGONO_FUERA_SITIO, POLIGONO_VACIO,
  ELEMENTO_NO_ENCONTRADO,
  SITIO_NO_SELECCIONADO,
  INVERNADERO_DEPENDENCIAS
} from '../../configuraciones/mensajes';
import paleta from '../../configuraciones/paleta';

const Invernadero = ({ history, match }) => {
  const mapRef = createRef(null);
  const [formErrors, setFormErrors] = useState({});
  const [invernadero, setInvernadero] = useState({});
  const [sitios, setSitios] = useState([]);
  const [poligonoInicial, setPoligonoInicial] = useState([]);
  const [poligono, setPoligono] = useState(null);
  const [poligonos, setPoligonos] = useState([]);
  const [bloqueoMapa, setBloqueoMapa] = useState({
    bloquear: false,
    motivo: '',
  });
  const { params: { id } } = match;

  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/invernaderos',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  const cancelar = useCallback(regresar, []);

  const validarPoligono = () => {
    let valido = true;
    let mensaje = '';
    const dibujo = poligono.state.polygon.getPath().getArray().map(({ lat, lng }) => {
      const punto = { latitud: lat(), longitud: lng() };
      if (id) punto.invernaderoID = id;
      return punto;
    });

    if (dibujo.length < 3) {
      toast.warning(POLIGONO_VACIO);
      return null;
    }

    for (const [index, poligonoSitio] of poligonos.entries()) {
      const poligonoContenedor = poligonoSitio.path.map(({ lat, lng }) => ([lat, lng]));
      for (const point of dibujo) {
        const puntoDentro = pointInPolygon([point.latitud, point.longitud], poligonoContenedor);
        if (!puntoDentro && index === 0) {
          valido = false;
          mensaje = POLIGONO_FUERA_SITIO;
          break;
        } else if (puntoDentro && index !== 0) {
          mensaje = POLIGONO_DENTRO_INVERNDERO;
          valido = false
        }

        if (!valido) break;
      }
    }
    if (!valido)
      toast.warning(mensaje);
    return valido ? dibujo : null;
  }

  const guardar = useCallback(() => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmInvernadero'), true);
    setFormErrors({ ...errors, ...regex });
    const coordenadas = validarPoligono();
    if (totalErrors > 0 || totalRegex > 0 || !coordenadas) return;

    const metodo = id ? 'put' : 'post';

    const objTrim = trim(invernadero);
    const nuevoObj = {
      ...objTrim,
      coordenadas,
    };

    axios[metodo](INVERNADEROS, nuevoObj).then(regresar);
  });

  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.sitiosCoordenadas()),
    ];
    if (id) promesas.push(axios.get(endpoints.base.url(INVERNADEROS, id)));

    Promise.all(promesas).then((resultadosPromesas) => {
      const [sitiosDb, invernaderoDb] = resultadosPromesas;
      setSitios(sitiosDb.map((s) => ({
        ...s,
        coordenadas: s.coordenadas.map((c) => ({ lat: c.latitud, lng: c.longitud }))
      })));

      if (invernaderoDb?.id) {
        setInvernadero(invernaderoDb);
        setPoligonoInicial(invernaderoDb.coordenadas.map((c) => ({ lat: c.latitud, lng: c.longitud })));
        if (invernaderoDb.naves?.length > 0)
          setBloqueoMapa({bloquear: true, motivo: INVERNADERO_DEPENDENCIAS});
      } else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      } else {
        setInvernadero({estatus: true});
        setBloqueoMapa({bloquear: true, motivo: SITIO_NO_SELECCIONADO});
      }
      resolve();
    }).catch(reject);
  });

  const handleClickSelect = () => {
    if ( bloqueoMapa.bloquear && bloqueoMapa.motivo !== SITIO_NO_SELECCIONADO ){
      toast.error(bloqueoMapa.motivo);
    }
  }

  useEffect(() => {
    consultarDatosIniciales();
  }, [id]);

  useEffect(() => {
    const sitio = sitios.find((s) => s.id === invernadero.sitioID);
    if (sitio) {
      const poligonosSitio = [
        {
          path: sitio?.coordenadas || [],
          options: {
            strokeColor: paleta.geocerca.container,
            fillColor: paleta.geocerca.container,
            fillOpacity: 0.40,
            strokeOpacity: 1,
            clickable: false,
          }
        },
        ...sitio.invernaderos.map((i) => ({
          id: i.id,
          path: i.coordenadas.map((c) => ({ lat: c.latitud, lng: c.longitud })),
          options: {
            strokeColor: 'transparent',
            fillColor: paleta.geocerca.secondary,
            fillOpacity: 0.55,
          },
        })).filter((p) => p.path.length > 0 && p.id.toString() !== id?.toString()),
      ];
      setPoligonos(poligonosSitio);
    }
  }, [sitios, invernadero.sitioID]);

  useEffect(() => {
    setBloqueoMapa({bloquear: false, motivo: ''});
  }, [invernadero.sitioID]);

  return <Default
    titulo={Boolean(id) ? 'Editar Invernadero' : 'Nuevo Invernadero'}
    placeHolder={''}
    mostrarCabeceroFormulario
    guardar={guardar}
    cancelar={cancelar}>
    <Grid container spacing={2} id="frmInvernadero">
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Código de invernadero"
              name="id"
              value={invernadero.id}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nombre"
              onChange={setInvernadero}
              name="nombre"
              value={invernadero.nombre}
              isHandleChange
              inputProps={{ maxLength: 50 }}
              error={formErrors.nombre}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Sitio"
              required
              options={sitios}
              labelProp="nombre"
              onChange={({ target: { value } }) => {
                mapRef.current.clearPreviousState();
                setInvernadero((current) => ({ ...current, sitioID: value, invernaderoID: '' }));
              }}
              name="sitioID"
              value={invernadero.sitioID || ''}
              error={formErrors.sitioID}
              disabled={(bloqueoMapa.bloquear && bloqueoMapa.motivo !== SITIO_NO_SELECCIONADO) ? true : false}
              onClick={handleClickSelect}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Estatus"
              required
              options={estatus}
              onChange={setInvernadero}
              name="estatus"
              value={invernadero.estatus}
              error={formErrors.estatus}
              isHandleChange
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Agrupador label="Geolocalización">
              <Mapa
                ref={mapRef}
                // bloquearClick={bloqueoMapa.bloquear}
                // motivoBloqueo={bloqueoMapa.motivo}
                setRef={setPoligono}
                poligonos={poligonos}
                drawPolygonOptions={{
                  strokeColor: paleta.geocerca.primary,
                  fillColor: paleta.geocerca.primary,
                }}
                poligonoInicial={poligonoInicial}
                defaultCenter={poligonos.length > 0 ? poligonos[0].path[0] : null}
              />
            </Agrupador>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(Invernadero);
