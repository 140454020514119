import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { batch, shallowEqual, useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';

import Table from '../../../../componentes/Table';
import Typography from '../../../../componentes/Typography';

import {
  actualizarDivision,
  agregarDivision,
  cargarDivisiones,
  eliminarDivision,
  obtenerDivisiones,
  setDisabledFormDivisor,
  setDivisionEnEdicion,
} from '../../../../ducks/temporada';

import styles, { BotonAgregarDivison, RowTextField } from './styles';
import globalStyles, { Boton } from '../../styles';

const selector = (state) => state.temporada.formDivisor;

const FormDivisor = ({
  initialValues,
  onConfirmar,
  onEliminarDisabled,
}) => {
  const classes = { ...globalStyles(), ...styles() };

  const dispatch = useDispatch();

  const disabled = useSelector((state) => selector(state).disabled);
  const divisionEnEdicion = useSelector((state) => selector(state).editingItemId);
  const divisiones = useSelector((state) => {
    const divisones = Object.values(selector(state).byId);
    return divisones.map(({ id, nombre }) => ({ id, nombre }));
  }, shallowEqual);

  const modificarNombre = useCallback((e) => {
    const { id, value: nombre } = e.target;
    dispatch(actualizarDivision({ id, nombre }));
  }, [dispatch]);

  const cabeceros = useMemo(() => [{
    label: 'nombre',
    transform: ({ id, nombre }) => {
      if (disabled) return <Typography>{nombre}</Typography>;
      return <RowTextField id={id} name="nombre" value={nombre} onChange={modificarNombre} />
    }
  }], [disabled, modificarNombre]);

  const handleConfirmar = () => {
    const divisiones = dispatch(obtenerDivisiones());
    onConfirmar(divisiones);
  };

  const handleCancelar = () => {
    batch(() => {
      dispatch(cargarDivisiones(initialValues));
      dispatch(setDisabledFormDivisor(true));
    })
  };

  const onClicAgregar = useCallback(() => {
    dispatch(agregarDivision());
  }, [dispatch]);

  const onClicEliminar = useCallback(({ id }) => {
    dispatch(eliminarDivision(id));
  }, [dispatch]);

  const seleccionarDivision = useCallback(({ id }) => {
    dispatch(setDivisionEnEdicion(id));
  }, [dispatch]);

  const iniciarDivision = useCallback(() => {
    dispatch(setDisabledFormDivisor(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(cargarDivisiones(initialValues));
  }, [dispatch, initialValues]);

  return (
    <Box
      className={classes.fadeInAnimate}
      display="flex"
      flexDirection="column"
      gridGap={15}
      width="100%"
    >
      <Typography component="h3" className={classes.label}>
        Divisiones
      </Typography>
      {!disabled && (<BotonAgregarDivison onClick={onClicAgregar} />)}
      {(divisiones.length > 0 || !disabled) && (
        <Box flex={1}>
          <Table
            hidePaginator
            headers={cabeceros}
            rows={divisiones}
            customClass={classes.tabla}
            editingItemId={divisionEnEdicion}
            emptyMessage="No se han registrado divisiones"
            onDelete={disabled ? onEliminarDisabled : onClicEliminar}
            onSelectRow={!disabled && seleccionarDivision}
            minRows={3}
          />
        </Box>
      )}
      {
        !disabled && (
          <Box flex={1} display="flex" gridGap={20} justifyContent="center">
            <Boton label="Confirmar" onClick={handleConfirmar} />
            <Boton border label="Cancelar" onClick={handleCancelar} />
          </Box>
        )
      }
      {
        disabled && (
          <Box flex={1} display="flex" gridGap={20} justifyContent="flex-start">
            <Boton label="Dividir Sitio" onClick={iniciarDivision} />
          </Box>
        )
      }
    </Box>
  )
};

FormDivisor.propTypes = {
  initialValues: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    nombre: PropTypes.string,
  })),
  onConfirmar: PropTypes.func,
  onEliminarDisabled: PropTypes.func,
};

FormDivisor.defaultProps = {
  initialValues: [],
  onConfirmar: () => { },
  onEliminarDisabled: () => { },
};

export default React.memo(FormDivisor);
