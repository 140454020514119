/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { toast } from 'react-toastify';
import { BsFillGearFill } from 'react-icons/bs';

import Default from '../../contenedores/Default';
import NaveGeolocalizacion from '../../componentes/NaveGeolocalizacion';
import NaveSecciones from '../../componentes/NaveSecciones';
import NaveValvulas from '../../componentes/NaveValvulas';
import Select from '../../componentes/Select';
import TextField from '../../componentes/TextField';

import axios from '../../configuraciones/axios';
import endpoints, { NAVES, SITIOS, TIPO_SUELO } from '../../configuraciones/endpoints';
import {
  ELEMENTO_NO_ENCONTRADO,
  GEOLOCALIZACION_NAVE_NO_CONFIGURADO,
  INVERNADERO_NO_SELECCIONADO,
} from '../../configuraciones/mensajes';
import paleta from '../../configuraciones/paleta';

import { findPropertysEmpty, trim } from '../../utilidades/functions';
import { DECIMAL_REGEX, NUMBER_REGEX } from '../../utilidades/regex';
import estatus from '../../constantes/estatus';

const Nave = ({ history, match }) => {
  const [formErrors, setFormErrors] = useState({});
  const naveInicial = {
    sitioID: '',
    invernaderoID: '',
    cantidadSecciones: '0',
    cantidadValvulas: '0',
    coordenadas: [],
    secciones: [],
    valvulas: [],
    estatus: true,
  };

  const { params: { id } } = match;

  const [nave, setNave] = useState({ ...naveInicial });
  const [sitios, setSitios] = useState([]);
  const [invernaderos, setInvernaderos] = useState([]);
  const [mostrarSecciones, setMostrarSecciones] = useState(false);
  const [mostrarValvulas, setMostrarValvulas] = useState(false);
  const [mostrarGeolocalizacion, setMostrarGeolocalizacion] = useState(false);
  const [tiposSuelos, setTiposSuelos] = useState([]);
  const [poligonoInicial, setPoligonoInicial] = useState([]);
  const [poligonos, setPoligonos] = useState([]);

  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/naves',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  const cancelar = useCallback(regresar, []);

  const guardarSecciones = (secciones) => {
    setNave((current) => ({ ...current, secciones, cantidadSecciones: secciones.filter(({ activo }) => activo).length }));
    setMostrarSecciones(false);
  }

  const guardarValvulas = (valvulas) => {
    setNave((current) => ({ ...current, valvulas, cantidadValvulas: valvulas.filter(({ activo }) => activo).length }));
    setMostrarValvulas(false);
  };

  const guardarGeolocalizacion = (coordenadas) => {
    setNave((current) => ({
      ...current,
      coordenadas,
    }));
    setMostrarGeolocalizacion(false);
  }

  const guardar = useCallback(() => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmNave'), true);

    if (nave.coordenadas.length === 0) {
      errors.coordenadas = true;
      toast.error(GEOLOCALIZACION_NAVE_NO_CONFIGURADO);
    }

    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0 || errors.coordenadas) return;

    const metodo = id ? 'put' : 'post';

    const objTrim = trim(nave);

    const { sitioID, ...rest } = objTrim;
    axios[metodo](NAVES, rest).then(regresar);
  });

  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.invernaderosCoordenadas()),
      axios.get(endpoints.base.busqueda(SITIOS)),
      axios.get(TIPO_SUELO),
    ];
    if (id) promesas.push(axios.get(endpoints.base.url(NAVES, id)));

    Promise.all(promesas).then((resultadosPromesas) => {
      const [invernaderosDb, sitiosDb, tipoSuelosDb, naveDb] = resultadosPromesas;
      setInvernaderos(invernaderosDb.map(invernadero => ({
        ...invernadero,
        coordenadas: invernadero.coordenadas.map((c) => ({ lat: c.latitud, lng: c.longitud }))
      })))

      setSitios(sitiosDb);
      setTiposSuelos(tipoSuelosDb);

      if (naveDb?.id) {
        const sitioID = invernaderosDb.find((invernadero) => invernadero.id === naveDb.invernaderoID).sitioID;
        setPoligonoInicial(naveDb.coordenadas.map((c) => ({ lat: c.latitud, lng: c.longitud })));

        axios.get(endpoints.base.url(SITIOS, sitioID)).then((resultado) => {
          setNave({ ...naveDb, sitioID });

          resolve();
        }).catch(reject);

      } else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      } else
        setNave({
          nombre: '',
          tipoSueloID: 1,
          especificacionSuelo: '',
          cantidadPlantas: '',
          hectareas: '',
          cantidadPeriodosRiegos: '',
          ...naveInicial,
          sitioID: '',
        });
      resolve();
    }).catch(reject);
  });

  const consultarTemporadaSitio = (id) => {
    axios.get(endpoints.base.url(SITIOS, id)).then((resultado) => {
      setNave((current) => ({
        ...current,
        sitioID: id,
        invernaderoID: '',
        coordenadas: [],
      }));
      setPoligonoInicial([]);
    }).catch();
  }

  const validarInvernaderoSeleccionado = (setState) => {
    if (nave.invernaderoID) {
      setState(true)
    } else {
      toast.error(INVERNADERO_NO_SELECCIONADO);
    }
  }

  useEffect(() => {
    consultarDatosIniciales();
  }, [id]);

  useEffect(() => {
    const invernadero = invernaderos.find((s) => s.id === nave.invernaderoID);
    if (invernadero) {
      const poligonosSitio = [
        {
          path: invernadero?.coordenadas || [],
          options: {
            strokeColor: 'transparent',
            fillColor: paleta.geocerca.container,
            fillOpacity: 0.70,
            clickable: false,
          }
        },
        ...invernadero.naves.map((i) => ({
          id: i.id,
          path: i.coordenadas.map((c) => ({ lat: c.latitud, lng: c.longitud })),
          options: {
            strokeColor: 'transparent',
            fillColor: paleta.geocerca.secondary,
            fillOpacity: 0.55,
          },
        })).filter((p) => p.path.length > 0 && p.id.toString() !== id?.toString()),
      ];

      setPoligonos(poligonosSitio);
    }
  }, [invernaderos, nave.invernaderoID]);

  return (
    <Default
      titulo={Boolean(id) ? 'Editar Nave' : 'Nueva Nave'}
      placeHolder={''}
      mostrarCabeceroFormulario
      guardar={guardar}
      cancelar={cancelar}
    >
      <Grid container spacing={2} id="frmNave">
        <NaveSecciones
          mostrar={mostrarSecciones}
          cancelar={() => setMostrarSecciones(false)}
          secciones={nave.secciones}
          guardar={guardarSecciones}
          naveID={id}
        />
        <NaveValvulas
          mostrar={mostrarValvulas}
          cancelar={() => setMostrarValvulas(false)}
          valvulas={nave.valvulas}
          guardar={guardarValvulas}
          naveID={id}
        />
        <NaveGeolocalizacion
          mostrar={mostrarGeolocalizacion}
          cancelar={() => setMostrarGeolocalizacion(false)}
          guardar={guardarGeolocalizacion}
          naveID={id}
          poligonos={poligonos}
          setPoligonoInicial={setPoligonoInicial}
          poligonoInicial={(poligonoInicial.length > 0 ? poligonoInicial : nave.coordenadas.map(coordenadas => ({ lat: coordenadas.latitud, lng: coordenadas.longitud })))}
        />
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                label="Código de nave"
                name="id"
                value={nave.id}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Nombre"
                onChange={setNave}
                name="nombre"
                value={nave.nombre}
                isHandleChange
                inputProps={{ maxLength: 50 }}
                error={formErrors.nombre}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                label="Sitio"
                required
                options={sitios}
                labelProp="nombre"
                onChange={({ target: { value } }) => {
                  consultarTemporadaSitio(value);
                }}
                name="sitioID"
                value={nave.sitioID || ''}
                error={formErrors.sitioID}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                label="Invernadero"
                required
                options={invernaderos.filter(({ sitioID }) => sitioID === nave.sitioID)}
                labelProp="nombre"
                onChange={({ target: { value } }) => {
                  setNave((current) => ({
                    ...current,
                    invernaderoID: value,
                    coordenadas: [],
                  }));
                  setPoligonoInicial([]);
                }}
                name="invernaderoID"
                value={nave.invernaderoID || ''}
                error={formErrors.invernaderoID}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Select
                label="Tipo Suelo"
                required
                options={tiposSuelos}
                labelProp="nombre"
                onChange={({ target: { value } }) => {
                  setNave((current) => ({ ...current, tipoSueloID: value }));
                }}
                name="tipoSueloID"
                value={nave.tipoSueloID || ''}
                error={formErrors.tipoSueloID}
              />
            </Grid>
            {
              nave.tipoSueloID === 2 && (
                <Grid item xs={12}>
                  <Grid container alignItems="flex-end">
                    <Grid item xs={10}>
                      <TextField
                        label="Cantidad de válvulas"
                        name="cantidadValvulas"
                        value={nave.valvulas.filter(({ activo }) => activo).length || '0'}
                        inputProps={{ regex: NUMBER_REGEX, regexonchange: 'true', maxLength: 9 }}
                        error={formErrors.cantidadValvulas}
                        required
                        disabled
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={() => setMostrarValvulas(true)}>
                        <BsFillGearFill color={paleta.bar.primary} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
            <Grid item xs={12}>
              <TextField
                label="Especificación de suelo"
                onChange={setNave}
                name="especificacionSuelo"
                value={nave.especificacionSuelo}
                isHandleChange
                inputProps={{ maxLength: 50 }}
                error={formErrors.especificacionSuelo}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Cantidad de plantas"
                onChange={setNave}
                isHandleChange
                name="cantidadPlantas"
                value={nave.cantidadPlantas}
                inputProps={{ regex: NUMBER_REGEX, regexonchange: 'true', maxLength: 9 }}
                error={formErrors.cantidadPlantas}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Hectáreas"
                onChange={setNave}
                name="hectareas"
                value={nave.hectareas}
                isHandleChange
                inputProps={{ regex: DECIMAL_REGEX, regexonchange: 'true', maxLength: 6 }}
                error={formErrors.hectareas}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Periodos de riego"
                onChange={setNave}
                name="cantidadPeriodosRiegos"
                value={nave.cantidadPeriodosRiegos}
                isHandleChange
                inputProps={{ regex: NUMBER_REGEX, regexonchange: 'true', maxLength: 9 }}
                error={formErrors.cantidadPeriodosRiegos}
                required
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container alignItems="flex-end">
                <Grid item xs={10}>
                  <TextField
                    label="Geolocalización"
                    name="geolocalizacion"
                    value={(nave.coordenadas.length > 2 || poligonoInicial.length > 2) ? "Configurado" : "Sin configurar"}
                    error={formErrors.coordenadas}
                    required
                    disabled
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => validarInvernaderoSeleccionado(setMostrarGeolocalizacion)}>
                    <BsFillGearFill color={paleta.bar.primary} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="flex-end">
                <Grid item xs={10}>
                  <TextField
                    label="Cantidad de secciones"
                    name="cantidadSecciones"
                    value={nave.secciones.filter(({ activo }) => activo).length || '0'}
                    inputProps={{ regex: NUMBER_REGEX, regexonchange: 'true', maxLength: 9 }}
                    error={formErrors.cantidadSecciones}
                    required
                    disabled
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => setMostrarSecciones(true)}>
                    <BsFillGearFill color={paleta.bar.primary} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Select
                label="Estatus"
                required
                options={estatus}
                onChange={setNave}
                name="estatus"
                value={nave.estatus}
                error={formErrors.estatus}
                isHandleChange
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Default>
  );
};

export default React.memo(Nave);
