import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { toast } from 'react-toastify';
import RightDrawer from '../RightDrawer';
import HeaderSubcatalogo from '../HeaderSubcatalogo';
import Tabla from '../Table';
import TextField from '../TextField';
import Select from '../Select';
import { findPropertysEmpty } from '../../utilidades/functions';
import estatus from '../../constantes/estatus';
import { NOMBRE_VALVULA_REPETIDO } from '../../configuraciones/mensajes';
import { NUMBER_REGEX } from '../../utilidades/regex';
import styles from './styles';

const NaveValvulas = ({ mostrar, guardar, cancelar, valvulas, naveID }) => {
  const classes = styles();
  const cabeceros = [
    {
      label: 'Código',
      key: 'id'
    },
    {
      label: 'Nombre',
      transform: ({ nombre, repetido }, index) => (
        <TextField
          name="nombre"
          onChange={(e) => manejadorValvula(e, index)}
          value={nombre}
          required
          error={(formErrors.nombre && (!nombre || nombre === '')) || repetido}
          inputProps={{ maxLength: 5, regexonchange: 'true', regex: NUMBER_REGEX }}
          className={classes.input}
        />
      ),
    },
    {
      label: 'Estatus',
      transform: (s, index) => (
        <Select
          required
          options={estatus}
          onChange={(e) => manejadorValvula(e, index)}
          name="estatus"
          value={s.estatus}
          error={formErrors.estatus && !s.hasOwnProperty('estatus')}
          style={{minWidth: 125}}
        />
      )
    },
  ];
  const [formErrors, setFormErrors] = useState({});
  const [nuevasValvulas, setNuevasValvulas] = useState([]);

  const validarNombresRepetidos = () => {
    let todosValidos = true;
    const edicionValvulas = [...nuevasValvulas];
    let index = 0;
    for (const s of edicionValvulas) {
      const indexExiste = nuevasValvulas.findIndex(({ nombre, activo }) => nombre?.toString().trim() === s.nombre?.toString().trim() && activo && s.activo);
      s.repetido = indexExiste >= 0 && indexExiste !== index;
      if (todosValidos && s.repetido) todosValidos = false;
      index++;
    }
    setNuevasValvulas(edicionValvulas);
    return todosValidos;
  };

  const agregar = () => {
    const valvula = {
      activo: true,
      nombre: nuevasValvulas.filter(({ activo }) => activo).length + 1,
      estatus: true,
    };
    if (naveID) valvula.naveID = naveID;
    setNuevasValvulas((current) => [...current, valvula]);
  };

  const eliminar = (row) => {
    if (row.id) {
      manejadorValvula({ target: { name: 'activo', value: false } }, row.index);
    } else {
      const edicionValvulas = [...nuevasValvulas];
      const valvulasActivas = edicionValvulas.filter(({ activo }) => activo);
      const indexEliminar = edicionValvulas.indexOf(valvulasActivas[row.index]);
      edicionValvulas.splice(indexEliminar, 1);
      setNuevasValvulas(edicionValvulas);
    }
  }

  const manejadorValvula = (e, index) => {
    const edicionValvulas = [...nuevasValvulas];
    edicionValvulas.filter(({ activo }) => activo)[index][e.target.name] = e.target.value;
    setNuevasValvulas(edicionValvulas);
  };

  const customGuardar = () => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmValvulas'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;
    const validos = validarNombresRepetidos();
    if (!validos) {
      return toast.warning(NOMBRE_VALVULA_REPETIDO);
    }
    guardar(nuevasValvulas);
  };

  useEffect(() => {
    setNuevasValvulas([...valvulas])
  }, [valvulas, mostrar]);

  return (
    <RightDrawer
      paperClassName={classes.paperDrawer}
      isOpen={mostrar}
      header={<HeaderSubcatalogo
        titulo="Válvulas"
        subtitulo="Configuración"
        guardar={customGuardar}
        cerrar={cancelar}
        agregar={agregar}
        textField={{
          label: 'Cantidad de válvulas',
          disabled: true,
          name: 'txtCantidadValvulas',
          value: nuevasValvulas.filter(({ activo }) => activo).length || '0'
        }}
      />}>
      <div id="frmValvulas">
        <Tabla
          headers={cabeceros}
          rows={nuevasValvulas.filter(({ activo }) => activo)}
          hidePaginator
          onDelete={eliminar}
        />
      </div>
    </RightDrawer>
  );
};

NaveValvulas.propTypes = {
  mostrar: propTypes.bool.isRequired,
  guardar: propTypes.func.isRequired,
  cancelar: propTypes.func.isRequired,
  valvulas: propTypes.array.isRequired,
  naveID: propTypes.oneOfType([propTypes.string, propTypes.number]),
};

NaveValvulas.defaultProps = {
  naveID: null,
};

export default NaveValvulas;
