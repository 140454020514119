import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosArrowDown } from 'react-icons/io';
import clsx from 'clsx';
import moment from 'moment';
import isEqual from 'lodash/isEqual';

import CalendarioSemanal from '../CalendarioSemanal';
import Chart from '../Chart';
import Typography from '../Typography';
import Select from '../Select';

import { setUbicacionDetecciones } from '../../ducks/nave';
import { setPlagaEnfermedadID } from '../../ducks/tablero';

import axios from '../../configuraciones/axios';
import paleta from '../../configuraciones/paleta';
import endpoints, { CAPTURA_PLANTAS, CAPTURA_TRAMPAS, ENFERMEDADES } from '../../configuraciones/endpoints';

import { PLANTAS, TRAMPAS } from '../../constantes/tipoDeteccion';
import { GEOLOCALIZADOS, VIRTUALIZADOS } from '../../constantes/tipoMarcadorPYE';
import { generarRecta, obtenerRangoFechas } from '../../utilidades/functions';

import styles from './styles';

const crearAnotacion = (x = 0, y = 0, color = paleta.graficos.barSelected) => {
  return {
    type: 'line',
    borderColor: color,
    borderDash: [6, 6],
    borderWidth: 2,
    value: (x) ? x : y,
    scaleID: (x) ? 'x' : 'y'
  }
};

const paletaEstados = {
  'Rojo': paleta.configuraciones.rojo,
  'Amarillo': paleta.configuraciones.amarillo,
  'Verde': paleta.configuraciones.verde,
};

const NavePlagasEnfermedades = ({ naveID }) => {
  const classes = styles();
  const { enfermedad, sitioID, semana, temporada, tipoMarcadorPYE } = useSelector(
    ({ tablero: {enfermedad, sitioID, semana, temporada}, nave: { tipoMarcadorPYE } }) => ({enfermedad, sitioID, semana, temporada, tipoMarcadorPYE}),
    (prev, next) => (
      isEqual(prev.enfermedad, next.enfermedad) &&
      prev.sitioID === next.sitioID &&
      prev.semana === next.semana &&
      isEqual(prev.temporada, next.temporada) &&
      prev.tipoMarcadorPYE === next.tipoMarcadorPYE
    )
  );
  const dispatch = useDispatch();

  const [enfermedades, setEnfermedades] = useState([]);
  const [enfermedadID, setEnfermedadID] = useState('');
  const [fecha, setFecha] = useState(null);
  const [data, setData] = useState({
    grafica: {
      labels: [], datasets: [{ data: [] }]
    }
  });

  const consultarCapturas = useCallback(async () => {
    try {
      if (sitioID && naveID && enfermedadID) {
        let nuevasCoordenadas = [];
        const dataset = [];
        const semanaActual = moment(semana, 'W (YYYY)');
  
        const tipoDeteccion = enfermedadID < 0 ? TRAMPAS : PLANTAS;
        const endpoint = tipoDeteccion === PLANTAS ? CAPTURA_PLANTAS : CAPTURA_TRAMPAS;
    
        const { capturas, capturasEnSemana = 0, tablas = null } = await axios.get(endpoint, {
          params: {
            naveID,
            enfermedadID: Math.abs(enfermedadID),
            sitioID,
            fecha: (tipoDeteccion === PLANTAS || !fecha)
              ? semanaActual.startOf('isoWeek').format('YYYY-MM-DD')
              : fecha,
            soloDia: (tipoDeteccion === TRAMPAS && fecha) ? 1 : 0,
          }
        });
  
        const semanas = obtenerRangoFechas(temporada.fechaInicio, temporada.fechaFin, 'W-YYYY', true);
        semanas.forEach(semana => {
          const { parametro = 0, estado, detalles = [], esTrampa } = capturas.find(val => val.fecha === semana) || {};
          dataset.push({
            semana,
            parametro: parseFloat(parametro),
            color: fecha === null || tipoDeteccion === PLANTAS ? (paletaEstados[estado] || paleta.configuraciones.gris) : paleta.graficos.barSelected,
            esTrampa,
            detalles
          });
        });
  
        const totalDetecciones = dataset.map(data => data.detalles.reduce((acc, { cantidadDetecciones }) => acc + parseFloat(cantidadDetecciones), 0));
        const { valor = 0, color = paleta.configuraciones.gris } = dataset.find(data => data.semana === semanaActual.format('W-YYYY') );
        const maxValue = Math.max(...totalDetecciones);
  
        if ( capturasEnSemana.length > 0 ) {
          if ( tipoDeteccion === TRAMPAS ) {
            nuevasCoordenadas = capturasEnSemana.map(({ id, latitud, longitud, trampa, valor, tabla }) => ({
              id,
              lat: parseFloat(latitud),
              lng: parseFloat(longitud),
              informacion: {
                mostrarTooltip: true,
                titulo: 'Trampas',
                imagen: [],
                icono: 'spider-web.svg',
                detalles: {
                  nombre: {
                    textoAMostrar: "Trampa",
                    valor: trampa
                  },
                  tabla: {
                    textoAMostrar: "Tabla",
                    valor: tabla,
                  },
                  numeroDetecciones: {
                    textoAMostrar: "Nº de detecciones",
                    valor: parseFloat(valor).toFixed(2),
                  },
                },
              }
            }));
          } else {
            nuevasCoordenadas = capturasEnSemana.map(({ id, latitud, longitud, tabla, tablaID, surco, cuadrante, valor, evidencias = [] }) => {
              let lat = null, lng = null;

              if ( tipoMarcadorPYE === GEOLOCALIZADOS || !tablas ) {
                lat = parseFloat(latitud);
                lng = parseFloat(longitud);
              } else if ( tipoMarcadorPYE === VIRTUALIZADOS ) {
                const { cantidadSurcos = 0, cantidadCuadrantesSurco = 0, coordenadasSurcos = [] } = tablas.find(({ id }) => id === tablaID);

                let cuadrantesVirtuales = [];
                const {
                  latitudInicioPrimerSurco, longitudInicioPrimerSurco,
                  latitudInicioUltimoSurco, longitudInicioUltimoSurco,
                  latitudFinPrimerSurco, longitudFinPrimerSurco,
                  latitudFinUltimoSurco, longitudFinUltimoSurco
                } = coordenadasSurcos;

                const inicioPrimerSurco = { lat: latitudInicioPrimerSurco, lng: longitudInicioPrimerSurco };
                const inicioUltimoSurco = { lat: latitudInicioUltimoSurco, lng: longitudInicioUltimoSurco };
                const finPrimerSurco = { lat: latitudFinPrimerSurco, lng: longitudFinPrimerSurco };
                const finUltimoSurco = { lat: latitudFinUltimoSurco, lng: longitudFinUltimoSurco };

                const filaInicio = generarRecta(inicioPrimerSurco, finPrimerSurco, cantidadSurcos);
                const filaFinal = generarRecta(inicioUltimoSurco, finUltimoSurco, cantidadSurcos);

                for (let i = 0; i < filaInicio.length; i++) {
                  const [x1, y1] = filaInicio[i];
                  const [x2, y2] = filaFinal[i];
          
                  cuadrantesVirtuales.push(generarRecta({lat: x1, lng: y1}, {lat: x2, lng: y2}, cantidadCuadrantesSurco));
                }

                cuadrantesVirtuales = cuadrantesVirtuales.map((surco) => {
                  return surco.map(([ lat, lng ], index) => ({ lat, lng, numero: index + 1 }))
                });

                lat = cuadrantesVirtuales[surco - 1][cuadrante - 1]?.lat || null;
                lng = cuadrantesVirtuales[surco - 1][cuadrante - 1]?.lng || null;
              }

              return {
                id,
                lat,
                lng,
                informacion: {
                  mostrarTooltip: true,
                  titulo: 'P&E en plantas',
                  imagen: evidencias,
                  icono: 'bug_rojo.svg',
                  detalles: {
                    tabla: {
                      textoAMostrar: "Tabla",
                      valor: tabla
                    },
                    surco: {
                      textoAMostrar: "Surco",
                      valor: surco
                    },
                    cuadrante: {
                      textoAMostrar: "Cuadrante",
                      valor: cuadrante
                    },
                    numeroDetecciones: {
                      textoAMostrar: "Nº de detecciones",
                      valor: parseFloat(valor).toFixed(2),
                    },
                  }
                },
              };
            });
          }
        }
  
        setData({
          grafica: {
            labels: semanas,
            datasets: [
              {
                label: 'Cantidad detectada',
                data: totalDetecciones,
                borderColor: color,
                pointBackgroundColor: dataset.map(() => '#FFF'),
                pointHoverBackgroundColor: dataset.map(({ color }) => color),
                pointBorderColor: dataset.map(({ color }) => color),
                pointStyle: 'circle',
                borderWidth: 2,
                pointRadius: 3,
                pointHoverRadius: 8,
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
              }
            ]
          },
          options: {
            plugins: {
              datalabels: { display: false },
              annotation: {
                drawTime: 'beforeDraw',
                annotations: {
                  line1: crearAnotacion(semanaActual - 1, null, color),
                  line2: crearAnotacion(null, valor, color),
                }
              },
              crosshair: false,
              tooltip: {
                padding: 10,
                titleMarginBottom: 8,
                bodySpacing: 10,
                bodyFont: {
                  family: 'Avenir',
                  size: 12,
                  weight: '600',
                },
                boxPadding: 5,
                callbacks: {
                  title: (tooltipItems) => {
                    return 'Semana ' + tooltipItems[0].label
                  },
                  afterBody: (tooltipItems) => {
                    if ( fecha !== null && tipoDeteccion === TRAMPAS ) return;
  
                    const { dataIndex, label } = tooltipItems[0];
                    const [ semanaObjetivo, anioObjetivo ] = label.split('-');
                    const { parametro: parametroGeneral, detalles, esTrampa } = dataset[dataIndex];

                    const parametro1 = esTrampa ? 'Detecciones' : 'Hallazgos';
                    const parametro2 = esTrampa ? 'Trampas monitoreadas' : '(PM * PPC)';

                    const resultados = detalles
                      .filter(({ semana, anio }) => String(semana) === semanaObjetivo && String(anio) === anioObjetivo)
                      .map(({ semana, anio, cantidadDetecciones, puntosMonitoreados, plantasPorCuadrante, trampasMonitoreadas, diasTranscurridos, parametro }) => {
                        return esTrampa
                          ? `(  ${cantidadDetecciones}  /  ${trampasMonitoreadas}  /  ${diasTranscurridos}  ) :  ${parametro}`
                          : `(  ${cantidadDetecciones}  /  (  ${puntosMonitoreados}  *  ${plantasPorCuadrante}  )  /  ${diasTranscurridos}  ) :  ${parametro}`;
                      });
                      
                    return [
                      'Fórmula:',
                      `(${parametro1} / ${parametro2} / Días transcurridos)`,
                      `Resultado (${parametroGeneral}):`,
                      ...resultados,
                    ];
                  },
                }
              },
            },
            scales: {
              y: {
                min: 0,
                suggestedMax: maxValue * 1.1,
                ticks: {
                  beginAtZero: true,
                  callback: function (value) { if (value % 1 === 0) { return value; } },
                },
              }
            },
          }
        });
        dispatch(setUbicacionDetecciones(nuevasCoordenadas));
      }

    } catch (error) {
      dispatch(setUbicacionDetecciones([]));
    }
  }, [semana, enfermedadID, naveID, sitioID, fecha, temporada, dispatch, tipoMarcadorPYE]);

  const onEnfermedadChange = useCallback(({ target: { value }}) => {
    const { id, nombre } = enfermedades.find(enfermedad => enfermedad.id === parseInt(value));
    dispatch(setPlagaEnfermedadID({ id: Math.abs(id), esTrampa: id < 0, nombre }));
  }, [dispatch, enfermedades]);
  
  const obtenerEnfermedades = useCallback(async () => {
    try {
      const data = [];
      const enfermedadesBD = await axios.get(endpoints.base.busqueda(ENFERMEDADES));
  
      for ( const { id, nombre, tipoEnfermedadID } of enfermedadesBD ) {
        data.push({ id, nombre });
        
        if ( tipoEnfermedadID === 2 ) {
          data.push({ id: id * -1, nombre: `${nombre} (Trampa)` });
        }
      }
      
      setEnfermedades(data);
    } catch { }
  }, []);

  const onClickSemana = useCallback(() => {
    setFecha(null);
  }, []);

  useEffect(() => {
    setFecha(null);
  }, [semana]);

  useEffect(() => {
    obtenerEnfermedades();
  }, [obtenerEnfermedades]);

  useEffect(() => {
    if ( enfermedad.id ) {
      const id = enfermedad.esTrampa ? enfermedad.id * -1 : enfermedad.id;
      setEnfermedadID(id);
      !enfermedad.esTrampa > 0 && setFecha(null);
    }
  }, [enfermedad.id, enfermedad.esTrampa, onEnfermedadChange]);

  useEffect(() => {
    consultarCapturas();
  }, [consultarCapturas, dispatch]);

  return (
    <Grid>
      <Grid container justifyContent="flex-start" alignItems="center">
        <Typography variant='h6' bold className={classes.labelTitle}>
          PLAGAS Y ENFERMEDADES
        </Typography>
        <Select
          classNameInput={classes.select}
          IconComponent={(props) => (
            <IoIosArrowDown
              {...props}
              color={paleta.bar.secondary}
              size={20}
            />
          )}
          options={enfermedades}
          labelProp="nombre"
          name="id"
          placeHolder="Seleccione una enfermedad"
          value={enfermedades.length > 0 ? enfermedadID : ""}
          onChange={onEnfermedadChange}
          style={{marginTop: 0}}
          displayTextStyles={{
            color: paleta.bar.secondary,
            fontWeight: '300',
            letterSpacing: 0.4,
            paddingLeft: 8,
          }}
        />
      </Grid>
      <CalendarioSemanal
        onClickSemana={onClickSemana}
	      onSelectDia={setFecha}
        semana={semana}
        className={clsx(classes.calendario, { active: (enfermedadID < 0) })}
      />
      <Chart
        type="line"
        data={data.grafica}
        options={data.options}
        height={250}
        scrollTo={moment(semana, 'W (YYYY)').format('W-YYYY')}
        redraw
      />
    </Grid>
  )
};

export default React.memo(NavePlagasEnfermedades);