/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { setNaves } from '../ducks/tablero';
import axios from '../configuraciones/axios';
import endpoints, { NAVES, SITIOS } from '../configuraciones/endpoints';
import paleta from '../configuraciones/paleta';

import { obtenerCentroide } from '../utilidades/functions';

const defaultOptions = {
  strokeColor: paleta.geocerca.container,
  fillColor: 'transparent',
  fillOpacity: 0.52,
  clickable: false
}

const paletaEstados = {
  "Verde": paleta.configuraciones.verde,
  "Amarillo": paleta.configuraciones.amarillo,
  "Rojo": paleta.configuraciones.rojo,
  "Indefinido": paleta.configuraciones.gris,
  "Vegetativo": paleta.configuraciones.vegetativo,
  "Balanceado": paleta.configuraciones.balanceado,
  "Generativo": paleta.configuraciones.generativo,
};

const naveParametrosDefault = {
  extraInfo: '',
  mostrarTooltip: false,
  nombreDeteccion: '',
  detallesSemaforo: {},
  estado: undefined
};

const poligonoSinEstadoOptions = {
  fillColor: paleta.configuraciones.gris,
  fillOpacity: 0.52,
  strokeColor: paleta.configuraciones.stroke.Gris,
  strokeWeight: 3,
};

const SIN_ESTADO = 'Indefinido';

export const useCoordenadasNaves = (naveID, nombreNave = '') => {
  const dispatch = useDispatch();
  const { sitioID, naves, navesDetectadas, ubicacionDetecciones, monitoreoActivo } = useSelector(
    ({
      tablero: { sitioID, naves, navesDetectadas, enfermedad, monitoreoActivo },
      nave: { ubicacionDetecciones }
    }) => ({ sitioID, naves, navesDetectadas, enfermedad, ubicacionDetecciones, monitoreoActivo }),
    (prev, next) => {
      return (
        prev.sitioID === next.sitioID &&
        isEqual(prev.naves, next.naves) &&
        isEqual(prev.navesDetectadas, next.navesDetectadas) &&
        isEqual(prev.enfermedad, next.enfermedad) &&
        isEqual(prev.monitoreoActivo, next.monitoreoActivo) &&
        isEqual(prev.ubicacionDetecciones, next.ubicacionDetecciones)
      )
    }
  );

  const [poligonos, setPoligonos] = useState([]);
  const [center, setCenter] = useState(null);

  useEffect(() => {
    if (!naveID) consultarCoordenadas();
  }, [sitioID]);

  useEffect(() => {
    if (naveID) obtenerNavePorID();
  }, [naveID]);

  // Pinta las naves detectadas
  useEffect(() => {
    if (navesDetectadas) {
      const navesDetectadasID = navesDetectadas.map((el) => el.id);
      const navesFiltradas = naves.map(nave => {
        if ( navesDetectadasID.includes(nave.id) ) {
          const { extraInfo = '', estado,  ...restNaveDetalles } = navesDetectadas.find(el => el.id === nave.id) || {};
          return { ...nave, ...restNaveDetalles, extraInfo, estado: estado ? estado : SIN_ESTADO };
        } else {
          return { ...nave, ...naveParametrosDefault, estado: SIN_ESTADO, extraInfo: monitoreoActivo.fenologia ? 'Sin estado' : '' };
        }
      });
      const nuevosPoligonos = poligonos.map((poligono) => {
        if ( navesDetectadasID.includes(poligono.id) ){
          const { estado = SIN_ESTADO } = navesDetectadas.find(el => el.id === poligono.id) || {};

          if ( estado ) {
            return {
              ...poligono,
              options: {
                ...poligono.options,
                fillColor: paletaEstados[estado],
                fillOpacity: 0.52,
                strokeColor: paleta.configuraciones.stroke[estado],
                strokeWeight: 3,
              }
            };
          }

          return {
            ...poligono,
            options: {
              ...poligono.options,
              ...poligonoSinEstadoOptions,
            }
          };
        } else if (poligono.id) {
          return {
            ...poligono,
            options: {
              ...poligono.options,
              ...poligonoSinEstadoOptions,
            }
          }
        } else {
          return poligono;
        }
      });
      setPoligonos(nuevosPoligonos);
      dispatch(setNaves(navesFiltradas));
    } else {
      const navesDefault = naves.map(nave => ({ ...nave, ...naveParametrosDefault }));
      dispatch(setNaves(navesDefault));
      resetPoligonos();
    }
  }, [navesDetectadas]);

  const obtenerNavePorID = async () => {
    try {
      const nave = await axios.get(endpoints.base.url(NAVES, naveID));
      const centro = obtenerCentroide(nave.coordenadas.map((vertice) => ({ x: vertice.latitud, y: vertice.longitud })));

      setCenter(() => {
        if (centro?.x) {
          return { lat: centro.x, lng: centro.y }
        } else {
          return null;
        }
      });

      setPoligonos([formatearObjeto(nave.coordenadas, {
        strokeColor: 'transparent',
        fillColor: paleta.geocerca.primary,
        fillOpacity: 0.60,
        clickable: false
      })]);
      dispatch(setNaves([{
        id: nave.id,
        nombre: nombreNave ? nombreNave : nave.nombre,
        sitioID: sitioID,
        invernaderoID: nave.invernaderoID,
        coordenadasPoligono: nave.coordenadas,
        lat: centro?.x || 0,
        lng: centro?.y || 0,
      }]));
    } catch {}
  };

  const consultarCoordenadas = async () => {
    try {
      if (!sitioID) return;

      const { coordenadas: coordenadasSitio, invernaderos } = await axios.get(endpoints.base.coordenadas(SITIOS, sitioID));

      const poligonos = [];
      const naves = [];
      const centros = [];

      poligonos.push(formatearObjeto(coordenadasSitio));

      poligonos.push(...invernaderos.map(({coordenadas}) => formatearObjeto(coordenadas, {
        strokeColor: 'transparent',
        fillColor: 'transparent',
        clickable: false
      })))

      invernaderos.forEach((invernadero) => {
        centros.push(...invernadero.naves.map(({coordenadas}) => obtenerCentroide(coordenadas.map((vertice) => ({ x: vertice.latitud, y: vertice.longitud })))))
        naves.push(...invernadero.naves.map(nave => {
          return {
            ...nave,
            invernaderoID: invernadero.id,
            nombre: `${invernadero.nombre} - ${nave.nombre}`
          }
        }));
      });

      poligonos.push(...naves.map(({id, coordenadas}) => formatearObjeto(coordenadas, {
        id,
        fillColor: paleta.geocerca.primary,
        fillOpacity: .50,
        clickable: false,
        strokeWeight: 3,
        strokeColor: paleta.geocerca.primary,
      })))

      setPoligonos(poligonos);

      dispatch(setNaves(naves.map((nave, index) => ({
        id: nave.id,
        nombre: nave.nombre,
        sitioID: sitioID,
        invernaderoID: nave.invernaderoID,
        coordenadasPoligono: nave.coordenadas,
        lat: centros[index]?.x || 0,
        lng: centros[index]?.y || 0,
      }))));
    } catch {}
  };

  const formatearObjeto = (coordenadas, options = defaultOptions ) => {
    const objeto = {
      id: options.id || null,
      path: coordenadas ? coordenadas.map((vertice) => ({ lat: vertice.latitud, lng: vertice.longitud })) : [],
      options: {
        ...options
      }
    }

    return objeto;
  };

  const resetPoligonos = () => {
    const nuevosPoligonos = poligonos.map((poligono) => {
      if (poligono.id) {
        return {
          ...poligono,
          options: {
            ...poligono.options,
            fillColor: paleta.geocerca.primary,
            fillOpacity: .50,
            clickable: false,
            strokeWeight: 3,
            strokeColor: paleta.geocerca.primary,
          }
        }
      } else {
        return poligono;
      }
    });
    setPoligonos(nuevosPoligonos);
  };

  return {
    poligonos,
    naves,
    ubicacionDetecciones,
    center,
    resetPoligonos,
  };
}
