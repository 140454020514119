import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: `${paleta.bar.primary}`,
  },
  container: {
    position: 'relative'
  },
  label: {
    color: paleta.bar.primary,
    fontWeight: '600',
    justifySelf: 'flex-start',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#0001',
  },
  divider: {
    borderColor: paleta.bar.elements,
    borderTop: "dashed 1.5px",
    marginTop: 20,
    marginBottom: 20,
    opacity: 0,
    width: '100%',

    transition: 'opacity 300ms ease-in-out',
    '&.active': {
      opacity: 1,
    },
  },
  lineChartContainer: {
    width: '100%',
    height: 300,
    overflowX: 'scroll',
  },
  lineChart: {
    minWidth: 2000,
    maxHeight: 300,
  },
  historial: {
    width: '100%',
    transform: 'translateY(100%)',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'transform 300ms ease-in-out, opacity 300ms ease-in-out',
    '&.active': {
      transform: 'translateY(0)',
      opacity: 1,
      pointerEvents: 'all',
    },
  },
  actionsContainer: {
    alignItems: 'center',
    '& button': {
      cursor: 'pointer',
    },
    display: 'flex',
    flex: 1,
    gap: 10,
    justifyContent: 'flex-end',
  },
  refreshButton: {
    position: 'relative',
    justifyContent: 'flex-start',
    width: 'auto',
    flex: 'none',
  },
}));

export default styles;
