import React from 'react';
import { batch, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card, CardMedia, CardContent,
  Tooltip,
  withStyles,
  Zoom,
} from '@material-ui/core';

import Typography from '../Typography';

import { setEvidencias, setModalOpen } from '../../ducks/nave';
import styles from './styles.js';

const CustomTooltip = withStyles((theme) => ({
  arrow: { 
    color: '#FFF',
  },
  tooltip: {
    backgroundColor: '#FFF',
    borderRadius: 6,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
    color: '#000',
    minWidth: 200,
    maxWidth: 220,
    padding: 0,
  },
}))(Tooltip);

const BugMarker = ({ mostrarTooltip, titulo, imagen, detalles, icono }) => {
  const classes = styles();
  const dispatch = useDispatch();
  
  const handleClick = () => {
    if ( imagen.length > 0) {
      const imagenes = imagen.map(i => {
        return {
          ...i,
          title: i.fechaRegistro,
          author: 'Del Campo',
          featured: true,
        }
      })
      batch(() => {
        dispatch(setEvidencias(imagenes));
        dispatch(setModalOpen());
      });
    }
  };
  
  return (
    <CustomTooltip
      title={
        <React.Fragment>
          <Card sx={{ maxWidth: 345 }} className={classes.cardContainer}>
            {imagen.length > 0 && (
              <CardMedia
                component="img"
                height="140"
                image={imagen[0]?.path}
                alt="Evidencia"
              />
            )}
            <CardContent style={{ paddingInline: 20, paddingBlock: 10 }}>
              <Typography variant="h6" component="div" bold>
                { titulo }
              </Typography>
              {
                Object.entries(detalles).map(([key, { textoAMostrar, valor }]) => (
                  <Typography key={key} variant="subtitle2">
                    { textoAMostrar }: <strong>{ valor }</strong>
                  </Typography>
                ))
              }
            </CardContent>
          </Card>
        </React.Fragment>
      }
      TransitionComponent={Zoom}
      disableHoverListener={!mostrarTooltip}
      arrow
    >
      <img 
        src={`/assets/images/${icono}`}
        alt="Marker"
        style={{ cursor: 'pointer' }}
        width="16px"
        height="16px"
        onClick={handleClick}
      />
    </CustomTooltip>
  )
}

BugMarker.propTypes = {
  mostrarTooltip: PropTypes.bool,
  titulo: PropTypes.string,
  imagen: PropTypes.array,
  detalles: PropTypes.object,
  icono: PropTypes.string,
};

BugMarker.defaultProps = {
  mostrarTooltip: false,
  titulo: '',
  imagen: [],
  detalles: {},
  icono: 'bug_rojo.svg',
};

export default BugMarker;