/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import Default from '../../contenedores/Default';
import TextField from '../../componentes/TextField';
import Select from '../../componentes/Select';
import axios from '../../configuraciones/axios';
import { findPropertysEmpty, trim } from '../../utilidades/functions';
import endpoints, {
  FENOLOGIAS, UNIDADES_MEDIDA
} from '../../configuraciones/endpoints';
import estatus from '../../constantes/estatus';
import requerido from '../../constantes/requerido';
import tipoCaptura from '../../constantes/tipoCaptura';
import { ELEMENTO_NO_ENCONTRADO } from '../../configuraciones/mensajes';

const Fenologia = ({ history, match }) => {
  const [fenologia, setFenologia] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [unidadesMedida, setUnidadesMedida] = useState([]);
  const { params: { id } } = match;

  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/fenologias',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  const cancelar = useCallback(regresar, []);

  const guardar = useCallback(() => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmFenologia'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;

    const metodo = id ? 'put' : 'post';

    const objTrim = trim(fenologia);

    axios[metodo](FENOLOGIAS, objTrim).then(regresar);
  }, [fenologia]);

  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.base.busqueda(UNIDADES_MEDIDA)),
    ];
    if (id) {
      promesas.push(axios.get(endpoints.base.url(FENOLOGIAS, id)));
    }

    Promise.all(promesas).then((resultadosPromesas) => {
      const [unidadesMedidaDb, fenologiaDb] = resultadosPromesas;
      setUnidadesMedida(unidadesMedidaDb);
      if (fenologiaDb?.id)
        setFenologia(fenologiaDb);
      else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      } else
        setFenologia({estatus: true, requerido: true});
      resolve();
    }).catch(reject);
  });

  useEffect(() => {
    consultarDatosIniciales();
  }, [id]);

  return <Default
    titulo={Boolean(id) ? 'Editar Fenología' : 'Nueva Fenología'}
    placeHolder={''}
    mostrarCabeceroFormulario
    guardar={guardar}
    cancelar={cancelar}>
    <Grid container spacing={2} id="frmFenologia">
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Nombre de Fenología"
              onChange={setFenologia}
              name="nombre"
              value={fenologia.nombre}
              isHandleChange
              inputProps={{ maxLength: 50 }}
              error={formErrors.nombre}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Tipo de Captura"
              required
              options={tipoCaptura}
              onChange={({ target: { value } }) => {
                setFenologia((current) => ({ ...current, tipoCaptura: value, unidadMedidaID: null }));
              }}
              name="tipoCaptura"
              value={fenologia.tipoCaptura || ''}
              error={formErrors.tipoCaptura}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Captura Obligatoria"
              required
              options={requerido}
              onChange={setFenologia}
              name="requerido"
              value={fenologia.requerido}
              error={formErrors.requerido}
              isHandleChange
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4}>
          {fenologia.tipoCaptura === 'numerico' && <Grid item xs={12}>
            <Select
              label="Unidad de medida"
              required
              options={unidadesMedida}
              onChange={setFenologia}
              name="unidadMedidaID"
              value={fenologia.unidadMedidaID || ''}
              labelProp="nombre"
              customLabel={(option) => `${option.nombre} (${option.nombreCorto})`}
              error={formErrors.unidadMedidaID}
              isHandleChange
            />
          </Grid>}
          <Grid item xs={12}>
            <Select
              label="Estatus"
              required
              options={estatus}
              onChange={setFenologia}
              name="estatus"
              value={fenologia.estatus}
              error={formErrors.estatus}
              isHandleChange
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(Fenologia);
