import React, { useCallback, useState } from 'react';
import { MdAddCircleOutline, MdOutlineFileUpload } from 'react-icons/md';

import SplitButton from '../../../../componentes/SplitButton';

import AyudaTemporadas from '../AyudaTemporadas';

import styles from '../../styles';

const BotonAgregar = ({ onClick }) => {
  const classes = styles();

  const [modalAyudaAbierto, setModalAyudaAbierto] = useState(false);

  const abrirAyudaTemporadas = useCallback(() => {
    setModalAyudaAbierto(true);
  }, []);

  const cerrarAyudaTemporadas = useCallback(() => {
    setModalAyudaAbierto(false);
  }, []);

  return (
    <>
      <AyudaTemporadas abierto={modalAyudaAbierto} onCancelar={cerrarAyudaTemporadas} />
      <SplitButton
        classes={{ boton: classes.button }}
        label="Agregar"
        icono={MdAddCircleOutline}
        opciones={[{
          label: 'Importar temporada',
          onClick: abrirAyudaTemporadas,
          icono: MdOutlineFileUpload,
        }]}
        onClick={onClick}
      />
    </>
  )
}

export default React.memo(BotonAgregar);
