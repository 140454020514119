import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';

import Typography from '../../../../componentes/Typography';

import general from '../../../../configuraciones/general';
import { calcularAreaDelimitacion } from '../../../../utilidades/functions';
import { options } from '../../../../constantes/poligonosOptions';

import styles from './styles';

const Mapa = ({
  flex,
  sitios,
  invernaderos,
  naves,
  tablas,
  seccionVisible,
  navesSeleccionadas,
  tablasSeleccionadas,
}) => {
  const classes = styles();
  const mapRef = useRef(null);
  const librariesRef = useRef(['drawing']);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: general.MAPS_API_KEY,
    libraries: librariesRef.current,
  });

  const onLoad = useCallback(function callback(map) {
    try {
      const bounds = new window.google.maps.LatLngBounds();
      map.fitBounds(bounds);
      mapRef.current = map;
    } catch (error) {
      console.error('Error al cargar el mapa:', error);
    }
  }, [])

  const onUnmount = useCallback(function callback() {
    mapRef.current = null;
  }, [])

  const areaDelimitacion = useMemo(() => {
    if (sitios.length === 0) return null;
    const [{ coordenadas }] = sitios;
    return calcularAreaDelimitacion(coordenadas.map(({ latitud, longitud }) => ({ lat: latitud, lng: longitud })));
  }, [sitios]);

  useEffect(() => {
    if (sitios.length === 0 || !mapRef.current) return;
    const [{ coordenadas }] = sitios;

    const bounds = new window.google.maps.LatLngBounds();
    coordenadas.forEach(({ latitud: lat, longitud: lng }) => bounds.extend({ lat, lng }));

    mapRef.current.fitBounds(bounds);
  }, [sitios]);

  if (!isLoaded) return null;

  return (
    <Box flex={flex} display="flex" flexDirection="column" gridGap={10}>
      <Typography component="h3">Mapa</Typography>
      <GoogleMap
        id='map-temporadas'
        mapContainerClassName={classes.root}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          mapTypeId: 'satellite',
          streetViewControl: false,
          restriction: areaDelimitacion,
          mapTypeControl: false,
        }}
      >
        {/* Sitios */}
        {seccionVisible === 0 && sitios.map(({ id, coordenadas }) => (
          <Polygon
            key={id}
            path={coordenadas.map(({ latitud, longitud }) => ({ lat: latitud, lng: longitud }))}
            options={options.primario}
          />
        ))}

        {/* Invernaderos */}
        {(seccionVisible === 1 || seccionVisible === 2) && invernaderos.map(({ id, coordenadas }) => (
          <Polygon
            key={id}
            path={coordenadas.map(({ latitud, longitud }) => ({ lat: latitud, lng: longitud }))}
            options={options.secundario}
          />
        ))}

        {/* Naves */}
        {(seccionVisible >= 3 && seccionVisible < (naves.length + 3)) && (
          <>
            {invernaderos.map(({ id, coordenadas }) => (
              <Polygon
                key={id}
                path={coordenadas.map(({ latitud, longitud }) => ({ lat: latitud, lng: longitud }))}
                options={options.contenedor}
              />
            ))}
            {naves.map(({ id, coordenadas }, index) => (
              <Polygon
                key={id}
                path={coordenadas.map(({ latitud, longitud }) => ({ lat: latitud, lng: longitud }))}
                options={index === (seccionVisible - 3) ? options.primario : options.disabled}
              />
            ))}
          </>
        )}

        {/* Tablas */}
        {seccionVisible >= (naves.length + 3) && (
          <>
            {invernaderos.map(({ id, coordenadas }) => (
              <Polygon
                key={id}
                path={coordenadas.map(({ latitud, longitud }) => ({ lat: latitud, lng: longitud }))}
                options={options.contenedor}
              />
            ))}
            {naves.map(({ id, coordenadas }) => (
              <Polygon
                key={id}
                path={coordenadas.map(({ latitud, longitud }) => ({ lat: latitud, lng: longitud }))}
                options={options.primario}
              />
            ))}
            {tablas.filter(({ naveID }) => navesSeleccionadas.includes(naveID)).map(({ id, coordenadas }) => (
              <Polygon
                key={id}
                path={coordenadas.map(({ latitud, longitud }) => ({ lat: latitud, lng: longitud }))}
                options={tablasSeleccionadas.includes(id) ? options.terciario : options.disabledTerciario}
              />
            ))}
          </>
        )}
      </GoogleMap>
    </Box>
  );
};

Mapa.propTypes = {
  flex: PropTypes.number,
  sitios: PropTypes.array,
  invernaderos: PropTypes.array,
  naves: PropTypes.array,
  tablas: PropTypes.array,
  seccionVisible: PropTypes.number,
  navesSeleccionadas: PropTypes.array,
  tablasSeleccionadas: PropTypes.array,
};

Mapa.defaultProps = {
  flex: 1,
  sitios: [],
  invernaderos: [],
  naves: [],
  tablas: [],
  seccionVisible: 0,
  navesSeleccionadas: [],
  tablasSeleccionadas: [],
};

export default React.memo(Mapa);
