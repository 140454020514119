import React from "react";
import propTypes from "prop-types";
import { Grid, IconButton } from "@material-ui/core";
import { BsPlusCircle } from "react-icons/bs";
import TextField from "../TextField";
import Typography from "../Typography";
import Boton from "../Button";
import styles from "./styles";
import Confirmacion from "../Confirmacion";

const HeaderSubcatalogo = ({
  titulo,
  subtitulo,
  guardar,
  cerrar,
  agregar,
  eliminar,
  textField,
  anchor,
  onClose,
  openConfirmacion,
  mostrarTextField,
}) => {
  const classes = styles();

  return (
    <Grid container alignItems="flex-end">
      <Grid item xs={12} className={classes.titleContainer}>
        <Typography className={classes.title}>
          {titulo}
          <Typography className="subtitle" component="span">
            {subtitulo}
          </Typography>
        </Typography>
      </Grid>
      <div className={classes.infoContainer}>
        <Grid item xs={8} className={classes.txtContainer}>
          {mostrarTextField ? (
            <TextField {...textField} type="number"/>
          ) : (
            <Typography className={classes.label}>Añadir</Typography>
          )}
          {agregar && (
            <IconButton onClick={agregar}>
              <BsPlusCircle />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={4} className={classes.btnContainer}>
          {
            eliminar && (
              <>
                <Boton onClick={openConfirmacion} label="Eliminar todos" />
                <Confirmacion
                  mensaje='¿Esta seguro de eliminar todos los elementos?'
                  anchor={anchor}
                  onClose={onClose}
                  onAccept={eliminar}
                />
              </>
            )
          }
          <Boton onClick={guardar} label="Guardar" />
          <Boton onClick={cerrar} label="Cancelar" border />
        </Grid>
      </div>
    </Grid>
  );
};

HeaderSubcatalogo.propTypes = {
  /** titulo del cabecero */
  titulo: propTypes.string.isRequired,
  /** Subtitulo del cabecero */
  subtitulo: propTypes.string,
  /** Funcion para el boton guardar */
  guardar: propTypes.func.isRequired,
  /** Funcion para el boton cerrar/cancelar */
  cerrar: propTypes.func.isRequired,
  /** Funcion para el boton de agregar registro */
  agregar: propTypes.func,
  /** Informacion para el campo de texto */
  textField: propTypes.shape({
    disabled: propTypes.bool,
    name: propTypes.string.isRequired,
    value: propTypes.oneOfType([propTypes.string, propTypes.number]),
    label: propTypes.string.isRequired,
  }),
  /** Indica si quiere mostrar el input informativo*/
  mostrarTextField: propTypes.bool,
};

HeaderSubcatalogo.defaultProps = {
  subtitulo: "",
  mostrarTextField: true,
  agregar: null,
  guardar: null,
  cerrar: null,
};

export default HeaderSubcatalogo;
