import EstadoMonitoreo from '../../componentes/EstadoMonitoreo';
import PronosticoClima from '../../componentes/PronosticoClima';
import TableroDetalles from '../../componentes/TableroDetalles';
import TableroDetecciones from '../../componentes/TableroDetecciones';
import TableroMonitorizacion from '../../componentes/TableroMonitorizacion';

const Tablero = ({ history, location }) => {
  return (
    <TableroDetalles history={history} location={location}>
      <PronosticoClima />
      <TableroMonitorizacion />
      <TableroDetecciones />
      <EstadoMonitoreo />
    </TableroDetalles>
  )
}

export default Tablero;
