import React, { useCallback, useDebugValue, useEffect, useState } from 'react';
import { BsFillCircleFill as CircleIcon } from 'react-icons/bs';
import pointInPolygon from 'point-in-polygon';

import { ERROR_COORDENADA_EN_CONFLICTO_CON_TABLA, ERROR_COORDENADA_FUERA_CONTENEDOR } from '../configuraciones/mensajes';
import { generarRecta } from '../utilidades/functions';

export const tabEnum = {
  TABLA: 0,
  PRIMER_SURCO: 1,
  ULTIMO_SURCO: 2,
};

export const instrucciones = {
  [tabEnum.TABLA]: [{
    descripcion: ['Del área señalada que corresponde a la nave, seleccione el área que corresponde a la tabla actual'],
    icono: <CircleIcon color="#FF0" size={40} />
  }],
  [tabEnum.PRIMER_SURCO]: [
    { descripcion: ['Seleccione el punto donde', 'inicia', 'el primer surco'], icono: <CircleIcon color="#00F" size={25} /> },
    { descripcion: ['Seleccione el punto donde', 'termina', 'el primer surco'], icono: <CircleIcon color="#F0F" size={25} /> },
  ],
  [tabEnum.ULTIMO_SURCO]: [
    { descripcion: ['Seleccione el punto donde', 'inicia', 'el último surco'], icono: <CircleIcon color="#F00" size={25} /> },
    { descripcion: ['Seleccione el punto donde', 'termina', 'el último surco'], icono: <CircleIcon color="#0F0" size={25} /> },
  ],
};

export const causasDesactivacion = {
  [tabEnum.TABLA]: '',
  [tabEnum.PRIMER_SURCO]: '¡Debe configurar las coordenadas de la tabla primero y contar con al menos 4 vertices!',
  [tabEnum.ULTIMO_SURCO]: '¡Debe establecer la ubicación del primer surco!',
};

export const mensajeVistaPrevia = {
  [tabEnum.TABLA]: '',
  [tabEnum.PRIMER_SURCO]: 'Vista previa de los cuadrantes',
  [tabEnum.ULTIMO_SURCO]: 'Vista previa de los cuadrantes',
};

const useTablaGeolocalizacion = ({
  activo,
  tablaID,
  coordenadasTabla,
  coordenadasSurcos: {
    latitudInicioPrimerSurco, longitudInicioPrimerSurco,
    latitudFinPrimerSurco, longitudFinPrimerSurco,
    latitudInicioUltimoSurco, longitudInicioUltimoSurco,
    latitudFinUltimoSurco, longitudFinUltimoSurco,
  },
  contenedor,
  poligonosVecinos,
  cantidadSurcos, cantidadCuadrantes,
}) => {
  const [coordenadas, setCoordenadas] = useState({ tabla: [], primerSurco: [], ultimoSurco: [] });
  const [mostrarVistaPrevia, setMostrarVistaPrevia] = useState(false);

  const [error, setError] = useState({
    [tabEnum.TABLA]: null,
    [tabEnum.PRIMER_SURCO]: null,
    [tabEnum.ULTIMO_SURCO]: null,
  });

  const validarPoligono = useCallback((coordenadas, contenedor) => {
    let valido = true;
    for (const {lat, lng} of coordenadas) {
      const puntoDentro = pointInPolygon([lat, lng], contenedor);

      if (!puntoDentro) {
        valido = false;
        break;
      }
    }

    return valido;
  }, []);

  const validarCoordenadaEnPoligono = useCallback((coordenada, contenedor) => {
    if ( Array.isArray(coordenada) ) {
      const valido = coordenada.some((coord) => {
        const { lat, lng } = coord;
        const puntoDentro = pointInPolygon([lat, lng], contenedor);
        return puntoDentro;
      });
      return valido;
    } else {
      const { lat, lng } = coordenada;
      const puntoDentro = pointInPolygon([lat, lng], contenedor);
      return puntoDentro;
    }
  }, []);

  const handleChangePoligono = useCallback((nuevaCoordenadas) => {
    let errorMsj = null;

    // Valida si el poligono se encuentra dentro del contenedor
    const poligonoContenedor = contenedor.map(({ latitud, longitud }) => [latitud, longitud]);
    const seEncuentraDentroDeContenedor = validarPoligono(nuevaCoordenadas, poligonoContenedor);
    if ( !seEncuentraDentroDeContenedor ) {
      errorMsj = ERROR_COORDENADA_FUERA_CONTENEDOR;
    }

    // Valida si el poligono no se encuentre dentro del área de las otras tablas
    const estaEnConflicto = poligonosVecinos.some((coordenadasTablaVecina, index) => {
      const poligonoVecino = coordenadasTablaVecina.map(({ latitud, longitud }) => [latitud, longitud]);
      return validarCoordenadaEnPoligono(nuevaCoordenadas, poligonoVecino);
    });
    
    if ( estaEnConflicto ) {
      errorMsj = ERROR_COORDENADA_EN_CONFLICTO_CON_TABLA;
    }

    setCoordenadas(({tabla, ...rest}) => ({ ...rest, tabla: nuevaCoordenadas }));
    setError((error) => ({ ...error, [tabEnum.TABLA]: errorMsj }));
  }, [contenedor, validarPoligono, poligonosVecinos, validarCoordenadaEnPoligono]);

  const handleChangeSurcos = useCallback(({ lat, lng }) => {
    const contenedor = coordenadas.tabla.map(({ lat, lng }) => [lat, lng]);
    if ( coordenadas.primerSurco.length > 0 ) {
      const esValido = validarPoligono([coordenadas.primerSurco[0], { lat, lng }], contenedor);
      setCoordenadas(({primerSurco: surcos, ...coordenadas}) => ({
        ...coordenadas,
        primerSurco: [surcos[0], { lat, lng, icon: 'assets/images/circlePink.svg' }],
      }));
      setError((error) => ({ ...error, [tabEnum.PRIMER_SURCO]: esValido ? null : ERROR_COORDENADA_FUERA_CONTENEDOR }));
    } else {
      const esValido = validarPoligono([{ lat, lng }], contenedor);
      setCoordenadas(({primerSurco: surcos, ...coordenadas}) => ({
        ...coordenadas,
        primerSurco: [{ lat, lng, icon: 'assets/images/circleBlue.svg' }],
      }));
      setError((error) => ({ ...error, [tabEnum.PRIMER_SURCO]: esValido ? null : ERROR_COORDENADA_FUERA_CONTENEDOR }));
    }
  }, [coordenadas.tabla, coordenadas.primerSurco, validarPoligono]);

  const handleChangeCuadrantes = useCallback(({ lat, lng }) => {
    const contenedor = coordenadas.tabla.map(({ lat, lng }) => [lat, lng]);

    if ( coordenadas.ultimoSurco.length > 0 ) {
      const esValido = validarPoligono([coordenadas.ultimoSurco[0], { lat, lng }], contenedor);
      setCoordenadas(({ultimoSurco: cuadrantes, ...coordenadas}) => ({
        ...coordenadas,
        ultimoSurco: [cuadrantes[0], { lat, lng, icon: 'assets/images/circleGreen.svg' }],
      }));
      setError((error) => ({ ...error, [tabEnum.ULTIMO_SURCO]: esValido ? null : ERROR_COORDENADA_FUERA_CONTENEDOR }));
    } else {
      const esValido = validarPoligono([{ lat, lng }], contenedor);
      setCoordenadas(({ultimoSurco: cuadrantes, ...coordenadas}) => ({
        ...coordenadas,
        ultimoSurco: [{ lat, lng, icon: 'assets/images/circleRed.svg' }],
      }));
      setError((error) => ({ ...error, [tabEnum.ULTIMO_SURCO]: esValido ? null : ERROR_COORDENADA_FUERA_CONTENEDOR }));
    }
  }, [coordenadas.tabla, coordenadas.ultimoSurco, validarPoligono]);

  const eliminarTabla = useCallback(() => {
    setCoordenadas({
      tabla: [],
      primerSurco: [],
      ultimoSurco: [],
    });
    setError({
      [tabEnum.TABLA]: null,
      [tabEnum.PRIMER_SURCO]: null,
      [tabEnum.ULTIMO_SURCO]: null,
    });
  }, []);

  const eliminarSurcos = useCallback(() => {
    setCoordenadas((coordenadas) => ({
      ...coordenadas,
      primerSurco: [],
      ultimoSurco: [],
    }));
    setError((error) => ({
      ...error,
      [tabEnum.PRIMER_SURCO]: null,
      [tabEnum.ULTIMO_SURCO]: null,
    }));
  }, []);

  const eliminarCuadrantes = useCallback(() => {
    setCoordenadas((coordenadas) => ({
      ...coordenadas,
      ultimoSurco: [],
    }));
    setError((error) => ({
      ...error,
      [tabEnum.ULTIMO_SURCO]: null,
    }));
  }, []);

  const generarVistaPrevia = useCallback((soloPrimero = false) => {
    try {
      let cuadrantesVirtuales = [];
      const [inicioPrimerSurco, finPrimerSurco] = coordenadas.primerSurco;
      const [inicioUltimoSurco, finUltimoSurco] = coordenadas.ultimoSurco;

      if ( soloPrimero ) {
        if (coordenadas.primerSurco.length < 2) return [];

        const primerSurco = generarRecta(inicioPrimerSurco, finPrimerSurco, cantidadCuadrantes);
        return primerSurco.map(([ lat, lng ], index) => ({
          id: index, lat, lng,
          icon: 'assets/images/cuadrante.svg'
        }));
      } else {
        if (coordenadas.ultimoSurco.length < 2) return [];

        const columnaInicialSurcos = generarRecta(inicioPrimerSurco, inicioUltimoSurco, cantidadSurcos);
        const columnaFinalSurcos = generarRecta(finPrimerSurco, finUltimoSurco, cantidadSurcos);

        for (let i = 0; i < columnaInicialSurcos.length; i++) {
          const [x1, y1] = columnaInicialSurcos[i];
          const [x2, y2] = columnaFinalSurcos[i];
  
          cuadrantesVirtuales.push(generarRecta({lat: x1, lng: y1}, {lat: x2, lng: y2}, cantidadCuadrantes));
        }

        cuadrantesVirtuales = cuadrantesVirtuales
          .flat()
          .map(([ lat, lng ], index) => ({
            id: index, lat, lng,
            icon: 'assets/images/cuadrante.svg'
          }));

        return cuadrantesVirtuales;
      }
    } catch (error) { /** Empty block */ }
  }, [cantidadCuadrantes, cantidadSurcos, coordenadas.ultimoSurco, coordenadas.primerSurco]);

  const toggleVistaPrevia = useCallback((e) => {
    setMostrarVistaPrevia(e.target.checked);
  }, []);

  useEffect(() => {
    if ( activo ) {
      setCoordenadas({
        tabla: coordenadasTabla.map(({latitud, longitud}) => ({ lat: latitud, lng: longitud })) || [],
        primerSurco: latitudInicioPrimerSurco
          ? [
            { lat: latitudInicioPrimerSurco, lng: longitudInicioPrimerSurco, icon: 'assets/images/circleBlue.svg' },
            { lat: latitudInicioUltimoSurco, lng: longitudInicioUltimoSurco, icon: 'assets/images/circlePink.svg' }
          ] : [],
        ultimoSurco: latitudFinPrimerSurco
          ? [
            { lat: latitudFinPrimerSurco, lng: longitudFinPrimerSurco, icon: 'assets/images/circleRed.svg' },
            { lat: latitudFinUltimoSurco, lng: longitudFinUltimoSurco, icon: 'assets/images/circleGreen.svg' }
          ] : [],
      });
    } else {
      setMostrarVistaPrevia(false);
    }
  }, [activo, cantidadCuadrantes, cantidadSurcos, contenedor, coordenadasTabla, latitudFinPrimerSurco, latitudFinUltimoSurco, latitudInicioPrimerSurco, latitudInicioUltimoSurco, longitudFinPrimerSurco, longitudFinUltimoSurco, longitudInicioPrimerSurco, longitudInicioUltimoSurco, tablaID]);

  useDebugValue('Tabla de Geolocalización');

  return {
    ...coordenadas,
    mostrarVistaPrevia,
    error,

    handleChangePoligono,
    handleChangeSurcos,
    handleChangeCuadrantes,
    eliminarTabla,
    eliminarSurcos,
    eliminarCuadrantes,
    generarVistaPrevia,
    toggleVistaPrevia,
  }
};

export default useTablaGeolocalizacion;
