import React from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { MdGrid3X3 } from 'react-icons/md';
import { TbMap2 } from 'react-icons/tb';

import Button from '../Button';
import { GEOLOCALIZADOS, VIRTUALIZADOS } from '../../constantes/tipoMarcadorPYE';

import styles from './styles';

const AccionesPYE = ({ onToggleMarcadores }) => {
  const classes = styles();
  const tipoMarcador = useSelector(state => state.nave.tipoMarcadorPYE);
  const { esTrampa, id } = useSelector(state => state.tablero.enfermedad);

  if (esTrampa || !id) return null;

  return (
    <Box className={classes.PyE}>
      <Button
        name='cuadrantesGeolocalizados'
        icono={<TbMap2 color={ tipoMarcador === GEOLOCALIZADOS ? '#FFF' : '#666666'} size={22} />}
        classesCustom={{ boton: classes.btnPyE, active: tipoMarcador === GEOLOCALIZADOS ? classes.active : '' }}
        onClick={onToggleMarcadores}
        value={GEOLOCALIZADOS}
        tooltipMsj="Mostrar ubicación tomada por SmartPhone"
      />
      <Button
        name='cuadrantesVirtualizados'
        icono={<MdGrid3X3 color={ tipoMarcador === VIRTUALIZADOS ? '#FFF' : '#666666'} size={22} />}
        classesCustom={{ boton: classes.btnPyE, active: tipoMarcador === VIRTUALIZADOS ? classes.active : '' }}
        onClick={onToggleMarcadores}
        value={VIRTUALIZADOS}
        tooltipMsj="Mostrar ubicación virtualizada"
      />
    </Box>
  );
};

AccionesPYE.propTypes = {
  /** Función que se ejecuta al hacer click en el botón de marcadores */
  onToggleMarcadores: propTypes.func.isRequired,
};

export default React.memo(AccionesPYE);