/* eslint-disable import/no-anonymous-default-export */
export const MENU_OPCIONES = [
  {
    label: "Tablero",
    path: "/tablero",
    id: 23,
    index: 0,
    subpaths: [{ label: "Nave", link: "/tablero/nave", id: 24 }],
  },
  {
    label: 'Configuración',
    submenu: [
      { label: 'Irrigaciones', sublabel: 'Configuraciones', link: '/configuraciones/irrigaciones', id: 27 },
      { label: 'Fenologías', sublabel: 'Configuraciones', link: '/configuraciones/fenologias', id: 28 },
      { label: 'P&E de Plantas', sublabel: 'Configuraciones', link: '/configuraciones/enfermedades', id: 29 },
      { label: 'Polinizaciones', sublabel: 'Configuraciones', link: '/configuraciones/polinizaciones', id: 30 },
    ],
    path: '/dashboard',
    index: 1
  },
  {
    label: "Catálogos",
    submenu: [
      { label: 'Usuarios', sublabel: 'Catálogo', link: '/catalogos/usuarios', id: 1 },
      { label: 'Perfiles', sublabel: 'Catálogo', link: '/catalogos/perfiles', id: 2 },
      { label: 'Sitios', sublabel: 'Catálogo', link: '/catalogos/sitios', id: 3 },
      { label: 'Invernaderos', sublabel: 'Catálogo', link: '/catalogos/invernaderos', id: 5 },
      { label: 'Naves', sublabel: 'Catálogo', link: '/catalogos/naves', id: 4 },
      { label: 'Tablas', sublabel: 'Catálogo', link: '/catalogos/tablas', id: 6 },
      { label: 'Enfermedades/Plagas', sublabel: 'Catálogo', link: '/catalogos/enfermedades', id: 7 },
      { label: 'Fenologías', sublabel: 'Catálogo', link: '/catalogos/fenologias', id: 8 },
      { label: 'Irrigaciones', sublabel: 'Catálogo', link: '/catalogos/irrigaciones', id: 9 },
      { label: 'Saneos', sublabel: 'Catálogo', link: '/catalogos/saneos', id: 10 },
      { label: 'Niveles de polinización', sublabel: 'Catálogo', link: '/catalogos/nivelespolinizacion', id: 13 },
      { label: 'Niveles de infección', sublabel: 'Catálogo', link: '/catalogos/nivelesinfeccion', id: 11 },
      { label: 'Actividades', sublabel: 'Catálogo', link: '/catalogos/actividades', id: 12 },
      { label: 'Cultivos', sublabel: 'Catálogo', link: '/catalogos/cultivos', id: 25 },
      { label: 'Unidades de medida', sublabel: 'Catálogo', link: '/catalogos/unidadesmedida', id: 14 },
    ],
    path: "/dashboard",
    index: 2,
  },
  {
    label: "Reportes",
    submenu: [
      { label: 'Fenologías', sublabel: 'Reportes', link: '/reportes/fenologiaspromedio', id: 31 },
      { label: 'Irrigaciones', sublabel: 'Reportes', link: '/reportes/irrigaciones', id: 32 },
      { label: 'P&E de Plantas', sublabel: 'Reportes', link: '/reportes/enfermedades', id: 33 },
      { label: 'Saneos', sublabel: 'Reportes', link: '/reportes/saneos', id: 34 },
      { label: 'Polinizaciones', sublabel: 'Reportes', link: '/reportes/polinizacion/detallado', id: 35 },
      { label: 'Nematodos', sublabel: 'Reportes', link: '/reportes/nematodos', id: 36 },
    ],
    path: "/dashboard",
    index: 3
  },
  {
    label: "Temporadas",
    submenu: [
      { label: 'Listado de temporadas', sublabel: 'Temporadas', link: '/temporadas/listado', id: 38 },
      { label: 'Histórico de temporadas', sublabel: 'Temporadas', link: '/temporadas/historico', id: 39 },
    ],
    path: "/dashboard",
    index: 4,
  }
];

const SET_INDEX_ACTIVO = 'SET_INDEX_ACTIVO';
const SET_SIDEBAR_ISOPEN = 'SET_SIDEBAR_ISOPEN';
const SET_PERMISOS = 'SET_PERMISOS';
const QUITAR_PERMISOS = 'QUITAR_PERMISOS';
const OCULTAR_OPCIONES = 'OCULTAR_OPCIONES';

const tabInitIndex = Number(sessionStorage.getItem('tabIndex')) || 0;

const initialState = {
  indexActivo: tabInitIndex,
  sidebarIsOpen: false,
  permisos: [],
  permisosConsultados: false,
  ocultarOpciones: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_INDEX_ACTIVO:
      return { ...state, indexActivo: payload };
    case SET_SIDEBAR_ISOPEN:
      return { ...state, sidebarIsOpen: !state.sidebarIsOpen };
    case SET_PERMISOS:
      return { ...state, permisos: payload, permisosConsultados: true };
    case QUITAR_PERMISOS:
      return { ...state, permisos: [], permisosConsultados: false };
    case OCULTAR_OPCIONES:
      return { ...state, ocultarOpciones: payload };
    default:
      return state;
  }
};

export const setOpcionActiva = (payload) => ({
  type: SET_INDEX_ACTIVO,
  payload,
});

export const setSidebarIsOpen = () => ({ type: SET_SIDEBAR_ISOPEN });

export const setPermisos = (payload) => ({ type: SET_PERMISOS, payload });

export const quitarPermisos = () => ({ type: QUITAR_PERMISOS });

export const ocultarOpciones = (payload) => ({ type: OCULTAR_OPCIONES, payload });
