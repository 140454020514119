import React from 'react';
import propTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@material-ui/core';
import { IoIosArrowDown as ExpandMoreIcon } from "react-icons/io";

import Typography from '../Typography';

import styles from './styles';

export const CustomAccordion = ({
  children,
  id, title, expanded, defaultExpanded,
  classes: customClasses, summaryClasses, detailsClasses,
  onClick,
}) => {
  const classes = styles();

  return (
    <Accordion
      className={classes.accordion}
      classes={customClasses}
      defaultExpanded={defaultExpanded}
      {...(expanded !== undefined ? { expanded } : {}) }
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={id}
        classes={summaryClasses}
        {...(onClick ? { onClick } : {}) }
      >
        {
          typeof title === 'string'
            ? <Typography className={classes.heading}>{ title }</Typography>
            : title
        }
      </AccordionSummary>
      <AccordionDetails classes={detailsClasses}>
        <Box className={classes.contentContainer} width="100%">
          {children}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
};

CustomAccordion.propTypes = {
  children: propTypes.node,
  id: propTypes.oneOfType([propTypes.string, propTypes.number]),
  title: propTypes.oneOfType([propTypes.string, propTypes.node]),
  expanded: propTypes.bool,
  defaultExpanded: propTypes.bool,
  classes: propTypes.shape({
    root: propTypes.string,
  }),
  summaryClasses: propTypes.shape({
    root: propTypes.string,
    content: propTypes.string,
    expandIcon: propTypes.string,
    expanded: propTypes.string,
  }),
  detailsClasses: propTypes.shape({
    root: propTypes.string,
  }),
  onClick: propTypes.func,
};

CustomAccordion.defaultProps = {
  children: null,
  id: null,
  title: '',
  expanded: undefined,
  defaultExpanded: false,
  classes: {},
  summaryClasses: {},
  detailsClasses: {},
  onClick: undefined,
};

export default CustomAccordion;
