/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import Default from '../../contenedores/Default';
import TextField from '../../componentes/TextField';
import Select from '../../componentes/Select';
import Tabla from '../../componentes/Table';
import axios from '../../configuraciones/axios';
import { findPropertysEmpty, trim } from '../../utilidades/functions';
import endpoints, {
  PERFILES, TIPO_PERFILES, PERMISOS
} from '../../configuraciones/endpoints';
import estatus from '../../constantes/estatus';
import { PERFILE_SIN_PERMISOS, ELEMENTO_NO_ENCONTRADO } from '../../configuraciones/mensajes';
import { WEB } from '../../constantes/tipoPerfiles';

const Perfil = ({ history, match }) => {
  const [formErrors, setFormErrors] = useState({});
  const [perfil, setPerfil] = useState({});
  const [tipoPerfiles, setTipoPerfiles] = useState([]);
  const [modulos, setModulos] = useState([]);
  const { params: { id } } = match;
  const cabeceros = [
    { label: 'Sección', key: 'descripcion' },
    { label: 'Módulo', key: 'nombre' },
  ];

  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/perfiles',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  const cancelar = useCallback(regresar, []);

  const guardar = useCallback(() => {
    const {
      errors, regex, totalErrors, totalRegex
    } = findPropertysEmpty(document.getElementById('frmPerfil'), true);
    setFormErrors({ ...errors, ...regex });
    if (totalErrors > 0 || totalRegex > 0) return;

    const metodo = id ? 'put' : 'post';

    const objTrim = trim(perfil);
    const nuevoObj = {
      ...objTrim,
      permisos: modulos.filter(({ isSelected }) => isSelected).map(({ moduloID }) => {
        const mod = { moduloID }
        if (id) mod.perfilID = id;
        return mod;
      })
    };
    
    if (nuevoObj.tipoPerfilID === WEB && nuevoObj.permisos.length === 0) {
      return toast.warning(PERFILE_SIN_PERMISOS);
    }

    axios[metodo](PERFILES, nuevoObj).then(regresar);
  }, [perfil, modulos]);

  const seleccionarTodos = (flag) => {
    const nuevosModulos = [...modulos];
    setModulos(nuevosModulos.map(modulo => ({ ...modulo, isSelected: !flag })));
  };

  const seleccionarModulo = (row) => {
    const index = modulos.findIndex(({ moduloID }) => moduloID === row.moduloID);
    if (index !== -1) {
      const nuevosModulos = [...modulos];
      nuevosModulos[index].isSelected = !nuevosModulos[index].isSelected;
      setModulos(nuevosModulos);
    }
  };

  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(TIPO_PERFILES),
      axios.get(PERMISOS),
    ];
    if (id) promesas.push(axios.get(endpoints.base.url(PERFILES, id)));

    Promise.all(promesas).then((resultadosPromesas) => {
      const [tipoPerfilesDb, modulosDb, perfilDb] = resultadosPromesas;
      setTipoPerfiles(tipoPerfilesDb);
      const modulosMapeados = [];
      for (const moduloPadre of modulosDb) {
        for (const modulo of moduloPadre.modulos) {
          const mod = {
            moduloID: modulo.id,
            nombre: modulo.descripcion,
            moduloWeb: moduloPadre.moduloWeb,
            descripcion: moduloPadre.descripcion,
            isSelected: false
          };
          if (id) {
            mod.isSelected = Boolean(perfilDb?.permisos?.find(permiso => permiso.moduloID === mod.moduloID));
          }
          modulosMapeados.push(mod);
        }
      }

      setModulos(modulosMapeados);
      if (perfilDb?.id)
        setPerfil(perfilDb);
      else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      } else {
        setPerfil({estatus: true});
      }
      resolve();
    }).catch(reject);
  });

  useEffect(() => {
    consultarDatosIniciales();
  }, [id]);

  return <Default
    titulo={Boolean(id) ? 'Editar Perfil' : 'Nuevo Perfil'}
    placeHolder={''}
    mostrarCabeceroFormulario
    guardar={guardar}
    cancelar={cancelar}>
    <Grid container spacing={2} id="frmPerfil">
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Nombre del perfil"
              onChange={setPerfil}
              name="nombre"
              value={perfil.nombre}
              isHandleChange
              inputProps={{ maxLength: 50 }}
              error={formErrors.nombre}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Tipo Perfil"
              required
              options={tipoPerfiles}
              labelProp="nombre"
              onChange={({ target: { value } }) => {
                setPerfil((current) => ({ ...current, tipoPerfilID: value }));
                seleccionarTodos(true);
              }}
              name="tipoPerfilID"
              value={perfil.tipoPerfilID || ''}
              error={formErrors.tipoPerfilID}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Estatus"
              required
              options={estatus}
              onChange={setPerfil}
              name="estatus"
              value={perfil.estatus}
              error={formErrors.estatus}
              isHandleChange
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Descripción"
              type="textarea"
              rows={10}
              onChange={setPerfil}
              name="descripcion"
              value={perfil.descripcion}
              isHandleChange
              inputProps={{ maxLength: 100 }}
              error={formErrors.descripcion}
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Tabla
          hidePaginator
          showActions={false}
          showCheckbox
          headers={cabeceros}
          rows={modulos.filter(({ moduloWeb }) => perfil.tipoPerfilID && moduloWeb === (perfil.tipoPerfilID === WEB))}
          onSelectAll={seleccionarTodos}
          onSelect={seleccionarModulo}
        />
      </Grid>
    </Grid>
  </Default>;
};

export default React.memo(Perfil);