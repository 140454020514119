import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: `${paleta.bar.primary}`,
  },
  agrupador: {
    marginInline: 5,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 10,
    opacity: 0,
    animation: '$fadeInUp 600ms ease-out 500ms forwards',
  },
  labelTitle: {
    color: paleta.textField.text,
    fontSize: '16px',
    fontWeight: 'bold',
  },
  labelSubtitle: {
    fontSize: '13px',
  },
  refresh: {
    paddingInline: 10,
    alignItems: 'flex-start',
    gap: 20,
    justifyContent: 'flex-end',
    position: 'relative',
  },
  refreshButton: {
    padding: 0,
    marginTop: -2,
  },
  '@keyframes fadeInUp': {
    '0%': {
      opacity: 0.1, // Ligeramente visible al inicio
      transform: 'translateY(20px) scale(0.98)', // Comenzar con una escala ligeramente menor
    },
    '50%': {
      opacity: 0.5, // Aumentar la opacidad en el punto medio
      transform: 'translateY(10px) scale(0.99)', // Movimiento y escala intermedios
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0) scale(1)', // Volver a la escala normal
    },
  },
}));

export default styles;
