import React from 'react';
import { makeStyles } from '@material-ui/core';

import Typography from '../../componentes/Typography';

import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingInline: theme.spacing(2),
      paddingBlock: theme.spacing(2),
    },
  },
  titulo: {
    margin: 0,
    fontSize: 16,
    fontWeight: '900',
    color: paleta.textField.textBlack,
    letterSpacing: 0.5,
  },
  small: {
    color: paleta.bar.elements,
    marginTop: 3,
    fontSize: 11,
    marginLeft: 10
  },
  button: {
    fontSize: 13,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  input: {
    minWidth: 150,
    maxWidth: 150,
  },
  select: {
    marginTop: -25,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      minWidth: 'calc(50% - 10px)',
      maxWidth: 'calc(50% - 10px)',
    },
  },
  search: {
    minWidth: 250,
    maxWidth: 250,
    marginTop: -5,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
  menuItem: {
    marginTop: 3,
  },
  textoDetalle: {
    color: paleta.textField.textBlack,
    fontSize: 13,
  },
  grid: {
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    display: 'grid',
    gridGap: '2.2vw',
    gridTemplateColumns: "repeat(auto-fit, minmax(370px, 31%))",
    justifyContent: 'center',
  }
}));

export const CampoDescripcion = React.memo(({ label, valor }) => {
  const classes = styles();

  return (
    <Typography variant="body1" component="p" className={classes.textoDetalle}>
      <Typography component="b">
        {label}:
      </Typography>
      {' '}
      {valor}
    </Typography>
  );
});

export const BotonIcono = React.memo(({ label, Icono }) => {
  const classes = styles();

  return (
    <>
      <Icono size={18} color='#000' />
      <Typography variant="body1" component="span" className={classes.menuItem}>
        <Typography component="b">
          {label}
        </Typography>
      </Typography>
    </>
  );
});

export default styles;
