import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import queryString from 'query-string';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { toast } from "react-toastify";

import AgrupadorListados from '../../contenedores/AgrupadorListados';
import ListaConfiguracion from '../../componentes/ListaConfiguracion';
import RangoTextFields from '../../componentes/RangoTextFields';

import { setRegistros } from '../../ducks/listados';

import axios from '../../configuraciones/axios'
import endpoints, { 
  CONFIGURACIONES_PLANTA,
  CONFIGURACIONES_TRAMPA, 
  SITIOS
} from '../../configuraciones/endpoints';
import paleta from '../../configuraciones/paleta';

import { parseFloatProperties } from '../../utilidades/functions';
import { RANGO_CONFIG_REGEX } from '../../utilidades/regex';

import styles from './style';

const DatosIniciales = {
  labelProp: "",
  headerLista: "",
  title: "",
  subTitle: "",
  data: [],
};

const validarRangos = ({
  rangoEstadoRojoMin,
  rangoEstadoRojoMax,
  rangoEstadoAmarilloMin,
  rangoEstadoAmarilloMax,
  rangoEstadoVerdeMin,
  rangoEstadoVerdeMax,
  enfermedadID,
}) => {
  if ( !rangoEstadoRojoMin && !rangoEstadoRojoMax && !rangoEstadoAmarilloMin && !rangoEstadoAmarilloMax && !rangoEstadoVerdeMin && !rangoEstadoVerdeMax ) return enfermedadID;
  if ( rangoEstadoRojoMin >= rangoEstadoRojoMax ) return enfermedadID;
  if ( rangoEstadoAmarilloMin >= rangoEstadoAmarilloMax ) return enfermedadID;
  if ( rangoEstadoVerdeMin >= rangoEstadoVerdeMax ) return enfermedadID;

  else if ( rangoEstadoRojoMin >= rangoEstadoAmarilloMin && rangoEstadoRojoMin <= rangoEstadoAmarilloMax ) return enfermedadID;
  else if ( rangoEstadoRojoMax >= rangoEstadoAmarilloMin && rangoEstadoRojoMax <= rangoEstadoAmarilloMax ) return enfermedadID;
  else if ( rangoEstadoRojoMin >= rangoEstadoVerdeMin && rangoEstadoRojoMin <= rangoEstadoVerdeMax ) return enfermedadID;
  else if ( rangoEstadoRojoMax >= rangoEstadoVerdeMin && rangoEstadoRojoMax <= rangoEstadoVerdeMax ) return enfermedadID;

  else if ( rangoEstadoAmarilloMin >= rangoEstadoVerdeMin && rangoEstadoAmarilloMin <= rangoEstadoVerdeMax ) return enfermedadID;
  else if ( rangoEstadoAmarilloMax >= rangoEstadoVerdeMin && rangoEstadoAmarilloMax <= rangoEstadoVerdeMax ) return enfermedadID;
  else if ( rangoEstadoAmarilloMin >= rangoEstadoRojoMin && rangoEstadoAmarilloMin <= rangoEstadoRojoMax ) return enfermedadID;
  else if ( rangoEstadoAmarilloMax >= rangoEstadoRojoMin && rangoEstadoAmarilloMax <= rangoEstadoRojoMax ) return enfermedadID;

  else if ( rangoEstadoVerdeMin >= rangoEstadoAmarilloMin && rangoEstadoVerdeMin <= rangoEstadoAmarilloMax ) return enfermedadID;
  else if ( rangoEstadoVerdeMax >= rangoEstadoAmarilloMin && rangoEstadoVerdeMax <= rangoEstadoAmarilloMax ) return enfermedadID;
  else if ( rangoEstadoVerdeMin >= rangoEstadoRojoMin && rangoEstadoVerdeMin <= rangoEstadoRojoMax ) return enfermedadID;
  else if ( rangoEstadoVerdeMax >= rangoEstadoRojoMin && rangoEstadoVerdeMax <= rangoEstadoRojoMax ) return enfermedadID;
  
  return;
}

const { configuraciones: { rojo, amarillo, verde } } = paleta;

const ConfiguracionEnfermedades = ({ history, location }) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { sitioID } =  queryString.parse(location.search);
  const { registros } = useSelector(store => store.listados);

  const tipoConfiguracion = useRef(
    registros?.rows[0]?.configuracionesPlanta 
    ? 'configuracionesPlanta' : 'configuracionesTrampa'
  );
  const [datosLista, setDatosLista] = useState({ ...DatosIniciales });
  const [formErrors, setFormErrors] = useState([]);

  useEffect(() => {
    consultarDatosIniciales();

    return () => {
      dispatch(setRegistros({rows: [], count: 0}));
      setFormErrors([]);
    }
  }, [dispatch]);

  useEffect(() => {
    setFormErrors([]);
  }, [registros])

  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.base.busqueda(SITIOS))
    ];
    Promise.all(promesas)
      .then((resultadosPromesas) => {
        const [sitiosDb] = resultadosPromesas;
        setDatosLista({
          labelProp: "nombre",
          headerLista: "Nombre",
          title: "Sitios",
          subTitle: "Configuración de umbrales",
          data: sitiosDb,
        });
        resolve();
      })
      .catch(reject);
  });

  const handleChange = (e, index) => {
    const propiedad = e.target.name;
    const key = tipoConfiguracion.current;
    const nuevoEstado = registros;
    nuevoEstado.rows[index][key][0] = {
      ...nuevoEstado.rows[index][key][0],
      [propiedad]: e.target.value,
    };
    dispatch(setRegistros(nuevoEstado));
  }

  const toggleConfig = (data, index) => {
    const key = tipoConfiguracion.current;
    const nuevoEstado = registros;
    const valorActual = (nuevoEstado.rows[index][key].length > 0)
      ? nuevoEstado.rows[index][key][0]
      : null;
    
    nuevoEstado.rows[index][key][0] = (valorActual) 
      ? { ...valorActual, estatus: !valorActual.estatus }
      : { ...valorActual, estatus: true };
        
    if ( valorActual?.estatus ){
      setFormErrors(formErrors.filter(fila => fila !== index));
    }

    dispatch(setRegistros(nuevoEstado));
  }

  const customActions = [{
    onClick: toggleConfig,
    transform: (data, index) => {
      tipoConfiguracion.current = data.configuracionesPlanta ? 'configuracionesPlanta' : 'configuracionesTrampa'
      if ( !data[tipoConfiguracion.current] ) return null;
      return (
        <IconButton
          style={{padding: 0}}
          key="toggleConfig"
          size="medium"
          className={classes.btnAction}
          onClick={() => toggleConfig(data, index)}
        >
          {
            data[tipoConfiguracion.current].length <= 0 || !data[tipoConfiguracion.current][0].estatus
            ?
            <AiOutlineEyeInvisible />
            :
            <AiOutlineEye />
          }
        </IconButton>
      )
    }
  }];

  const guardar = async () => {
    try {
      const rowErrors = [];
      const key = tipoConfiguracion.current;
      const endpoint = key === 'configuracionesPlanta' ? CONFIGURACIONES_PLANTA : CONFIGURACIONES_TRAMPA;
      
      const filtro = registros.rows.filter(configuracion => {
        if ( configuracion[key].length > 0 ){
          if ( !configuracion[key][0]?.activo ){
            if ( configuracion[key][0]?.estatus ) {
              return true;
            }
            return false;
          }
          return true;
        }
        return false;
      });

      if ( filtro.length <= 0 ) return;

      const configuraciones = filtro.map(configuracion => {
        if ( !configuracion[key][0].estatus ){
          return {
            enfermedadID: configuracion.id,
            sitioID: sitioID,
            estatus: false,
          }
        }
        return {
          ...parseFloatProperties(configuracion[key][0]),
          enfermedadID: configuracion.id,
          sitioID: sitioID,
        }
      });

      configuraciones.forEach(configuracion => {
        const id = configuracion.estatus && validarRangos(configuracion);
        id && rowErrors.push(registros.rows.findIndex(r => r.id === id));
      });

      if ( rowErrors.length > 0 ) {
        setFormErrors(rowErrors);
        return toast.error(`Rangos de estado inválidos.`);
      }

      const data = {
        [key]: configuraciones,
        sitioID: sitioID,
      }

      await axios.put(endpoint, data);
      setFormErrors([]);
    } catch {}
  }

  const tabsData = {
    '1': {
      name: 'En planta',
      apiKey: CONFIGURACIONES_PLANTA,
      cabeceros: [
        { label: 'Plaga/Enfermedad', key: 'nombre' },
        { 
          label: 'Rango estado rojo',
          align: 'center',
          transform: (data, index) => {
            if ( !data.configuracionesPlanta ) return null;
            return (
              <RangoTextFields 
                backgroundColor={rojo}
                names={['rangoEstadoRojoMin', 'rangoEstadoRojoMax']}
                formValues={{
                  min: String(data?.configuracionesPlanta[0]?.rangoEstadoRojoMin ?? ''),
                  max: String(data?.configuracionesPlanta[0]?.rangoEstadoRojoMax ?? '')
                }}
                activo={data?.configuracionesPlanta[0]?.estatus}
                onChange={(e) => handleChange(e, index)}
                inputProps={{ regex: RANGO_CONFIG_REGEX, regexonchange: 'true' }}
              />
            )
          }
        },
        { 
          label: 'Rango estado amarillo',
          align: 'center',
          transform: (data, index) => {
            if ( !data.configuracionesPlanta ) return null;
            return (
              <RangoTextFields 
                backgroundColor={amarillo}
                names={['rangoEstadoAmarilloMin', 'rangoEstadoAmarilloMax']}
                formValues={{
                  min: String(data?.configuracionesPlanta[0]?.rangoEstadoAmarilloMin ?? ''),
                  max: String(data?.configuracionesPlanta[0]?.rangoEstadoAmarilloMax ?? '')
                }}
                activo={data?.configuracionesPlanta[0]?.estatus}
                onChange={(e) => handleChange(e, index)}
                inputProps={{ regex: RANGO_CONFIG_REGEX, regexonchange: 'true' }}
              />
            )
          }
        },
        { 
          label: 'Rango estado verde',
          align: 'center',
          transform: (data, index) => {
            if ( !data.configuracionesPlanta ) return null;
            return (
              <RangoTextFields 
                backgroundColor={verde}
                names={['rangoEstadoVerdeMin', 'rangoEstadoVerdeMax']}
                formValues={{
                  min: String(data?.configuracionesPlanta[0]?.rangoEstadoVerdeMin ?? ''),
                  max: String(data?.configuracionesPlanta[0]?.rangoEstadoVerdeMax ?? '')
                }}
                activo={data?.configuracionesPlanta[0]?.estatus}
                onChange={(e) => handleChange(e, index)}
                inputProps={{ regex: RANGO_CONFIG_REGEX, regexonchange: 'true' }}
              />
            )
          }
        },
      ],
    },
    '2': {
      name: 'En trampa',
      apiKey: CONFIGURACIONES_TRAMPA,
      cabeceros: [
        { label: 'Plaga/Enfermedad', key: 'nombre' },
        { 
          label: 'Rango estado rojo',
          align: 'center',
          transform: (data, index) => {
            if ( !data.configuracionesTrampa ) return null;
            return (
              <RangoTextFields 
                backgroundColor={rojo}
                names={['rangoEstadoRojoMin', 'rangoEstadoRojoMax']}
                formValues={{
                  min: String(data?.configuracionesTrampa[0]?.rangoEstadoRojoMin ?? ''),
                  max: String(data?.configuracionesTrampa[0]?.rangoEstadoRojoMax ?? '')
                }}
                activo={data?.configuracionesTrampa[0]?.estatus}
                onChange={(e) => handleChange(e, index)}
                inputProps={{ regex: RANGO_CONFIG_REGEX, regexonchange: 'true' }}
              />
            )
          }
        },
        { 
          label: 'Rango estado amarillo',
          align: 'center',
          transform: (data, index) => {
            if ( !data.configuracionesTrampa ) return null;
            return (
              <RangoTextFields 
                backgroundColor={amarillo}
                names={['rangoEstadoAmarilloMin', 'rangoEstadoAmarilloMax']}
                formValues={{
                  min: String(data?.configuracionesTrampa[0]?.rangoEstadoAmarilloMin ?? ''),
                  max: String(data?.configuracionesTrampa[0]?.rangoEstadoAmarilloMax ?? '')
                }}
                activo={data?.configuracionesTrampa[0]?.estatus}
                onChange={(e) => handleChange(e, index)}
                inputProps={{ regex: RANGO_CONFIG_REGEX, regexonchange: 'true' }}
              />
            )
          }
        },
        { 
          label: 'Rango estado verde',
          align: 'center',
          transform: (data, index) => {
            if ( !data.configuracionesTrampa ) return null;
            return (
              <RangoTextFields 
                backgroundColor={verde}
                names={['rangoEstadoVerdeMin', 'rangoEstadoVerdeMax']}
                formValues={{
                  min: String(data?.configuracionesTrampa[0]?.rangoEstadoVerdeMin ?? ''),
                  max: String(data?.configuracionesTrampa[0]?.rangoEstadoVerdeMax ?? '')
                }}
                activo={data?.configuracionesTrampa[0]?.estatus}
                onChange={(e) => handleChange(e, index)}
                inputProps={{ regex: RANGO_CONFIG_REGEX, regexonchange: 'true' }}
              />
            )
          }
        },
      ],
    }
  }

  return (
    <AgrupadorListados 
      titulo={"Plagas & Enfermedades por hallazgo"}
      subtitulo={"Rangos de umbrales"}
      placeHolder="Búsqueda por nombre"
      mostrarLista={<ListaConfiguracion datosLista={datosLista} />}
      history={history}
      location={location}
      tabsData={tabsData}
      customActions={customActions}
      guardar={guardar}
      cancelar={() => { }}
      rowErrors={formErrors}
    />
  );
};

export default ConfiguracionEnfermedades;
