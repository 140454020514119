import React from 'react';
import propTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import Mapa from '../Mapa';

import { useCoordenadasNaves } from '../../hooks/useCoordenadasNaves';

import styles from './styles';

const MapaSitio = ({naveID, nombreNave, zoom, handleMarkClick, ComponenteAcciones}) => {
  const classes = styles();
  const { poligonos, naves, center, ubicacionDetecciones } = useCoordenadasNaves(naveID, nombreNave);

  return (
    <Grid className={classes.root}>
      <Mapa
        esPoligono={false}
        poligonos={poligonos}
        ocultarBusqueda
        ocultarEliminar
        onChangeMarcador={() => {}}
        marcadores={{naves, otros: ubicacionDetecciones}}
        defaultCenter={(center) ? center : (poligonos[0]?.path ? poligonos[0].path[0] : null)}
        zoom={zoom}
        customMarcador
        customMarcadorClick={handleMarkClick}
        bloquearClick={false}
        ComponenteAcciones={ComponenteAcciones}
      />
    </Grid>
  )
};

export default React.memo(MapaSitio);

MapaSitio.propTypes = {
  /** Identificador de la Nave */
  naveID: propTypes.oneOfType([propTypes.string, propTypes.number]),
  /** Alias de la nave */
  nombreNave: propTypes.string,
  /** Zoom inicial que mostrara el mapa */
  zoom: propTypes.number,
  /** Función que se ejecuta al dar clic en el marcador */
  handleMarkClick: propTypes.func,
  /** Componente de acciones para el mapa */
  ComponenteAcciones: propTypes.node,
};

MapaSitio.defaultProps = {
  naveID: null,
  nombreNave: null,
  zoom: 15,
  handleMarkClick: () => {},
  ComponenteAcciones: null,
};