/* eslint prefer-promise-reject-errors: 0 */
import axios from 'axios';
import { toast } from 'react-toastify';

import store from '../ducks';
import { startAjax, endAjax } from '../ducks/ajax';
import { STORAGE } from '../ducks/user';

import { ERROR, SUCCESS } from './mensajes';

import general from './general';

const abortController = new AbortController();

const axiosInstance = axios.create({
  baseURL: general.URL_API,
  signal: abortController.signal,
});

function isNetworkError(error) {
  return error.message === 'Network Error';
}

function onRequestOk(config) {
  const { dispatch } = store;
  const user = JSON.parse(localStorage.getItem(STORAGE) || sessionStorage.getItem(STORAGE) || '{}');

  if (user.token) {
    config.headers.Authorization = user.token;
  }

  dispatch(startAjax());

  return config;
}

function onRequestError(error) {
  const { dispatch } = store;
  dispatch(endAjax());
  return Promise.reject(error);
}

function onResponseOk(response) {
  const { dispatch } = store;
  dispatch(endAjax());

  if (response.status === 201 || response.status === 204) {
    toast.success(SUCCESS);
  }

  return response.data;
}

function onResponseError(error) {
  let isError = false;
  let isMessage = false;
  const { dispatch } = store;
  dispatch(endAjax());

  if (error.response) {
    const { status, data } = error.response;
    const isString = typeof data === 'string';
    const isNotEmpty = data.trim() !== '';
    const doesNotIncludeHtml = !data.includes('html');

    if (isString && isNotEmpty && doesNotIncludeHtml) isMessage = true;
    if (status !== 404 && status !== 403) isError = true;

    toast.error(isMessage
      ? error.response.data
      : ERROR, { toastId: `AXIOS_ERROR_${error.response.status || 500}` }
    );
  } else {
    isError = true;
    isMessage = false;

    if (isNetworkError(error)) {
      toast.error('No fue posible establecer conexión con el servidor, intente más tarde.');
    }
  }

  return Promise.reject({
    ...error.response,
    isCancel: error.message === 'canceled',
    isError,
    isMessage,
    message: error.message,
  });
}

axiosInstance.interceptors.request.use(onRequestOk, onRequestError);
axiosInstance.interceptors.response.use(onResponseOk, onResponseError);

export default axiosInstance;
