import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${paleta.textField.border}`,
    borderRadius: 5,
    height: 'auto',
    maxHeight: '75vh',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.only('xs')]: {
      overflow: 'auto',
      height: '55vh',
    },
    position: 'relative',
  },
  noPaginator: {
    [theme.breakpoints.only('xs')]: {
      height: '70vh',
    }
  },
  root2: {
    border: '1px solid #E6EBF5',
    height: '50vh'
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    maxHeight: '75vh',
    position: 'relative',

    '& th, td': {
      height: 40,
      borderBottom: `1px solid ${paleta.tabla.bordertd}`,
      padding: theme.spacing(0.5, 2),
      '&:last-child': {
        borderRight: 'none',
      }
    },
    '& th': {
      backgroundColor: paleta.sidebar.active,
      position: 'sticky',
      top: '0px',
      zIndex: 10,
      '& span': {
        fontSize: 12,
        textTransform: 'uppercase',
      },
    },
    '& tr:last-child': {
      '& td': {
        border: 'none',
      }
    }
  },
  tablev2: {
    '& th, td': {
      borderRight: 'none',
    },
  },
  label: {
    fontWeight: 600,
    fontSize: 16,
  },
  btnAdd: {
    backgroundColor: '#F4F9FD',
    color: '#1F2129',
    textTransform: 'none',
    boxShadow: 'none',
  },
  btnAction: {
    margin: theme.spacing(0.5),
  },
  actions: {
    minWidth: 100,
    textAlign: 'center',
  },
  rowHover: {
    backgroundColor: 'initial',
    '&:hover': {
      backgroundColor: '#F4F9FD',
    },
  },
  rowError: {
    backgroundColor: `${paleta.tabla.rowError}AA`,
    '&:hover': {
      backgroundColor: `${paleta.tabla.rowError}77`,
    },
  },
  selectable: {
    cursor: 'pointer',
  },
  rowSelected: {
    backgroundColor: `${paleta.textField.backgroundColorHover}`,
    '&:hover': {
      backgroundColor: `${paleta.textField.backgroundColorHover}`,
    },
  },
}));

export default styles;
