import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  root: {
  },
  contenedorBtnConfig: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
  },
  btnConfig: {
    color: paleta.bar.primary,
  },
}));

export default styles;