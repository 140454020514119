import React from 'react';
import propTypes from 'prop-types';
import Sidebar from '../../componentes/Sidebar';
import CabeceroListado from '../../componentes/CabeceroListado';
import styles from './styles';

const Default = ({
  children,
  mostrarCabeceroListado,
  mostrarCabeceroFormulario,
  customCabecero,
  titulo,
  subtitulo,
  placeHolder,
  buscar,
  agregar,
  guardar,
  cancelar,
  style,
  mostrarLista,
  filtrosCabeceros,
}) => {
  const classes = styles();

  return (
    <div className={classes.root}>
      <Sidebar mostrarLista={mostrarLista} />
      <main className={classes.main}>
        <CabeceroListado
          titulo={titulo}
          subtitulo={subtitulo}
          placeHolder={placeHolder}
          buscar={buscar}
          agregar={agregar}
          customCabecero={customCabecero}
          esListado={mostrarCabeceroListado && !mostrarCabeceroFormulario}
          guardar={guardar}
          cancelar={cancelar}
          customStyles={style}
          filtrosCabeceros={filtrosCabeceros}
        />
        {children}
      </main>
    </div>
  );
};

Default.propTypes = {
  children: propTypes.node.isRequired,
  mostrarCabeceroListado: propTypes.bool,
  mostrarCabeceroFormulario: propTypes.bool,
  titulo: propTypes.string,
  subtitulo: propTypes.string,
  placeHolder: propTypes.string,
  buscar: propTypes.func,
  agregar: propTypes.func,
  guardar: propTypes.func,
  cancelar: propTypes.func,
  datosLista: propTypes.object,
  filtrosCabeceros: propTypes.shape({
    sitio: propTypes.bool,
    invernadero: propTypes.bool,
    nave: propTypes.bool,
  }),
};

Default.defaultProps = {
  mostrarCabeceroListado: false,
  mostrarCabeceroFormulario: false,
  titulo: "",
  subtitulo: "",
  placeHolder: "",
  buscar: null,
  agregar: null,
  guardar: null,
  cancelar: null,
  datosLista: {},
  mostrarLista: false,
  filtrosCabeceros: {
    sitio: false,
    invernadero: false,
    nave: false,
  },
};

export default React.memo(Default);
