import React from 'react';
import propTypes from 'prop-types';

import styles from './styles';

export const Switch = ({ condition, children }) => {
  if (!children) {
    return null
  }

  const arrayOfChildren = Array.isArray(children) ? children : [children]
  const cases = arrayOfChildren.filter((child) => child.props.when === condition)
  const defaultCases = arrayOfChildren.filter((child) => child.props.when === undefined)

  if (defaultCases.length > 1) {
    throw new Error('¡Solo se permite un <Switch.Default>!')
  }

  const defaultCase = defaultCases[0]

  return cases.length > 0 ? <>{cases}</> : <>{defaultCase}</>
};

Switch.Case = ({ children }) => {
  const classes = styles();
  return <div className={classes.case}>{children}</div>
};

Switch.Default = ({ children }) => {
  return <>{children}</>
};

Switch.propTypes = {
  condition: propTypes.any,
  children: propTypes.node,
};

export default Switch;
